import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import ReactHtmlParser from "html-react-parser";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
// @material-ui/icons
import Button from "../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import {getStockIDA} from "../../utils/strategy.utils";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

const styles = {
    noWarp: {
        whiteSpace: 'nowrap',
    },
    chartBox: {
        fontFamily: 'Arial, Helvetica',
        height: 'auto',
        margin: '25px 0px 5px 10px',
        padding: '0p',
        fontWeight: 500,
        border: '1px solid #000000DE',
    },
    tabletitle: {
        fontSize: '18px',
        fontWeight: 550,
        padding: '10px 15px',
        color: '#000000DE',
        backgroundColor: '#e5e0e0',
    },
    title: {
        fontSize: '15px',
        fontWeight: 400,
        padding: '10px 0px 0px 10px',
        color: '#000000DE',
    },
    error: {
        fontSize: '16px',
        fontWeight: 800,
        padding: '3px 0px',
        color: 'red',
    },
    waitingMsg: {
        fontWeight: 400,
        fontSize: 20,
        textAlign: 'center',
        marginTop: 70,
        marginLeft: -80,
    },
    submitBox: {
        height: '35px',
        marginLeft: '-25px',
        textTransform: 'capitalize',
        fontSize: '12px',
        marginTop: '-2px',
    },
    circularProgressTop: {
        marginTop: 20,
    },
    circularProgressDiv: {
        border: '1px solid 1px solid #d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
    },
    idaBox: {
        position: 'relative',
        width: '100%',
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '5px',
        margin: '0px 0px 5px 10px',
        fontWeight: '400',
        fontSize: '16px',
        fontFamily: 'Arial, Helvetica',
    },
    functionBox: {
        width: '250px',
        // marginLeft: '15px',
        display: 'inline-block',
        height: 'auto',
        padding: '5px 0px 0px 0px',
    },
    menuList: {
        marginLeft: '-15px',
        width: '230px',
        border: '1px solid #000000DE',
        marginTop: '-8px',
        padding: '5px 15px',
        borderRadius: '5px',
    },
    menuItem: {
        color: 'black',
        fontSize: '16px',
        '& .Mui-selected': {
            display: 'block',
            backgroundColor: '#EBEBEB',
        },
        fontFamily: 'Arial, Helvetica',
        color: '#000000DE',
    },
    infoButton: {
        borderRadius: 5,
        marginLeft: '35px',
        backgroundColor: '#FFF',
        border:'1px soild #E5E0E0',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        opacity: 0.4,
        zIndex: 8888,
    },
    explanation: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '400px',
        backgroundColor: 'whitesmoke',
        padding: '20px 30px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        overflow: 'auto',
    },
};

const useStyles = makeStyles(styles);

export default function IDAMarketFAView() {
    const [idaColumns, setIdaColumns] = React.useState([]);
    const [idaTable, setIdaTable] = React.useState([]);
    const [updateTime, setUpdateTime] = React.useState('');
    const [progress, setProgress] = React.useState(false);
    const [tableWidth, setTableWidth] = React.useState('auto');
    const [menuIndex, setMenuIndex] = React.useState(1);
    const [lengthIndex, setLengthIndex] = React.useState(3);
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const [showExplanation, setShowExplanation] = React.useState(false);
    const [explanationMsg, setExplanationMsg] = React.useState('');
    const [disableMenu, setDisableMenu] = React.useState(true);
    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const classes = useStyles();

    const menu_options = [
        'Select category',
        'Economy',
        'Interest Rate & Credit Risk',
        'Inflation',
        'Currency & Commodity',
        'Risk Appetite & Sentiment',
    ]

    const length_options = [
        'Select Length',
        '1',
        '5',
        '10',
        '20',
    ]

    const economy_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'calendarDate',
            sortable: true,
            width: '110px',
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Manufacturing PMI</div>
        //         ),
        //     selector: 'mpmi',
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.mpmi? row.mpmi :"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Service PMI</div>
        //         ),
        //     selector: 'spmi',
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.spmi? row.spmi :"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Chicago Activity Index</div>
                ),
            selector: 'cfnai',
            center: true,
            width: '130px',
            cell: (row) => {
                const data = row.cfnai? row.cfnai :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>GDP</div>
                ),
            selector: 'gdp',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.gdp? '$' + Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(row.gdp) + 'B':"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Housing Start</div>
                ),
            selector: 'housensa',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.housensa? row.housensa :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Unemployment Rate</div>
        //         ),
        //     selector: 'unrate',
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.unrate? row.unrate + '%' :"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Retail Sale</div>
                ),
            selector: 'rsxfs',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.rsxfs? '$' + Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(row.rsxfs) + 'M' :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Consumer Sentiment</div>
        //         ),
        //     selector: 'cncn',
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.cncn? row.cncn :"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>JOLTS</div>
        //         ),
        //     selector: 'joboff',
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.joboff? Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(row.joboff) + 'K' :"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Household Debt Payments to Income Ratio</div>
                ),
            selector: 'tdsp',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.tdsp? Intl.NumberFormat("en-US",{maximumFractionDigits: 4}).format(row.tdsp) + '%' :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Economy Score</div>
        //         ),
        //     selector: 'economyScore',
        //     center: true,
        //     width: '145px',
        //     cell: (row) => {
        //         const data = row.economyScore? row.economyScore:"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
    ];

    const interest_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'calendarDate',
            sortable: true,
            width: '110px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Financial Condition Index</div>
                ),
            selector: 'anfci',
            center: true,
            width: '125px',
            cell: (row) => {
                const data = row.anfci? row.anfci :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>10Y-2Y Yield%</div>
                ),
            selector: 't10y2y',
            center: true,
            width: '125px',
            cell: (row) => {
                const data = row.t10y2y? row.t10y2y + '%' :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>10Y-3M Yield%</div>
                ),
            selector: 't10y3m',
            center: true,
            width: '125px',
            cell: (row) => {
                const data = row.t10y3m? row.t10y3m + '%':"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Fund Rate</div>
                ),
            selector: 'dff',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.dff? row.dff + "%" :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>High Yield Credit Spread</div>
                ),
            selector: 'bamlh0a0hym2',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.bamlh0a0hym2? row.bamlh0a0hym2 :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Fed Total Assets (Less Eliminations from Consolidation)</div>
                ),
            selector: 'walcl',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.walcl? '$' + Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(row.walcl) + 'M' :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>30-year Mortgage Rate</div>
        //         ),
        //     selector: 'mortg',
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.mortg? row.mortg +"%":"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Overnight Reverse Repurchase Agreements</div>
                ),
            selector: 'rrpontsyd',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.rrpontsyd? '$' + Intl.NumberFormat("en-US",{}).format(row.rrpontsyd) + 'B' :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>M2</div>
                ),
            selector: 'wm2ns',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.wm2ns? '$' + Intl.NumberFormat("en-US",{}).format(row.wm2ns) + 'B' :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Rate Score</div>
        //         ),
        //     selector: 'ratesScore',
        //     center: true,
        //     width: '145px',
        //     cell: (row) => {
        //         const data = row.ratesScore? row.ratesScore:"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
    ];

    const inflation_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'calendarDate',
            sortable: true,
            width: '110px',
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Consumer Price Index</div>
        //         ),
        //     selector: 'ccpi',
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.ccpi? row.ccpi.toFixed(2) + "%":"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Producer Price Index</div>
        //         ),
        //     selector: 'ppi',
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.ppi? row.ppi.toFixed(2) + "%":"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Core Personal Consumption Expenditures</div>
                ),
            selector: 'pcepilfe',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.pcepilfe? row.pcepilfe.toFixed(2) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Home Price Index</div>
                ),
            selector: 'csushpinsa',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.csushpinsa? Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(row.csushpinsa) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Wage Growth</div>
        //         ),
        //     selector: 'wggr',
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.wggr? row.wggr + '%':"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>5 years expected inflation</div>
                ),
            selector: 't5yie',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.t5yie? row.t5yie + '%':"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>10 years expected inflation</div>
                ),
            selector: 't10yie',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.t10yie? row.t10yie + '%':"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Inflation Score</div>
        //         ),
        //     selector: 'inflationScore',
        //     center: true,
        //     width: '160px',
        //     cell: (row) => {
        //         const data = row.inflationScore? row.inflationScore:"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
    ];

    const commodity_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'calendarDate',
            sortable: true,
            width: '110px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>U.S. Dollar Index</div>
                ),
            selector: 'dx',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.dx? row.dx.toFixed(2) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Euro Future</div>
                ),
            selector: 'sixe',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.sixe? row.sixe.toFixed(5) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Yen Future</div>
                ),
            selector: 'sixj',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.sixj? row.sixj.toFixed(5) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Crude Oil Prices</div>
                ),
            selector: 'cl',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.cl? '$' + row.cl :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Gold Price</div>
                ),
            selector: 'gc',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.gc? '$' + Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(row.gc) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Silver Price</div>
                ),
            selector: 'si',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.si? '$' + Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(row.si) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Copper Price</div>
                ),
            selector: 'hg',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.hg? '$' + Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(row.hg) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Steel Rebar</div>
                ),
            selector: 'srr',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.srr? '$' + Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(row.srr) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Corn Future (CME)</div>
                ),
            selector: 'zc',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.zc? '$' + Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(row.zc) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Wheat Future (CME)</div>
                ),
            selector: 'zw',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.zw? '$' + Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(row.zw) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Rough Rice Future</div>
                ),
            selector: 'zr',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.zr? '$' + Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(row.zr) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Commodity Score</div>
        //         ),
        //     selector: 'commodityScore',
        //     center: true,
        //     width: '160px',
        //     cell: (row) => {
        //         const data = row.commodityScore? row.commodityScore:"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
    ];

    const risk_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'calendarDate',
            sortable: true,
            width: '110px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>CBOE Volatility</div>
                ),
            selector: 'vix',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.vix? row.vix :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>CBOE Volatility 9d</div>
                ),
            selector: 'vix9d',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.vix9d? row.vix9d :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>CBOE Volatility 3m</div>
                ),
            selector: 'vix3m',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.vix3m? row.vix3m :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>CBOE Volatility 6m</div>
                ),
            selector: 'vix6m',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.vix6m? row.vix6m :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>CBOE Volatility 1y</div>
                ),
            selector: 'vix1y',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.vix1y? row.vix1y :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>S&P 500 PE</div>
                ),
            selector: 'sppe',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.sppe? row.sppe :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>MOVE (Bond Volatility)</div>
                ),
            selector: 'move',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.move? Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(row.move) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>AUDJPY</div>
                ),
            selector: 'audjpy',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.audjpy? Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(row.audjpy) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>SP500 Earning Yield & Cash Spread</div>
                ),
            selector: 'speyc',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.speyc? Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(row.speyc) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Wilshire 5k Price Index/GDP</div>
                ),
            selector: 'will5000pr',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.will5000pr? Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(row.will5000pr) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>AAII Sentiment Bullish</div>
                ),
            selector: 'aaiiBullish',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.aaiiBullish? row.aaiiBullish.toFixed(5) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>AAII Sentiment Neutral</div>
                ),
            selector: 'aaiiNeutral',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.aaiiNeutral? row.aaiiNeutral.toFixed(5) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>AAII Sentiment Bearish</div>
                ),
            selector: 'aaiiBearish',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.aaiiBearish? row.aaiiBearish.toFixed(5) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>SPY Put Volume</div>
                ),
            selector: 'putVolume',
            sortable: true,
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.putVolume? Intl.NumberFormat("en-US",{}).format(row.putVolume) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>SPY Call Volume</div>
                ),
            selector: 'callVolume',
            sortable: true,
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.callVolume? Intl.NumberFormat("en-US",{}).format(row.callVolume) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>SPY Put/Call Ratio of Volume</div>
                ),
            selector: 'putCallVolumeRatio',
            sortable: true,
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.putCallVolumeRatio? Intl.NumberFormat("en-US",{}).format(row.putCallVolumeRatio) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>SPY Put Open Interest</div>
                ),
            selector: 'putOpenInterest',
            sortable: true,
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.putOpenInterest? Intl.NumberFormat("en-US",{}).format(row.putOpenInterest) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>SPY Call Open Interest</div>
                ),
            selector: 'callOpenInterest',
            sortable: true,
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.callOpenInterest? Intl.NumberFormat("en-US",{}).format(row.callOpenInterest) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>SPY Put/Call Ratio of Open Interest</div>
                ),
            selector: 'putCallOpenInterestRatio',
            sortable: true,
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.putCallOpenInterestRatio? Intl.NumberFormat("en-US",{}).format(row.putCallOpenInterestRatio) :"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Sentiment Score</div>
        //         ),
        //     selector: 'sentimentScore',
        //     center: true,
        //     width: '160px',
        //     cell: (row) => {
        //         const data = row.sentimentScore? row.sentimentScore:"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
    ];

    const handleClickMenuItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setMenuIndex(index);
        setAnchorEl1(null);
        getDataTable(index, length_options[lengthIndex]);
    }

    const handleLengthMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setLengthIndex(index);
        getDataTable(menuIndex, length_options[index]);
        setAnchorEl2(null);
    };

    const handleOpenInfo = (id) => {
        // console.info(" (ID: %o", detection);
        setExplanationMsg(id);
        setShowExplanation(true);
    };

    const onClose = () => {
        setShowExplanation(false);
    }

    const setLoading = (on) => {
        setProgress(on);
        setDisableMenu(on);
    }

    React.useEffect(() => {
        getDataTable(1, 10);
    }, []);

    const getDataTable = (type, length) => {
        setLoading(true);
        getStockIDA('', 19, 1, length, type)
            .then((response) => {
                if (response.data.code === 200) {
                    const data = response.data.data;
                    // setProgress(false);
                    setLoading(false);
                    if (data.idaTable !== null) {
                        setIdaTable(data.idaTable);
                        switch (type) {
                            case 1:
                                setIdaColumns(economy_columns);
                                break;
                            case 2:
                                setIdaColumns(interest_columns);
                                break;
                            case 3:
                                setIdaColumns(inflation_columns);
                                break;
                            case 4:
                                setIdaColumns(commodity_columns);
                                break;
                            case 5:
                                setIdaColumns(risk_columns);
                                break;
                            default:
                                break;
                        }
                    }
                    if (data.updateTime !== null) {
                        setUpdateTime(data.updateTime);
                    }
                } else {
                    const err = response?.data?.errMsg? response.data.errMsg : null;
                    // setProgress(false);
                    setLoading(false);
                }
            })
            .catch((error) => {
                // setProgress(false);
                console.debug(error);
                setLoading(false);
            });
    }

    return (
        <>
            <div className={classes.title} style={{marginTop: '15px'}} />
            <div className={classes.idaBox}>
                <span className={classes.functionBox}>
                    <Box
                        ml={2}
                        sx={{ width: '50%',
                            maxWidth: 200,
                        }}
                    >
                        <List
                            component="nav"
                            aria-label="Functions"
                        >
                            <ListItem
                                className={classes.menuList}
                                button
                                id="lock-button"
                                aria-haspopup="listbox"
                                onClick={handleClickMenuItem}
                            >
                                <ListItemText
                                    primary={menu_options[menuIndex]}
                                    secondary="category"
                                />
                            </ListItem>
                        </List>
                        <Menu
                            className={classes.menuItem}
                            id="function-menu"
                            anchorEl={anchorEl1}
                            open={open1}
                            onClose={handleClose1}
                            MenuListProps={{
                                'aria-labelledby': 'lock-button',
                                role: 'listbox',
                            }}
                        >
                            {menu_options.map((option, index) => (
                                <MenuItem
                                    className={classes.menuItem}
                                    key={option}
                                    disabled={index === 0 || disableMenu}
                                    selected={index === menuIndex}
                                    onClick={(event) => handleMenuItemClick(event, index)}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </span>
                <span className={classes.functionBox}>
                        <Box
                            ml={2}
                            sx={{ width: '50%',
                                maxWidth: 200,
                            }}
                        >
                            <List
                                component="nav"
                                aria-label="Functions"
                            >
                                <ListItem
                                    style={{width: '200px', marginLeft: '20px'}}
                                    className={classes.menuList}
                                    button
                                    id="lock-button"
                                    aria-haspopup="listbox"
                                    onClick={handleClickListItem}
                                >
                                    <ListItemText
                                        primary={length_options[lengthIndex]}
                                        secondary="length"
                                    />
                                </ListItem>
                            </List>
                            <Menu
                                className={classes.menuItem}
                                id="function-menu"
                                anchorEl={anchorEl2}
                                open={open2}
                                onClose={handleClose2}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                }}
                            >
                                {length_options.map((option, index) => (
                                    <MenuItem
                                        className={classes.menuItem}
                                        key={option}
                                        disabled={index === 0 || disableMenu}
                                        selected={index === lengthIndex}
                                        onClick={(event) => handleLengthMenuItemClick(event, index)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </span>
            </div>
            <hr style={{margin: '15px 0px 5px 0px'}}/>
            { progress && (
                <div className={classes.circularProgressDiv}>
                    <CircularProgress className={classes.circularProgressTop} />
                    <div className={classes.waitingMsg}>Please wait...</div>
                </div>
            )}
            <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                <div className={classes.tabletitle}>{menu_options[menuIndex]}
                    <small style={{fontSize: '15px'}}>(Based on End Of Day Data of {updateTime})</small>
                    {/*<Tooltip title="Click to show explanation">*/}
                    {/*    <IconButton className={classes.infoButton} aria-label="strategyDetail" size="small" onClick={() => handleOpenInfo (menuIndex)}>*/}
                    {/*        <InfoIcon fontSize="default" /><small style={{marginLeft:'-4px'}}>Help</small>*/}
                    {/*    </IconButton>*/}
                    {/*</Tooltip>*/}
                </div>
                <div>
                    <DataTable
                        data={idaTable}
                        columns={idaColumns}
                        fixedHeaderScrollHeight="60vh"
                        fixedHeader
                        highlightOnHover
                        striped
                        defaultSortFieldId={1}
                        noHeader={true}
                    />
                </div>
            </div>
            { showExplanation && (
                <>
                    <div className={classes.overlay} onClick={onClose}/>
                    <div className={classes.explanation} onClick={onClose}>
                        <div style={{textAlign:'center'}}><span><InfoIcon fontSize="default" /></span><span style={{verticalAlign:'top', fontSize:'18px'}}>Help</span></div>
                        {explanationMsg}
                    </div>
                </>
            )}
        </>
    );
}
