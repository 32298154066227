import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { getStrategyTrackingInfo } from '../../utils/strategy.utils';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import TrackingBacktestTableView from './TrackingBacktestTable.view';
import TrackingBacktestChartView from './TrackingBacktestChart.view.tsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItemIconWidth: {
    width: '30%',
    fontWeight: 400,
    fontSize: 16,
  },
  listItemTextWidth: {
    width: '70%',
  },
  period: {
    color: '#1890ff',
    textDecoration: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'color .3s',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '1.3em',
    position: 'relative',
    padding: '7px 0',
    border: '1px solid #ddd',
    backgroundColor: '#f5f5f5',
    borderRadius: 3,
  },
}));

export default function StrategyTrackingInfoView(props) {
  const classes = useStyles();
  const [trackingInfo, setTrackingInfo] = React.useState({
    period: '',
    trackItem: {},
    chartData: null,
  });
  const { strategyId, strategyData, benchmarkSymbol } = props;

  useEffect(() => {
    getStrategyTrackingInfo(strategyId)
      .then((response) => {
        const data = response?.data.data ?? [];
        // console.log('StrategyTrackingInfo getStrategy data length = %s', data.length);
        const lastIndex = data.length > 0 ? data.length - 1 : 0;
        // console.log('StrategyTrackingInfo getStrategy data = %o lastIndex = %s', data, lastIndex);
        let trackingPeriod = '';
        if (lastIndex >= 0) {
          const trackItem = data[lastIndex];
          // const btPortfolioId = trackItem && trackItem.portfolioId ? trackItem.portfolioId : '';
          trackingPeriod =
            data[0].tradeDate.substring(0, 4) +
            '-' +
            data[0].tradeDate.substring(4, 6) +
            '-' +
            data[0].tradeDate.substring(6);
          const beginTime = trackingPeriod;
          const endTime =
            data[lastIndex].tradeDate.substring(0, 4) +
            '-' +
            data[lastIndex].tradeDate.substring(4, 6) +
            '-' +
            data[lastIndex].tradeDate.substring(6);

          trackingPeriod +=
            ' ~ ' +
            data[lastIndex].tradeDate.substring(0, 4) +
            '-' +
            data[lastIndex].tradeDate.substring(4, 6) +
            '-' +
            data[lastIndex].tradeDate.substring(6);
          // console.log(
          //   'StrategyTrackingInfo getStrategy trackingPeriod = %s trackItem = %o chartData = %o',
          //   trackingPeriod,
          //   trackItem,
          //   data
          // );
          setTrackingInfo({ period: trackingPeriod, trackItem, chartData: data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {trackingInfo.period ? (
        <div className={classes.root}>
          <div className={classes.period}>
            <span>Period: {trackingInfo.period}</span>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TrackingBacktestTableView
                strategyType="tracking"
                trackItem={trackingInfo.trackItem}
                benchmarkSymbol={benchmarkSymbol}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TrackingBacktestChartView chartData={trackingInfo.chartData} viewTitle={false} />
            </GridItem>
          </GridContainer>
        </div>
      ) : (
        <div>
          <h4>No Tracking Info to display</h4>
        </div>
      )}
    </div>
  );
}

StrategyTrackingInfoView.propTypes = {
  strategyData: PropTypes.object,
  strategyId: PropTypes.string,
  benchmarkSymbol: PropTypes.string,
};
