import React from 'react';
import { stringUtils } from '../../utils/string.utils';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import {
  getLoginUser,
  modifyMobile,
  modifyEmail,
  modifyPassword,
  UpdateUserAccountInfo,
} from '../../utils/user.utils';
import AlertView from 'components/Alert/Alert.view';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  memberShipClass: {
    color: '#000000',
    opacity: '0.8',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: 14,
    height: 30,
  },
  tableTrHeader: {
    backgroundColor: '#f9f9f9',
    fontWeight: 600,
  },
};

const useStyles = makeStyles(styles);
export default function UserAccountInfo(props) {
  const classes = useStyles();
  const [errMsg, setErrMsg] = React.useState(false);
  // {
  //   email: data.data.email,
  //       mobile: data.data.mobile,
  //     firstName: data.data.firstName,
  //     lastName: data.data.lastName,
  //     changePassword: false,
  //     originalPassword: '',
  //     newPassword: '',
  //     repeatPassword: '',
  // }
  //
  const [values, setValues] = React.useState({
    email: props.currentUser.email,
    mobile: props.currentUser?.mobile ?? null,
    changePassword: false,
    originalPassword: '',
    newPassword: '',
    repeatPassword: '',
    firstName: props.currentUser?.firstName ?? '',
    lastName: props.currentUser?.lastName ?? '',
    riskLevel:
      props.currentUser.riskLevel && props.currentUser.riskText
        ? `R${props.currentUser.riskLevel}: ${props.currentUser.riskText}`
        : '',
  });
  const [responseData, setResponseData] = React.useState(props.currentUser);
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });

  const errorEmail = values.email === '';
  const errorFirstName = values.firstName === '' || values.firstName?.length > 48;
  const errorLastName = values.lastName === '' || values.lastName?.length > 48;
  const errorNewPassword = values.changePassword && values.newPassword === '';
  const errorOrgPassword = values.changePassword && values.originalPassword === '';
  const errorRepeatPassword = values.changePassword && values.repeatPassword !== values.newPassword;

  const fetchData = React.useCallback(() => {
    getLoginUser(null)
      .then((response) => {
        // console.log('*****UserAccountInfo.view: login user response = %o', response);
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          setResponseData(data.data);
          setValues({
            email: data.data.email,
            mobile: data.data.mobile,
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            changePassword: false,
            originalPassword: '',
            newPassword: '',
            repeatPassword: '',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  React.useEffect(() => {
    // fetchData();
    // fetchMembershipData();
    // console.log('#### useEffect changemembership = %s', changemembership);
    // if (onSuccess) {
    //   handlePaymentOnSuccess(onSuccess);
    // } else if (changemembership === 'true') {
    //   console.log('#### onCancel && changemembership');
    //   //open membership table by default
    //   setMembershipTable(true);
    // } else if (onCancel && changepayment === 'true') {
    //   history.replace({ pathname: location.pathname });
    // }
  }, []);

  const handleChange = (name) => (event) => {
    setErrMsg(false);
    console.log('#### name = %s event.target.value name= %s', name, event.target.value);
    setValues({ ...values, [name]: event.target.value });
  };

  const handleOnClickUpdateProfile = () => {
    const params: UpdateUserAccountInfo = {
      oldpassword: values.originalPassword,
      newpassword: values.newPassword,
      email: values.email,
      mobile: values.mobile,
    };
    console.debug('UserAccountInfo.view: params = %o', params);
    if (params.mobile !== responseData.mobile) {
      modifyMobile(params)
        .then((res) => {
          console.debug('UserAccountInfo.view: modifyMobile: res = %o', res);
          if (res.status === 200) {
            setOpenAlertMsg({
              alert: true,
              alertContent: 'Update account successfully',
              severity: 'success',
            });
          }
        })
        .catch((error) => {
          setOpenAlertMsg({
            alert: true,
            alertContent: error.data.errMsg,
            severity: 'error',
          });
          console.debug('Account update failed = %o', error);
        });
    }

    if (params.email !== responseData.email) {
      modifyEmail(params)
        .then((res) => {
          console.debug('UserAccountInfo.view: modifyEmail: res = %o', res);
          if (res.status === 200) {
            setOpenAlertMsg({
              alert: true,
              alertContent:
                'Verification email has been sent. Please click on the link in the verification email to enable the new email address.',
              severity: 'success',
            });
          }
        })
        .catch((error) => {
          setOpenAlertMsg({
            alert: true,
            alertContent: error.data.errMsg,
            severity: 'error',
          });
          console.error(error);
        });
    }

    if (values?.changePassword) {
      if (
        !values.originalPassword ||
        !values.newPassword ||
        values.newPassword !== values.repeatPassword
      ) {
        setOpenAlertMsg({
          alert: true,
          alertContent:
            'Original Password or New Password is empty or New Password does not match the Re-enter Password',
          severity: 'error',
        });
      } else {
        modifyPassword(params)
          .then((res) => {
            console.debug('UserAccountInfo.view: modifyPassword: res = %o', res);
            if (res.status === 200) {
              setOpenAlertMsg({
                alert: true,
                alertContent: 'Change password successfully',
                severity: 'success',
              });
            }
          })
          .catch((error) => {
            setOpenAlertMsg({
              alert: true,
              alertContent: error.data.errMsg,
              severity: 'error',
            });
            console.error(error);
          });
      }
    }
  };

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  const errorFirstNameMsg =
    values?.firstName?.length > 48
      ? 'First Name cannot exceed 48 characters'
      : stringUtils.fieldRequired;
  const errorLastNameMsg =
    values?.lastName?.length > 48
      ? 'Last Name cannot exceed 48 characters'
      : stringUtils.fieldRequired;

  return (
    <div>
      {responseData && openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      {responseData && (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>Account: {responseData.userName}</h4>
                  <p className={classes.cardCategoryWhite}>Account Settings</p>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        margin="dense"
                        id="emailAddress"
                        label="Email Address"
                        type="email"
                        required
                        value={values.email}
                        onChange={handleChange('email')}
                        error={errorEmail}
                        helperText={errorEmail ? 'This field is required' : ' '}
                        fullWidth
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        margin="dense"
                        id="phoneNumber"
                        label="Mobile Phone Number"
                        value={values.mobile}
                        onChange={handleChange('mobile')}
                        fullWidth
                        inputProps={{
                          autoComplete: 'off',
                          defaultValue: '',
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        margin="dense"
                        id="firstName"
                        label="First Name"
                        value={values.firstName}
                        onChange={handleChange('firstName')}
                        error={errorFirstName}
                        helperText={errorFirstName ? errorFirstNameMsg : ' '}
                        fullWidth
                        disabled
                        // inputProps={{ readOnly: true }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        margin="dense"
                        id="lastName"
                        label="Last Name"
                        value={values.lastName}
                        onChange={handleChange('lastName')}
                        error={errorLastName}
                        helperText={errorLastName ? errorLastNameMsg : ' '}
                        fullWidth
                        disabled
                        // inputProps={{ readOnly: true }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        margin="dense"
                        id="riskLevel"
                        label="Risk Level"
                        value={values.riskLevel}
                        fullWidth
                        disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <hr></hr>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Checkbox
                        style={{ marginLeft: '-12px' }}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={(e) => {
                          console.log('ChangePassword = ' + e.target.checked);
                          setErrMsg(false);
                          setValues({ ...values, changePassword: e.target.checked });
                          if (!e.target.checked) {
                            setValues({
                              ...values,
                              originalPassword: '',
                              newPassword: '',
                              repeatPassword: '',
                            });
                          }
                        }}
                      />
                      Change Password
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        id="original-password"
                        disabled={!values.changePassword}
                        label="Original Password"
                        type="password"
                        required
                        value={values.originalPassword}
                        onChange={handleChange('originalPassword')}
                        error={errorOrgPassword}
                        helperText={errorOrgPassword ? stringUtils.fieldRequired : ' '}
                        fullWidth
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        id="password"
                        disabled={!values.changePassword}
                        label="New Password"
                        type="password"
                        required
                        value={values.newPassword}
                        onChange={handleChange('newPassword')}
                        error={errorNewPassword}
                        helperText={errorNewPassword ? 'This field is required' : ' '}
                        fullWidth
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        id="repeat-password"
                        disabled={!values.changePassword}
                        label="Re-enter Password"
                        type="password"
                        required
                        value={values.repeatPassword}
                        onChange={handleChange('repeatPassword')}
                        error={errorRepeatPassword}
                        helperText={errorRepeatPassword ? 'Passwords do not match' : ' '}
                        fullWidth
                      />
                    </GridItem>
                  </GridContainer>
                  {/*<GridContainer>*/}
                  {/*  <GridItem xs={12} sm={12} md={12}>*/}
                  {/*    <InputLabel style={{ color: '#AAAAAA' }}>Documents</InputLabel>*/}
                  {/*    <Checkbox*/}
                  {/*      checked={true}*/}
                  {/*      tabIndex={-1}*/}
                  {/*      checkedIcon={<Check className={classes.checkedIcon} />}*/}
                  {/*      icon={<Check className={classes.uncheckedIcon} />}*/}
                  {/*      classes={{*/}
                  {/*        checked: classes.checked,*/}
                  {/*        root: classes.root,*/}
                  {/*      }}*/}
                  {/*    />{' '}*/}
                  {/*    User Agreement ( signed )*/}
                  {/*  </GridItem>*/}
                  {/*</GridContainer>*/}
                </CardBody>
                <CardFooter>
                  <Button
                    disabled={
                      values.email === responseData.email &&
                      values.firstName === responseData.firstName &&
                      values.lastName === responseData.lastName &&
                      values.mobile === responseData.mobile &&
                      !values.originalPassword &&
                      values.newPassword === values.repeatPassword
                    }
                    onClick={handleOnClickUpdateProfile}
                    color="info"
                    className={classes.label}
                  >
                    Update Account
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </div>
  );
}
