import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import AqmStrategyBasicInfoView from "./AqmStrategyBasicInfo.view";
import {
  getStrategy,
  canStrategyBackTest,
  strategyBackTestDateRange,
} from '../../utils/strategy.utils';
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import { stringUtils } from "../../utils/string.utils";
import {getAQMStrategyList} from '../../utils/strategy.utils';

const styles = {
  portfolioNameFont: {
    fontWeight: 400,
  },
  waitingMsg: {
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 70,
    marginLeft: -80,
  },
  circularProgressTop: {
    marginTop: 20,
  },
  circularProgressDiv: {
    border: '1px solid 1px solid #d3d3d3',
    display: 'flex',
    justifyContent: 'center',
    height: 100,
  },
};

const useStyles = makeStyles(styles);

export default function AqmStrategyDetailsView() {
  const classes = useStyles();
  const {state} = useLocation();
  const [responseData, setResponseData] = React.useState(null);
  const strategyId = state?.strategyId ?? '';
  const strategyName = state?.strategyName ?? '';
  const [strategyData, setStrategyData] = React.useState(null);

  //console.log('AQM Detail:', state.strategyDetail);
  if (strategyData === null) {
    getAQMStrategyList()
        .then((response) => {
          const data = response && response?.data ? response.data : null;
          if (data.code === 200) {
            data.data.aqms.forEach((aqm: any) => {
               if (aqm.id === strategyId) {
                setStrategyData(aqm);
              }
            });
          }
        })
        .catch((error) => {
          console.info(error);
        });
  }

  useEffect(() => {
  }, []);

  const title = '->AQM Account Details ';

  return (
    <div className="mainPagePanel">
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ marginTop: 10 }}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{ float: 'left' }}>
                      <h4>
                        <Link to="/admin/aqmModelAccount">{stringUtils.aqmModelAccounts}</Link>
                        {title}
                        <span className={classes.portfolioNameFont}>
                          {`- ${strategyName}`} {`(ID: ${strategyId})`}
                        </span>
                      </h4>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: 'Basic',
                  tabIcon: ViewModuleIcon,
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        {strategyData && <AqmStrategyBasicInfoView strategyData={strategyData} />}
                      </GridItem>
                    </GridContainer>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
