import {
  defaultFont,
  primaryColor,
  dangerColor,
  grayColor,
} from 'assets/jss/material-dashboard-react.js';
import tooltipStyle from 'assets/jss/material-dashboard-react/tooltipStyle.js';
import checkboxAdnRadioStyle from 'assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js';
const strategyCardStyle = {
  ...tooltipStyle,
  ...checkboxAdnRadioStyle,
  table: {
    marginBottom: '0',
    overflow: 'visible',
  },
  tableRow: {
    position: 'relative',
    borderBottom: '1px solid ' + grayColor[5],
  },
  tableActions: {
    display: 'flex',
    border: 'none',
    padding: '12px 8px !important',
    verticalAlign: 'middle',
  },
  tableCell: {
    ...defaultFont,
    padding: '8px',
    verticalAlign: 'middle',
    border: 'none',
    lineHeight: '1.42857143',
    fontSize: '14px',
  },
  tableCellRTL: {
    textAlign: 'right',
  },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0',
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px',
  },
  edit: {
    backgroundColor: 'transparent',
    color: primaryColor[0],
    boxShadow: 'none',
  },
  close: {
    backgroundColor: 'transparent',
    color: dangerColor[0],
    boxShadow: 'none',
  },
  header: {
    backgroundColor: 'transparent',
    color: dangerColor[0],
    boxShadow: 'none',
    textAlign: 'center',
  },
  img: {
    backgroundColor: 'transparent',
    objectFit: 'fill',
    width: '100%',
  },
  titleValue: {
    fontWeight: 400,
  },
  chartStrategyLegned: {
    marginBottom: '3px',
    display: 'inline-block',
    width: '14px',
    height: '14px',
    verticalAlign: 'middle',
    marginRight: '5px',
  },
  chartStrategySpan: {
    backgroundColor: '#7cb5ec',
  },
  chartBenchmarkSpan: {
    backgroundColor: '#f7a35c',
  },
  strategyCardTitle: {
    paddingRight: '10px',
    fontWeight: 600,
  },
  strategyHr: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    border: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  strategyNameLink: {
    color: '#1890ff',
    fontWeight: 600,
  },
  portfolioTitle: {
    color: '#9c27b0',
    margin: '0',
  },
  marginLeft100: {
    marginLeft: '100px',
  },
  marginLeft80: {
    marginLeft: '80px',
  },
  marginLeft60: {
    marginLeft: '60px',
  },
  tableTrOdd: {
    backgroundColor: '#f9f9f9',
    fontWeight: 400,
  },
  tableTrEven: {
    fontWeight: 400,
  },
  cardIconPanel: {
    float: 'right',
    width: '25%',
  },
  aqmIconPanel: {
    float: 'right',
    width: 95,
    marginRight: -15,
    marginTop: -10,
  },
  myAQMIconPanel: {
    float: 'right',
    width: 125,
    marginRight: -35,
    marginTop: -10,
  },
  iconPadding: {
    paddingRight: 4,
    paddingLeft: 4,
    paddingBottom: 4,
  },
  warning: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    width: '400px',
    backgroundColor: 'whitesmoke',
    padding: '10px',
    borderRadius: '5px',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
    overflow: 'auto',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'black',
    opacity: 0.4,
    zIndex: 8888,
  },
  warningButton: {
    height: '35px',
    width: '100px',
    marginLeft: '55px',
    marginTop: '5px',
    textTransform: 'capitalize',
    fontSize: '12px',
  },
  error: {
    fontSize: '16px',
    fontWeight: 800,
    padding: '3px 0px',
    margin:'0px 15px 5px 15px',
    color: 'blue',
  },
};
export default strategyCardStyle;
