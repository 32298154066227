import React from 'react';
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import PrivacyPolicyPdf from "../../assets/help/Privacy Policy.pdf";

const styles = {
    dialogCenter: {
        margin: 'auto',
        textAlign: 'center',
    },
    label: {
        textTransform: 'capitalize',
        fontSize: 14,
        marginTop: 25,
        marginLeft: 20,
    },
    dialogContentTextWidth: {
        margin: 'auto',
        textAlign: 'center',
        width: 500,
    },
    errorMsg: {
        color: 'red',
    },
};
const useStyles = makeStyles(styles);

export default function PrivacyPolicyView() {
    const classes = useStyles();
    const fullScreen = true;

    return (
        <>

            <Dialog
                fullScreen={fullScreen}
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialogCenter}
            >
                <DialogTitle id="validate-email-dialog-title">{'GoPIPA Privacy Policy'}</DialogTitle>
                <DialogContent dividers id="validate-email--dialog-description">
                    <DialogContentText id="validate-email--dialog-description">
                        <div style={{ marginTop: 25 }}></div>
                        <iframe id="UserAgreement"
                                title="User Agreement"
                                width="1080"
                                height="675"
                                marginwidth="0"
                                src={PrivacyPolicyPdf}
                        >
                        </iframe>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );

}
