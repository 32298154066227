import React from 'react';
import moment from 'moment';
import { environment } from 'environments/environment';
import { getUserName } from '../../utils/common';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import {
  ForumTopic,
  TopicSearch,
  getForumToken,
  forumTopicType,
  forumTopicSearch,
  saveForumTopic,
  commentOnSearchTopic,
  editOnSearchTopic,
  deleteForumTopic,
  flagForumTopic,
  deleteForumCommentTopic,
} from '../../utils/forum.utils';
import PropTypes from 'prop-types';
import CardBody from '../../components/Card/CardBody';
import Card from '../../components/Card/Card';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { defaultFont, grayColor } from '../../assets/jss/material-dashboard-react';
import NewEditIssueEditorDialog from '../../components/Dialog/NewEditIssueEditorDialog';
import CommentIssueEditorDialog from '../../components/Dialog/CommentIssueEditorDialog';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog.view';

const styles = (theme) => ({
  label: {
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + ' !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
  },
  selectInput: {
    width: '100%',
    marginTop: 5,
  },
  listItemIconWidth: {
    width: '30%',
    fontWeight: 400,
    fontSize: 16,
  },
  listItemTextWidth: {
    width: '70%',
  },
  listItemHeight: {
    paddingTop: 4,
    paddingBottom: 4,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(styles);

export default function ForumView() {
  const classes = useStyles();
  const currentUser = getUserName();
  const sortItems = [
    { key: 'updateTime', value: 'Sort By Newest Order' },
    { key: 'starNum', value: 'Sort By Number Of Collections' },
    { key: 'viewNum', value: 'Sort By Number Of Views' },
  ];
  let pageNo: number = 1,
    perPageNum: number = 200,
    editTopicTypeId: string = '';

  const [listCount, setListCount] = React.useState(0);
  const [topics, setTopics] = React.useState([]);
  const [openComment, setOpenComment] = React.useState({ open: false, item: null });
  const [openIssue, setOpenIssue] = React.useState({ open: false, item: null });
  const [forumType, setForumType] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [myValue, setMyValue] = React.useState(null);
  const [mySort, setMySort] = React.useState('updateTime');
  const [searchValue, setSearchValue] = React.useState('');
  const [admin, setAdmin] = React.useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState({ open: false, item: null });

  const fetchData = React.useCallback(() => {
    forumTopicType()
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        setAdmin(data.admin);
        if (data.code === 0) {
          const topicTypeData = data.data;
          const typeList: any[] = [];
          topicTypeData.forEach((topicType: any) => {
            typeList.push({
              id: topicType.id,
              name: topicType.en_name,
              isOpened: topicType.isOpened,
            });
            setForumType(typeList);
            if (!editTopicTypeId && topicType.isOpened) {
              editTopicTypeId = topicType.id;
            }
          });
          getTopicSearch(null, null, null, null);
        }
      })
      .catch((error) => {
        console.debug('Forum.view: forumTopicType failed = %o', error);
      });
  }, []);

  React.useEffect(() => {
    if (!localStorage.getItem(environment.forumTokenKey)) {
      getForumToken()
        .then((response) => {
          const data = response && response?.data ? response.data : null;
          if (response.status === 200) {
            localStorage.setItem(environment.forumTokenKey, data.token);
            fetchData();
          }
        })
        .catch((error) => {
          console.debug('Forum.view: getForumToken failed = %o', error);
        });
    } else {
      fetchData();
    }
  }, [fetchData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      // ALL
      setMyValue(null);
      getTopicSearch(newValue, mySort, null, searchValue);
    } else {
      getTopicSearch(newValue, mySort, myValue, searchValue);
    }
  };

  const handleMyValueChange = (event, newValue) => {
    setMyValue(newValue);
    getTopicSearch(value, mySort, newValue, searchValue);
  };

  const handleMySortChange = () => (event) => {
    setMySort(event.target.value);
    getTopicSearch(value, event.target.value, myValue, searchValue);
  };

  const handleCloseNewIssue = () => {
    setOpenIssue({ open: false, item: null });
    if (openIssue.item) {
      getTopicSearch(value, mySort, myValue, searchValue);
    }
  };

  const handleOpenAddNewIssue = () => {
    setOpenIssue({ open: true, item: null });
  };

  const getTopicSearch = (searchTopic, orderBy, filterBy, searchText) => {
    const param: TopicSearch = {
      pageNo,
      perPageNum,
    };
    console.debug(
      '!!!! getTopicSearch searchTopic = %s orderBy = %s filterBy = %s searchText = %s',
      searchTopic,
      orderBy,
      filterBy,
      searchText
    );

    if (!filterBy && filterBy === 0) {
      param.filter_by = 'myComment';
    }
    if (searchTopic || orderBy || filterBy || searchText) {
      if (orderBy) {
        param.order_by = orderBy;
      }

      if (searchTopic) {
        if (searchTopic === 1) {
          param.essence = true;
        } else {
          param.type_id = forumType[searchTopic - 2].id;
        }
        // param.filter_by = myComment, myStar, myTopic
        if (filterBy) {
          param.filter_by = filterBy === 1 ? 'myStar' : 'myTopic';
        }
      } else {
        if (filterBy) {
          param.filter_by = filterBy === 1 ? 'myStar' : 'myTopic';
        } else if (!filterBy && filterBy === 0) {
          param.filter_by = 'myComment';
        }
      }
      if (searchText) {
        param.keyword = searchText;
      }
    }

    console.debug('Forum.view: forumTopicSearch param = %o', param);
    forumTopicSearch(param)
      .then((datas) => {
        const searchData = datas && datas?.data ? datas.data : null;
        if (searchData.code === 0) {
          setTopics(searchData.data);
          setListCount(searchData.count);
          console.debug(
            'Forum.view: forumTopicSearch topics = %o listCount = %s',
            topics,
            listCount
          );
        }
      })
      .catch((error) => {
        console.debug('Forum.view: forumTopicSearch: failed = %o', error);
      });
  };

  const handleAddNewIssue = (content, type, subject, op, topic_id) => {
    console.debug(
      '### content = %s type = %s subject = %s topic_id = %s',
      content,
      type,
      subject,
      topic_id
    );
    const param: ForumTopic = {
      content,
      op,
      title: subject,
      type_id: type,
    };

    if (op === 'update') {
      param.topic_id = topic_id;
    }

    saveForumTopic(param)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 0) {
          setOpenIssue({ open: false, item: null });
          getTopicSearch(value, mySort, myValue, searchValue);
        }
      })
      .catch((error) => {
        console.debug('Forum.view: getForumToken failed = %o', error);
      });
  };

  const onClickCommentIssue = (topic_id: string) => {
    commentOnSearchTopic(topic_id)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 0) {
          setOpenComment({ open: true, item: data.data });
        }
      })
      .catch((error) => {
        console.debug('Forum.view: commentOnSearchTopic failed = %o', error);
      });
  };

  const onClickEditIssue = (item) => {
    editOnSearchTopic(item.topic_id)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 0) {
          setOpenIssue({ open: true, item: data.data });
        }
      })
      .catch((error) => {
        console.debug('Forum.view: onClickEditIssue failed = %o', error);
      });
  };

  const onClickDeleteIssue = (item) => {
    setDeleteConfirmation({ open: true, item });
  };

  const handleDeleteTopicClick = (confirm) => {
    if (confirm) {
      const param: ForumTopic = {
        op: 'delete',
        topic_id: deleteConfirmation.item.topic_id,
      };

      deleteForumTopic(param)
        .then((response) => {
          const data = response && response?.data ? response.data : null;
          if (data.code === 0) {
            setDeleteConfirmation({ open: false, item: null });
            getTopicSearch(value, mySort, myValue, searchValue);
          }
        })
        .catch((error) => {
          console.debug('Forum.view: onClickDeleteIssue failed = %o', error);
        });
    } else {
      setDeleteConfirmation({ open: false, item: null });
    }
  };

  const onClickFlagIssue = (item) => {
    flagForumTopic(item.topic_id)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 0) {
          getTopicSearch(value, mySort, myValue, searchValue);
        }
      })
      .catch((error) => {
        console.debug('Forum.view: onClickFlagIssue failed = %o', error);
      });
  };

  const handleSearchIssue = () => {
    getTopicSearch(value, mySort, myValue, searchValue);
  };

  const handleSearchTextChange = () => (event) => {
    setSearchValue(event.target.value);
  };

  const handleCloseCommentIssue = () => {
    setOpenComment({ open: false, item: null });
    getTopicSearch(value, mySort, myValue, searchValue);
  };

  const handleOnRefreshCommentIssue = (topic_id: string) => {
    onClickCommentIssue(topic_id);
  };

  const handleDeleteCommentIssue = (topic_id: string, answer_id: string) => {
    deleteForumCommentTopic(topic_id, answer_id)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 0) {
          onClickCommentIssue(topic_id);
        }
      })
      .catch((error) => {
        console.debug('Forum.view: handleDeleteCommentIssue failed = %o', error);
      });
  };

  const getEssence = (isEssence: boolean) => {
    return isEssence ? 'Unflag' : 'Flag';
  };

  return (
    <div>
      {forumType && deleteConfirmation.open && (
        <ConfirmationDialog
          dlgData={'Yes'}
          handleDeleteDialogClick={handleDeleteTopicClick}
          open={deleteConfirmation.open}
          dlgTitle={'Delete Topic: ' + deleteConfirmation.item.title}
          dlgContentText={'Are you sure you want to delete this topic?'}
        />
      )}
      {forumType && openIssue.open && (
        <NewEditIssueEditorDialog
          open={openIssue.open}
          topicItem={openIssue.item}
          onNewIssueEditor={handleAddNewIssue}
          onClose={handleCloseNewIssue}
          forumType={forumType}
          aria-labelledby="responsive-dialog-title"
        ></NewEditIssueEditorDialog>
      )}
      {forumType && openComment.open && (
        <CommentIssueEditorDialog
          open={openComment.open}
          topicItem={openComment.item}
          onCommentIssueEditor={onClickCommentIssue}
          onDeleteCommentIssue={handleDeleteCommentIssue}
          onClose={handleCloseCommentIssue}
          onRefreshCommentIssue={handleOnRefreshCommentIssue}
          forumType={forumType}
          isAdmin={admin}
          aria-labelledby="responsive-dialog-title"
        ></CommentIssueEditorDialog>
      )}
      {forumType && (
        <div className="mainPagePanel">
          <Card style={{ marginBottom: 'unset', marginTop: 10, minHeight: 800 }}>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <AppBar
                    position="static"
                    style={{ boxShadow: 'unset', color: '#1890ff', backgroundColor: '#fff' }}
                  >
                    <Tabs value={value} onChange={handleChange} aria-label="forumn Tabs">
                      <Tab label="All" {...a11yProps(0)} style={{ minWidth: 100 }} />
                      <Tab label="Suggested" {...a11yProps(1)} style={{ minWidth: 100 }} />
                      <Tab label="Q&A" {...a11yProps(2)} style={{ minWidth: 100 }} />
                      <Tab label="Research" {...a11yProps(3)} style={{ minWidth: 100 }} />
                      <Tab label="Info" {...a11yProps(4)} style={{ minWidth: 100 }} />
                      <Tab label="Notice" {...a11yProps(5)} style={{ minWidth: 100 }} />
                    </Tabs>
                  </AppBar>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <div>
                    <TextField
                      label=""
                      value={searchValue}
                      onChange={handleSearchTextChange()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton onClick={() => handleSearchIssue()}>
                              <SearchIcon color="primary" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <div>
                    <Tooltip title="Add New Issue">
                      <IconButton aria-label="add" onClick={() => handleOpenAddNewIssue()}>
                        <AddIcon color="primary"></AddIcon>
                        <span style={{ fontSize: 18, marginLeft: 5, color: '#3f51b5' }}>
                          New Issue Subject
                        </span>
                      </IconButton>
                    </Tooltip>
                  </div>
                </GridItem>
              </GridContainer>
              <hr></hr>
              <div style={{ paddingLeft: 20 }}>
                <GridContainer>
                  <GridItem xs={7} sm={7} md={7}>
                    <div>
                      <GridContainer>
                        <GridItem
                          xs={4}
                          sm={4}
                          md={4}
                          style={{ fontSize: 16, fontWeight: 400, marginTop: 10, color: 'black' }}
                        >
                          <span>{listCount}</span> Subject(s) in Common
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <FormControl className={classes.selectInput}>
                            <Select
                              labelId="sort-select-label"
                              id="sort-status-select"
                              value={mySort}
                              onChange={handleMySortChange()}
                            >
                              {sortItems.map((item) => (
                                <MenuItem key={item.key} value={item.key}>
                                  {item.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </GridItem>
                  <GridItem xs={5} sm={5} md={5} style={{ float: 'right' }}>
                    <AppBar
                      position="static"
                      style={{ boxShadow: 'unset', color: '#1890ff', backgroundColor: '#fff' }}
                    >
                      <Tabs
                        value={myValue}
                        onChange={handleMyValueChange}
                        aria-label="forum my tabs"
                      >
                        <Tab label="My Comments" {...a11yProps(0)} />
                        <Tab label="My Collections" {...a11yProps(1)} />
                        <Tab label="My Subjects" {...a11yProps(2)} />
                      </Tabs>
                    </AppBar>
                  </GridItem>
                </GridContainer>
                {topics.map((item) => (
                  <div className="subject">
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <span
                          onClick={() => {
                            onClickCommentIssue(item.topic_id);
                          }}
                          className="subject-title"
                        >
                          {item.title}
                        </span>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={3} sm={3} md={3}>
                        <div>
                          <span className="font-desc">Author: {item.user}</span>
                          {admin && (
                            <span
                              className="operateItem"
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                onClickFlagIssue(item);
                              }}
                            >
                              {getEssence(item.isEssence)}
                            </span>
                          )}
                        </div>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <div className="font-desc">
                          Share Time: {moment(item.createTime).tz("America/New_York").format('lll')}
                        </div>
                      </GridItem>
                      <GridItem xs={1} sm={1} md={1}>
                        <div>
                          {currentUser === item.user && (
                            <span
                              className="operateItem"
                              onClick={() => {
                                onClickEditIssue(item);
                              }}
                            >
                              Edit
                            </span>
                          )}
                          {admin && (
                            <span
                              className="operateItem"
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                onClickDeleteIssue(item);
                              }}
                            >
                              Delete
                            </span>
                          )}
                        </div>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}>
                        <div className="">
                          <span className="font-desc" style={{ marginRight: 15 }}>
                            <i className="fa fa-eye topic-view"></i> {item.viewNum}
                          </span>
                          <span className="font-desc">
                            <i className="fa fa-star-o topic-star"></i> {item.starNum}
                          </span>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
}
