import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import { defaultFont, grayColor } from '../../assets/jss/material-dashboard-react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import E from 'wangeditor';
import $ from "jquery";

const styles = {
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + ' !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
  },
  selectInput: {
    width: '100%',
    marginTop: 20,
  },
  dialogActionCenter: {
    margin: 'auto',
  },
};
const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: 'none' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

let editor = null;

export default function CommentEditorDialog(props) {
  const theme = useTheme();
  const commentIssueEditorId = 'commentIssueEditorId';
  const classes = useStyles();
  const { commentItem } = props;

  const [content, setContent] = React.useState('');
  const fullScreen = true; //useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    // 注：class写法需要在componentDidMount 创建编辑器
    const timer = setTimeout(() => {
      editor = new E('#' + commentIssueEditorId);
      editor.config.onchange = (newHtml) => {
        setContent(newHtml);
      };
      /**一定要创建 */
      editor.create();
      $('#' + commentIssueEditorId + ' .w-e-text-container').height(120);
      return () => {
        // 组件销毁时销毁编辑器  注：class写法需要在componentWillUnmount中调用
        editor.destroy();
      };
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const onClickCommentIssue = () => {
    props.onCommentEditor(commentItem, content);
  };

  const updateCommentContent = (id: string, content: string) => {
    const timer = setTimeout(() => {
      $('#' + id).html(content);
    }, 500);
    return () => clearTimeout(timer);
  };

  const dialogTitle = 'Comment Issue';

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
        style={{
          paddingLeft: 120,
          paddingRight: 120,
          paddingTop: 50,
          paddingBottom: 50,
          height: 500,
        }}
      >
        <DialogTitle style={{ maxWidth: 1050 }} id="responsive-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <span className="subject-title" id="commentItemId">
                {updateCommentContent('commentItemId', commentItem.content)}
              </span>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div id={commentIssueEditorId} style={{ marginTop: 10, marginBottom: 20 }}></div>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActionCenter}>
          <Button disabled={!content} onClick={onClickCommentIssue} color="primary">
            Submit
          </Button>
          <Button autoFocus onClick={props.onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CommentEditorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCommentEditor: PropTypes.func,
  commentItem: PropTypes.object,
};
