import iPapaIBSetupPdf from '../../assets/help/Interactive Brokers Account Setup Guide.pdf';
import howToUSeAQMPdf from '../../assets/help/How to use iPIPA AQM services.pdf';
import iPapaFQAPdf from '../../assets/help/GoPIPA Frequently Asked Questions 03262024.pdf';
import iPapaUserTutorialPdf from '../../assets/help/iPIPA Alph User Tutorial.pdf';
export const userSeminarVideo = 'https://www.dropbox.com/scl/fi/857jy4av6bdds2ze7rvde/iPIPA-User-Seminar-10212023.mp4?rlkey=s7nvo5luucffx1dsscsie06c8&dl=0';
export const userTutorialVideo = 'https://www.dropbox.com/scl/fi/ge0zj1povg1bm4ov2sz3q/iPIPA-User-Tutorial-12212023.mp4?rlkey=ko5kkfksqhbp67sppys94su9j&dl=0';

export const tutorialText: any = {
    //summary
    summary1: 'Step 1. Apply and Configure Your Interactive Brokers (IB) Account',
    summary2: 'Step 2. Link Your IB Account with Your iPIPA Account',
    summary3: 'Step 3. Complete Your Risk Assessment',
    summary4: 'Step 4. Subscribe to AQS and/or AQM with Your IB Account or iPIPA Paper Account',
    summary5: 'Step 5. Monitor the Performance of your AQS Portfolios and/or AQM Accounts',

    title: 'How to Use iPIPA',
    step1Subtitle: 'Step 1. Apply and Configure Your Interactive Brokers (IB) Account',
    step2Subtitle: 'Step 1. Apply and Configure Your Interactive Brokers (IB) Account',
    step3Subtitle: 'Step 2. Link Your IB Account with Your iPIPA Account',
    step4Subtitle: 'Step 2. Link Your IB Account with Your iPIPA Account',
    step5Subtitle: 'Step 2. Link Your IB Account with Your iPIPA Account',
    step6Subtitle: 'Step 3. Complete Your Risk Assessment',
    step7Subtitle: 'Step 3. Complete Your Risk Assessment',
    step8Subtitle: 'Step 4. Subscribe to AQS and/or AQM with Your IB Account or iPIPA Paper Account',
    step9Subtitle: 'Step 4. Subscribe to AQS and/or AQM with Your IB Account or iPIPA Paper Account',
    step10Subtitle: 'Step 4. Subscribe to AQS and/or AQM with Your IB Account or iPIPA Paper Account',
    step11Subtitle: 'Step 4. Subscribe to AQS and/or AQM with Your IB Account or iPIPA Paper Account',
    step12Subtitle: 'Step 4. Subscribe to AQS and/or AQM with Your IB Account or iPIPA Paper Account',
    step13Subtitle: 'Step 4. Subscribe to AQS and/or AQM with Your IB Account or iPIPA Paper Account',
    step14Subtitle: 'Step 4. Subscribe to AQS and/or AQM with Your IB Money Account',
    step15Subtitle: 'Step 5. Monitor the Performance of Your AQS Portfolios and/or AQM Accounts',
    step16Subtitle: 'Step 5. Investment Summary',
    step17Subtitle: 'To get more Info on iPIPA services, please click the following links:',

    //content
    step1Text: '1.1. Apply for an Interactive Brokers account by going to <a href="http://www.interactivebrokers.com/" target="_blank" rel="noreferrer" style="color: #00acc1">www.interactivebrokers.com</a>.<br>1.2. Disable Dividend Reinvestment plan (DRIP): Go to IBKR and click on the "User" menu (head and shoulders icon in the top right corner), followed by "Settings". Under "Trading", click "Dividend Election" and select "Receive Cash".<br>1.3. Turn on TWS & All Statemens options for "Users & Access Rights" under "Settings".<br>1.4. Switch from IB Lite to IBKR Pro (if applicable): Click on Settings followed by "Account Configuration." Click on "IBKR Pricing Plan" and wait for the IBKR Pricing Plan screen opens up. Select IBKR PRO, then click "CONTINUE" and follow the prompts.<br><br>Note: See the complete setup instructions in <a href="' + encodeURI(iPapaIBSetupPdf) + '" target="_blank" rel="noreferrer" style="color: #00acc1">How to set up IB account</a> document in the support page.<br>',
    step2Text: 'In order to reduce the AQM turnover ratio for the tax benefit, we plan to use “Leveraged & Inverse ETF” to hedge the positions instead of selling them when reducing the market exposure. To ensure the AQM copy trades are successful, our users need to have the required IB trading permission. The following are the steps to configure this:<br>1.5 Log in to IB client portal<br>1.6 Click on “Settings” (right top corner)<br>1.7 Find “Trading”, click on “Trading Permission”<br>1.8 Find “Complex or Leveraged Exchange Traded Product”, click on “Request”<br>1.9 Sign your name and submit.',
    step3Text: '2.1. Go to “My Account” from the sidebar menu.<br>2.2. Select the “Broker Account” tab, then click the “Add IB Account” button to link.',
    step4Text: '2.3. Fill in your IB account info and assign a nickname to this account in the pop-up dialog box. You will be redireted to IB site after press the "Apply" button.',
    step5Text: '2.4. After you see IB’s log in page, select your account type, Live (Money trade) or Paper. Enter your account’s Username and Password correctly (Note: this is the username which iPIPA uses to interact with IB). Please note that you will be asked to enter an SMS code if your account has two-factor authentication enabled. <br>2.5. Read IB user agreement and sign your name by typing your name. Press “I Agree” button.<br>2.6. Your account information will display in the "Broker Account" table if authentication succeeds.',
    step6Text: '3.1. Each AQM model account or AQS strategy is categorized by 5 risk levels. You need to complete Risk Level Assessment Questionnaire before subscribing to any AQM model accounts or AQS strategies. You can only subscribe AQM model accounts or AQS strategies that are the same or below your risk level. For example, if your risk level is R3, you can only subscribe AQM model accounts with R1,R2,R3. Users need to redo the user risk assessment at least once a year or whenever their financial conditions change.<br>',
    step7Text: '3.2. Access to Risk Level Assessment Questionnaire from "My Account" then click on the "User Risk Assessment" tab. Or go to the <a href="/admin/useraccount?userAssessment=no" target="_blank" rel="noreferrer" style="color: #00acc1">User Risk Assessment</a> page directly.<br>3.3. Answers all ten questions and click on the "Submit" button to complete.<br>3.4. Once you complete the user risk assessment, iPIPA will determine your risk level.<br><br>Note: Users can redo the risk assessement at any time as needed.',
    step8Text: 'Every user receives $1M iPIPA paper money to try out iPIPA. You could use it to subscribe to any AQM model accounts or AQS strategies you want. You can learn more about AQM Service on <a href="' + encodeURI(howToUSeAQMPdf) + '" target="_blank" rel="noreferrer" style="color: #00acc1">How to use iPIPA AQM services</a> on the support page.<br>4.1. Go to "AQM Service" or "AQS Service" from the sidebar menu.<br>4.2. You can only subscribe to AQM model accounts or AQS strategies with risk levels that are the same or below your risk level.<br>4.3. Subscribe by clicking on the "+".',
    step9Text: '4.4. Fill "Account Name" field as the nickname of your subscription. The "Trade Type" should be Paper Trade. "Trading Account" should be your iPIPA paper account (Default Paper Trade Account) or your IB paper account.<br>4.5. Press the "Submit" button to complete the subscription.',
    step10Text: 'Need help finding good strategies to invest? Here are the tools you can use:<br><span style="font-weight: 900;">Comparing Tracking:</span>Tracking data is the strategy performance after it is online. You can see the strategies\' recent performance with tracking data.<br>4.6. Go to AQS Service, click on "Comparing Tracking" tab.<br>4.7. Click on any table header to sort the table in ascending or descending order.<br>4.8. CAGR (Compound Annual Growth Rate) value provides useful insignt about the strategy\'s potenial. GAGR is a financial metric that calculates the average annual growth rate of an investment over a certain period of time, assuming that the investment has grown at a constant rate each year.',
    step11Text: '<span style="font-weight: 900;">Comparing Backtest:</span> Backtesting is a process of evaluating the performance of an investment strategy or trading system by applying it to historical market data. iPIPA simulated the AQS investment strategy on past market conditions to see how it would have performed in real-world scenarios.<br>4.9. Go to AQS Service, click on "Comparing Backtest" tab.<br>4.10. Look at the backtest result for selected time period. Backtesting can be a useful tool in evaluating the historical performance of an investment strategy.',
    step12Text: '4.11. iPIPA manages the AQM model accounts based on investment objectives, market conditions and strategy performance. Before you subscribe an AQM model account, you need to understand its characteristics by clicking on “AQM Model Account Details” on the top of each model account.',
    step13Text: 'You can check your stock positions for the iPIPA paper money account:<br>4.12. Go to “My Account” from the sidebar menu.<br>4.13. Select the “Broker Account” tab, then click on the "Account Positions" icon seen in the picture above.',
    step14Text: 'Subscribe to AQM model accounts or AQS strategies with money account is similar to paper account:<br>4.14. Go to "AQM Service" or "AQS Service" from the sidebar menu.<br>4.15. Subscribe by clicking on the "+".<br>4.16. Fill "Account Name" field as the nickname of your subscription. The "Trade Type" should be Money Trade. "Trading Account" should be your IB money account.<br>4.17. Press the "Submit" button to complete the subscription.<br>4.18. AQS Strategy Subscriptions Only: Users need to specify the investment amount when subscribing to AQS strategies.',
    step15Text: 'Users can check the portfolios\' performance in "My AQM Accounts" and "My AQS Portfolios":<br>5.1. Go to "AQM Service" or "AQS Service" from the sidebar menu.<br>5.2. Click on "My AQM Accounts" or "My AQS Portfolios" to review your portfolios\' performance metrics.<br>5.3. AQS Portfolios Only:  The user can check detail on the portfolio\'s performance metrics and positions in the monitor (see the picture above).',
    step16Text: '5.4. The Investment Summary provides a clear and concise overview of your money account investments. Go to "Dashboard" and click "My Investment Summary" to view.',
    step17Text: '<a href="' + encodeURI(iPapaFQAPdf) + '" target="_blank" rel="noreferrer" style="color: #00acc1">GoPIPA Frequently Asked Questions (FAQ)</a><br><a href="' + encodeURI(userTutorialVideo) + '" target="_blank" rel="noreferrer" style="color: #00acc1">GoPIPA User Tutorial</a><br><a href="' + encodeURI(userSeminarVideo) + '" target="_blank" rel="noreferrer" style="color: #00acc1">GoPIPA User Seminar</a><br><span style="font-family: arial; font-weight: bold; font-size: 18px; color: #3c4858">If you have any questions, please post them in <a href="/admin/forum" target="_blank" rel="noreferrer" style="color: #00acc1">forum</a> or send them to <a href="mailto:support@gopipa.com" style="color: #00acc1">support@gopipa.com</a>.</span>',
};

export const newFeatureText: any = {
    step1Subtitle: 'introducing IDA (Intelligent Data Analysis)',
    step2Subtitle: 'Announcing Ask iPIPA',

    step1Text: 'IDA provides intelligent investment information that is delivered in a timely manner to users to assist more efficient and profitable trading. Users can use IDA as their personal investment advisory tool to enhance their trading decisions, and act on market opportunities more efficiently. No more dependency on human brokers, advisors or analysts for their opinions or recommendations that are often too late for trading purposes. IDA provides AI based indicators and predictions from processing tremendous amounts of data continuously. IDA is usually used by experienced investors who can understand investment information and want to trade themselves.',
    step2Text: 'Ask iPIPA is an innovative AI-powered investment tool that leverages the power of GPT, a state-of-the-art language model, to provide you with expert-level insights and guidance. Designed exclusively for investors like you, this platform empowers you to make informed decisions, stay ahead of market trends, and achieve your financial goals with confidence.',
};
