import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import {newFeatureText, tutorialText} from "./tutorial-text";
import ReactHtmlParser from "html-react-parser";
import Button from "@material-ui/core/Button";
import PrevIcon from "@material-ui/icons/ArrowBack";
import NextIcon from "@material-ui/icons/ArrowForward";
import idaimg from "../../assets/img/idaimg.png";
import askipipaimg from "../../assets/img/askipipa.png";

const styles = {
    started: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        backgroundColor: 'whitesmoke',
        padding: '5px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        minHeight: '600px',
        width: '880px',
        overflow: 'auto',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        opacity: 0.4,
    },
    imageBox: {
        display: 'block',
        position: 'relative',
        // border:'solid black 1px',
        top: '5px',
        margin: '10px',
        textAlign: 'center',
    },
    image: {
        position: 'relative',
        // height: 'auto',
        // width: '800px',
        height: '270px',
        maxWidth: '750px',
        border: 'solid grey 1px',
        boxShadow: '10px 10px 5px #ccc',
    },
    prevButton: {
        position: 'absolute',
        left: '15px',
        bottom: '5px',
        margin: '5px',
        // backgroundColor: 'whitesmoke',
        fontFamily: 'Arial',
        borderRadius: '5px',
    },
    closeButton: {
        position: 'absolute',
        right: '46%',
        left: '46%',
        bottom: '5px',
        margin: '5px',
        // backgroundColor: 'whitesmoke',
        fontFamily: 'Arial',
        borderRadius: '5px',
    },
    nextButton: {
        position: 'absolute',
        right: '15px',
        bottom: '5px',
        margin: '5px',
        // backgroundColor: 'whitesmoke',
        fontFamily: 'Arial',
        borderRadius: '5px',
    },
    button: {
        position: 'absolute',
        // right: '15px',
        bottom: '5px',
        margin: '5px',
        // backgroundColor: 'whitesmoke',
        fontFamily: 'Arial',
        borderRadius: '5px',
    },
    textBox: {
        position: 'relative',
        fontFamily: 'Arial',
        fontSize: '14px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginLeft: '25px',
        marginRight: '25px',
        marginTop: '5px',
        textAlign: 'left',
    },
    bigtitle: {
        fontFamily: 'Arial',
        color:'#000',
        fontWeight: 'bold',
        fontSize: '22px',
        margin:'20px 10px',
    },
    title: {
        textAlign: 'center',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '22px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginTop: '25px',
        marginRight: '25px',
        marginLeft: '25px',
    },
    subtitle: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '18px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginTop: '25px',
        marginRight: '25px',
        marginLeft: '25px',
    },
}

function getImage(step) {
    console.info('Step: ' + step);
    if (step === 1) {
        return idaimg;
    } else if (step === 2) {
        return askipipaimg
    } else {
        return trackingimg;
    }
}

function getSubtitle(step) {
    console.info('Step: ' + step);
    if (step === 1) {
        return newFeatureText.step1Subtitle;
    } else if (step === 2) {
        return newFeatureText.step2Subtitle;
    } else {
        return 'N/A';
    }
}

function getText(step) {
    if (step === 1) {
        return newFeatureText.step1Text;
    } else if (step === 2) {
        return newFeatureText.step2Text;
    } else {
        return 'N/A';
    }
}

const useStyles = makeStyles(styles);

function NewFeature({onClick, init}) {

    const maxstep = 1;
    // const [templateZero, setTemplateZero] = React.useState(true);
    const [templateOne, setTemplateOne] = React.useState(true);
    const [prevButtonState, setPrevButtonState] = React.useState(false);
    const [nextButtonState, setNextButtonState] = React.useState(true);
    const [image, setImage] = React.useState('N/A');
    const [subtitleText, setSubtitleText] = React.useState('N/A');
    const [textContent, setTextContent] = React.useState('N/A');
    const [step, setStep] = React.useState(1);

    function nextStep() {
        console.info('Current Step: ' + (step + 1))
        if ((step+1) <= maxstep) {
            setImage(getImage(step + 1));
            setSubtitleText(getSubtitle(step + 1));
            setTextContent(getText(step + 1));
            setPrevButtonState(true);
            if (step === (maxstep-1))
                setNextButtonState(false);
            setStep(step + 1);
        }
    }

    React.useEffect(() => {
        console.info("Initialize " + init);
        if (step === 1) {
            setImage(getImage(step));
            setSubtitleText(getSubtitle(step));
            setTextContent(getText(step));
            if (step >= 1) {
                setPrevButtonState(true);
            }
            if (step >= maxstep) {
                setNextButtonState(false);
            }
        }
    },[]);


    const classes = useStyles();

    return (
        <>
            <div className={classes.overlay} onClick={onClick}/>
            <div className={classes.started}>
                <div align="center" className={classes.bigtitle}>What's New in GoPIPA?</div>
                <div style={{display: (templateOne? 'block' : 'none')}}>
                    <div  className={classes.imageBox}>
                        <img className={classes.image} src={image}/>
                    </div>
                    <div className={classes.subtitle}>
                        {ReactHtmlParser(String(subtitleText))}
                    </div>
                    <div className={classes.textBox} >
                        {ReactHtmlParser(String(textContent))}
                    </div>
                </div>
                {/*<div  style={{display: (prevButtonState ? 'block' : 'none')}}>*/}
                {/*    /!*<button className={classes.prevButton} onClick={prevStep}>Prev</button>*!/*/}
                {/*    <Button className={classes.prevButton} variant="contained" size="small" onClick={prevStep} startIcon={<PrevIcon/>}>Prev</Button>*/}
                {/*</div>*/}
                <div  align="center">
                    <   Button className={classes.closeButton} variant="contained" size="small" onClick={onClick}>Close</Button>
                </div>
                <div style={{display: (nextButtonState ? 'block' : 'none')}}>
                    <Button className={classes.nextButton} variant="contained" size="small" onClick={nextStep} endIcon={<NextIcon/>}>Next</Button>
                </div>
            </div>
        </>
    );
}

export default NewFeature;
