import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomTabs from 'components/CustomTabs/CustomTabs.js';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import VerifiedUserOutlined from '@material-ui/icons/VerifiedUserOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import UserAccountInfo from 'views/UserAccount/UserAccountInfo.view.tsx';
import UserMembershipView from 'views/UserAccount/UserMembership.view.tsx';
import IBAccountTable from 'views/UserAccount/IBAccount.view.tsx';
import IBWebApiAccountTableView from 'views/UserAccount/IBWebApiAccount.view.tsx';
import BillingTableView from 'views/UserAccount/BillingTable.view.tsx';
import $ from 'jquery';
import UserRiskAssessment from 'views/UserAccount/UserRiskAssessment.view.tsx';
import { getLoginUser } from '../../utils/user.utils';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  memberShipClass: {
    color: '#000000',
    opacity: '0.8',
  },
};


const useStyles = makeStyles(styles);
export default function UserAccount() {
  const classes = useStyles();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const oauth_token = !$.isEmptyObject(params) && params?.oauth_token;
  const oauth_verifier = !$.isEmptyObject(params) && params?.oauth_verifier;
  const onSuccess = !$.isEmptyObject(params) && params?.success;
  const onCancel = !$.isEmptyObject(params) && params?.cancel;
  const changemembership = !$.isEmptyObject(params) && params?.changemembership;
  const changepayment = !$.isEmptyObject(params) && params?.changepayment;
  const userAssessment = !$.isEmptyObject(params) && params?.userAssessment;
  const linkStatus = !$.isEmptyObject(params) && params?.code;

  let tabNum = 0;
  if (oauth_token && oauth_verifier) {
    tabNum = 2;
  } else if (onSuccess || onCancel || changemembership || changepayment) {
    tabNum = 1;
  } else if (userAssessment === 'no') {
    tabNum = 4;
  } else if (linkStatus) {
    tabNum = 2;
  }
  const [tabValue, setTabValue] = React.useState(tabNum);
  const [responseData, setResponseData] = React.useState(null);

  React.useEffect(() => {
    console.debug('UserAccount.view');
    getLoginUser(null)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          setResponseData(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="mainPagePanel">
      {responseData && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              tabValue={tabValue}
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: 'Account Information',
                  tabIcon: AccountBoxIcon,
                  tabContent: <UserAccountInfo currentUser={responseData} />,
                },
                {
                  tabName: 'Membership',
                  tabIcon: CardMembershipIcon,
                  tabContent: (
                    <UserMembershipView
                      onSuccess={onSuccess}
                      onCancel={onCancel}
                      currentUser={responseData}
                      changemembership={changemembership}
                      changepayment={changepayment}
                    />
                  ),
                },
                {
                  tabName: 'Broker Accounts',
                  tabIcon: AccountBalanceIcon,
                  tabContent: (
                    <IBWebApiAccountTableView
                      oauth_token={oauth_token}
                      oauth_verifier={oauth_verifier}
                      linkStatus={linkStatus}
                    />
                  ),
                },
                {
                  tabName: 'Billing',
                  tabIcon: PaymentIcon,
                  tabContent: <BillingTableView />,
                },
                {
                  tabName: 'User Risk Assessment',
                  tabIcon: VerifiedUserOutlined,
                  tabContent: <UserRiskAssessment currentUser={responseData} />,
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
