import React from 'react';
import {getAccountPosition} from "../../utils/user.utils";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {makeStyles} from "@material-ui/core/styles";

const styles = {
    tablePadding: {
        paddingRight: 5,
        paddingLeft: 5,
        paddingBottom: 35,
    },
    noWarp: {
        whiteSpace: 'nowrap',
    },
    position: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        backgroundColor: 'whitesmoke',
        padding: '10px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        // height: '80%',
        overflow: 'auto',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        opacity: 0.4,
    },
    button: {
        position: 'fixed',
        bottom: '5px',
        left: '50%',
        backgroundColor: 'whitesmoke',
        fontFamily: 'Arial, Helvetica',
        borderRadius: '5px',
    },
};

const useStyles = makeStyles(styles);

function BrokerAccountPosition({onClick, positionData}) {
    const classes = useStyles();

    const columns = [
        {
            name: 'Symbol',
            selector: 'symbol',
            sortable: true,
            width: '80px',
        },
        {
            name: 'Market Price',
            selector: 'marketPrice',
            sortable: true,
            right: true,
            className: classes.noWarp,
            width: '120px',
        },
        {
            name: 'Cost Basis',
            selector: 'costBasis',
            sortable: true,
            right: true,
            className: classes.noWarp,
            width: '120px',
        },
        {
            name: 'Position',
            selector: 'position',
            sortable: true,
            right: true,
        },
        {
            name: 'Position Value',
            selector: 'positionValue',
            sortable: true,
            right: true,
            width: '130px',
        },
    ];

    return (
        <>
            <div className={classes.overlay} onClick={onClick}/>
            <div className={classes.position}>
                <div className={classes.tablePadding}>
                    <DataTable
                        title="Account Positions"
                        data={positionData}
                        columns={columns}
                        fixedHeaderScrollHeight="50vh"
                        fixedHeader
                        highlightOnHover
                        striped
                        defaultSortFieldId={1}
                        sortIcon={<SortIcon />}
                    />
                </div>
                <div>
                    <button className={classes.button} onClick={onClick}>Close</button>
                </div>
            </div>
        </>
    );
}

export default BrokerAccountPosition;
