import React from 'react';

import {makeStyles} from "@material-ui/core/styles";
import ReactHtmlParser from "html-react-parser";
import backtestimg from 'assets/img/backtest.png';
import aqmlistimg from 'assets/img/aqm-table.png';
import aqmsettingimg from 'assets/img/aqm-setting.png';
import aqmdetailtimg from 'assets/img/aqm_detail.png';

const styles = {
    imageBox: {
        display: 'block',
        position: 'relative',
        // border:'solid black 1px',
        top: '5px',
        margin: '10px',
        textAlign: 'center',
    },
    textBox: {
        position: 'relative',
        fontFamily: 'Arial',
        fontSize: '14px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginLeft: '25px',
        marginRight: '25px',
        marginTop: '25px',
        textAlign: 'left',
    },
    image: {
        position: 'relative',
        height: '160px',
        maxWidth: '450px',
        border: 'solid grey 1px',
        boxShadow: '10px 10px 5px #ccc',
    },
    bigtitle: {
        fontFamily: 'Arial',
        color:'#999',
        fontWeight: 'bold',
        fontSize: '20px',
        margin:'20px 10px',
    },
    title: {
        textAlign: 'center',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '22px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginTop: '25px',
        marginRight: '25px',
        marginLeft: '25px',
    },
    subtitle: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '18px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginTop: '25px',
        marginRight: '25px',
        marginLeft: '25px',
    },
    tutorial: {
        width: '880px',
        margin: 'auto',
        paddingBottom: '50px',
    }
};

const title1 = 'Subscribe to AQM with Your IB Account or iPIPA Paper Account';
const title2 = 'Monitor the Performance of Your AQS Portfolios';

const tutorial1 = 'iPIPA manages the AQM model accounts based on investment objectives, market conditions and strategy performance. Before you subscribe an AQM model account, you need to understand its characteristics by clicking on “AQM Model Account Details” on the top of each model account.';
const tutorial2 = 'Every user receives $1M iPIPA paper money to try out iPIPA. You could use it to subscribe to any AQM model account you want.<br>1. Go to "AQM Service" from the sidebar menu.<br>2. You can only subscribe to AQM model account with risk levels that are the same or below your risk level.';
const tutorial3 = '3. Subscribe by clicking on the "+".<br>4. Fill "Account Name" field as the nickname of your subscription. The "Trade Type" should be Paper Trade. "Trading Account" should be your iPIPA paper account (Default Paper Trade Account) or your IB paper account.<br>5. Press the "Submit" button to complete the subscription.';

const useStyles = makeStyles(styles);

export default function TutorialView() {
    const classes = useStyles();

    return (
        <div className={classes.tutorial}>
            <div className={classes.subtitle}>
                {ReactHtmlParser(title1)}
            </div>
            <div className={classes.imageBox}>
                <img className={classes.image} style={{width: '500px'}} src={aqmdetailtimg}/>
            </div>
            <div className={classes.textBox} >
                {ReactHtmlParser(tutorial1)}
            </div>
            <div className={classes.imageBox}>
                <img className={classes.image} style={{width: '500px'}} src={aqmlistimg}/>
            </div>
            <div className={classes.textBox} >
                {ReactHtmlParser(tutorial2)}
            </div>
            <div className={classes.imageBox}>
                <img className={classes.image} style={{width: '500px'}} src={aqmsettingimg}/>
            </div>
            <div className={classes.textBox} >
                {ReactHtmlParser(tutorial3)}
            </div>
        </div>
    );
}
