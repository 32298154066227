/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Home from '@material-ui/icons/Home';
import Person from '@material-ui/icons/Person';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Apartment from '@material-ui/icons/Apartment';
import BubbleChart from '@material-ui/icons/BubbleChart';
import LocationOn from '@material-ui/icons/LocationOn';
import Notifications from '@material-ui/icons/Notifications';
import Unarchive from '@material-ui/icons/Unarchive';
import Language from '@material-ui/icons/Language';
import AccountTree from '@material-ui/icons/AccountTree';
import Forum from '@material-ui/icons/Forum';
import ContactSupportTwoToneIcon from '@material-ui/icons/ContactSupportTwoTone';
import AccountBalance from '@material-ui/icons/AccountBalanceWallet';
import BarChartIcon from '@material-ui/icons/BarChart';
import ChatIcon from '@material-ui/icons/Chat';
import EmailIcon from '@material-ui/icons/Email';
import FolderIcon from '@material-ui/icons/Folder';

// core components/views for Admin layout
import DashboardView from 'views/Dashboard/Dashboard.view.tsx';
import UserProfile from 'views/UserProfile/UserProfile.js';
import UserAccount from 'views/UserAccount/UserAccount.view.tsx';
import TableList from 'views/TableList/TableList.js';
import PortfolioActivityView from 'views/Portfolio/PortfolioActivity.view.tsx';
import PortfolioMonitorView from 'views/Portfolio/PortfolioMonitor.view.tsx';
import StrategyDetailsView from 'views/Strategy/StrategyDetails.view.tsx';
import AqmStrategyDetailsView from 'views/AqmStrategy/AqmStrategyDetails.view.tsx';
import WebsiteValidateDialog from 'components/Dialog/WebsiteValidateDialog.view.tsx';
import AccountPositionView from 'views/AqmStrategy/AccountPosition.view.tsx';
import IDAServiceView from 'views/IDA/IDAService.view.tsx';
import ReferFriendsView from 'views/Dashboard/ReferFriends.view.tsx';
// import AqmStrategyListView from 'views/AqmStrategy/MyAQMList.view.tsx';
import UserAgreementView from 'components/Dialog/UserAgreement.view.tsx';
import Typography from 'views/Typography/Typography.js';
import Icons from 'views/Icons/Icons.js';
import Maps from 'views/Maps/Maps.js';
import NotificationsPage from 'views/Notifications/Notifications.js';
// import UpgradeToPro from 'views/UpgradeToPro/UpgradeToPro.js';
import PrivacyPolicyView from 'components/Dialog/PrivacyPolicy.view.tsx';

//GoPipa
// import Billing from 'views/Billing/Billing.js';
import PortfolioListView from 'views/Portfolio/PortfolioList.view.tsx';
import StrategyListView from 'views/Strategy/StrategyList.view.tsx';
import AqmStrategyListView from 'views/AqmStrategy/AqmStrategyList.view.tsx';
import MyAqmListView from 'views/AqmStrategy/MyAqmList.view.tsx';
import MyAQSView from 'views/Strategy/MyAQS.view.tsx';
import AskIPIPAView from './views/IDA/AskiPIPAService.view';
// core components/views for RTL layout
import RTLPage from 'views/RTLPage/RTLPage.js';
import HomePageView from 'views/Home/HomePage.view.tsx';
import CompanyView from 'views/Company/Company.view.tsx';
import SupportView from 'views/Support/Support.view.tsx';
import ForumView from 'views/Forum/Forum.view.tsx';
import { stringUtils } from 'utils/string.utils';
import { getLoginUser } from 'utils/user.utils';

const dashboardRoutes = [
  {
    path: '/home',
    name: 'Home',
    rtlName: 'Home',
    icon: Home,
    component: HomePageView,
    layout: '/admin',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    component: DashboardView,
    layout: '/admin',
    invisible: false,
  },
  {
    path: '/useraccount',
    name: 'My Account',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: Person,
    component: UserAccount,
    layout: '/admin',
  },
  {
    path: '/user',
    name: 'My Account',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: Person,
    component: UserProfile,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/portfolioMonitor',
    name: 'Portfolio Monitor',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: BubbleChart,
    component: PortfolioMonitorView,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/portfolioActivities',
    name: 'Portfolio Activities',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: BubbleChart,
    component: PortfolioActivityView,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/portfolio',
    name: stringUtils.myQqsPortfolio,
    rtlName: 'ملف تعريفي للمستخدم',
    icon: BubbleChart,
    component: MyAQSView,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/table',
    name: 'Table',
    rtlName: 'قائمة الجدول',
    icon: 'content_paste',
    component: TableList,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/askIPIPA',
    name: 'Ask iPIPA (Beta)',
    rtlName: 'قائمة الجدول',
    icon: ChatIcon,
    component: AskIPIPAView,
    layout: '/admin',
    invisible: false,
  },
  {
    path: '/ida',
    name: 'IDA Service',
    rtlName: 'قائمة الجدول',
    icon: BarChartIcon,
    component: IDAServiceView,
    layout: '/admin',
    invisible: false,
  },
  {
    path: '/strategy',
    name: stringUtils.aqsStrategies,
    rtlName: 'قائمة الجدول',
    icon: FolderIcon,
    component: StrategyListView,
    layout: '/admin',
  },
  {
    path: '/aqmModelAccount',
    name: stringUtils.aqmModelAccounts,
    rtlName: 'قائمة الجدول',
    icon: AccountBalance,
    component: AqmStrategyListView,
    layout: '/admin',
  },
  // {
  //   path: '/myAQMAccount',
  //   name: stringUtils.myAqmStrategies,
  //   rtlName: 'قائمة الجدول',
  //   icon: AccountBalance,
  //   component: AqmStrategyListView,
  //   layout: '/admin',
  //   invisible: true,
  // },
  {
    path: '/accountPosition',
    name: stringUtils.aqmStrategies,
    rtlName: 'قائمة الجدول',
    icon: AccountBalance,
    component: AccountPositionView,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/strategyDetails',
    name: 'Strategy Details',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: BubbleChart,
    component: StrategyDetailsView,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/aqmStrategyDetails',
    name: 'AQM Strategy Details',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: BubbleChart,
    component: AqmStrategyDetailsView,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/forum',
    name: 'Forum',
    rtlName: 'قائمة الجدول',
    icon: Forum,
    component: ForumView,
    // component: Forum,
    layout: '/admin',
  },
  {
    path: '/support',
    name: 'Support',
    rtlName: 'قائمة الجدول',
    icon: ContactSupportTwoToneIcon,
    component: SupportView,
    layout: '/admin',
  },
  {
    path: '/company',
    name: 'Company',
    rtlName: 'طباعة',
    icon: Apartment,
    component: CompanyView,
    layout: '/admin',
  },
  {
    path: '/validate',
    name: 'Validate',
    rtlName: 'طباعة',
    icon: Apartment,
    component: WebsiteValidateDialog,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/userAgreement',
    name: 'User Agreement',
    rtlName: 'طباعة',
    icon: Apartment,
    component: UserAgreementView,
    layout: '/admin',
    invisible: true,
  },
  // {
  //   path: '/billing',
  //   name: 'Subscription',
  //   rtlName: 'طباعة',
  //   icon: LibraryBooks,
  //   component: Billing,
  //   layout: '/admin',
  //   invisible: true,
  // },
  {
    path: '/typography',
    name: 'Typography',
    rtlName: 'طباعة',
    icon: LibraryBooks,
    component: Typography,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/icons',
    name: 'Forum',
    rtlName: 'الرموز',
    icon: BubbleChart,
    component: Icons,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/maps',
    name: 'Maps',
    rtlName: 'خرائط',
    icon: LocationOn,
    component: Maps,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    rtlName: 'إخطارات',
    icon: Notifications,
    component: NotificationsPage,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/rtl-page',
    name: 'RTL Support',
    rtlName: 'پشتیبانی از راست به چپ',
    icon: Language,
    component: RTLPage,
    layout: '/rtl',
    invisible: true,
  },
  // {
  //   path: '/upgrade-to-pro',
  //   name: 'Upgrade To PRO',
  //   rtlName: 'التطور للاحترافية',
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  //   layout: '/admin',
  //   invisible: true,
  // },
  {
    path: '/myaqs',
    name: 'My AQS Portfolio',
    rtlName: 'التطور للاحترافية',
    icon: Unarchive,
    component: MyAQSView,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/referral',
    name: 'Refer Friends',
    rtlName: 'التطور للاحترافية',
    icon: EmailIcon,
    component: ReferFriendsView,
    layout: '/admin',
    invisible: false,
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    rtlName: 'طباعة',
    icon: Apartment,
    component: PrivacyPolicyView,
    layout: '/admin',
    invisible: true,
  },
];

export default dashboardRoutes;
