import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
// core components
import { getPortfolioLog } from '../../utils/portfolio.utils.ts';
import PropTypes from "prop-types";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import {getEstDateTime} from '../../utils/common';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
};

const columns = [
  {
    name: 'Activity Time',
    selector: 'time',
    sortable: true,
    cell: (row) => {
      return <div>{getEstDateTime(row?.time)}</div>;
    },
  },
  {
    name: 'User Name',
    selector: 'userName',
    sortable: true,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: true,
  },
  {
    name: 'Message',
    selector: 'message',
    sortable: true,
    minWidth: '60%',
  },
];

const useStyles = makeStyles(styles);

export default function PortfolioActivityTableView(props) {
  const [portfolioActivity, setPortfolioActivity] = React.useState([]);
  const { portfolioId } = props;

  const fetchData = React.useCallback(() => {
    if (portfolioId) {
      getPortfolioLog(portfolioId)
        .then((response) => {
          console.log('PortfolioActivityTable.view: Portfolio responseData = %o', response);
          setPortfolioActivity(response?.data?.data ?? []);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <DataTable
        title="Activities"
        data={portfolioActivity}
        columns={columns}
        // fixedHeader
        highlightOnHover
        striped
        defaultSortFieldId={1}
        sortIcon={<SortIcon />}
        noHeader={true}
      />
    </div>
  );
}

PortfolioActivityTableView.propTypes = {
  portfolioId: PropTypes.string,
};
