import { environment } from 'environments/environment';
import { getDefaultConfig, getForumConfig } from './common';
import { fetchJSON, postJSON, putJSON, deleteJSON } from './fetch.js';

export type ForumTopic = {
  content: string;
  op: string;
  title: string;
  type_id: string;
};

export type TopicSearch = {
  op: string;
  pageNo: number;
  perPageNum: number;
  type_id: string | null;
  order_by: string | null;
  filter_by: string | null;
  essence: boolean | null;
  keyword: string | null;
};

export type AnswerComment = {
  op: string;
  type_id: string;
  answer_id: string;
  comment_id: string;
  content: string | null;
};

//https://forum.gopipa.com/topicType
export const getForumToken = () => {
  const url = environment.forum_url + 'api-token-auth';
  const name = environment.currentUser.userName;
  //environment.currentUser.userName; environment.userNameKey
  const currentUserName = localStorage.getItem(environment.userNameKey);
  const params = { username: currentUserName, password: '31F7C8AB886D2D8' };
  console.log('###111 name = %s params = %o', name, params);
  return postJSON(url, params, getDefaultConfig(null));
};

export const forumTopicType = () => {
  const url = `${environment.forum_url}topicType`;
  return fetchJSON(url, getForumConfig());
};

// op=list&pageNo=1&perPageNum=10&type_id=5bc032df13bef30008175985&order_by=updateTime&filter_by=myTopic
// SELECTED: essence=true&order_by=updateTime
// topicSearch?op=list&pageNo=1&perPageNum=10&order_by=starNum sort by collection
// topicSearch?op=list&pageNo=1&perPageNum=10&order_by=viewNum by view
export const forumTopicSearch = (topicSearch: TopicSearch) => {
  console.log('#### topicSearch = %o', topicSearch);
  let url = `${environment.forum_url}topicSearch?op=list&pageNo=${topicSearch.pageNo}&perPageNum=${topicSearch.perPageNum}`;
  if (topicSearch?.essence) {
    url += `&essence=true`;
  }
  if (topicSearch.type_id) {
    url += `&type_id=${topicSearch.type_id}`;
  }
  if (topicSearch.order_by) {
    url += `&order_by=${topicSearch.order_by}`;
  }
  if (topicSearch.filter_by) {
    url += `&filter_by=${topicSearch.filter_by}`;
  }
  if (topicSearch.keyword) {
    url += `&keyword=${topicSearch.keyword}`;
  }

  return fetchJSON(url, getForumConfig());
};

export const saveForumTopic = (param: ForumTopic) => {
  const url = `${environment.forum_url}topic`;
  return postJSON(url, param, getForumConfig());
};

// https://forum.gopipa.com/topicSearch?op=byId&topic_id=6113187b32d11100093b58b3
//                          topicSearch?op=byId&topic_id=6110b31a32d11100093b57fa
export const commentOnSearchTopic = (topicId: string) => {
  const url = `${environment.forum_url}topicSearch?op=byId&topic_id=${topicId}`;
  return fetchJSON(url, getForumConfig());
};

export const editOnSearchTopic = (topicId: string) => {
  const url = `${environment.forum_url}topicSearch?op=byId&topic_id=${topicId}`;
  return fetchJSON(url, getForumConfig());
};

// https://forum.gopipa.com/star POST
//     Request Payload
// {"op":"add","topic_id":"6116d41a32d11100093b5a23"}

export const starSearchTopic = (topicId: string) => {
  const param = {
    op: 'add',
    topic_id: topicId,
  };
  const url = `${environment.forum_url}star`;
  return postJSON(url, param, getForumConfig());
};

export const answerSearchTopic = (topicId: string, content: string) => {
  // {"op":"add","topic_id":"6110b31a32d11100093b57fa","content":"<p>Go to Support and click the How to setup up IB account</p>"}
  const param = {
    op: 'add',
    topic_id: topicId,
    content,
  }
  const url = `${environment.forum_url}answer`;
  return postJSON(url, param, getForumConfig());
};

export const deleteForumTopic = (param: ForumTopic) => {
  const url = `${environment.forum_url}topic`;
  return postJSON(url, param, getForumConfig());
};

export const flagForumTopic = (topicId: string) => {
  const param = {
    op: 'essence',
    topic_id: topicId,
  };
  const url = `${environment.forum_url}topic`;
  return postJSON(url, param, getForumConfig());
};

export const deleteForumCommentTopic = (topic_id: string, answer_id: string) => {
  const param = {
    op: 'delete',
    topic_id,
    answer_id,
  };
  const url = `${environment.forum_url}answer`;
  return postJSON(url, param, getForumConfig());
};

export const answerCommentTopic = (param: AnswerComment) => {
  const url = `${environment.forum_url}answerComment`;
  return postJSON(url, param, getForumConfig());
};
