import React, { useRef, useLayoutEffect }from 'react';
// @material-ui/core components
import Grid from '@material-ui/core/Grid';
// core components
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import GoPiPaGridItem from 'components/GoPipa/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardBody from 'components/Card/CardBody.js';
import Card from 'components/Card/Card.js';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import StrategyView from 'components/GoPipa/Strategy/Strategy.view';
import $ from 'jquery';

const useStyles = makeStyles((theme) => ({
  cardPadding: {
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: 14,
    height: 30,
  },
}));

export default function CardView(props: any) {
  const [viewType, setViewType] = React.useState('cardView');
  const [openFilter, setOpenFilter] = React.useState(false);
  const classes = useStyles();
  const { strategyData, trackAccount, onClickOpen } = props;
  const ref = useRef(null);
  useLayoutEffect(() => {
    if (ref && ref.current) {
      console.log('###111 ref.current = %o', ref.current);
      ref.current.style.setProperty('padding', '0 5px', 'important');
    }
    $('.MuiGrid-grid-md-4').css('padding', '0 5px !important');
  }, []);

  const handleOnDisableInvestorState = () => {
    console.log('Card.view: handleOnClickSubscribe');
    setOpenFilter(!openFilter);
  };

  return (
    <div>
      {viewType === 'cardView' && strategyData && (
        <div>
          <GridContainer>
            {/*<GridItem xs={12} sm={12} md={12}>*/}
            {/*  <Button*/}
            {/*    style={{ marginTop: 15, marginBottom: -20, float: 'right' }}*/}
            {/*    onClick={handleOnDisableInvestorState}*/}
            {/*    color="info"*/}
            {/*    className={classes.label}*/}
            {/*  >*/}
            {/*    Use AQS Filter*/}
            {/*  </Button>*/}
            {/*</GridItem>*/}
            {openFilter && (
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ marginTop: 30, border: '2px solid blue', height: 300 }}></div>
              </GridItem>
            )}
          </GridContainer>
          <GridContainer>
            {strategyData.map((value, d) => (
              <GoPiPaGridItem id="cardId" key={d} xs={12} sm={6} md={4}>
                <Card style={{ marginBottom: 'unset' }}>
                  <CardBody
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                      border: '1px solid transparent',
                      borderRadius: 4,
                      borderColor: '#7a8cf7',
                    }}
                  >
                    <StrategyView
                      // handleOnClickSubscribe={handleOnClickSubscribe}
                      strategyDetail={value}
                      trackAccount={trackAccount}
                      onClickOpen={onClickOpen}
                    />
                  </CardBody>
                </Card>
              </GoPiPaGridItem>
            ))}
          </GridContainer>
        </div>
      )}
      {viewType === 'subscribeView' && <div>Subscribe a portfolio</div>}
    </div>
  );
}
