import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewListIcon from '@material-ui/icons/ViewList';
import StrategyBasicInfoView from "./StrategyBasicInfo.view";
import StrategyTrackingInfoView from "./StrategyTrackingInfo.view";
import StrategyBacktestView from "./StrategyBacktest.view.tsx";
import {
  getStrategy,
  canStrategyBackTest,
  strategyBackTestDateRange,
} from '../../utils/strategy.utils';
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import { stringUtils } from '../../utils/string.utils';

const styles = {
  portfolioNameFont: {
    fontWeight: 400,
  },
  waitingMsg: {
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 70,
    marginLeft: -80,
  },
  circularProgressTop: {
    marginTop: 20,
  },
  circularProgressDiv: {
    border: '1px solid 1px solid #d3d3d3',
    display: 'flex',
    justifyContent: 'center',
    height: 100,
  },
};

const useStyles = makeStyles(styles);

export default function StrategyDetailsView() {
  const classes = useStyles();
  const { state } = useLocation();
  const [responseData, setResponseData] = React.useState(null);
  const [canBackTest, setCanBackTest] = React.useState(true);
  const [backTestDateRange, setBackTestDateRange] = React.useState([]);
  const [benchmarkSymbol, setBenchMarkSymbol] = React.useState('SPY');
  const strategyId = state?.strategyId ?? '';
  const strategyName = state?.strategyName ?? '';

  useEffect(() => {
    getStrategy(strategyId, true)
      .then((response) => {
        if (response?.data.code === 200) {
          const data = response?.data?.data.length > 0 ? response.data.data[0] : [];
          console.log('StrategyDetailsView getStrategy data = %o', data);
          setResponseData(data);
          if (data.backtestReports) {
            const btReport = data.backtestReports[0];
            // console.log('StrategyDetailsView backtest data = %o', btReport.benchmarkSymbol);
            setBenchMarkSymbol(btReport.benchmarkSymbol);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    canStrategyBackTest(strategyId)
      .then((response) => {
        // console.log('SUNNY canStrategyBackTest getStrategy = %o', response.data);
        setCanBackTest(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    strategyBackTestDateRange()
      .then((response) => {
        // console.log('SUNNY canStrategyBackTest getStrategy = %o', response.data);
        setBackTestDateRange(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const title = '->Strategy Details ';

  return (
    <div className="mainPagePanel">
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ marginTop: 10 }}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{ float: 'left' }}>
                      <h4>
                        <Link to="/admin/strategy">{stringUtils.aqsStrategies}</Link>
                        {title}
                        <span className={classes.portfolioNameFont}>
                          {`- ${strategyName}`} {`(ID: ${strategyId})`}
                        </span>
                      </h4>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: 'Basic',
                  tabIcon: ViewModuleIcon,
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        {responseData && <StrategyBasicInfoView strategyData={responseData} />}
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'Tracking',
                  tabIcon: ViewListIcon,
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <StrategyTrackingInfoView
                          strategyData={responseData}
                          strategyId={strategyId}
                          benchmarkSymbol={benchmarkSymbol}
                        />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'BackTest',
                  tabIcon: ViewListIcon,
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <StrategyBacktestView
                            strategyData={responseData}
                            benchmarkSymbol={benchmarkSymbol}
                        />
                      </GridItem>
                    </GridContainer>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
