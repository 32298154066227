import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
// @material-ui/core components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Link } from 'react-router-dom';
// core components
import noperf from 'assets/img/noperf.svg';
import styles from 'assets/jss/material-dashboard-react/components/strategyCardStyle.js';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import PersonalVideo from '@material-ui/icons/PersonalVideo';
import StopIcon from '@material-ui/icons/Stop';
import HistoryIcon from '@material-ui/icons/History';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CashChangeHistoryIcon from '@material-ui/icons/Description';
import StartIcon from '@material-ui/icons/PlayCircleOutline';
import EmailIconEnable from '@material-ui/icons/Email';
import EmailIconDisable from '@material-ui/icons/EmailOutlined';
import EmailUnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import CheckIcon from '@material-ui/icons/Check';
import {
  getSvg,
  getTrackAccount,
  startSubscribePortfolio,
  subscribePortfolio,
} from '../../../utils/strategy.utils';
import {
  deletePortfolio,
  startPortfolio,
  getPortfolio,
  modifyInvestmentAmount,
} from '../../../utils/portfolio.utils';
import classNames from 'classnames';
import StopPortfolioDialogView from 'components/Dialog/StopPortfolioDialog.view.tsx';
import SubscribePortfolioDialog from '../../../components/Dialog/SubscribePortfolioDialog';
import EditInvestmentAmountView from 'components/Dialog/EditInvestmentAmountDialog.view.tsx';
import AlertView from '../../../components/Alert/Alert.view';
import { getTextColor, getNextTradeDate } from '../../../utils/common';
import { stringUtils } from '../../../utils/string.utils';
import { getValidDateTime, getEstDateTime } from '../../../utils/common';

const useStyles = makeStyles(styles);

export default function PortFolioListView(props) {
  const classes = useStyles();
  const [openDeletePortfolioDlg, setOpenDeletePortfolioDlg] = React.useState(false);
  const [openStartPortfolioDlg, setOpenStartPortfolioDlg] = React.useState(false);
  const [openStopPortfolioDlg, setOpenStopPortfolioDlg] = React.useState(false);
  const [openEditPortfolioDlg, setOpenEditPortfolioDlg] = React.useState(false);
  const [openInvestmentAmountDlg, setOpenInvestmentAmountDlg] = React.useState(false);
  const [tradeNotification, setTradeNotification] = React.useState(false);
  const [trackAccount, setTrackAccount] = React.useState(null);
  const [portfolioData, setPortfolioData] = React.useState(null);
  const [noImage, setNoImage] = React.useState(false);
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });
  const { strategyDetail, portfolioType } = props;
  const time = Math.floor(new Date().getTime() / 3600000);
  const requestImage = getSvg(strategyDetail.id, `.svg?t=${time}`, strategyDetail.subPath);
  const deletePortfolioDLgTitle = `Delete Portfolio - ${strategyDetail.name}`;
  const startPortfolioDLgTitle = `Start Portfolio - ${strategyDetail.name}`;
  const activityPathName = '/admin/portfolioActivities';
  const monitorPathName = '/admin/portfolioMonitor';

  const activeTime = strategyDetail.createTime?
      moment(strategyDetail.createTime.replace(/\.\d+/, "").replace(/-/g,"/")).tz("America/New_York").format('YYYY-MM-DD')
      : '--';

  const handleStartPortfolio = () => {
    console.debug('handleStartPortfolio id = %s', strategyDetail.id);
    startPortfolio(strategyDetail.id, true)
      .then((response) => {
        console.debug('Portfolio.view: handleStart Portfolio responseData = %o', response);
        setOpenStartPortfolioDlg(false);
        window.location.reload();
      })
      .catch((error) => {
        console.debug(error);
      });
  };

  const handleStopDialogClose = () => {
    setOpenStopPortfolioDlg(false);
  };

  const handleOpenStopPortfolioDlg = () => {
    setOpenStopPortfolioDlg(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeletePortfolioDlg(false);
  };

  const handleOpenDeletePortfolioDlg = () => {
    setOpenDeletePortfolioDlg(true);
  };

  const handleStartDialogClose = () => {
    setOpenStartPortfolioDlg(false);
  };

  const handleOpenStartPortfolioDlg = () => {
    setOpenStartPortfolioDlg(true);
  };

  const handleDeletePortfolio = () => {
    console.deubg('handleDeletePortfolio id = %s', strategyDetail.id);
    deletePortfolio(strategyDetail.id)
      .then((response) => {
        console.debug('Portfolio.view: handleDelete Portfolio responseData = %o', response);
        setOpenDeletePortfolioDlg(false);
        window.location.reload();
      })
      .catch((error) => {
        console.debug(error);
      });
  };

  const getTrackAccounts = () => {
    getTrackAccount()
      .then((response) => {
        console.debug('Portfolio view: setTrackAccount = %o', response);
        // IB: DU819878   (Max Usable Cash: 428,868.02)
        const trackAcct = [];
        response.data.data.forEach((item: any) => {
          const accoutName = item.trackAccountName
            ? item.trackAccountName + (item.account ? ' (' + item.account + ')' : '')
            : item.account;
          const accountValue = item.usableCash ? parseFloat(item.usableCash.toFixed(2)) : 0;
          const accountInfo = `${item.broker}: ${accoutName}  (Max Usable Cash: ${accountValue})`;
          trackAcct.push({ key: item.account, value: accountInfo, id: item.id });
          console.debug('Portfolio.view: trackAcct = %o', trackAcct);
        });
        setTrackAccount(trackAcct);
        getPortfolio(strategyDetail.id)
          .then((response) => {
            console.debug('Portfolio view: getPortfolio = %o', response);
            const data = response?.data?.data[0];
            console.debug('Portfolio view: getPortfolio data = %o', data);
            setPortfolioData(data);
            setOpenEditPortfolioDlg(true);
          })
          .catch((error) => {
            console.debug(error);
          });
      })
      .catch((error) => {
        console.debug(error);
      });
  };

  const handleOpenEditPortfolioDlg = () => {
    getTrackAccounts();
  };

  const handleSubscribeClose = () => {
    setOpenEditPortfolioDlg(false);
  };

  const handleEditSubscribePortfolio = (values) => {
    let trackAccountId = 0;
    trackAccount.forEach((item) => {
      console.debug('Strategy view: item = %o', item);
      if (item.key === values.tradingAccount) {
        trackAccountId = item.id;
      }
    });

    const params = {
      'portfolio.id': strategyDetail.id,
      'portfolio.buyValuePerOrder': '',
      'portfolio.marketType': strategyDetail.marketType,
      'portfolio.name': values.portfolioName,
      'portfolio.note': values.portfolioDesc,
      'portfolio.portfolioClass': 'Investor',
      'portfolio.sellValuePerOrder': '',
      'portfolio.strategyId': strategyDetail.strategyId,
      'portfolio.trackAccountId': trackAccountId,
      'profile.expectMinAnnualReturn': strategyDetail.profile.expectMinAnnualReturn,
      'profile.importanceOrder': '123',
      'profile.investmentAmount': values.investmentAmount,
      'profile.investmentTimeframe': strategyDetail.profile.investmentTimeframe,
      'profile.liquidateOnEmergency': values.emergencyStop === 'stopSell' ? false : true,
      'profile.portfolioType': 'NotCare',
      'profile.tolenranceMaxAnnualVolatility': strategyDetail.profile.tolenranceMaxAnnualVolatility,
      'profile.tolenranceMaxDrawdown': strategyDetail.profile.tolenranceMaxDrawdown,
    };

    console.debug('Portfolio view: handleEditSubscribePortfolio params = %o', params);
    subscribePortfolio(params)
      .then((response) => {
        console.debug('Portfolio view: handleEditSubscribePortfolio response = %o', response);
        startSubscribePortfolio(response.data.data.id)
          .then((response1) => {
            console.debug('Portfolio.view: startSubscribePortfolio response1 = %o', response1);
            setOpenEditPortfolioDlg(false);
            window.location.reload();
          })
          .catch((error) => {
            console.debug('Portfolio view: startSubscribePortfolio error = %o', error);
            setOpenAlertMsg({ alert: true, alertContent: error?.data?.errMsg, severity: 'error' });
          });
      })
      .catch((error) => {
        console.debug(error);
        if (error?.data?.errMsg.includes('DuplicateKeyException')) {
          setOpenAlertMsg({
            alert: true,
            alertContent: 'Portfolio Name exists',
            severity: 'error',
          });
        } else {
          setOpenAlertMsg({ alert: true, alertContent: error?.data?.errMsg, severity: 'error' });
        }
      });
  };

  const handleInvestmentAmountClose = () => {
    setOpenInvestmentAmountDlg(false);
  };

  const handleOpenInvestmentAmountDlg = () => {
    setOpenInvestmentAmountDlg(true);
  };

  const turnOnNotification = () => {
    strategyDetail.notification = true;
    const params = {
      'portfolio.id': strategyDetail.id,
      'tradeNotification': true,
    };
    subscribePortfolio(params)
        .then((response) => {
          setTradeNotification(true);
        })
        .catch((error) => {
            console.debug(error);
            setOpenAlertMsg({ alert: true, alertContent: error?.data?.errMsg, severity: 'error' });
        });
  }

  const turnOffNotification = () => {
    strategyDetail.notification = false;
    const params = {
      'portfolio.id': strategyDetail.id,
      'tradeNotification': false,
    };
    subscribePortfolio(params)
        .then((response) => {
          setTradeNotification(false);
        })
        .catch((error) => {
          console.debug(error);
          setOpenAlertMsg({ alert: true, alertContent: error?.data?.errMsg, severity: 'error' });
        });
  }

  const handleInvestmentAmountSubmit = (values) => {
    const investmentAmount =
      values.method === 'increase' ? values.investmentAmount : -values.investmentAmount;

    modifyInvestmentAmount(strategyDetail.id, investmentAmount)
      .then((response) => {
        handleInvestmentAmountClose();
        if (response?.data.code === 200) {
          handleInvestmentAmountClose();
        } else {
          console.debug(error);
          setOpenAlertMsg({ alert: true, alertContent: error?.data?.errMsg, severity: 'error' });
        }
      })
      .catch((error) => {
        console.debug(error);
        setOpenAlertMsg({ alert: true, alertContent: error?.data?.errMsg, severity: 'error' });
      });
  };

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  React.useEffect(() => {
    if (strategyDetail.tradeNotification)
      setTradeNotification(true);
  }, []);

  return (
    <div>
      <div>
        <div style={{ float: 'left', width: '30%' }}>
          <h5 style={{ margin: '0' }} className={classes.portfolioTitle}>
            {strategyDetail.name}
          </h5>
        </div>
        { tradeNotification? (
            <div style={{float: 'right'}}>
              <Tooltip title="Turn Off Trade Notification">
                <IconButton
                    className={classes.iconPadding}
                    aria-label="start"
                    onClick={() => turnOffNotification()}
                >
                  <span><EmailIconDisable color="primary" fontSize="small"/>
                    <CheckIcon color="green" style={{marginLeft:'-25px', color:'red', weight:'600'}} fontSize="small"/>
                    </span>
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div style={{float: 'right'}}>
              <Tooltip title="Turn On Trade Notification">
                <IconButton
                    className={classes.iconPadding}
                    aria-label="start"
                    onClick={() => turnOnNotification()}
                >
                  <EmailIconEnable color="primary" fontSize="small"/>
                </IconButton>
              </Tooltip>
            </div>
          )
        }
        {(strategyDetail.status === 'StartFailed' || strategyDetail.status === 'ReadyToStart') && (
          <div style={{ float: 'right' }}>
            <Tooltip title="Start">
              <IconButton
                className={classes.iconPadding}
                aria-label="start"
                onClick={() => handleOpenStartPortfolioDlg()}
              >
                <StartIcon color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                className={classes.iconPadding}
                aria-label="edit"
                onClick={() => handleOpenEditPortfolioDlg()}
              >
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
            {/*<Tooltip title="Delete">*/}
            {/*  <IconButton*/}
            {/*    className={classes.iconPadding}*/}
            {/*    aria-label="delete"*/}
            {/*    onClick={() => handleOpenDeletePortfolioDlg()}*/}
            {/*  >*/}
            {/*    <DeleteIcon color="primary" fontSize="small" />*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}
            <Tooltip title="Portfolio Activities">
              <IconButton aria-label="portfolioActivities">
                <Link
                  to={{
                    pathname: activityPathName,
                    state: {
                      portfolioId: strategyDetail.id,
                      portfolioName: strategyDetail.name,
                    },
                  }}
                >
                  <HistoryIcon color="primary" fontSize="small" />
                </Link>
              </IconButton>
            </Tooltip>
          </div>
        )}
        {strategyDetail.status === 'Starting' && (
          <div style={{ float: 'right' }}>
            <Tooltip title="Portfolio Activities">
              <IconButton aria-label="portfolioActivities">
                <Link
                  to={{
                    pathname: activityPathName,
                    state: {
                      portfolioId: strategyDetail.id,
                      portfolioName: strategyDetail.name,
                    },
                  }}
                >
                  <HistoryIcon color="primary" fontSize="small" />
                </Link>
              </IconButton>
            </Tooltip>
          </div>
        )}

        {strategyDetail.status === 'Started' && (
          <div style={{ float: 'right' }}>
            <Tooltip title="Monitor">
              <IconButton aria-label="monitor" size="small">
                <Link
                  to={{
                    pathname: monitorPathName,
                    state: {
                      portfolioId: strategyDetail.id,
                      portfolioName: strategyDetail.name,
                      portfolioMarketType: strategyDetail.marketType,
                      strategyId: strategyDetail.strategyId,
                      period: activeTime,
                    },
                  }}
                >
                  <PersonalVideo color="primary" fontSize="small" />
                </Link>
              </IconButton>
            </Tooltip>
            <Tooltip title="Increase/Decrease Investment Amount">
              <IconButton
                className={classes.iconPadding}
                aria-label="investmentAmount"
                size="small"
                onClick={() => handleOpenInvestmentAmountDlg()}
              >
                <MoneyIcon color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Investment Amount Activities">
              <IconButton aria-label="portfolioInvestmentAmountActivities">
                <Link
                  to={{
                    pathname: activityPathName,
                    state: {
                      portfolioId: strategyDetail.id,
                      portfolioName: strategyDetail.name,
                      portfolioLog: 'investmentLog',
                    },
                  }}
                >
                  <CashChangeHistoryIcon color="primary" fontSize="small" />
                </Link>
              </IconButton>
            </Tooltip>
            <Tooltip title="Stop">
              <IconButton
                className={classes.iconPadding}
                aria-label="stop"
                size="small"
                onClick={() => handleOpenStopPortfolioDlg()}
              >
                <StopIcon color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Portfolio Activities">
              <IconButton aria-label="portfolioActivities" size="small">
                <Link
                  to={{
                    pathname: activityPathName,
                    state: {
                      portfolioId: strategyDetail.id,
                      portfolioName: strategyDetail.name,
                    },
                  }}
                >
                  <HistoryIcon color="primary" fontSize="small" />
                </Link>
              </IconButton>
            </Tooltip>
          </div>
        )}
        {strategyDetail.status === 'Stop' && (
          <div style={{ float: 'right' }}>
            {/*<Tooltip title="Monitor">*/}
            {/*  <IconButton aria-label="monitor">*/}
            {/*    <Link*/}
            {/*      to={{*/}
            {/*        pathname: monitorPathName,*/}
            {/*        state: {*/}
            {/*          portfolioId: strategyDetail.id,*/}
            {/*          portfolioName: strategyDetail.name,*/}
            {/*          portfolioMarketType: strategyDetail.marketType,*/}
            {/*          strategyId: strategyDetail.strategyId,*/}
            {/*          period: activeTime,*/}
            {/*        },*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <PersonalVideo color="primary" fontSize="small" />*/}
            {/*    </Link>*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}

            {/*<Tooltip title="Delete">*/}
            {/*  <IconButton*/}
            {/*    className={classes.iconPadding}*/}
            {/*    aria-label="delete"*/}
            {/*    onClick={() => handleOpenDeletePortfolioDlg()}*/}
            {/*  >*/}
            {/*    <DeleteIcon color="primary" fontSize="small" />*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}

            <Tooltip title="Portfolio Activities">
              <IconButton aria-label="portfolioActivities">
                <Link
                  to={{
                    pathname: activityPathName,
                    state: {
                      portfolioId: strategyDetail.id,
                      portfolioName: strategyDetail.name,
                    },
                  }}
                >
                  <HistoryIcon color="primary" fontSize="small" />
                </Link>
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      <table width="100%">
        <tbody>
          <tr>
            <td colSpan="4">
              <div className={classes.strategyHr}></div>{' '}
            </td>
          </tr>
          <tr>
            <td>
              <span>Current Status: </span>
              <span className={classes.titleValue}>{strategyDetail.status}</span>
            </td>
            <td>
              <span>Trade Type: </span>
              <span className={classes.titleValue}>
                {strategyDetail.tradeType.startsWith('SIMU_') ? 'Paper Trade' : 'Money Trade'}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Create Time: </span>
              <span className={classes.titleValue}>
                {strategyDetail.createTime
                  ? getEstDateTime(strategyDetail.createTime, 'YYYY-MM-DD HH:mm z')
                  : ''}
              </span>
            </td>
            <td>
              <span>Start Time: </span>
              <span className={classes.titleValue}>
                {strategyDetail.startTime
                  ? getEstDateTime(strategyDetail.startTime, 'YYYY-MM-DD HH:mm z')
                  : ''}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <span>Broker Account: </span>
              <span className={classes.titleValue}>{strategyDetail.brokerAccountName}</span>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <span>Estimated date for next portfolio update: </span>
              <span className={classes.titleValue}>
                {getNextTradeDate(strategyDetail.nextTradeDate)}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Cash: </span>
              <span className={classes.titleValue}>
                {strategyDetail.cash < 0 ? 0 : strategyDetail.cash.toLocaleString()}
              </span>
            </td>
            <td>
              <span>Margin: </span>
              <span className={classes.titleValue}>
                {strategyDetail.cash < 0
                  ? parseFloat(strategyDetail.cash.toFixed(2)).toLocaleString()
                  : 0}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Portfolio Value: </span>
              <span className={classes.titleValue}>
                {parseFloat(strategyDetail.portfolioValue.toFixed(2)).toLocaleString()}
              </span>
            </td>
            <td>
              <span>Total Profit/Loss: </span>
              <span className={classes.titleValue}>
                {parseFloat(strategyDetail.totalReturn.toFixed(2)).toLocaleString()}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span
                className={classNames(classes.chartStrategyLegned, classes.chartStrategySpan)}
              ></span>
              <span>Total Return: </span>
              <span className={classes.titleValue}>
                {strategyDetail.returnRatio === 0
                  ? '0%'
                  : strategyDetail.returnRatio
                  ? (strategyDetail.returnRatio * 100).toFixed(2) + '%'
                  : 'N/A'}
              </span>
            </td>
            <td>
              <span
                className={classNames(classes.chartStrategyLegned, classes.chartBenchmarkSpan)}
              ></span>
              <span>{strategyDetail.benchmarkSymbol} Return: </span>
              <span className={classes.titleValue}>
                {(strategyDetail.benchmarkReturnRatio * 100).toFixed(2)}%
              </span>
            </td>
          </tr>
          <tr style={{ height: 250 }}>
            {noImage &&
            (strategyDetail.status === 'Started' || strategyDetail.status === 'Starting') ? (
              <td
                className={classes.titleValue}
                colSpan="4"
                style={{ fontSize: 18, fontWeight: 500 }}
              >
                {stringUtils.portfolioNoImageNote}
              </td>
            ) : (
              <td colSpan="4">
                {' '}
                <img
                  src={requestImage}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = noperf;
                    setNoImage(true);
                  }}
                  alt="logo"
                  height="245"
                  className={classes.img}
                />
              </td>
            )}
          </tr>
          <tr>
            <td colSpan="2">
              <span>Last Check Time of Portfolio Value: </span>
              <span className={classes.titleValue}>
                {getEstDateTime(strategyDetail.lastValueUpdateTime)}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {/*<div className={classes.strategyHr}></div>*/}
      {/*<span className={classes.strategyCardTitle}>Portfolio Profile Info</span>*/}
      {/*<div className={classes.strategyHr}></div>*/}
      <table width="100%">
        {portfolioType === 'Core_Strategy' ? (
          <tbody>
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    <span>Minimum Investment Amount: </span>*/}
            {/*    <span className={classes.titleValue}>*/}
            {/*      {strategyDetail.profile.investmentAmount.toLocaleString()}*/}
            {/*    </span>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    <span>Investment Timeframe: </span>*/}
            {/*    <span className={classes.titleValue}>*/}
            {/*      {strategyDetail.profile.investmentTimeframe} months*/}
            {/*    </span>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    <span>Expectation of Min CAGR: </span>*/}
            {/*    <span className={classes.titleValue}>*/}
            {/*      {strategyDetail.profile.expectMinAnnualReturn}%*/}
            {/*    </span>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    <span>Tolerance of Max Annual Volatility: </span>*/}
            {/*    <span className={classes.titleValue}>*/}
            {/*      {strategyDetail.profile.tolenranceMaxAnnualVolatility}%*/}
            {/*    </span>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    <span>Tolerance of Max Drawdown: </span>*/}
            {/*    <span className={classes.titleValue}>*/}
            {/*      {strategyDetail.profile.tolenranceMaxDrawdown}%*/}
            {/*    </span>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    <span>The order of importance to you: </span>*/}
            {/*    <span className={classes.titleValue}>*/}
            {/*      {portfolio.importanceOrder[strategyDetail.profile.importanceOrder]}*/}
            {/*    </span>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    <span>Preference of the portfolio type: </span>*/}
            {/*    <span className={classes.titleValue}>*/}
            {/*      {portfolio.portfolioType[strategyDetail.profile.portfolioType]}*/}
            {/*    </span>*/}
            {/*  </td>*/}
            {/*</tr>*/}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>
                <span>Minimum Investment Amount: </span>
                <span className={classes.titleValue}>
                  {strategyDetail.profile.investmentAmount.toLocaleString()}
                </span>
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <div className={classes.strategyHr}></div>
      <span className={classes.strategyCardTitle}>Strategy Name:</span>
      <span className={getTextColor(strategyDetail.strategy.name)}>
        {strategyDetail.strategy.name} (ID: {strategyDetail.strategyId} )
      </span>
      <div className={classes.strategyHr}></div>
      <span>Developer Name: </span>
      <span className={classes.titleValue}>GoPIPA</span>

      {openStopPortfolioDlg && (
        <StopPortfolioDialogView
          openStopPortfolioDlg={openStopPortfolioDlg}
          handleStopDialogClose={handleStopDialogClose}
          strategyId={strategyDetail.id}
          strategyName={strategyDetail.name}
        />
      )}

      {openDeletePortfolioDlg && (
        <Dialog
          open={openDeletePortfolioDlg}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{deletePortfolioDLgTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>Are you sure you want to delete this portfolio?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={handleDeletePortfolio} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openStartPortfolioDlg && (
        <Dialog
          open={openStartPortfolioDlg}
          onClose={handleStartDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{startPortfolioDLgTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>Are you sure you want to start this portfolio?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleStartDialogClose} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={handleStartPortfolio} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openEditPortfolioDlg && (
        <SubscribePortfolioDialog
          open={openEditPortfolioDlg}
          onSubscribePortfolio={handleEditSubscribePortfolio}
          onClose={handleSubscribeClose}
          trackAccount={trackAccount}
          strategy={strategyDetail}
          portfolioData={portfolioData}
          aria-labelledby="responsive-dialog-title"
        ></SubscribePortfolioDialog>
      )}
      {openInvestmentAmountDlg && (
        <EditInvestmentAmountView
          open={openInvestmentAmountDlg}
          handleInvestmentAmountSubmit={handleInvestmentAmountSubmit}
          handleInvestmentAmountClose={handleInvestmentAmountClose}
          strategy={strategyDetail}
          portfolioData={portfolioData}
          aria-labelledby="responsive-dialog-title"
        ></EditInvestmentAmountView>
      )}
      {openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        ></AlertView>
      )}
    </div>
  );
}

PortFolioListView.propTypes = {
  strategyDetail: PropTypes.object,
  portfolioType: PropTypes.string,
};
