import React from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { environment } from '../../environments/environment';
// import Button from '@material-ui/core/Button';
import Button from '../../components/CustomButtons/Button.js';
import SubscribePortfolioDialog from '../../components/Dialog/SubscribePortfolioDialog';
import AlertView from '../../components/Alert/Alert.view';
import {
  startSubscribePortfolio,
  subscribePortfolio,
  subscribeAQMStrategy,
  AqmStrategy
} from '../../utils/strategy.utils';
import { getValidDateTime } from '../../utils/common';
import { getTrackAccountAQMSubscribe } from '../../utils/user.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItemIconWidth: {
    width: '30%',
    fontWeight: 400,
    fontSize: 16,
  },
  listItemTextWidth: {
    width: '70%',
  },
  listItemHeight: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '14px',
  },
}));

export default function AqmStrategyBasicInfoView(props) {
  const classes = useStyles();
  const { strategyData } = props;
  const strategy = environment.selectDataItems['strategy'];
  const [trackAccount, setTrackAccount] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [redirectMyPortfolio, setRedirectMyPortfolio] = React.useState(false);
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });

  const strategyNameId = `${strategyData.name} (ID: ${strategyData.id})`;

  const fetchTrackAccountData = React.useCallback(() => {
    getTrackAccountAQMSubscribe()
      .then((response) => {
        console.debug('StrategyBasicInfo.view: setTrackAccount = %o', response);
        // IB: DU819878   (Max Usable Cash: 428,868.02)
        const trackAcct = [];
        response.data.data.forEach((item: any) => {
          const accoutName = item.trackAccountName
            ? item.trackAccountName + (item.account ? ' (' + item.account + ')' : '')
            : item.account;
          const accountValue = item.usableCash ? parseFloat(item.usableCash.toFixed(2)) : 0;
          const accoutInfo = `${item.broker}: ${accoutName}  (Max Usable Cash: ${accountValue})`;
          trackAcct.push({ key: item.account, value: accoutInfo, id: item.id });
        });
        setTrackAccount(trackAcct);
      })
      .catch((error) => {
        console.debug(error);
      });
  }, []);

  React.useEffect(() => {
    fetchTrackAccountData();
  }, []);

  const handleSubscribeAqm = (values) => {
    let trackAccountId = 0;
    trackAccount.forEach((item) => {
      if (item.key === values.tradingAccount) {
        trackAccountId = item.id;
      }
    });

    const params: AqmStrategy = {
      trackAccountId,
      aqmId: strategyData.id,
      name: values.portfolioName,
      note: values.portfolioDesc,
    };

    console.debug('AQM Strategy view: handleSubscribeAqm params = %o', params);
    subscribeAQMStrategy(params)
        .then((response) => {
          const data = response && response?.data ? response.data : null;
          if (data.code === 200) {
            setRedirectMyPortfolio(true);
            setOpen(false);
          } else {
            setOpenAlertMsg({
              open: true,
              message: 'Error: ' + data.code,
              severity: 'error',
            });
          }
        })
        .catch((error) => {
          console.debug(error);
          if (error?.data?.errMsg.includes('DuplicateKeyException')) {
            setOpenAlertMsg({
              alert: true,
              alertContent: 'AQM Name exists',
              severity: 'error',
            });
          } else {
            setOpenAlertMsg({ alert: true, alertContent: error?.data?.errMsg, severity: 'error' });
          }
        });
  };

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  const handleOnClickSubscribeStrategy = () => {
    setOpen(true);
  };

  const handleSubscribeClose = () => {
    setOpen(false);
  };

  //console.log('1111 basic info strategyData = %o', strategyData);
  return (
    <div className={classes.root}>
      {open && (
        <SubscribePortfolioDialog
          open={open}
          strategyType="AQM"
          onSubscribePortfolio={handleSubscribeAqm}
          onClose={handleSubscribeClose}
          trackAccount={trackAccount}
          strategy={strategyData}
          aria-labelledby="responsive-dialog-title"
        ></SubscribePortfolioDialog>
      )}
      {openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      {redirectMyPortfolio && <Redirect to="/admin/aqmModelAccount" />}
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>AQM Model Account Name</span>
          </ListItemIcon>
          <div>
            <span className={classes.listItemTextWidth}>
              {strategyNameId}
            </span>
            <Button
              onClick={handleOnClickSubscribeStrategy}
              color="info"
              className={classes.label}
              style={{ marginLeft: 20, height: 30 }}
            >
              Subscribe AQM
            </Button>
          </div>
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Current Status</span>
          </ListItemIcon>
          <ListItemText className={classes.listItemTextWidth} primary={strategyData.status} />
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Create Time</span>
          </ListItemIcon>
          <ListItemText
            className={classes.listItemTextWidth}
            primary={getValidDateTime(strategyData.createTime)}
          />
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Modified Time</span>
          </ListItemIcon>
          <ListItemText
            className={classes.listItemTextWidth}
            primary={getValidDateTime(strategyData.modifiedTime)}
          />
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Developer Name</span>
          </ListItemIcon>
          <ListItemText
            className={classes.listItemTextWidth}
            primary={strategyData?.developerName ?? 'GoPIPA'}
          />
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Model Account Description</span>
          </ListItemIcon>
          <ListItemText className={classes.listItemTextWidth} primary={strategyData.description} />
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Model Account Investment Goal</span>
          </ListItemIcon>
          <ListItemText className={classes.listItemTextWidth} primary={strategyData.investmentGoal} />
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Model Account Investment Method</span>
          </ListItemIcon>
          <ListItemText className={classes.listItemTextWidth} primary={strategyData.investmentMethod} />
        </ListItem>
      </List>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Model Account Risk Level</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy?.riskLevel[strategyData?.riskLevel]}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Investment Type</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData?.componentType ?? 'Stock and ETF'}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Long and/or Short</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy?.longShort[strategyData?.longShort]}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Use Margin</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData.useMargin ? 'Yes' : 'No'}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Allow Paper Trading</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData.allowPaperTrading ? 'Yes' : 'No'}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Annual Management Fee (% of AUM)</span>
        </ListItemIcon>
        <ListItemText className={classes.listItemTextWidth} primary={strategyData.chargeAmount} />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Minimum to Investor Member Class</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy?.limitToMemberClass[strategyData?.limitToMemberClass]}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Market Type</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData?.marketType ?? 'US'}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Min Investment Amount</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData.minInvestmentAmount.toLocaleString()}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Max Investment Amount</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData?.maxInvestmentAmount.toLocaleString()}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Min Investment Period</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={
            strategyData.minInvestmentPeriod ? strategyData.minInvestmentPeriod : '12' + ' months'
          }
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Open for New Investors</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData.openForInvestor ? 'Yes' : 'No'}
        />
      </ListItem>
    </div>
  );
}

AqmStrategyBasicInfoView.propTypes = {
  strategyData: PropTypes.object,
};
