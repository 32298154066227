import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import data from './../../data/aapl-ohlcv.json';
var ohlc = [],
  volume = [],
  dataLength = data.length,
  i = 0;
for (i; i < dataLength; i += 1) {
  ohlc.push([
    data[i][0], // the date
    data[i][1], // open
    data[i][2], // high
    data[i][3], // low
    data[i][4], // close
  ]);

  volume.push([
    data[i][0], // the date
    data[i][5], // the volume
  ]);
}
const options = {
  yAxis: [
    {
      labels: {
        align: 'left',
      },
      height: '80%',
      resize: {
        enabled: true,
      },
    },
    {
      labels: {
        align: 'left',
      },
      top: '80%',
      height: '20%',
      offset: 0,
    },
  ],
  tooltip: {
    shape: 'square',
    headerShape: 'callout',
    borderWidth: 0,
    shadow: false,
    positioner: function (width, height, point) {
      var chart = this.chart,
        position;

      if (point.isHeader) {
        position = {
          x: Math.max(
            // Left side limit
            chart.plotLeft,
            Math.min(
              point.plotX + chart.plotLeft - width / 2,
              // Right side limit
              chart.chartWidth - width - chart.marginRight
            )
          ),
          y: point.plotY,
        };
      } else {
        position = {
          x: point.series.chart.plotLeft,
          y: point.series.yAxis.top - chart.plotTop,
        };
      }

      return position;
    },
  },
  series: [
    {
      type: 'ohlc',
      id: 'aapl-ohlc',
      name: 'Strategy Performance',
      data: ohlc,
    },
    {
      type: 'column',
      id: 'aapl-volume',
      name: 'Volume',
      data: volume,
      yAxis: 1,
    },
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 800,
        },
        chartOptions: {
          rangeSelector: {
            inputEnabled: false,
          },
        },
      },
    ],
  },
};

const StockChart = ({ highcharts }) => (
  <HighchartsReact highcharts={highcharts} constructorType={'stockChart'} options={options} />
);
export default StockChart;
