import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import { getCashChangeActivities, cancelInvestmentAmount } from '../../utils/portfolio.utils.ts';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog.view';
import PropTypes from 'prop-types';
import SortIcon from '@material-ui/icons/ArrowDownward';
import DataTable from 'react-data-table-component';
import { getEstDateTime } from '../../utils/common';
import Button from '@material-ui/core/Button';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  label: {
    textTransform: 'capitalize',
    fontSize: 14,
    marginLeft: -32,
  },
};

const useStyles = makeStyles(styles);
export default function PortfolioInvestmentAmountActivityTableView(props) {
  const classes = useStyles(styles);
  const [portfolioActivity, setPortfolioActivity] = React.useState([]);
  const [openDlg, setOpenDlg] = React.useState({
    open: false,
    id: null,
  });
  const { portfolioId } = props;

  const handleOnClickStop = (id) => {
    setOpenDlg({ open: true, id });
  };

  const handleCancelClick = (result) => {
    if (result) {
      cancelInvestmentAmount(openDlg.id)
        .then((response) => {
          console.debug(
            'PortfolioInvestmentAmountActivityTableView.view: cancelInvestmentAmount Portfolio responseData = %o',
            response
          );
          setOpenDlg({ open: false, id: null });
          fetchData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setOpenDlg({ open: false, id: null });
    }
  };

  const getTradeDate = (tradeDate) => {
    if (tradeDate) {
      const year = tradeDate.substring(0, 4);
      const month = tradeDate.substring(4, 6);
      const day = tradeDate.substring(6);
      return `${year}-${month}-${day}`;
    } else {
      return '';
    }
  };

  const columns = [
    {
      name: 'Operate Time',
      selector: 'createTime',
      sortable: true,
      cell: (row) => {
        return <div>{getEstDateTime(row?.createTime)}</div>;
      },
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
    },
    {
      name: 'Cash Change ($)',
      selector: 'cash',
      sortable: true,
    },
    {
      name: 'Trade Date',
      selector: 'tradeDate',
      sortable: true,
      cell: (row) => {
        return <div>{getTradeDate(row?.tradeDate)}</div>;
      },
    },
    {
      name: 'Action',
      selector: 'action',
      sortable: false,
      cell: (row) => {
        return (
          <div>
            {row.status === 'ReadyToExecute' ? (
              <Button
                onClick={() => handleOnClickStop(row.id)}
                color="primary"
                classes={{ label: classes.label }}
                id={row.id}
              >
                Stop
              </Button>
            ) : (
              <div></div>
            )}
          </div>
        );
      },
    },
  ];

  const fetchData = React.useCallback(() => {
    if (portfolioId) {
      getCashChangeActivities(portfolioId)
        .then((response) => {
          setPortfolioActivity(response?.data?.data ?? []);
        })
        .catch((error) => {
          console.debug(error);
        });
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <DataTable
        title="Investment Amount Activities"
        data={portfolioActivity}
        columns={columns}
        // fixedHeader
        highlightOnHover
        striped
        sortIcon={<SortIcon />}
        noHeader={true}
      />
      {openDlg.open && (
        <ConfirmationDialog
          dlgData={{ result: 'Yes' }}
          handleDeleteDialogClick={handleCancelClick}
          open={openDlg.open}
          dlgTitle={'Investment Amount Change'}
          dlgContentText={'Are you sure you want to cancel your investment amount change?'}
        />
      )}
    </div>
  );
}

PortfolioInvestmentAmountActivityTableView.propTypes = {
  portfolioId: PropTypes.string,
};
