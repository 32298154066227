import React from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import { getTextColor } from '../../utils/common';

const strategyDetailsPathName = '/admin/strategyDetails';
const columns = [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    minWidth: 5,
    format: (row) => {
      return row?.id ?? '';
    },
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    minWidth: '310px',
    cell: (row) => {
      return (
        <div>
          {row.name === 'S&P500' ? (
            <span>{row.name}</span>
          ) : (
            <Link
              className={getTextColor(row.name)}
              to={{
                pathname: strategyDetailsPathName,
                state: {
                  strategyId: row.id,
                  strategyName: row.name,
                },
              }}
            >{`${row.name}`}</Link>
          )}
        </div>
      );
    },
  },
  {
    name: 'Total Return',
    selector: 'totalReturn',
    sortable: true,
    right: true,
    minWidth: '120px',
    format: (row) =>
      row?.totalReturn === 0
        ? '0%'
        : row?.totalReturn
        ? (row?.totalReturn * 100).toFixed(2) + '%'
        : 'N/A',
  },
  {
    name: 'Annualized Return',
    sortable: true,
    selector: 'annualReturn',
    right: true,
    minWidth: '150px',
    cell: (row) => {
      const data =
        row?.annualReturn === 0
          ? '0%'
          : row?.annualReturn
          ? (row?.annualReturn * 100).toFixed(2) + '%'
          : 'N/A';
      return (
        <div>
          <span>{data}</span>
        </div>
      );
    },
  },
  {
    name: 'CAGR',
    selector: 'cagr',
    sortable: true,
    right: true,
    format: (row) =>
      row?.cagr === 0 ? '0%' : row?.cagr ? (row?.cagr * 100).toFixed(2) + '%' : 'N/A',
  },
  {
    name: 'Alpha',
    selector: 'alpha',
    sortable: true,
    right: true,
    // width: '80px',
    format: (row) => (row?.alpha === 0 ? '0' : row?.alpha ? row?.alpha.toFixed(2) : 'N/A'),
  },
  {
    name: 'Beta',
    selector: 'beta',
    sortable: true,
    right: true,
    // width: '80px',
    format: (row) => (row?.beta === 0 ? '0' : row?.beta ? row?.beta.toFixed(2) : 'N/A'),
  },
  {
    name: 'Sharpe',
    selector: 'sharpe',
    sortable: true,
    right: true,
    // width: '80px',
    format: (row) => (row?.sharpe === 0 ? '0' : row?.sharpe ? row?.sharpe.toFixed(2) : 'N/A'),
  },
  {
    name: 'Sortino',
    selector: 'sortino',
    sortable: true,
    right: true,
    // width: '80px',
    format: (row) => (row?.sortino === 0 ? '0' : row?.sortino ? row?.sortino.toFixed(2) : 'N/A'),
  },
  {
    name: 'Volatility',
    selector: 'volatility',
    sortable: true,
    right: true,
    format: (row) =>
      row?.volatility === 0
        ? '0%'
        : row?.volatility
        ? (row?.volatility * 100).toFixed(2) + '%'
        : 'N/A',
  },
  {
    name: 'MaxDD',
    selector: 'maxDrawdown',
    sortable: true,
    right: true,
    format: (row) =>
      row?.maxDrawdown === 0
        ? '0%'
        : row?.maxDrawdown
        ? (row?.maxDrawdown * 100).toFixed(2) + '%'
        : 'N/A',
  },
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    width: '120px',
    format: (row) => {
      return row?.category ?? '';
    },
  },
  {
    name: 'Type',
    selector: 'algoType',
    sortable: true,
    width: '120px',
    format: (row) => {
      return row?.algoType ?? '';
    },
  },
  {
    name: 'Risk Level',
    selector: 'riskLevel',
    sortable: true,
    width: '160px',
    format: (row) => {
      return row?.riskLevel ?? '';
    },
  },
];

class CompareBacktestTableListView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { strategyCompareData, index } = this.props;
    const periodInfp = `${index}. BackTest Period：${strategyCompareData.begin} - ${strategyCompareData.end}`;
    return (
      <DataTable
        title={periodInfp}
        data={strategyCompareData.data}
        columns={columns}
        dense={true}
        defaultSortField="name"
        defaultSortAsc={true}
        // fixedHeaderScrollHeight="62vh"
        fixedHeader
        highlightOnHover
        striped
        sortIcon={<SortIcon />}
      />
    );
  }
}

export default CompareBacktestTableListView;
