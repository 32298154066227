import { environment } from 'environments/environment';
import { getDefaultConfig } from './common';
import {fetchJSON, postJSON} from './fetch.js';

export const getDashboard = () => {
  const url = `${environment.api_url}users/getDashboardData`;
  console.debug('dashboard.utils getDashboard url = %s', url);
  return fetchJSON(url, getDefaultConfig(null));
};

export const getNewsletter = (version: string) => {
  const url = `${environment.api_url}users/getNewsletter?version=${version}`;
  console.debug('dashboard.utils getNewsletter url = %s', url);
  return fetchJSON(url, getDefaultConfig(null));
};

export const getInvestmentSummary = () => {
  const url = `${environment.api_url}portfolios/investmentSummary`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getUserOption = (option: string) => {
  const url = `${environment.api_url}users/getUserOption?option=${option}`;
  // const url = `http://localhost:8080/svr/user/users/getUserOption?option=${option}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const setUserOption = (option: string, value: string) => {
  const url = `${environment.api_url}users/setUserOption?option=${option}&value=${value}`;
  // const url = `http://localhost:8080/svr/user/users/setUserOption?option=${option}&value=${value}`;
  return postJSON(url, {}, getDefaultConfig(null));
};

