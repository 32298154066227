import { environment } from 'environments/environment';
import { getUserId, getDefaultConfig } from './common';
import { fetchJSON, fetch, postJSON, putJSON, deleteJSON, putJSONNoPayload } from './fetch.js';
export type UserLogin = {
  username: string;
  password: string;
  token: string;
};

export type UserSignUp = {
  userName: string;
  email: string;
  password: string;
  inviteCode: string;
  referral: string;
  mobile: string;
  firstName: string;
  lastName: string;
};

export type UpdateUserAccountInfo = {
  username?: string | '';
  email?: string | '';
  mobile?: string | '';
  oldpassword?: string | '';
  newpassword?: string | '';
  investorState: string;
  developerState: string;
};

export type UpdateUserProfile = {
  annualIncome?: number | '';
  birthday?: string | '';
  investmentExp?: string | '';
  investmentGoal?: string | '';
  maritalStatus?: string | '';
  realName?: string | '';
  totalAsset?: number | '';
  totalLiquidityAsset?: number | '';
  userId: string;
  workingStatus?: string | '';
  yearOfInvestExp?: number | '';
};

export type TrackAccount = {
  id: string | null;
  userId: string;
  broker: string;
  tradeType: string;
  trackAccountName: string;
  userName: string;
  account: string;
  password: string;
};

export type RiskAssessment = {
  answer: object;
};

const loginUser = 'users/currentLoginUser';
const loginUserUrl = `${environment.api_url}${loginUser}`;

export const login = (userLogin: UserLogin) => {
  const url = `${environment.api_url}public/login?clientId=PiPA&clientSecret=secret&userNameOrEmail=${userLogin.username}&password=${userLogin.password}&codeToken=${userLogin.token}`;
  return fetchJSON(encodeURI(url))
    .then((reponse) => {
      console.debug('repo = %o', reponse);
      const data = reponse && reponse.data ? reponse.data : null;
      if (!data || data.code !== 200) {
        return data;
      } else {
        const access_token = data ? data.data?.access_token : '';
        // get the login user information
        // data.data.access_token
        fetch(loginUserUrl, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
          .then((res) => {
            const data = res && res.data ? res.data : null;
            console.debug('currentLoginUser data = %o', data);
            if (!data || data.code !== 200) {
              return data;
            } else {
              const useAgreement = true;
              const userId = data ? data.data?.userId : '';
              const userName = data ? data.data?.userName : '';
              const firstName = data ? data.data?.firstName : '';
              const lastName = data ? data.data?.lastName : '';
              const riskLevel = data?.data?.riskLevel
                ? `R${data?.data?.riskLevel}: ${data?.data?.riskText}`
                : '';
              const userData = data ? data.data : {};
              // environment.currentUser = userData;
              localStorage.setItem(environment.userNameKey, userName);
              localStorage.setItem(environment.userIdKey, JSON.stringify(userId));
              localStorage.setItem(environment.userRiskLevelKey, riskLevel);
              localStorage.setItem(environment.tokenKey, access_token);
              localStorage.setItem(environment.currentUser, userData);
              localStorage.setItem(environment.membership, userData.investorState);
              // console.info('user data state: %o', userData.investorState);
              if (useAgreement && !data?.data.agreementRev) {
                let backupToken = access_token;
                window.addEventListener("beforeunload", (ev) =>
                {
                  localStorage.removeItem(environment.tokenKey);
                });
                window.location.href = `../admin/userAgreement?token=${backupToken}&firstName=${firstName}&lastName=${lastName}`;
              } else {
                localStorage.setItem(environment.tokenKey, access_token);
                window.location.href = '../admin/dashboard';
              }
            }
          })
          .catch((error) => {
            console.debug('current user error = %o', error);
            console.error(error);
          });
      }
    })
    .catch((error) => {
      console.debug('login user error = %o', error);
      console.error(error);
      return error;
    });
};

export const getLoginUser = (token: string | null) => {
  let tokenKey = token;
  if (!tokenKey) {
    tokenKey = localStorage.getItem(environment.tokenKey);
  }

  return fetch(loginUserUrl, {
    headers: {
      Authorization: `Bearer ${tokenKey}`,
    },
  });
};

export const logout = () => {
  console.debug('### user.utils: logout');
  localStorage.removeItem(environment.tokenKey);
  localStorage.removeItem(environment.forumTokenKey);
  localStorage.removeItem(environment.userIdKey);
  localStorage.removeItem(environment.userNameKey);
  localStorage.removeItem(environment.userRiskLevelKey);
  environment.currentUser = {};
  window.location.href = '../admin/home';
};

export const reset = () => {
  localStorage.removeItem(environment.tokenKey);
  localStorage.removeItem(environment.forumTokenKey);
  localStorage.removeItem(environment.userIdKey);
  localStorage.removeItem(environment.userNameKey);
  localStorage.removeItem(environment.userRiskLevelKey);
  environment.currentUser = {};
  window.location.href = '../admin/dashboard';
};

export const forgotPasswordChange1 = (email: string) => {
  console.debug('user.utils: forgotPasswordChange email = %s', email);
  const url = `${environment.api_url}public/requestResetPassword`;
  const params = { email };
  postJSON(url, params)
    .then((res) => {
      console.debug('user.utils: res = %o', res);
      // @ts-ignore
      const code = res ? res.data.code : null;
      if (code === 200) {
        console.debug('user.utils: forgotPasswordChange - NO ERROR');
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const forgotPasswordChange = (email: string) => {
  console.debug('user.utils: forgotPasswordChange email = %s', email);
  const url = `${environment.api_url}public/requestResetPassword?email=${email}`;
  return putJSON(encodeURI(url), {});
};

export const resetPassword = (param: string, password: string) => {
  console.debug('user.utils: resetPassword param = %s password = %s', param, password);
  const url = `${environment.api_url}public/resetPassword?param=${param}&password=${password}`;
  return putJSON(encodeURI(url), {});
};

export const signUpAccount = (userSignUp: UserSignUp) => {
  // console.info('user.utils: signUpAccount userSignUp = %o', userSignUp);
  let url = `${environment.api_url}public/register?userName=${userSignUp.userName}&email=${userSignUp.email}&password=${userSignUp.password}&inviteCode=${userSignUp.inviteCode}&firstName=${userSignUp.firstName}&lastName=${userSignUp.lastName}`;
  if (userSignUp.referral) {
    url += `&referral=${userSignUp.referral}`;
  }
  return postJSON(encodeURI(url), {});
};

export const modifyMobile = (updateUserAccountInfo: UpdateUserAccountInfo) => {
  const tokenKey = localStorage.getItem(environment.tokenKey);
  console.debug('user.utils: modifyMobile updateUserAccountInfo = %o', updateUserAccountInfo);
  const url = `${environment.api_url}users?mobile=${updateUserAccountInfo.mobile}`;

  return putJSON(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${tokenKey}`,
      },
    }
  );
};

export const modifyEmail = (updateUserAccountInfo: UpdateUserAccountInfo) => {
  const tokenKey = localStorage.getItem(environment.tokenKey);
  console.debug('user.utils: modifyEmail updateUserAccountInfo = %o', updateUserAccountInfo);
  const url = `${environment.api_url}users/changeEmail?email=${updateUserAccountInfo.email}`;

  return putJSON(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${tokenKey}`,
      },
    }
  );
};

export const modifyPassword = (updateUserAccountInfo: UpdateUserAccountInfo) => {
  const tokenKey = localStorage.getItem(environment.tokenKey);
  console.debug('user.utils: modifyEmail updateUserAccountInfo = %o', updateUserAccountInfo);
  const url = `${environment.api_url}users/changepwd?oldpassword=${updateUserAccountInfo.oldpassword}&newpassword=${updateUserAccountInfo.newpassword}`;

  return putJSON(
    encodeURI(url),
    {},
    {
      headers: {
        Authorization: `Bearer ${tokenKey}`,
      },
    }
  );
};

export const disableUserMembershipRights = (updateUserAccountInfo: UpdateUserAccountInfo) => {
  console.debug('user.utils: modifyEmail updateUserAccountInfo = %o', updateUserAccountInfo);
  let url = `${environment.api_url}users/disableInvestorState`;
  return putJSON(url, {}, getDefaultConfig(null));
};

export const enableUserMembershipRights = (signature: string) => {
  console.debug('user.utils: enableUserMembershipRights signature = %o', signature);
  let url = `${environment.api_url}users/enableInvestorState?signature=${signature}`;
  return putJSON(url, {}, getDefaultConfig(null));
};

export const getUserProfile = () => {
  let url = `${environment.api_url}users/accountProfiles`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const updateUserProfile = (userProfile: UpdateUserProfile) => {
  userProfile.userId = getUserId();
  const url = `${environment.api_url}users/accountProfiles`;
  return postJSON(url, userProfile, getDefaultConfig(null));
};

export const getTrackAccount = () => {
  const url = `${environment.api_url}users/trackAccount`;
  return fetchJSON(url, getDefaultConfig(null));
};

// 订阅AQM时的ib账号列表调用：/svr/user/users/trackAccountForAQMSubscribe
export const getTrackAccountAQMSubscribe = () => {
  const url = `${environment.api_url}users/trackAccountForAQMSubscribe`;
  return fetchJSON(url, getDefaultConfig(null));
};

//创建portfolio时显示的可用ib账号列表调用 svr/user/users/trackAccountForPortfolio
export const getTrackAccountAQSPortfolio = () => {
  const url = `${environment.api_url}users/trackAccountForPortfolio`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getAccountBrokerProps = () => {
  let url = `${environment.api_url}users/accountBrokerProps`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const newEditTrackAccount = (params: TrackAccount) => {
  // POST: create/edit a new account
  params.userId = getUserId();
  let url = `${environment.api_url}users/trackAccount`;
  return postJSON(url, params, getDefaultConfig(null));
};

export const editTrackAccount = () => {
  // PUT: update an account
  let url = `${environment.api_url}users/trackAccount`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const refreshTrackAccount = (accountId: number) => {
  let url = `${environment.api_url}users/refreshTrackAccount?trackAccountId=${accountId}`;
  return putJSON(url, {}, getDefaultConfig(null));
};

export const deleteTrackAccount = (accountId: number) => {
  let url = `${environment.api_url}users/trackAccount?trackAccountId=${accountId}`;
  return deleteJSON(url, {}, getDefaultConfig(null));
};

export const validateEmailAccount = (param: string) => {
  console.debug('user.utils: validateEmailAccount = %o', param);
  let url = `${environment.api_url}public/validate/email?param=${param}`;
  return putJSONNoPayload(url, {}, getDefaultConfig(null));
};

///svr/user/users/riskAssessment
export const getRiskAssessment = () => {
  console.debug('user.utils: getRiskAssessment');
  let url = `${environment.api_url}users/riskAssessment`;
  return fetchJSON(url, getDefaultConfig(null));
};

//answerRiskAssessmentQuestionnaire answer: {"1015":4,"1014":5,"1013":3} signature: PUT
export const setRiskAssessment = (param: RiskAssessment) => {
  const answer = JSON.stringify(param.answer);
  console.debug(
    'user.utils: setRiskAssessment param.answer string = %s',
    JSON.stringify(param.answer)
  );
  const url = `${environment.api_url}users/answerRiskAssessment?answer=${answer}`;
  return putJSON(encodeURI(url), {}, getDefaultConfig(null));
};

export const signUserRiskLevel = (signature: string) => {
  const url = `${environment.api_url}users/signUserRiskLevel?signature=${signature}`;
  return putJSON(url, {}, getDefaultConfig(null));
};

export const getUserAgreement = (token: string) => {
  console.debug('user.utils: getUserAgreement');
  let url = `${environment.api_url}users/getUserAgreement`;
  return fetchJSON(url, getDefaultConfig(token));
};

export const signUserAgreement = (signature: string, token: string) => {
  console.debug('user.utils: signUserAgreement');
  let url = `${environment.api_url}users/signUserAgreement?signature=${signature}`;
  return putJSON(url, {}, getDefaultConfig(token));
};

export const getTdAuthUrl = (account: string, nickname: string) => {
  const url = `${environment.api_url}users/tdAuthUrl?account=${account}&nickName=${nickname}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getWebapiRefreshToken = (accountId: string) => {
  const url = `${environment.api_url}users/webapiRequestTokenForRebind?trackAccountId=${accountId}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getVerificationImage = () => {
  let url = `${environment.api_url}public/verificationCode?codeType=IMAGE_1`;
  return fetchJSON(url, {});
};

export const setVerificationCode = (args: string, token: string) => {
  let url = `${environment.api_url}public/verifyCode?args=${args}&codeToken=${token}`;
  return putJSON(url, {});
};

export const getSMSCode = (userLogin: UserLogin) => {
  const url = `${environment.api_url}public/getSMSCode?clientId=PiPA&clientSecret=secret&userNameOrEmail=${userLogin.username}&password=${userLogin.password}`;
  // const url = `http://localhost:8080/svr/user/public/getSMSCode?clientId=PiPA&clientSecret=secret&userNameOrEmail=${userLogin.username}&password=${userLogin.password}`;
  return fetchJSON(encodeURI(url));
};

export const createPaperAccount = (nickName: string, value: number ) => {
  const url = `${environment.api_url}simutrader/newSimuAccount?trackAccountName=${nickName}&initCash=${value}`;
  return postJSON(url, {}, getDefaultConfig(null));
};

export const adjustPaperAccount = (accountId: string, value: number ) => {
  const url = `${environment.api_url}simutrader/adjustCash?trackAccountId=${accountId}&cash=${value}`;
  return putJSON(url, {}, getDefaultConfig(null));
};

export const changeTrackAccountNickName = (nickName: string, trackAccountId: string ) => {
  const url = `${environment.api_url}users/trackAccountNickname?nickname=${nickName}&trackAccountId=${trackAccountId}`;
  return putJSON(url, {}, getDefaultConfig(null));
};

export const getAccountPosition = (trackAccountId: string) => {
  const url = `${environment.api_url}users/getBrokerAccountPosition?trackAccountId=${trackAccountId}`;
  return fetchJSON(encodeURI(url), getDefaultConfig(null));
};

export const askIPIPA = (id: string, question: string) => {
  const url = `${environment.api_url}topic/ask?topicId=${id}`;
  return postJSON(url, question, getDefaultConfig(null));
}

export const getTopics = () => {
  const url = `${environment.api_url}topic`;
  return fetchJSON(encodeURI(url), getDefaultConfig(null));
}

export const createTopics = () => {
  const url = `${environment.api_url}topic`;
  const param = {
    topic: 'new topic',
  }
  return postJSON(url, param, getDefaultConfig(null));
}

export const referFriends = (emails: string) => {
  const url = `${environment.api_url}users/referFriends?emails=${emails}`;
  return postJSON(url, {}, getDefaultConfig(null));
}

export const getTrackAccountForIDA = () => {
  const url = `${environment.api_url}users/trackAccountForIDA`;
  // const url = 'http://localhost:8080/svr/user/users/trackAccountForIDA';
  return fetchJSON(url, getDefaultConfig(null));
};

export const confirmUserRiskLevelChange = (signature: string, risklevel: string) => {
  const url = `${environment.api_url}users/confirmUserRiskLevelChange?signature=${signature}&risklevel=${risklevel}`;
  return putJSON(url, {}, getDefaultConfig(null));
};
