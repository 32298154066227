import React from 'react';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import PortfolioStatsView from './PortfolioStats.view.tsx';

export default function PortfolioMonitorStatsView(props) {
  const { seriesData, classes } = props;
  const alphaType = 'Alpha',
    betaType = 'Beta',
    sortinoType = 'Sortino',
    volatilityType = 'Volatility',
    sharpeType = 'Sharpe';
  // console.log('SUNNY222 PortfolioMonitorStatsView volatilitySeriesData = %o', seriesData.volatilitySeriesData);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <PortfolioStatsView
                classes={classes}
                seriesData={seriesData.alphaSeriesData}
                type={alphaType}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <PortfolioStatsView
                classes={classes}
                seriesData={seriesData.betaSeriesData}
                type={betaType}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <PortfolioStatsView
                classes={classes}
                seriesData={seriesData.sharpeSeriesData}
                type={sharpeType}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <PortfolioStatsView
                classes={classes}
                seriesData={seriesData.sortinoSeriesData}
                type={sortinoType}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <PortfolioStatsView
                classes={classes}
                seriesData={seriesData.volatilitySeriesData}
                type={volatilityType}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
