import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import StopIcon from '@material-ui/icons/Stop';
import PersonalVideo from '@material-ui/icons/PersonalVideo';
import moment from 'moment';
import classNames from 'classnames';
// core components
import noperf from 'assets/img/noperf.svg';
import styles from 'assets/jss/material-dashboard-react/components/strategyCardStyle.js';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { getSvg } from 'utils/strategy.utils';
import StopAqmDialogView from '../../Dialog/StopAqmDialog.view';
import {getEstDateTime, getValidDateTime} from "../../../utils/common";

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function MyAqmStrategyView(props) {
  const classes = useStyles();
  const [openStopAqmDlg, setOpenStopAqmDlg] = React.useState(false);
  const { strategyDetail, trackAccount, strategyType, aqmStatus } = props;
  const time = Math.floor(new Date().getTime() / 3600000);
  const positionPathName = '/admin/accountPosition';

  const trackingInfoImage = getSvg(
    strategyDetail.id,
    `.svg?t=${time}`,
    strategyDetail.subPath
  );

  const tradeDate = strategyDetail.tradeDate
      ? moment(strategyDetail.tradeDate).tz("America/New_York").format('YYYY-MM-DD')
      : 'N/A';

  const activeTime = strategyDetail.activeTime
      ? moment(strategyDetail.activeTime.replace(/\.\d+/, "").replace(/-/g,"/")).tz("America/New_York").format('YYYY-MM-DD')
      : moment(strategyDetail.createTime.replace(/\.\d+/, "").replace(/-/g,"/")).tz("America/New_York").format('YYYY-MM-DD');

  const trackingTime = `${activeTime}~${tradeDate}`;

  const aqmId = strategyDetail.aqmId;

  const [value, setValue] = React.useState(0);

  const handleOpenStopAqmDlg = () => {
    setOpenStopAqmDlg(true);
  };

  const handleStopDialogClose = () => {
    setOpenStopAqmDlg(false);
  };

  const dollarFormat = (value) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value);

  //console.log('111 My AQM aqmStatus = %s strategyDetail = %o', aqmStatus, strategyDetail);
  return (
    <div>
      {openStopAqmDlg && (
        <StopAqmDialogView
          openStopAqmDlg={openStopAqmDlg}
          handleStopDialogClose={handleStopDialogClose}
          strategyId={strategyDetail.id}
          strategyName={strategyDetail.name}
        />
      )}
      <div>
        <div style={{ float: 'left', width: '80%', marginTop: 3 }}>
          <h6 style={{ margin: '0' }}>
            <span style={{ fontSize: '1.1em' }}>
              {strategyDetail.name} {`(ID: ${strategyDetail.id})`}
            </span>
          </h6>
        </div>
        {aqmStatus === 'Subscribed' && (
          <div className={classes.myAQMIconPanel}>
            <Tooltip title="Stop">
              <IconButton
                className={classes.iconPadding}
                aria-label="stop"
                onClick={() => handleOpenStopAqmDlg()}
              >
                <StopIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Account Position">
              <IconButton className={classes.iconPadding} aria-label="Account Position">
                <Link
                    to={{
                      pathname: positionPathName,
                      state: {
                        aqmOrSubscribeId: strategyDetail.id,
                        aqmName: strategyDetail.name,
                      },
                    }}
                >
                  <PersonalVideo color="primary" fontSize="small"/>
                </Link>
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>

      <table width="100%">
        <tbody>
          <tr>
            <td colSpan="4">
              <div className={classes.strategyHr}></div>{' '}
            </td>
          </tr>
          <tr>
            <td>
              <span>Broker Account: </span>
              <span className={classes.titleValue}>{strategyDetail.brokerAccountName}</span>
            </td>
            <td>
              <span>Account Type: </span>
              <span className={classes.titleValue}>{(strategyDetail.brokerAccountName.indexOf('DU') === 0) || (strategyDetail.brokerAccountName.indexOf('P') === 0)?'Paper Account':'Money Account'}</span>
            </td>
            <td>
              <span>Account Value: </span>
              <span className={classes.titleValue}>{strategyDetail.lastPerfLiquidation?dollarFormat(strategyDetail.lastPerfLiquidation):'N/A'}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div className={classes.strategyHr}></div>
      <div>
        <TabPanel value={value} index={0}>
          <div>
            <span>Period: </span>
            <span className={classes.titleValue}>{trackingTime}</span>
          </div>
          <div>
            <span>Subscribed Model Account: </span>
            <span className={classes.titleValue}>(ID: {aqmId})</span>
          </div>
          <div>
            <span
              className={classNames(classes.chartStrategyLegned, classes.chartStrategySpan)}
            ></span>
            <span>Total Return: </span>
            <span className={classes.titleValue}>
              {strategyDetail?.cumReturn === 0
                ? '0%'
                : strategyDetail?.cumReturn
                ? (strategyDetail.cumReturn * 100).toFixed(2) + '%'
                : 'N/A'}
            </span>
            <span
              className={classNames(
                classes.chartStrategyLegned,
                classes.chartBenchmarkSpan,
                classes.marginLeft60
              )}
            ></span>
            <span>S&P 500 Return: </span>
            <span className={classes.titleValue}>
              {strategyDetail?.benchmarkCumReturn === 0
                ? '0%'
                : strategyDetail?.benchmarkCumReturn
                ? (strategyDetail.benchmarkCumReturn * 100).toFixed(2) + '%'
                : 'N/A'}
            </span>
          </div>
          <table width="100%">
            <tbody>
              <tr>
                <td colSpan="4">
                  {' '}
                  <img
                    src={trackingInfoImage}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noperf;
                    }}
                    alt="logo"
                    height="250"
                    className={classes.img}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <span>Last Check Time of Account Value: </span>
                  <span className={classes.titleValue}>
                    {getEstDateTime(getValidDateTime(strategyDetail.tradeDate, 'YYYY-MM-DD') + " 05:06")}
              </span>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table table-hover table-condensed table-striped table-bordered">
            <tr className={classes.tableTrOdd}>
              <td width="30%"></td>
              <td width="35%">AQM Model Account</td>
              <td>S&P 500</td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>CAGR</td>
              <td>
                {strategyDetail?.algoCAGR === 0
                  ? '0%'
                  : strategyDetail?.algoCAGR
                  ? (strategyDetail.algoCAGR * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.benchmarkCAGR === 0
                  ? '0%'
                  : strategyDetail?.benchmarkCAGR
                  ? (strategyDetail.benchmarkCAGR * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrOdd}>
              <td>Alpha</td>
              <td>
                {strategyDetail?.alpha === 0
                  ? '0'
                  : strategyDetail?.alpha
                  ? strategyDetail?.alpha.toFixed(2)
                  : 'N/A'}
              </td>
              <td>0</td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>Beta</td>
              <td>
                {strategyDetail?.beta === 0
                  ? '0'
                  : strategyDetail?.beta
                  ? strategyDetail?.beta.toFixed(2)
                  : 'N/A'}
              </td>
              <td>1</td>
            </tr>
            <tr className={classes.tableTrOdd}>
              <td>Sharpe</td>
              <td>
                {strategyDetail?.sharpe === 0
                  ? '0'
                  : strategyDetail?.sharpe
                  ? strategyDetail?.sharpe.toFixed(2)
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.benchmarkSharpe === 0
                  ? '0'
                  : strategyDetail?.benchmarkSharpe
                  ? strategyDetail?.benchmarkSharpe.toFixed(2)
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>Sortino</td>
              <td>
                {strategyDetail?.sortino === 0
                  ? '0'
                  : strategyDetail?.sortino
                  ? strategyDetail?.sortino.toFixed(2)
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.benchmarkSortino === 0
                  ? '0'
                  : strategyDetail?.benchmarkSortino
                  ? strategyDetail?.benchmarkSortino.toFixed(2)
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrOdd}>
              <td>Volatility</td>
              <td>
                {strategyDetail?.algoVolatility === 0
                  ? '0%'
                  : strategyDetail?.algoVolatility
                  ? (strategyDetail.algoVolatility * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.benchmarkVolatility === 0
                  ? '0%'
                  : strategyDetail?.benchmarkVolatility
                  ? (strategyDetail.benchmarkVolatility * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>MaxDD</td>
              <td>
                {strategyDetail?.trackingReport?.maxDrawdown === 0
                    ? '0%'
                    : strategyDetail?.maxDrawdown
                        ? (strategyDetail.maxDrawdown * 100).toFixed(2) + '%'
                        : 'N/A'}
              </td>
              <td>
                {strategyDetail?.benchmarkMaxDrawdown === 0
                  ? '0%'
                  : strategyDetail?.benchmarkMaxDrawdown
                  ? (strategyDetail?.benchmarkMaxDrawdown * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
            </tr>
          </table>
        </TabPanel>
      </div>
    </div>
  );
}

MyAqmStrategyView.propTypes = {
  strategyDetail: PropTypes.object,
  trackAccount: PropTypes.array,
  strategyType: PropTypes.string,
  aqmStatus: PropTypes.string,
};
