import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import CoreButton from '@material-ui/core/Button';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Checkbox from '@material-ui/core/Checkbox';
import {
  getLoginUser,
  disableUserMembershipRights,
  enableUserMembershipRights,
  UpdateUserAccountInfo,
} from '../../utils/user.utils';
import {
  getInvestorMembership,
  getUserMembershipInfo,
  subscribeMembershipOnSuccess,
  updateUserPayment,
  deleteUserPayment,
} from '../../utils/billing.utils';
import AlertView from 'components/Alert/Alert.view';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog.view';
import EnableMembershipDialog from '../../components/Dialog/EnableMembershipDialog.view';
import SubscribeMembershipView from './SubscribeMembership.view';
import UIButton from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import {environment} from "../../environments/environment";

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  memberShipClass: {
    color: '#000000',
    opacity: '0.8',
  },
  label: {
    borderRadius: '5px',
    textTransform: 'capitalize',
    fontSize: 14,
    height: 35,
  },
  tableTrHeader: {
    backgroundColor: '#f9f9f9',
    fontWeight: 600,
  },
};

const useStyles = makeStyles(styles);
export default function UserAccountInfo(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);
  // const membershipPayment = ['free', 'monthly', 'quarterly', 'annual'];
  const membershipPayment = ['free', 'monthly', 'quarterly', 'annual'];
  const [responseData, setResponseData] = React.useState(props.currentUser);
  const [investorState, setInvestorState] = React.useState(props.currentUser.investorState);
  // const [developerState, setDeveloperState] = React.useState('Disabled');
  const [dueDate, setDueDate] = React.useState(null);
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });
  const [openDisableInvestorDlg, setOpenDisableInvestorDlg] = React.useState(false);
  const [enableInvestorDlg, setEnableInvestorDlg] = React.useState(false);
  const [membershipTable, setMembershipTable] = React.useState(false);
  const [showPaymentTable, setShowPaymentTable] = React.useState(false);
  const [paymentLevel, setPaymentLevel] = React.useState({
    monthly: null,
    quarterly: null,
    annual: null,
    currentUser: null,
    paymentInfo: null,
  });
  const [strategyMaxInfo, setStrategyMaxInfo] = React.useState(null);
  const [membershipState, setMembershipState] = React.useState(props.currentUser.investorState);
  const { onSuccess, onCancel, changemembership, changepayment } = props;

  // console.log(
  //   // '#### UserMembership onSuccess = %s onCancel = %s changemembership =  %s',
  //   onSuccess,
  //   onCancel,
  //   typeof changemembership
  // );

  const getLevelPayment = (membershipData, type) => {
    const data = membershipData.find((x) => x.payment === type)?.data ?? [];
    let freeData;
    if (type === 'monthly') {
      freeData = membershipData.find((x) => x.payment === 'free').data[0];
      // console.log('getLevelPayment freeData = %o', freeData);
    // } else {
    //   freeData = {
    //     level: 'Bronze',
    //     price: '--',
    //     discount: '--',
    //     productId: '--',
    //     description: '--',
    //   };
    }
    // data.unshift(freeData);
    // console.log('### getLevelPayment = %o', data);
    return data;
  };

  const fetchData = React.useCallback(() => {
    getLoginUser(null)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          setResponseData(data.data);
          setMembershipState(data.data.investorState);
          //update membership stabe
          localStorage.setItem(environment.membership, data.data.investorState);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchMembershipData = React.useCallback(() => {
    getInvestorMembership()
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          let monthly, quarterly, annual, free;
          membershipPayment.forEach((item) => {
            if (item === 'monthly') {
              monthly = getLevelPayment(data.data, item);
            } else if (item === 'quarterly') {
              quarterly = getLevelPayment(data.data, item);
            } else if (item === 'annual') {
              annual = getLevelPayment(data.data, item);
            } else if (item === 'free') {
              free = getLevelPayment(data.data, item);
            }
          });
          getUserMembershipPayment(monthly, quarterly, annual);
          getStrategyMaxInfo(monthly, quarterly, annual);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // const getStrategyMaxInfo = (free, monthly, quarterly, annual) => {
  const getStrategyMaxInfo = (monthly, quarterly, annual) => {
    const membership = ['Gold'];
    // const membership = ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond'];
    const strategyMaxInfo = {
      membershipLevel: {
        name: 'Services',
        data: membership,
      },
      premiumStrategy: {
        name: 'Subscribe Premium Strategy',
        data: [],
      },
      maxNoStrategiesMoneyTrade: {
        name: 'Max No. 0f Strategies for Money Trade',
        data: [],
      },
      maxNoStrategiesPaperTrade: {
        name: 'Max No. of Strategies for Paper Trade',
        data: [],
      },
      maxTotalMoneyPortfolio: {
        name: 'Max of Total Amount of Money Portfolios',
        data: [],
      },
    };

    membership.forEach((item) => {
      if (typeof free != "undefined") {
        free.forEach((level: any) => {
          if (level.memberClass === item) {
            strategyMaxInfo.premiumStrategy.data.push(level.premiumStrategyOK ? 'Yes' : 'No');
            strategyMaxInfo.maxNoStrategiesMoneyTrade.data.push(level.maxMoneyPortfolioCount);
            strategyMaxInfo.maxNoStrategiesPaperTrade.data.push(level.maxPaperPortfolioCount);
            strategyMaxInfo.maxTotalMoneyPortfolio.data.push(level.maxValue);
          }
        });
     }
      console.info('monthly %o', monthly);
      monthly.forEach((level: any) => {
        if (level.memberClass === item) {
          strategyMaxInfo.premiumStrategy.data.push(level.premiumStrategyOK ? 'Yes' : 'No');
          strategyMaxInfo.maxNoStrategiesMoneyTrade.data.push(level.maxMoneyPortfolioCount);
          strategyMaxInfo.maxNoStrategiesPaperTrade.data.push(level.maxPaperPortfolioCount);
          strategyMaxInfo.maxTotalMoneyPortfolio.data.push(level.maxValue);
        }
      });
    });
    console.info('strategyMaxInfo %o', strategyMaxInfo);
    setStrategyMaxInfo(strategyMaxInfo);
  };

  const handlePaymentOnSuccess = React.useCallback(() => {
    subscribeMembershipOnSuccess(onSuccess)
      .then((response) => {
        console.log(
          '*****UserAccountInfo.view: subscribeMembershipOnSuccess response = %o',
          response
        );
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          // console.log(
          //   '###subscribeMembershipOnSuccess Change membership successfully paymentLevel = %o',
          //   paymentLevel
          // );
          setTimeout(function () {
            fetchData();
            fetchMembershipData();
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getUserMembershipPayment = (monthly, quarterly, annual) => {
    getUserMembershipInfo()
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          // console.log('getUserMembershipInfo successfully data.data =  %o', data.data);
          const currentUser = data.data;
          let paymentInfo = data?.data?.paymentInfo ?? {};
          const billingCycle = '(' + getBillingCycle(currentUser.billingCycle);
          const memberClass = currentUser.memberClass;
          if (currentUser.status === 'ACTIVE') {
            currentUser.memberShipMsg = memberClass ? memberClass + billingCycle : billingCycle;
            if (currentUser?.price) {
              currentUser.memberShipMsg += ' / ' + currentUser?.price + ')';
            }
            if (currentUser?.dueDate) {
              let date = currentUser.dueDate;
              setDueDate('Next Billing Date: ' + date.substring(0,10));
            }
          } else if (currentUser.status === 'TRIAL') {
            currentUser.memberShipMsg = memberClass ? memberClass + '(Free)' : '(Free)';
            if (currentUser?.dueDate) {
              let date = currentUser.dueDate;
              setDueDate('Trial Expiration Date: ' + date.substring(0,10));
            }
          } else {
            currentUser.memberShipMsg = '';
          }
          console.info('payment data %o', paymentInfo);
          setPaymentLevel({ monthly, quarterly, annual, currentUser, paymentInfo });
          if ( paymentInfo !== null && Object.keys(paymentInfo).length > 0) {
            setShowPaymentTable(true);
          }
          if (onCancel && changemembership === 'true') {
            console.log('#### getUserMembershipInfo change');
            //open membership table by default
            setMembershipTable(true);
            history.replace({ pathname: location.pathname, search: params.toString() });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchMembershipData();
    fetchData();
    console.log('#### useEffect changemembership = %s', changemembership);
    if (onSuccess) {
      handlePaymentOnSuccess(onSuccess);
    } else if (changemembership === 'true') {
      console.log('#### onCancel && changemembership');
      //open membership table by default
      setMembershipTable(true);
    } else if (onCancel && changepayment === 'true') {
      history.replace({ pathname: location.pathname });
    }
  }, []);

  const handleDisableMembership = () => {
    const params: UpdateUserAccountInfo = {
      investorState: 'Disabled', //responseData.investorState,
    };
    disableUserMembershipRights(params)
      .then((res) => {
        console.debug('UserAccountInfo.view: disableUserMembershipRights: res = %o', res);
        if (res.status === 200) {
          setOpenAlertMsg({
            alert: true,
            alertContent: 'Disable investor membership successfully',
            severity: 'success',
          });
          setOpenDisableInvestorDlg(false);
          fetchData();
        }
      })
      .catch((error) => {
        setOpenAlertMsg({
          alert: true,
          alertContent: error.data.errMsg,
          severity: 'error',
        });
        console.error(error);
      });

    // temp disable/enable the developer feature
    // if (developerState !== responseData.developerState) {
    //   const params: UpdateUserAccountInfo = {
    //     developerState: responseData.developerState,
    //   };
    //   disableUserMembershipRights(params)
    //     .then((res) => {
    //       if (res.status === 200) {
    //         setOpenAlertMsg({
    //           alert: true,
    //           alertContent: 'Update developer membership successfully',
    //           severity: 'success',
    //         });
    //       }
    //       setInvestorState('Disabled');
    //       setOpenDisableInvestorDlg(false);
    //     })
    //     .catch((error) => {
    //       setOpenAlertMsg({
    //         alert: true,
    //         alertContent: error.data.errMsg,
    //         severity: 'error',
    //       });
    //       console.error(error);
    //     });
    // }
  };

  // const handleOnChangeDeveloperState = (event) => {
  //   setResponseData({
  //     ...responseData,
  //     ...{ developerState: event.target.checked ? 'Enabled' : 'Disabled' },
  //   });
  // };

  const handleOnDisableInvestorState = () => {
    setOpenDisableInvestorDlg(true);
  };

  // const handleSubscribeMembership = () => {
  //   console.info('subscribe');
  //   setOpenDisableInvestorDlg(true);
  // };

  const handleUnsubscribeMembership = () => {
    setOpenDisableInvestorDlg(true);
  };

  const handleDisableMembershipClick = (result) => {
    if (result) {
      handleDisableMembership();
    } else {
      setOpenDisableInvestorDlg(false);
    }
  };

  const showMembershipTable = () => {
    setMembershipTable(true);
    console.log('showMembershipTable membershipTable = %s', membershipTable);
  };

  const hideMembershipTable = () => {
    setMembershipTable(false);
    console.log('hideMembershipTable membershipTable = %s', membershipTable);
  };

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  const enableInvestorMembershipClick = (data) => {
    console.log('#### enableInvestorMembershipClick data = %o', data);
    enableUserMembershipRights(data.signature)
      .then((res) => {
        console.debug(
          'UserAccountInfo.view: handleOnClickUpdateMembership Developer: res = %o',
          res
        );
        if (res.status === 200) {
          setOpenAlertMsg({
            alert: true,
            alertContent: 'Enable investor membership successfully',
            severity: 'success',
          });
          setEnableInvestorDlg(false);
          fetchData();
        }
      })
      .catch((error) => {
        setOpenAlertMsg({
          alert: true,
          alertContent: error.data.errMsg,
          severity: 'error',
        });
        console.error(error);
      });
  };

  const handleAgreementCancelDialogClick = () => {
    setEnableInvestorDlg(false);
  };

  const createPopupWin = (pageURL, pageTitle, popupWinWidth, popupWinHeight) => {
    const left = window.screen.width;
    const top = window.screen.height;
    const settings =
      'resizable=yes, width=' +
      popupWinWidth +
      ', height=' +
      popupWinHeight +
      ', top=' +
      top +
      ', left=' +
      left;

    console.log(
      'left = %s, top = %s popupWinWidth = %s, popupWinHeight =  %s settings = %s',
      left,
      top,
      popupWinWidth,
      popupWinHeight,
      settings
    );
    const myWindow = window.open(
      pageURL,
      pageTitle,
      'resizable=yes, width=' +
        popupWinWidth +
        ', height=' +
        popupWinHeight +
        ', top=' +
        top +
        ', left=500'
    );
    return myWindow;
  };

  const handleUpdateMembership = () => {
    hideMembershipTable();
    getUserMembershipPayment(paymentLevel.monthly, paymentLevel.quarterly, paymentLevel.annual);
  };

  const getBillingCycle = (billingCycle) => {
    let billingType = membershipPayment[0];
    if (billingCycle === 0) {
      billingType = membershipPayment[0];
    } else if (billingCycle === 1) {
      billingType = membershipPayment[1];
    } else if (billingCycle === 3) {
      billingType = membershipPayment[2];
    } else if (billingCycle === 12) {
      billingType = membershipPayment[3];
    }
    return billingType.charAt(0).toUpperCase() + billingType.slice(1);
  };

  const handleChangePayment = () => {
    console.log('#### handleChangePayment');
    updateUserPayment()
      .then((response) => {
        console.debug('UserAccountInfo.view: handleChangePayment : response = %o', response);
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          console.debug('UserAccountInfo.view: data.data = %s', data.data);
          if (data.data) {
            // launch separately tab to show stripe checkout
            const pop = (url) => {
              // const popup = createPopupWin(url, 'iPIPA Payment Checkout', 600, 800);
              const popup = window.open(
                url,
                '_self'
                // 'toolbar=0,location=0,directories=0,status=1,menubar=0,titlebar=0,scrollbars=1,resizable=1,width=600,height=700'
              );
              return popup !== null && typeof popup !== 'undefined';
            };
            pop(data.data);
          } else {
            console.log('NO paymentURL');
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeletePayment = () => {
    console.info('delete card info');
    deleteUserPayment()
        .then((response) => {
          const data = response && response?.data ? response.data : null;
          if (data.code === 200) {
            console.debug('Sucessfully delete payment method');
            setPaymentLevel({ ...paymentLevel, paymentInfo: null});
            setShowPaymentTable(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
  }

  return (
    <div>
      {responseData && openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      {responseData && enableInvestorDlg && (
        <EnableMembershipDialog
          enableInvestorMembershipClick={enableInvestorMembershipClick}
          handleCancelDialogClick={handleAgreementCancelDialogClick}
          open={enableInvestorDlg}
        />
      )}
      {responseData && openDisableInvestorDlg && (
        <ConfirmationDialog
          dlgData={'Yes'}
          handleDeleteDialogClick={handleDisableMembershipClick}
          open={openDisableInvestorDlg}
          dlgTitle={'Disable Investor Membership'}
          dlgContentText={'Are you sure you want to disable your investor membership?'}
        />
      )}
      {responseData && paymentLevel.paymentInfo !== null && (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={7}>
              <Card>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>GoPIPA Investment Club (GIC) Membership Information</h4>
                  <p className={classes.cardCategoryWhite}>Membership Settings</p>
                </CardHeader>
                <CardBody>
                  {/*<GridContainer>*/}
                  {/*  <GridItem xs={12} sm={12} md={6}>*/}
                  {/*<Checkbox*/}
                  {/*  style={{ marginLeft: '-12px' }}*/}
                  {/*  checked={responseData.investorState === 'Disabled' ? false : true}*/}
                  {/*  color="primary"*/}
                  {/*  inputProps={{ 'aria-label': 'secondary checkbox' }}*/}
                  {/*  onChange={handleOnChangeInvestorState}*/}
                  {/*/>*/}
                  {/*    <InputLabel className={classes.memberShipClass} style={{ marginTop: 15 }}>*/}
                  {/*      Investor Membership {`(${responseData.investorState})`}*/}
                  {/*    </InputLabel>*/}
                  {/*  </GridItem>*/}
                  {/*  <GridItem xs={12} sm={12} md={2}>*/}
                  {/*    <InputLabel className={classes.memberShipClass} style={{ marginTop: 15 }}>*/}
                  {/*      {responseData.investorState}*/}
                  {/*    </InputLabel>*/}
                  {/*  </GridItem>*/}
                  {/*</GridContainer>*/}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <hr></hr>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <InputLabel className={classes.memberShipClass}>
                        Investor Membership: {`${membershipState}`}
                      </InputLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      {responseData.investorState !== 'Disabled' && (
                        <InputLabel className={classes.memberShipClass}>
                          {paymentLevel.currentUser.memberShipMsg}
                        </InputLabel>
                      )}
                      {dueDate != null && (
                        <InputLabel className={classes.memberShipClass} style={{fontSize:'13px'}}>
                          {dueDate}
                        </InputLabel>
                      )}
                      </GridItem>
                    <GridItem xs={12} sm={12} md={3} style={{marginTop: '4px'}}>
                      {(responseData.investorState === 'Enabled' && paymentLevel.currentUser.investorMemberClass !== 'Internal') && (
                        <Button
                          style={{ marginTop: -10 }}
                          onClick={handleUnsubscribeMembership}
                          color="info"
                          className={classes.label}
                        >
                          Unsubscribe
                        </Button>
                      )}
                    </GridItem>
                  </GridContainer>
                  {responseData && membershipTable && (
                      <SubscribeMembershipView
                          paymentLevel={paymentLevel}
                          onClose={hideMembershipTable}
                          updateMembership={handleUpdateMembership}
                          membershipState={responseData.investorState}
                          membershipInfo={strategyMaxInfo}
                      ></SubscribeMembershipView>
                  )}
                </CardBody>
                {paymentLevel.currentUser.memberClass !== 'Internal' && (
                  <CardFooter>
                    {responseData && !membershipTable && (
                      <Button onClick={showMembershipTable} color="info" className={classes.label}>
                        View/Subscribe Membership
                      </Button>
                    )}
                  </CardFooter>
                )}
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              {paymentLevel.currentUser.memberClass !== 'Internal' && (
                <Card>
                  <CardHeader color="info">
                    <h4 className={classes.cardTitleWhite}>Payment Information</h4>
                    <p className={classes.cardCategoryWhite}>Payment Settings</p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <span className={classes.tableTrHeader}>Card Information</span>
                          { showPaymentTable && (
                              <table className="table table-condensed table-bordered table-dense">
                                <thead>
                                  <tr className={classes.tableTrHeader}>
                                    <td>Type</td>
                                    <td>Ending In</td>
                                    <td>Name</td>
                                    <td>Expiration Date</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{paymentLevel.paymentInfo?.brand}</td>
                                    <td>{paymentLevel.paymentInfo.last4}</td>
                                    <td>{paymentLevel.paymentInfo?.nameOnCard}</td>
                                    <td>{paymentLevel.paymentInfo?.exp}</td>
                                  </tr>
                                </tbody>
                              </table>
                          )}
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <span>
                      <Button onClick={handleChangePayment} color="info" className={classes.label}>
                        Add/Change Payment Info
                      </Button>
                      { showPaymentTable && (
                      <Button onClick={handleDeletePayment} color="info" className={classes.label} style={{marginLeft: '50px'}}>
                        Delete Payment Info
                      </Button>
                      )}
                    </span>
                  </CardFooter>
                </Card>
              )}
            </GridItem>
          </GridContainer>
        </div>
      )}
    </div>
  );
}
