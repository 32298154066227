import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

// @material-ui/core components
import Card from '@material-ui/core/Card';

class PortfolioStatsView extends React.Component {
  constructor(props) {
    super(props);
    const { seriesData, type } = props;
    this.state = { seriesData, type };
  }

  render() {
    const options = {
      chart: {
        height: 220, //(9 / 16) * 100 + '%', // 16:9 ratio
      },
      rangeSelector: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      navigator: { adaptToUpdatedData: true, series: { visible: false }, margin: 5, height: 30 },
      tooltip: {
        valueDecimals: 2,
        split: false,
        shared: true,
        borderColor: '#0BA9F9',
        borderWidth: 1,
        style: { fontSize: '13px' },
        headerFormat: '<span style="font-size: 13px">{point.key}</span><br/>',
      },
      yAxis: {
        gridLineWidth: 1,
      },
      title: this.state.type,
      series: [],
    };

    if (this.state.type === 'Volatility') {
      options.tooltip.valueSuffix = '%';
      options.yAxis = [
        {
          labels: {
            formatter: function () {
              return (this.value > 0 ? ' + ' : '') + this.value + '%';
            },
          },
        },
      ];
    }

    const chartOptions = {
      ...options,
      series: [
        { data: this.state.seriesData, name: this.state.type, type: 'line', color: '#7cb5ec' },
      ],
    };

    const { classes } = this.props;

    return (
      <div className={classes.cardMargin}>
        <Card>
          <div className={classes.chartTitle}>{this.state.type}</div>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            constructorType={'stockChart'}
          />
        </Card>
      </div>
    );
  }
}

export default PortfolioStatsView;
