import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import PropTypes from 'prop-types';
import { stopPortfolio } from 'utils/portfolio.utils.ts';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  dlgWidth: {
    width: 450,
  },
  radioGroupTop: {
    marginTop: -10,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: 'none' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles(styles);
export default function StopPortfolioDialogView(props) {
  const classes = useStyles();
  const [sellPositions, setSellPositions] = React.useState('sell');
  const { strategyId, strategyName } = props;
  const stopPortfolioDLgTitle = `Stop Portfolio - ${strategyName}`;

  const handleSellPositionChange = () => (event) => {
    setSellPositions(event.target.value);
  };

  const handleStopPortfolio = () => {
    const sellPosition = sellPositions === 'sell' ? true : false;
    console.debug('handleStopPortfolio id = %s sellPosition = %s', strategyId, sellPosition);
    stopPortfolio(strategyId, sellPosition)
      .then((response) => {
        console.debug('Portfolio.view: handle Stop Portfolio responseData = %o', response);
        props.handleStopDialogClose(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Dialog
        open={props.openStopPortfolioDlg}
        onClose={props.handleStopDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className={classes.dlgWidth}
          id="customized-dialog-title"
          onClose={props.handleStopDialogClose}
        >
          {stopPortfolioDLgTitle}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            {/*<FormControl component="fieldset">*/}
            {/*  <FormLabel component="legend">*/}
            {/*    <span>Do you want iPIPA to sell all the positions in this portfolio?</span>*/}
            {/*  </FormLabel>*/}
            {/*  <RadioGroup*/}
            {/*    className={classes.radioGroupTop}*/}
            {/*    row*/}
            {/*    aria-label="emergencyStop"*/}
            {/*    name="emergencyStop"*/}
            {/*    value={sellPositions}*/}
            {/*    onChange={handleSellPositionChange()}*/}
            {/*  >*/}
            {/*    <FormControlLabel value="sell" control={<Radio />} label="Yes" />*/}
            {/*    <FormControlLabel value="hold" control={<Radio />} label="No" />*/}
            {/*  </RadioGroup>*/}
            {/*</FormControl>*/}
            <div>
              <span>Are you sure you want to stop this portfolio?</span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleStopDialogClose} color="primary" autoFocus>
            No
          </Button>
          <Button onClick={handleStopPortfolio} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

StopPortfolioDialogView.propTypes = {
  openStopPortfolioDlg: PropTypes.bool,
  handleStopDialogClose: PropTypes.func,
  strategyId: PropTypes.number,
  strategyName: PropTypes.string,
};
