export const environment: any = {
  env: 'dev',
  domain_cn: process.env.REACT_APP_DOMAIN_CN,
  domain_us: process.env.REACT_APP_DOMAIN_US,
  api_url: process.env.REACT_APP_API,
  forum_url: process.env.REACT_APP_FORUM,
  websocket_url: process.env.REACT_APP_WEBSOCKET,
  websocket_debug_url: process.env.REACT_APP_WEBSOCKET_DEBUG,
  websocket_debug_file: '/python/strategy/StrategyCode.py',
  ty_url: '',
  perf_image_url: process.env.REACT_APP_PERF_IMAGE,
  clientId: 'PiPA',
  clientSecret: 'secret',
  tokenKey: 'access_token',
  forumTokenKey: 'pipa_forum_token',
  userNameKey: 'userName',
  userRiskLevelKey: 'userRiskLevel',
  languageKey: 'language',
  userIdKey: 'userId',
  membership: 'membership',
  currentUser: {},
  systemConfig: {},
  minScreenWidth: 1350,
  navbarOuterHeight: 61,
  selectDataItems: {}, // 数据字典项，对应每个form表单上的下拉框
  calendar_i18n_zh: {
    previousMonth: '上个月',
    nextMonth: '下个月',
    months: [
      '一月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月',
    ],
    weekdays: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
    weekdaysShort: ['日', '一', '二', '三', '四', '五', '六'],
  },
  calender_i18n_en: {
    previousMonth: 'Previous Month',
    nextMonth: 'Next Month',
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  },
  editor_md_en: {
    设置标题: 'title',
    正文: 'content',
    字号: 'font-size',
    字体: 'font-family',
    文字颜色: 'font-color',
    背景色: 'background-color',
    链接文字: 'link text',
    链接: 'link',
    插入: 'append',
    设置列表: 'list',
    有序列表: 'ordered',
    无序列表: 'unordered',
    对齐方式: 'alignment',
    靠左: 'left',
    居中: 'center',
    靠右: 'right',
    默认: 'default',
    新浪: 'sina',
    上传图片: 'upload image',
    网络图片: 'internet image',
    图片链接: 'image url',
    插入表格: 'table',
    创建: 'create',
    行: 'rows',
    列的表格: 'columns',
    插入视频: 'audio',
    插入代码: 'code',
  },
  codeAutoCompletes: null,
  perPageNum: 50,
  filterPerPageNum: 50,
  limitViewPages: 10,
  portfolioSocket: null,
  algorithmWorker: null,
  algorithmDebugSocket: null,
  realtimeStatPoints: 60, // 实时资源使用统计图表上最多xx个点
};
