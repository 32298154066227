import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
// core components
import { getUserInvoices } from '../../utils/billing.utils.ts';
import PropTypes from "prop-types";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
};
//Aug 8, 2021 1:46 PM   :  MMM DD, YYYY hh:mm  A
const columns = [
  {
    name: 'Due Date',
    selector: 'dueDate',
    sortable: true,
    cell: (row) => {
      return row.dueDate ? moment(row.dueDate.replace(/\.\d+/, "").replace(/-/g,"/")).tz("America/New_York").format('MMM DD, YYYY') : '';
    },
    // cell: (row) => {
    //   const milliSeconds = moment(row.time).valueOf();
    //   var newdate = moment.utc(row.time).local().format('MMM DD, YYYY hh:mm ');
    //   console.log('#### newdate = %s', newdate);
    //   const local = moment.utc(row.time, 'MMM DD, YYYY hh:mm A').local();
    //   const momentFormat = moment(local, 'MMM DD, YYYY hh:mm A').format('MMM DD, YYYY hh:mm A');
    //   console.log('#### local  = %s momentFormat = %s', local, momentFormat);
    //   const period = row?.time ? moment(row.time).format('MMM DD, YYYY hh:mm A') : '';
    //   return <div>{period}</div>;
    // },
  },
  {
    name: 'Paid Date',
    selector: 'paidDate',
    sortable: true,
    cell: (row) => {
      return row.paidDate ? moment(row.paidDate.replace(/\.\d+/, "").replace(/-/g,"/")).tz("America/New_York").format('MMM DD, YYYY') : '';
    },
  },
  {
    name: 'Payment Type',
    selector: 'paymentType',
    sortable: true,
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'billStatus',
    sortable: true,
  },
  {
    name: 'Membership',
    selector: 'name',
    sortable: true,
    cell: (row) => {
      return row.name.split(' ')[0];
    },
  },
  {
    name: 'Description',
    selector: 'description',
    sortable: true,
    minWidth: '30%',
  },
];

const useStyles = makeStyles(styles);
// V5 : "name": "Silver Membership",
//     "paymentType": "Credit",
//     "amount": "$49.00",
//     "billStatus": "paid",
//     "description": "Silver Membership 1 Month, billed monthly",
//     "billingDate": "2021-09-02 00:14:46.056 +0000",
//     "paidDate": "2021-09-02 00:14:47.256 +0000"
// { V4:
//   "id": "182210763480502272",
//     "userId": "133",
//     "billMonth": "202107",
//     "status": "SettledPayed",
//     "type": "StrategyIncome",
//     "investorMemberClass": "Diamond",
//     "developerMemberClass": "Silver",
//     "amount": 0,
//     "payTime": "2021-08-16 04:05:00.220 +0000",
//     "createTime": "2021-08-16 04:05:00.214 +0000",
//     "modifiedTime": "2021-08-16 04:05:00.221 +0000",
//     "payType": null,
//     "tradeNo": null,
//     "tradeStatus": null
// }
export default function BillingTableView() {
  const [billingData, setBillingData] = React.useState([]);

  const fetchData = React.useCallback(() => {
    getUserInvoices()
      .then((response) => {
        console.log('getUserInvoices.view: responseData = %o', response);
        const data = response?.data?.data ?? [];
        setBillingData(data.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <DataTable
        title="Billing/Invoice History"
        data={billingData}
        columns={columns}
        // fixedHeader
        highlightOnHover
        striped
        sortIcon={<SortIcon />}
        noHeader={true}
      />
    </div>
  );
}

BillingTableView.propTypes = {
};
