import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactHtmlParser from "html-react-parser";
import queryString from 'query-string';
import css from './HomePage.css';
import { stringUtils } from 'utils/string.utils.ts';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import LoginDialog from 'components/Dialog/LoginDialog.js';
import SignUpDialog from 'components/Dialog/SignUpDialog.js';
import $ from 'jquery';
import FooterView from "./Footer.view";

export default function HomePageView() {
  const { state } = useLocation();
  const location = useLocation();
  const params = queryString.parse(location.search);
  console.debug('#### home page state = %o location = %o params = %o', state, location, params);
  const [openSignUp, setOpenSignUp] = React.useState(!$.isEmptyObject(params) && params.register);
  const openLoginDlg = state?.open ?? false;
  const [open, setOpen] = React.useState(openLoginDlg);

  const handleLoginDialogClick = (flag) => {
    setOpen(flag);
  };

  const handleSignUpDialogClick = (flag) => {
    setOpenSignUp(flag);
  };

  return (
    <div>
      <div>
        <LoginDialog
          handleLoginDialogClick={handleLoginDialogClick}
          open={open}
          forgetPassword={false}
        />
        <SignUpDialog
          handleLoginDialogClick={handleLoginDialogClick}
          handleSignUpDialogClick={handleSignUpDialogClick}
          email={params?.email}
          invitationCode={params.register}
          referral={params.referral}
          open={openSignUp}
        />
      </div>

      <div className={css.testFloat}></div>
      <div className="homeImage">
        <h2 className="h2_45">
          {stringUtils.productName}
          <sup>®</sup>
          {stringUtils.homeTitle}
        </h2>
        <h2 className="h2_28">{stringUtils.homeSubTitle}</h2>
        <h2 className="h2_29">{stringUtils.homeSubTitle2}</h2>
      </div>

      <div className="content1300" style={{ background: '#fff', padding: '0 50px 50px 50px' }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div className="platformContent">{stringUtils.home}</div>
            <div className="h4SubTitle">
              <b>{stringUtils.homeOurVisionTitle}</b>
            </div>
            <div className="platformContent">{stringUtils.homeOurVision}</div>
            <div className="h4SubTitle">
              <b>{stringUtils.homeIPIPAServicesTitle}</b>
            </div>
            <div className="platformContent">{ReactHtmlParser(stringUtils.homeIPIPAServices)}</div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <div>
              <i className="titleLine"></i>
            </div>
            <h4 className="h4SubTitle">
              <b>{stringUtils.homeIDATitle}</b>
            </h4>
            <h4 className="h4Content">{stringUtils.homeIDA}</h4>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <div className="investor-cpm-theImage"></div>
          </GridItem>
        </GridContainer>

        {/*<GridContainer>*/}
        {/*  <GridItem xs={6} sm={6} md={6}>*/}
        {/*    <div className="investor-spm-theImage"></div>*/}
        {/*  </GridItem>*/}
        {/*  <GridItem xs={6} sm={6} md={6}>*/}
        {/*    <div className="theContent">*/}
        {/*      <div style={{ textAlign: 'right' }}>*/}
        {/*        <i className="titleLine"></i>*/}
        {/*      </div>*/}
        {/*      <div style={{ textAlign: 'right' }}>*/}
        {/*        <h4 className="h4SubTitle">*/}
        {/*          <b>Satellite Portfolio Management (SPM)</b>*/}
        {/*        </h4>*/}
        {/*        <h4 className="h4Content" style={{ textAlign: 'left' }}>*/}
        {/*          SPM provides investors with various automated trading strategies to maximize their*/}
        {/*          return with managed risk. Investors can compare various strategies to find the*/}
        {/*          ones that meet their requirements. The SPM portfolios are designed to be satellite*/}
        {/*          portfolios, i.e., relatively smaller portion of investor’s overall assets, to*/}
        {/*          augment the core-holding portfolios. The SPM portfolios are specialized and*/}
        {/*          differentiated with various objectives and scopes of strategies that include:*/}
        {/*          global momentum, sector momentum, pair trading, trading on trend, mean reversion,*/}
        {/*          event driven trading, volatility trading, market hedging, etc.*/}
        {/*        </h4>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </GridItem>*/}
        {/*</GridContainer>*/}

        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <div className="investor-ida-theImage"></div>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <div className="theContent">
              <div style={{ textAlign: 'left' }}>
                <i className="titleLine"></i>
              </div>
              <h4 className="h4SubTitle" style={{ textAlign: 'Left' }}>
                <b>{stringUtils.homeAQSTitle}</b>
              </h4>
              <h4 className="h4Content">{stringUtils.homeAQS}</h4>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <div className="theContent">
              <div>
                <i className="titleLine"></i>
              </div>
              <h4 className="h4SubTitle">
                <b>{stringUtils.homeAQMTitle}</b>
              </h4>
              <h4 className="h4Content">{stringUtils.homeAQM}</h4>
            </div>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <div className="investor-cis-theImage"></div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
            <h4 className="h4Title">
              <b>{stringUtils.homeDiffValue}</b>
            </h4>
            <h4 className="h4SubTitle1">{stringUtils.homeDiffValueTitle}</h4>
          </GridItem>
        </GridContainer>
        <div style={{ marginTop: 40 }}> </div>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <div className="valueItem" style={{ height: 'auto' }}>
              <h4 className="h4CoreContent">{stringUtils.homeDiffValueDesc1}</h4>
              <h4 className="h4CoreContent" style={{ marginTop: 20 }}>
                <b style={{ color: '#387EE8', fontSize: 18 }}>
                  {stringUtils.homeDiffValueSubTitle}
                </b>{' '}
                {stringUtils.homeDiffValueDesc11}
              </h4>
              <div style={{ minHeight: '30px' }}></div>
            </div>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <div className="valueItem" style={{ height: 'auto' }}>
              <h4 className="h4CoreContent">{stringUtils.homeDiffValueDesc2}</h4>
              <h4 className="h4CoreContent" style={{ marginTop: 20 }}>
                <b style={{ color: '#387EE8', fontSize: 18 }}>
                  {stringUtils.homeDiffValueSubTitle}
                </b>{' '}
                {stringUtils.homeDiffValueDesc22}
              </h4>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} style={{ marginTop: 40 }}>
            <div className="valueItem" style={{ height: 'auto' }}>
              <h4 className="h4CoreContent">{stringUtils.homeDiffValueDesc3}</h4>
              <h4 className="h4CoreContent" style={{ marginTop: 20 }}>
                <b style={{ color: '#387EE8', fontSize: 18 }}>
                  {stringUtils.homeDiffValueSubTitle}
                </b>{' '}
                {stringUtils.homeDiffValueDesc33}
              </h4>
            </div>
          </GridItem>
          <GridItem xs={6} sm={6} md={6} style={{ marginTop: 40 }}>
            <div className="valueItem" style={{ height: 'auto' }}>
              <h4 className="h4CoreContent">{stringUtils.homeDiffValueDesc4}</h4>
              <h4 className="h4CoreContent" style={{ marginTop: 20 }}>
                <b style={{ color: '#387EE8', fontSize: 18 }}>
                  {stringUtils.homeDiffValueSubTitle}
                </b>{' '}
                {stringUtils.homeDiffValueDesc44}
              </h4>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <div className="content1300" style={{ background: '#F7F8FA' }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4 className="h4Title" style={{ marginTop: 50 }}>
              <b>iPIPA Core Technologies</b>
            </h4>
            <h4 className="h4SubTitle1" style={{ marginTop: 15, marginBottom: 50 }}>
              iPIPA is built with patented core technologies in the following areas
            </h4>
          </GridItem>
        </GridContainer>
        <GridContainer style={{ paddingLeft: 50, paddingRight: 50 }}>
          <GridItem xs={12} sm={12} md={12} style={{ backgroundColor: '#fff', height: 200 }}>
            <GridContainer>
              <div className="coreItem">
                <div className="core-img1"></div>
                <div className="h4Content1" style={{ marginTop: 20 }}>
                  {stringUtils.homeAI}
                </div>
              </div>
              <div className="coreItem">
                <div className="core-img2"></div>
                <div className="h4Content1" style={{ marginTop: 20 }}>
                  {stringUtils.homeAlgorithmicTrading}
                </div>
              </div>
              <div className="coreItem">
                <div className="core-img3"></div>
                <div className="h4Content1" style={{ marginTop: 20 }}>
                  {stringUtils.homeDataAnalytics}
                </div>
              </div>
              <div className="coreItem-last-child">
                <div className="core-img4"></div>
                <div className="h4Content1" style={{ marginTop: 20 }}>
                  {stringUtils.homeSaaS}
                </div>
              </div>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div className="core-bg"></div>
          </GridItem>
        </GridContainer>
      </div>

      <div className="important-img">
        <div className="container">
          <div className="row content1400">
            <h4 className="col-md-12 h4Title" style={{ color: '#fff', marginTop: 50 }}>
              {stringUtils.homeDisclaimer}
            </h4>
            <h4
              className="col-md-12 h4Content1"
              style={{ color: '#fff', marginTop: 25, marginBottom: 30, textAlign: 'left' }}
            >
              {stringUtils.homeDisclaimerDesc}
            </h4>
          </div>
        </div>
      </div>
      <FooterView></FooterView>
    </div>
  );
}
