import { environment } from 'environments/environment';
import { getDefaultConfig } from './common';
import {deleteJSON, fetchJSON, putJSON} from './fetch.js';

export const getInvestorMembership = () => {
  const url = `${environment.api_url}users/getProducts?type=InvestorMembership`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const subscribeMembership = (productId: string) => {
  let url = `${environment.api_url}users/subscribe?productId=${productId}`;
  return putJSON(url, {}, getDefaultConfig(null));
};

export const subscribeMembershipOnSuccess = (sessionId: string) => {
  let url = `${environment.api_url}users/stripeSuccess?id=${sessionId}`;
  return putJSON(url, {}, getDefaultConfig(null));
};

export const getUserMembershipInfo = () => {
  const url = `${environment.api_url}users/getUserMemberSubscription`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getUserInvoices = () => {
  const url = `${environment.api_url}users/getUserInvoices`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const updateUserPayment = () => {
  const url = `${environment.api_url}users/updateUserPaymentInfo`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getWebapiRequestToken = (account, nickName) => {
  const url = `${environment.api_url}users/webapiRequestToken?account=${account}&nickName=${nickName}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getWebapiAuthCallback = (oauthToken, oauthVerifier) => {
  // https://ipsofacto.com/?oauth_token=7417008ab632e50668ac&oauth_verifier=ed3eb1244d550d87bf58
  //param oauth_token=, oauth_verifier=
  const url = `${environment.api_url}/svr/user/public/webapiAuthCallback?oauthToken=${oauthToken}&oauthVerifier=${oauthVerifier}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const deleteUserPayment = () => {
  const url = `${environment.api_url}users/deleteUserPaymentInfo`;
  return deleteJSON(url, {}, getDefaultConfig(null));
};
