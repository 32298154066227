import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
// core components
import noperf from 'assets/img/noperf.svg';
import styles from 'assets/jss/material-dashboard-react/components/strategyCardStyle.js';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ViewDetailIcon from '@material-ui/icons/ViewHeadline';
// import PersonalVideo from '@material-ui/icons/PersonalVideo';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import { getSvg, subscribePortfolio, startSubscribePortfolio } from 'utils/strategy.utils';
import SubscribePortfolioDialog from 'components/Dialog/SubscribePortfolioDialog.tsx';
import { getTextColor, getNextTradeDate } from 'utils/common.ts';
import AlertView from '../../Alert/Alert.view';
import Button from "../../CustomButtons/Button";
import { stringUtils } from 'utils/string.utils';

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function StrategyView(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [redirectMyPortfolio, setRedirectMyPortfolio] = React.useState(false);
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });
  const [showConfirmation, setshowConfirmation] = React.useState(false);
  const [subscriptParam, setSubscriptParam] = React.useState(null);
  const {strategyDetail, trackAccount, onClickOpen} = props;
  const time = Math.floor(new Date().getTime() / 3600000);
  const backTest = strategyDetail.backTest === undefined ? null : strategyDetail.backTest;
  const trackingInfo = strategyDetail.trackingReport;
  const trackingInfoImage = trackingInfo
      ? getSvg(trackingInfo.portfolioId, `.svg?t=${time}`, trackingInfo.subPath)
      : '';
  let trackingBenchmarkType = 'SPY';
  if (trackingInfo != undefined ) {
    if (trackingInfo.benchmarkSymbol != 'SPY') {
      trackingBenchmarkType = trackingInfo.benchmarkSymbol;
    }
  }
  let backtestBenchmarkType = 'SPY';
  if (backTest != undefined ) {
    if (backTest.benchmarkSymbol != 'SPY') {
      backtestBenchmarkType = backTest.benchmarkSymbol;
    }
  }
  let backtestInfoImage = '',
    backtestTime = 'NA';
  if (backTest) {
    const backtestPortfolioId = backTest?.backtestPortfolioId ?? null;
    backtestInfoImage = backTest
      ? getSvg(backtestPortfolioId, `.svg?t=${time}`, backTest.subPath)
      : '';

    backtestTime =
      backTest.annualReturn === 0 || backTest.annualReturn
        ? backTest.beginTime.substring(0, 10) + '~' + backTest.endTime.substring(0, 10)
        : 'N/A';
  }
  const trackingTime = trackingInfo
    ? `${moment(trackingInfo.startTradeDate).tz("America/New_York").format('YYYY-MM-DD')}~${moment(
        trackingInfo.tradeDate
      ).tz("America/New_York").format('YYYY-MM-DD')}`
    : '';

  const [value, setValue] = React.useState(1);
  const strategyDetailsPathName = '/admin/strategyDetails';

  if (!trackingInfo && value === 1) {
    setValue(0);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  };

  const handleOnClickSubscribe = () => {
    setOpen(true);
  };

  const handleSubscribeClose = () => {
    setOpen(false);
  };

  const handleSubscribePortfolio = (values) => {
    let trackAccountId = 0;
    var params = null
    trackAccount.forEach((item) => {
      console.info('item:%o', item);
      if (item.key === values.tradingAccount) {
        trackAccountId = item.id;
        const investmentAmt = values.investmentAmount;
        const cashText = item.value;
        const pos = cashText.indexOf('Cash:');
        const end = cashText.indexOf(')', pos);
        const cash = Number(cashText.substring(pos+5, end));
        params = {
          'portfolio.buyValuePerOrder': '',
          'portfolio.marketType': strategyDetail.marketType,
          'portfolio.name': values.portfolioName,
          'portfolio.note': values.portfolioDesc,
          'portfolio.portfolioClass': 'Investor',
          'portfolio.sellValuePerOrder': '',
          'portfolio.strategyId': strategyDetail.id,
          'portfolio.trackAccountId': trackAccountId,
          'profile.expectMinAnnualReturn': parseFloat((backTest.cagr * 100).toFixed(2)),
          'profile.importanceOrder': '123',
          'profile.investmentAmount': values.investmentAmount,
          'profile.investmentTimeframe': strategyDetail.minInvestmentPeriod,
          'profile.liquidateOnEmergency': values.emergencyStop === 'stopSell' ? false : true,
          'profile.portfolioType': 'NotCare',
          'profile.tolenranceMaxAnnualVolatility': parseFloat((backTest.volatility * 100).toFixed(2)),
          'profile.tolenranceMaxDrawdown': parseFloat((backTest.maxDrawdown * 100).toFixed(2)),
        };
        setSubscriptParam(params);
        if (cash < investmentAmt) {
          setshowConfirmation(true);
          return;
        } else {
          subscribePortfolioAction(params);
        }
      }
    });
  }

  function subscribePortfolioAction(params) {
    if (params != null ) {
      subscribePortfolio(params)
          .then((response) => {
            console.debug('Strategy.view: handleSubscribePortfolio response = %o', response);
            startSubscribePortfolio(response.data.data.id)
                .then((response1) => {
                  console.debug('Strategy.view: startSubscribePortfolio response1 = %o', response1);
                  setRedirectMyPortfolio(true);
                  setOpen(false);
                })
                .catch((error) => {
                  console.debug(error);
                  setOpenAlertMsg({
                    open: true,
                    message: error.response.data.message,
                    severity: 'error',
                  });
                });
          })
          .catch((error) => {
            console.debug(error);
            if (error?.data?.errMsg.includes('DuplicateKeyException')) {
              setOpenAlertMsg({
                alert: true,
                alertContent: 'Portfolio Name exists',
                severity: 'error',
              });
            } else {
              setOpenAlertMsg({alert: true, alertContent: error?.data?.errMsg, severity: 'error'});
            }
          });
    }
  };

  const handleConfirm = (values) => {
    setshowConfirmation(false);
    console.info('handle confirm parameters: %o', subscriptParam);
    subscribePortfolioAction(subscriptParam);
  }

  const onCancel = () => {
    setshowConfirmation(false);
  }

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  const trackingInfoTab = backTest ? 1 : 0;
  return (
    <div>
      <span style={{verticalAlign: 'middle'}}>
        <div style={{ float: 'left', width: '75%', paddingTop: 5}}>
          <h6 style={{ margin: '0' }}>
            <span className={getTextColor(strategyDetail.name)} style={{ fontSize: '1.1em' }}>
              {strategyDetail.name} {`(ID: ${strategyDetail.id})`}
            </span>
          </h6>
        </div>
        <div className={classes.cardIconPanel}>
          <Tooltip title="Subscribe Strategy">
            <IconButton
              className={classes.iconPadding}
              onClick={handleOnClickSubscribe}
              aria-label="subscribe"
              size="small"
            >
              <AddIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Strategy Details">
            <IconButton aria-label="strategyDetail" size="small">
              <Link
                to={{
                  pathname: strategyDetailsPathName,
                  state: {
                    strategyId: strategyDetail.id,
                    strategyName: strategyDetail.name,
                  },
                }}
              >
                <ViewDetailIcon color="primary" fontSize="small" />
              </Link>
            </IconButton>
          </Tooltip>
          <Tooltip title="Positions">
            <IconButton aria-label="strategyDetail" size="small" onClick={() => onClickOpen(strategyDetail.id, strategyDetail.name)}>
              <FolderIcon color="primary" fontSize="medium" />
            </IconButton>
          </Tooltip>
        </div>
      </span>
      <table width="100%">
        <tbody>
          <tr>
            <td colSpan="4">
              <div className={classes.strategyHr}></div>{' '}
            </td>
          </tr>
          <tr>
            <td>
              <span>Developer Name</span>
            </td>
            <td>
              <span>Open for New Investors </span>
            </td>
            <td>
              <span>Allow Paper Trading </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className={classes.titleValue}>
                {strategyDetail.developerName ? strategyDetail.developerName : 'N/A'}
              </span>
            </td>
            <td>
              <span className={classes.titleValue}>
                {strategyDetail.openForInvestor ? 'Yes' : 'No'}
              </span>
            </td>
            <td>
              <span className={classes.titleValue}>
                {strategyDetail.allowPaperTrading ? 'Yes' : 'No'}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <span>Estimated date for next portfolio update: </span>
              <span className={classes.titleValue}>
                {getNextTradeDate(strategyDetail.nextTradeDate)}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div className={classes.strategyHr}></div>
      <div>
        <AppBar
          position="static"
          style={{ boxShadow: 'unset', color: '#1890ff', backgroundColor: '#fff' }}
        >
          <Tabs value={value} onChange={handleChange} aria-label="Strategy Tabs">
            {backTest && <Tab label="BackTest Info" {...a11yProps(0)} />}
            {trackingInfo && <Tab label="Tracking Info" {...a11yProps(trackingInfoTab)} />}
          </Tabs>
        </AppBar>
        <div className={classes.strategyHr}></div>
        {trackingInfoTab === 1 && (
          <TabPanel value={value} index={0}>
            <div>
              <span>Period: </span>
              <span className={classes.titleValue}>{backtestTime}</span>
            </div>
            <div>
              <span
                className={classNames(classes.chartStrategyLegned, classes.chartStrategySpan)}
              ></span>
              <span>Total Return: </span>
              <span className={classes.titleValue}>
                {backTest && backTest.totalReturn === 0
                  ? '0%'
                  : (backTest.totalReturn * 100).toFixed(2) + '%'}
              </span>
              <span
                className={classNames(
                  classes.chartStrategyLegned,
                  classes.chartBenchmarkSpan,
                  classes.marginLeft60
                )}
              ></span>
              <span>{trackingBenchmarkType} Return: </span>
              <span className={classes.titleValue}>
                {backTest?.benchmarkTotalReturn === 0
                  ? '0%'
                  : (backTest.benchmarkTotalReturn * 100).toFixed(2) + '%'}
              </span>
            </div>
            <table width="100%">
              <tbody>
                <tr>
                  <td colSpan="4">
                    {' '}
                    <img
                      src={backtestInfoImage}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = noperf;
                      }}
                      alt="logo"
                      height="250"
                      className={classes.img}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table table-hover table-condensed table-striped table-bordered table-dense">
              <tr className={classes.tableTrOdd}>
                <td width="30%"></td>
                <td width="35%">Strategy</td>
                <td>{backtestBenchmarkType}</td>
              </tr>
              <tr className={classes.tableTrEven}>
                <td>CAGR</td>
                <td>
                  {backTest?.cagr === 0
                    ? '0%'
                    : backTest?.cagr
                    ? (backTest.cagr * 100).toFixed(2) + '%'
                    : 'N/A'}
                </td>
                <td>
                  {backTest?.benchmarkCAGR === 0
                    ? '0%'
                    : backTest?.benchmarkCAGR
                    ? (backTest.benchmarkCAGR * 100).toFixed(2) + '%'
                    : 'N/A'}
                </td>
              </tr>
              <tr className={classes.tableTrOdd}>
                <td>Alpha</td>
                <td>
                  {backTest?.alpha === 0 ? '0' : backTest.alpha ? backTest.alpha.toFixed(2) : 'N/A'}
                </td>
                <td>
                  {backTest?.benchmarkAlpha === 0
                    ? '0'
                    : backTest.benchmarkAlpha
                    ? backTest.benchmarkAlpha.toFixed(2)
                    : 'N/A '}
                </td>
              </tr>
              <tr className={classes.tableTrEven}>
                <td>Beta</td>
                <td>
                  {backTest?.beta === 0 ? '0' : backTest.beta ? backTest.beta.toFixed(2) : 'N/A'}
                </td>
                <td>
                  {backTest?.benchmarkBeta === 0
                    ? '0'
                    : backTest.benchmarkBeta
                    ? backTest.benchmarkBeta.toFixed(2)
                    : 'N/A '}
                </td>
              </tr>
              <tr className={classes.tableTrOdd}>
                <td>Sharpe</td>
                <td>
                  {backTest?.sharpe === 0
                    ? '0'
                    : backTest.sharpe
                    ? backTest.sharpe.toFixed(2)
                    : 'N/A'}
                </td>
                <td>
                  {backTest?.benchmarkSharpe === 0
                    ? '0'
                    : backTest.benchmarkSharpe
                    ? backTest.benchmarkSharpe.toFixed(2)
                    : 'N/A'}
                </td>
              </tr>
              <tr className={classes.tableTrEven}>
                <td>Sortino</td>
                <td>
                  {backTest?.sortino === 0
                    ? '0'
                    : backTest.sortino
                    ? backTest.sortino.toFixed(2)
                    : 'N/A'}
                </td>
                <td>
                  {backTest?.benchmarkSortino === 0
                    ? '0'
                    : backTest.benchmarkSortino
                    ? backTest.benchmarkSortino.toFixed(2)
                    : 'N/A'}
                </td>
              </tr>
              <tr className={classes.tableTrOdd}>
                <td>Volatility</td>
                <td>
                  {backTest?.volatility === 0
                    ? '0%'
                    : backTest?.volatility
                    ? (backTest.volatility * 100).toFixed(2) + '%'
                    : 'N/A'}
                </td>
                <td>
                  {backTest?.benchmarkVolatility
                    ? backTest?.benchmarkVolatility === 0
                      ? '0%'
                      : (backTest.benchmarkVolatility * 100).toFixed(2) + '%'
                    : 'N/A'}
                </td>
              </tr>
              <tr className={classes.tableTrEven}>
                <td>MaxDD</td>
                <td>
                  {backTest?.maxDrawdown === 0
                    ? '0%'
                    : backTest?.maxDrawdown
                    ? (backTest.maxDrawdown * 100).toFixed(2) + '%'
                    : 'N/A'}
                </td>
                <td>
                  {backTest?.benchmarkMaxDrawdown
                    ? backTest?.benchmarkMaxDrawdown === 0
                      ? '0%'
                      : (backTest.benchmarkMaxDrawdown * 100).toFixed(2) + '%'
                    : 'N/A'}
                </td>
              </tr>
            </table>
          </TabPanel>
        )}

        <TabPanel value={value} index={1}>
          <div>
            <span>Period: </span>
            <span className={classes.titleValue}>{trackingTime}</span>
          </div>
          <div>
            <span
              className={classNames(classes.chartStrategyLegned, classes.chartStrategySpan)}
            ></span>
            <span>Total Return: </span>
            <span className={classes.titleValue}>
              {trackingInfo?.cumReturn === 0
                ? '0%'
                : trackingInfo?.cumReturn
                ? (trackingInfo.cumReturn * 100).toFixed(2) + '%'
                : 'N/A'}
            </span>
            <span
              className={classNames(
                classes.chartStrategyLegned,
                classes.chartBenchmarkSpan,
                classes.marginLeft60
              )}
            ></span>
            <span>{backtestBenchmarkType} Return: </span>
            <span className={classes.titleValue}>
              {trackingInfo?.benchmarkCumReturn === 0
                ? '0%'
                : trackingInfo?.benchmarkCumReturn
                ? (trackingInfo.benchmarkCumReturn * 100).toFixed(2) + '%'
                : 'N/A'}
            </span>
          </div>
          <table width="100%">
            <tbody>
              <tr>
                <td colSpan="4">
                  {' '}
                  <img
                    src={trackingInfoImage}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noperf;
                    }}
                    alt="logo"
                    height="250"
                    className={classes.img}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table table-hover table-condensed table-striped table-bordered">
            <tr className={classes.tableTrOdd}>
              <td width="30%"></td>
              <td width="35%">Strategy</td>
              <td>{backtestBenchmarkType}</td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>CAGR</td>
              <td>
                {trackingInfo?.algoCAGR === 0
                  ? '0%'
                  : trackingInfo?.algoCAGR
                  ? (trackingInfo.algoCAGR * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
              <td>
                {trackingInfo?.benchmarkCAGR === 0
                  ? '0%'
                  : trackingInfo?.benchmarkCAGR
                  ? (trackingInfo.benchmarkCAGR * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrOdd}>
              <td>Alpha</td>
              <td>
                {strategyDetail?.trackingReport?.alpha === 0
                  ? '0'
                  : strategyDetail?.trackingReport?.alpha
                  ? strategyDetail?.trackingReport.alpha.toFixed(2)
                  : 'N/A'}
              </td>
              <td>0</td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>Beta</td>
              <td>
                {strategyDetail?.trackingReport?.beta === 0
                  ? '0'
                  : strategyDetail?.trackingReport?.beta
                  ? strategyDetail?.trackingReport.beta.toFixed(2)
                  : 'N/A'}
              </td>
              <td>1</td>
            </tr>
            <tr className={classes.tableTrOdd}>
              <td>Sharpe</td>
              <td>
                {strategyDetail?.trackingReport?.sharpe === 0
                  ? '0'
                  : strategyDetail?.trackingReport?.sharpe
                  ? strategyDetail?.trackingReport.sharpe.toFixed(2)
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.trackingReport?.benchmarkSharpe === 0
                  ? '0'
                  : strategyDetail?.trackingReport?.benchmarkSharpe
                  ? strategyDetail?.trackingReport.benchmarkSharpe.toFixed(2)
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>Sortino</td>
              <td>
                {strategyDetail?.trackingReport?.sortino === 0
                  ? '0'
                  : strategyDetail?.trackingReport?.sortino
                  ? strategyDetail?.trackingReport.sortino.toFixed(2)
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.trackingReport?.benchmarkSortino === 0
                  ? '0'
                  : strategyDetail?.trackingReport?.benchmarkSortino
                  ? strategyDetail?.trackingReport.benchmarkSortino.toFixed(2)
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrOdd}>
              <td>Volatility</td>
              <td>
                {strategyDetail?.trackingReport?.algoVolatility === 0
                  ? '0%'
                  : strategyDetail?.trackingReport?.algoVolatility
                  ? (strategyDetail.trackingReport.algoVolatility * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.trackingReport?.benchmarkVolatility === 0
                  ? '0%'
                  : strategyDetail?.trackingReport?.benchmarkVolatility
                  ? (strategyDetail.trackingReport.benchmarkVolatility * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>MaxDD</td>
              <td>
                {strategyDetail?.trackingReport?.maxDrawdown === 0
                  ? '0%'
                  : strategyDetail?.trackingReport?.maxDrawdown
                  ? (strategyDetail.trackingReport.maxDrawdown * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.trackingReport?.benchmarkMaxDrawdown === 0
                  ? '0%'
                  : strategyDetail?.trackingReport?.benchmarkMaxDrawdown
                  ? (strategyDetail.trackingReport.benchmarkMaxDrawdown * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
            </tr>
          </table>
        </TabPanel>
        {/*<Link className={classes.strategyNameLink}>*/}
        {/*  Back Test Info*/}
        {/*</Link>*/}
        {/*<Link className={classes.strategyNameLink}>*/}
        {/*  Tracking Info*/}
        {/*</Link>*/}
        {/*<Button*/}
        {/*  id="BacktestButton"*/}
        {/*  onClick={handleOnClickBacktest}*/}
        {/*  color="primary"*/}
        {/*  classes={{ label: classes.label }}*/}
        {/*  // variant="outlined"*/}
        {/*>*/}
        {/*  Back Test Info*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*  id="TrackingInfoButton"*/}
        {/*  onClick={handleOnClickTrackingInfo('TrackingInfo')}*/}
        {/*  color="primary"*/}
        {/*  classes={{ label: classes.label }}*/}
        {/*>*/}
        {/*  Tracking Info*/}
        {/*</Button>*/}
      </div>
      {/*<StrategyImageTabView></StrategyImageTabView>*/}
      {open && (
        <SubscribePortfolioDialog
          open={open}
          strategyType="AQS"
          onSubscribePortfolio={handleSubscribePortfolio}
          onClose={handleSubscribeClose}
          trackAccount={trackAccount}
          strategy={strategyDetail}
          aria-labelledby="responsive-dialog-title"
        ></SubscribePortfolioDialog>
      )}
      {openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      {showConfirmation && (
          <>
            <div className={classes.overlay} onClick={onCancel}/>
            <div className={classes.warning}>
              <div className={classes.error}>
                {stringUtils.maxoutUseableCash}
              </div>
              <div>
                        <span>
                        <Button
                            onClick={handleConfirm}
                            color="info"
                            className={classes.warningButton}
                        >
                            Yes
                        </Button>
                        </span>
                <span>
                        <Button
                            onClick={onCancel}
                            color="info"
                            className={classes.warningButton}
                        >
                            Cancel
                        </Button>
                    </span>
              </div>
            </div>
          </>
      )}
      {redirectMyPortfolio && <Redirect to="/admin/strategy" />}
    </div>
  );
}

StrategyView.propTypes = {
  strategyDetail: PropTypes.object,
  trackAccount: PropTypes.array,
};
