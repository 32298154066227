import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function LinkAccountStatusDialog(props) {
    const handleCloseConfirmation = () => {
        props.handleLinkAccountStatusDialogClick(props.dlgData);
    };
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleCloseConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Linking Broker Account'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
                        {props.dlgData}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmation} color="primary" style={{ margin: "auto" }}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

LinkAccountStatusDialog.propTypes = {
    open: PropTypes.bool,
    dlgData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    handleLinkAccountStatusDialogClick: PropTypes.func,
};
