import React, { useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { environment } from '../../environments/environment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {login, forgotPasswordChange } from '../../utils/user.utils';
import AlertView from 'components/Alert/Alert.view.tsx';
import { stringUtils } from '../../utils/string.utils';
import { getVerificationImage, setVerificationCode, getSMSCode } from '../../utils/user.utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  label: {

    textTransform: 'capitalize',
    fontSize: '14px',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: 'none' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles(styles);
export default function LoginDialog(props) {
  const classes = useStyles(styles);
  const uesrnameTextFieldRef = useRef();
  const passwordTextFieldRef = useRef();
  const smsTextFieldRef = useRef();
  const [values, setValues] = React.useState({
    name: localStorage.getItem(environment.userNameKey)
      ? localStorage.getItem(environment.userNameKey)
      : null,
    password: null,
    sms: null,
  });
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });
  const [forgetPassword, setForgetPassword] = React.useState(false);
  //show sms box
  const [showSMS, setShowSMS] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(true);
  const [errMsg, setErrMsg] = React.useState(false);
  const [isLoading, setLoading ] = React.useState(false);
  const [image, setImage ] = React.useState(null);
  const [token, setToken ] = React.useState(null);
  //enable input boxes
  const [enable, setEnable ] = React.useState(true);
  const [unlocked, setUnlocked ] = React.useState(false);
  const [verifyMsg, setVerifyMsg] = React.useState(false);

  const initImage = () => {
    getVerificationImage()
        .then((res) => {
          if (res.status === 200) {
            var img = res.data.data? res.data.data.img : null;
            var code = res.data.data? res.data.data.codeToken : null;
            setImage(img);
            setToken(code);
          }
        })
        .catch((error) => {
          console.log('captcha error = %o', error);
        });
  };

  const sendVerification = (position) => {
    console.log('Send x:' +  position.x + " y: "+position.y);
    var args = position.x + ',' + position.y;
    setVerificationCode(args, token)
        .then((res) => {
          if (res.status === 200) {
            var verify = res.data.data ? res.data.data.verified : false;
            var retry = res.data.data ? res.data.data.remainCount : 0;
            if (verify) {
              setEnable(true);
              setUnlocked(true);
              setVerifyMsg('Matched');
              if (token !== res.data.data.codeToken)
                console.info('token not matched');
            } else if (retry == 0 ) {
              //renew image
              initImage();
              setVerifyMsg('Please Try Again');
            } else if (verify == false) {
              // console.info('fail to verify');
              setVerifyMsg('Not Matched');
            }
          }
        })
        .catch((error) => {
          console.log('verification error = %o', error);
        });
  };

  const sendSMSCode = () => {
    console.log('sendSMS');
    const username = uesrnameTextFieldRef.current.value.trim();
    const password = passwordTextFieldRef.current.value;
    getSMSCode({ username, password })
        .then((res) => {
          if (res.status === 200) {
            console.log('sms sent');
          }
        })
        .catch((error) => {
          console.log('sendSMS error = %o', error);
        });
  };

  const handleChange = (name) => (event) => {
    setErrMsg(false);
    setValues({ ...values, [name]: event.target.value });
  };

  const handleOnClickSignIn = () => {
    console.log('LoginModal: handleOnClickSignIn');
    const username = uesrnameTextFieldRef.current.value.trim();
    const password = passwordTextFieldRef.current.value;
    // const sms = smsTextFieldRef.current.value;
    login({ username, password, token})
      .then((response) => {
        console.log('Login dialog repo = %o', response);
        if (response && response?.code !== 200) {
          if (response.data.errMsg.includes('user.notactivated')) {
            setErrMsg('User is not activated');
            setOpenAlertMsg({
              alert: true,
              alertContent: stringUtils.userActivate,
              severity: 'error',
            });
          } else if (response.data.errMsg.includes('does not exist')) {
            setErrMsg(response.data.errMsg);
          } else {
            setErrMsg('Invalid username password combination');
          }
          setUnlocked(false);
          initImage();
          setVerifyMsg('Please Try Again');
        } else {
          // setShowSMS(true);
        }
      })
      .catch((error) => {
        console.log('login user error = %o', error);
        console.log('login user error msg = %s', error.data.errMsg);
        setErrMsg(error.data.errMsg);
        if (error.data.errMsg.includes('user.notactivated')) {
          setErrMsg('User is not activated');
          setOpenAlertMsg({
            alert: true,
            alertContent: stringUtils.userActivate,
            severity: 'error',
          });
        } else if (error.data.errMsg.includes('does not exist')) {
          setErrMsg(error.data.errMsg);
        } else {
          setErrMsg('Invalid username password combination');
        }
      });
  };

  const handleForgetPassword = () => {
    setForgetPassword(true);
  };

  const handleOnClickSendRequest = () => {
    forgotPasswordChange(uesrnameTextFieldRef.current.value.trim())
      .then((res) => {
        if (res.data.code === 200) {
          setOpenAlertMsg({
            alert: true,
            alertContent: stringUtils.forgetSuccess,
            severity: 'success',
          });
          handleClose();
        }
      })
      .catch((error) => {
        console.debug('forgot password: error = %o', error);
        setErrMsg(error.data.errMsg);
      });
  };

  const handleClose = () => {
    setForgetPassword(false);
    setLoading(false);
    setVerifyMsg(false);
    setUnlocked(false);
    props.handleLoginDialogClick(false);
  };

  const emailLabel = forgetPassword ? 'Email Address' : 'User Name or Email';
  const titleLabel = forgetPassword ? 'Forget Password ' : 'Login';
  const errorName = values.name === '';
  const errorPassword = values.password === '';

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  // const [slippy, setSlippy] = React.useState(false);
  const [borderColor, setBorderColor] = React.useState('red');

  const useMousePosition = () => {
    const [position, setPosition] = React.useState({ x: 0, y: 0 });
    React.useEffect(() => {
      const onMousemove = (e) => {
        setPosition({x: e.clientX, y: e.clientY});
        var m_posx = 0, m_posy = 0, e_posx = 0, e_posy = 0,
            obj = document.getElementById('imgbox');
        if (e.pageX || e.pageY) {
          m_posx = e.pageX;
          m_posy = e.pageY;
        } else if (e.clientX || e.clientY) {
          m_posx = e.clientX + document.body.scrollLeft
              + document.documentElement.scrollLeft;
          m_posy = e.clientY + document.body.scrollTop
              + document.documentElement.scrollTop;
        }
        if (obj && obj.offsetParent){
            var pos = obj.getBoundingClientRect();
            e_posx = Math.round(pos.left);
            e_posy = Math.round(pos.top);
        }
        setPosition({x: m_posx-e_posx, y: m_posy-e_posy});
      }
      window.addEventListener("mousemove", onMousemove, false);

      return () => {
        window.removeEventListener("mousemove", onMousemove, false);
      };
    }, []);
    return position;
  };

  const position = useMousePosition();

  //const Example = ({ data }) => <img src={`data:image/jpeg;base64,${data}`} onClick={mouseClick}  />
  const Example = ({ data }) => <img src={`data:image/jpeg;base64,${data}`} />

  function mouseClick(){
    sendVerification(position);
    var box = document.getElementById('imgbox');
    box.style.border = '3px solid blue';
    setTimeout(() => {
      box.style.border = '1px solid black';
    }, 800);
    setErrMsg(false);
  }

  function sendSMS() {
      console.log('request sms code');
      sendSMSCode();
      setShowSMS(true);
  };

  if (!showSMS && !isLoading) {
    setLoading(true);
    initImage();
  }

  return (
    <div>
      {openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle style={{ width: '450px' }} id="customized-dialog-title" onClose={handleClose}>
          {titleLabel}
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label={emailLabel}
            type="email"
            required
            value={values.name}
            onChange={handleChange('name')}
            error={errorName}
            helperText={errorName ? 'This field is required' : ' '}
            fullWidth
            inputRef={uesrnameTextFieldRef}
            disabled={!enable}
          />
          <div style={{ marginTop: '10px' }}></div>
          {!forgetPassword && showPassword && (
            <TextField
              id="password"
              label="Password"
              type="password"
              required
              value={values.password}
              onChange={handleChange('password')}
              error={errorPassword}
              helperText={errorPassword ? 'This field is require' : ' '}
              fullWidth
              inputRef={passwordTextFieldRef}
              disabled={!enable}
            />
          )}
          {!forgetPassword && (
            <div classes={{ label: classes.label}} style={{ marginTop: '10px', border:'none'}}>Match the image to unlock: <span style={{ fontWeight: 'bold'}}> {verifyMsg}</span></div>
          )}
          {!forgetPassword && (
            <div id='imgbox' style={{ marginTop: '0px', border:'1px solid black'}} onClick={mouseClick} >
              <Example data={image} />
            </div>
          )}
          {/*<div style={{ marginTop: '10px' }}></div>*/}
          {/*{showSMS &&*/}
          {/*  (<TextField*/}
          {/*    id="sms"*/}
          {/*    label="SMS Code"*/}
          {/*    type="text"*/}
          {/*    required*/}
          {/*    onChange={handleChange('sms')}*/}
          {/*    fullWidth*/}
          {/*    inputRef={smsTextFieldRef}*/}
          {/*    disabled={!values.name || !values.password}*/}
          {/*      />*/}
          {/*  )}*/}
          <div style={{ marginTop: '20px' }}></div>
          {errMsg && <div className="errorMsg">{errMsg}</div>}
        </DialogContent>
        <DialogActions>
          {!forgetPassword ? (
            <div>
              {/*<Button*/}
              {/*    onClick={sendSMS}*/}
              {/*    color="primary"*/}
              {/*    classes={{ label: classes.label }}*/}
              {/*    disabled={!values.name || !values.password}*/}
              {/*>*/}
              {/*  Request SMS Code*/}
              {/*</Button>*/}
              <Button
                onClick={handleOnClickSignIn}
                color="primary"
                classes={{ label: classes.label }}
                disabled={!values.name || !values.password || !unlocked /*|| !values.sms*/}
              >
                Sign In
              </Button>
              <Button onClick={handleForgetPassword} color="primary" className={classes.label}>
                Forget Password
              </Button>
            </div>
          ) : (
            <Button
              onClick={handleOnClickSendRequest}
              color="primary"
              className={classes.label}
              disabled={!values.name || errorName}
            >
              Send
            </Button>
          ) }
        </DialogActions>
      </Dialog>
    </div>
  );
}

LoginDialog.propTypes = {
  open: PropTypes.bool,
  forgetPassword: PropTypes.bool,
  handleLoginDialogClick: PropTypes.func,
  handleOnClickSignIn: PropTypes.func,
};
