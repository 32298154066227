import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import introimg from 'assets/img/gopipa_logo.png';
import trackingimg from 'assets/img/broker-account-table.png';
import brokerimg from 'assets/img/broker-account.png';
import addaccountimg from 'assets/img/add-ib-account.png';
import ibauthorizeimg from 'assets/img/ib-authorization.png';
import aqmlistimg from 'assets/img/aqm-table.png';
import risk1img from 'assets/img/userRiskLevelDetails.png';
import risk2img from 'assets/img/risk_assessment.png';
import amqpaperimg from 'assets/img/aqm-paper.png';
import amsmoneyimg from 'assets/img/aqs-money.png';
import amsmonitorimg from 'assets/img/aqs-monitor.png';
import supportimg from 'assets/img/support.png';
import positionimg from 'assets/img/positions.png';
import compareimg from 'assets/img/aqs_compare.png';
import backtestimg from 'assets/img/backtest.png';
import aqmdetailtimg from 'assets/img/aqm_detail.png';
import investmentimg from 'assets/img/investment.png';
import leveragedimg from 'assets/img/leveragedetf.png';
import ReactHtmlParser from "html-react-parser";
import { tutorialText } from './tutorial-text';
import Button from "@material-ui/core/Button";
import NextIcon from '@material-ui/icons/ArrowForward';
import PrevIcon from '@material-ui/icons/ArrowBack';
import {stringUtils} from "../../utils/string.utils";

const styles = {
    started: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        backgroundColor: 'whitesmoke',
        padding: '5px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        height: '650px',
        width: '880px',
        // width: 'auto',
        overflow: 'auto',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        opacity: 0.4,
    },
    imageBox: {
        display: 'block',
        position: 'relative',
        // border:'solid black 1px',
        top: '5px',
        margin: '10px',
        textAlign: 'center',
    },
    logo: {
        position: 'relative',
        // height: 'auto',
        // width: '800px',
        height: '200px',
        maxWidth: '800px',
    },
    image: {
        position: 'relative',
        // height: 'auto',
        // width: '800px',
        height: '270px',
        maxWidth: '750px',
        border: 'solid grey 1px',
        boxShadow: '10px 10px 5px #ccc',
    },
    prevButton: {
        position: 'absolute',
        left: '15px',
        bottom: '5px',
        margin: '5px',
        // backgroundColor: 'whitesmoke',
        fontFamily: 'Arial',
        borderRadius: '5px',
    },
    closeButton: {
        position: 'absolute',
        right: '46%',
        left: '46%',
        bottom: '5px',
        margin: '5px',
        // backgroundColor: 'whitesmoke',
        fontFamily: 'Arial',
        borderRadius: '5px',
    },
    nextButton: {
        position: 'absolute',
        right: '15px',
        bottom: '5px',
        margin: '5px',
        // backgroundColor: 'whitesmoke',
        fontFamily: 'Arial',
        borderRadius: '5px',
    },
    button: {
        position: 'absolute',
        // right: '15px',
        bottom: '5px',
        margin: '5px',
        // backgroundColor: 'whitesmoke',
        fontFamily: 'Arial',
        borderRadius: '5px',
    },
    intro: {
        position: 'relative',
        fontFamily: 'Arial',
        fontWeight: '500',
        color: '#00acc1',
        fontSize: '18px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginLeft: '25px',
        marginRight: '25px',
        marginTop: '15px',
        textAlign: 'left',
    },
    textBox: {
        position: 'relative',
        fontFamily: 'Arial',
        fontSize: '14px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginLeft: '25px',
        marginRight: '25px',
        marginTop: '5px',
        textAlign: 'left',
    },
    bigtitle: {
        fontFamily: 'Arial',
        color:'#999',
        fontWeight: 'bold',
        fontSize: '20px',
        margin:'20px 10px',
    },
    title: {
        textAlign: 'center',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '22px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginTop: '25px',
        marginRight: '25px',
        marginLeft: '25px',
    },
    subtitle: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '18px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginTop: '25px',
        marginRight: '25px',
        marginLeft: '25px',
    },
}

function getImage(step) {
    if (step === 0) {
    } else if (step === 1) {
        return brokerimg
    } else if (step === 2) {
        return leveragedimg;
    } else if (step === 3) {
        return trackingimg;
    } else if (step === 4) {
        return addaccountimg;
    } else if (step === 5) {
        return ibauthorizeimg;
    } else if (step === 6) {
        return risk1img;
    } else if (step === 7) {
        return risk2img;
    } else if (step === 8) {
        return aqmlistimg;
    } else if (step === 9) {
        return amqpaperimg;
    } else if (step === 10 ) {
        return compareimg;
    } else if (step === 11 ) {
        return backtestimg;
    } else if (step === 12 ) {
        return aqmdetailtimg;
    } else if (step === 13) {
        return positionimg;
    } else if (step === 14) {
        return amsmoneyimg;
    } else if (step === 15) {
        return amsmonitorimg;
    } else if (step === 16) {
        return investmentimg;
    } else if (step === 17) {
        return supportimg;
    } else {
        return trackingimg;
    }
}

function getSubtitle(step) {
    // console.info('Step: ' + step);
    if (step === 0) {
        return tutorialText.title;
    } else if (step === 1) {
        return tutorialText.step1Subtitle;
    } else if (step === 2) {
        return tutorialText.step2Subtitle;
    } else if (step === 3) {
        return tutorialText.step3Subtitle;
    } else if (step === 4) {
        return tutorialText.step4Subtitle;
    } else if (step === 5) {
        return tutorialText.step5Subtitle;
    } else if (step === 6) {
        return tutorialText.step6Subtitle;
    } else if (step === 7) {
        return tutorialText.step7Subtitle;
    } else if (step === 8) {
        return tutorialText.step8Subtitle;
    } else if (step === 9) {
        return tutorialText.step9Subtitle;
    } else if (step === 10) {
        return tutorialText.step10Subtitle;
    } else if (step === 11) {
        return tutorialText.step11Subtitle;
    } else if (step === 12) {
        return tutorialText.step12Subtitle;
    } else if (step === 13) {
        return tutorialText.step13Subtitle;
    } else if (step === 14) {
        return tutorialText.step14Subtitle;
    } else if (step === 15) {
        return tutorialText.step15Subtitle;
    } else if (step === 16) {
        return tutorialText.step16Subtitle;
    } else if (step === 17) {
        return tutorialText.step17Subtitle;
    } else {
        return 'N/A';
    }
}

function getText(step) {
    if (step === 0) {
        return tutorialText.intro;
    } else if (step === 1) {
        return tutorialText.step1Text;
    } else if (step === 2) {
        return tutorialText.step2Text;
    } else if (step === 3) {
        return tutorialText.step3Text;
    } else if (step === 4) {
        return tutorialText.step4Text;
    } else if (step === 5) {
        return tutorialText.step5Text;
    } else if (step === 6) {
        return tutorialText.step6Text;
    } else if (step === 7) {
        return tutorialText.step7Text;
    } else if (step === 8) {
        return tutorialText.step8Text;
    } else if (step === 9) {
        return tutorialText.step9Text;
    } else if (step === 10) {
        return tutorialText.step10Text;
    } else if (step === 11) {
        return tutorialText.step11Text;
    } else if (step === 12) {
        return tutorialText.step12Text;
    } else if (step === 13) {
        return tutorialText.step13Text;
    } else if (step === 14) {
        return tutorialText.step14Text;
    } else if (step === 15) {
        return tutorialText.step15Text;
    } else if (step === 16) {
        return tutorialText.step16Text;
    } else if (step === 17) {
        return tutorialText.step17Text;
    } else {
        return 'N/A';
    }
}

const useStyles = makeStyles(styles);

function GetStarted({onClick, init}) {

    const maxstep = 17;
    const [templateZero, setTemplateZero] = React.useState(true);
    const [templateOne, setTemplateOne] = React.useState(false);
    const [prevButtonState, setPrevButtonState] = React.useState(false);
    const [nextButtonState, setNextButtonState] = React.useState(true);
    const [image, setImage] = React.useState('N/A');
    const [subtitleText, setSubtitleText] = React.useState('N/A');
    const [textContent, setTextContent] = React.useState('N/A');
    const [step, setStep] = React.useState(init);

    const classes = useStyles();

    function prevStep() {
        // console.info('Current Step: ' + (step - 1))
        if ((step - 1) === 0) {
            setTemplateZero(true);
            setTemplateOne(false);
        } else {
            setTemplateZero(false);
            setTemplateOne(true);
        }
        if (step >= 1) {
            setImage(getImage(step - 1));
            setSubtitleText(getSubtitle(step - 1));
            setTextContent(getText(step - 1));
            if (step === 1)
                setPrevButtonState(false);
            if (step <= (maxstep))
                setNextButtonState(true);
            setStep(step - 1);
        } else {
            setPrevButtonState(false);
            setNextButtonState(true);
        }
    }

    function nextStep() {
        console.info('Current Step: ' + (step + 1))
        if ((step + 1) === 0) {
            setTemplateZero(true);
            setTemplateOne(false);
        } else {
            setTemplateZero(false);
            setTemplateOne(true);
        }
        if ((step+1) <= maxstep) {
            setImage(getImage(step + 1));
            setSubtitleText(getSubtitle(step + 1));
            setTextContent(getText(step + 1));
            setPrevButtonState(true);
            if (step === (maxstep-1))
                setNextButtonState(false);
            setStep(step + 1);
        }
    }

    function gotoStep(step) {
        // console.info('Go to Step:' + step)
        if (step === 0) {
            setTemplateZero(true);
            setTemplateOne(false);
        } else {
            setTemplateZero(false);
            setTemplateOne(true);
        }
        if (step <= maxstep) {
            setImage(getImage(step));
            setSubtitleText(getSubtitle(step));
            setTextContent(getText(step));
            setPrevButtonState(true);
            if (step === maxstep)
                setNextButtonState(false);
        }
        setStep(step);
    }

    React.useEffect(() => {
        console.info("Initialize " + init);
        if (step === 0) {
            setTemplateZero(true);
            setTemplateOne(false);
            setSubtitleText(getSubtitle(step));
            setTextContent(getText(step));
        } else {
            setTemplateZero(false);
            setTemplateOne(true);
            setImage(getImage(step));
            setSubtitleText(getSubtitle(step));
            setTextContent(getText(step));
            if (step >= 1) {
                setPrevButtonState(true);
            }
            if (step >= maxstep) {
                setNextButtonState(false);
            }
        }
    },[]);

    return (
        <>
            <div className={classes.overlay} onClick={onClick}/>
            <div className={classes.started}>
                <div align="center" className={classes.bigtitle}>Get Started</div>
                <div style={{display: (templateZero? 'block' : 'none')}}>
                    <div  className={classes.imageBox}>
                        <img className={classes.logo} src={introimg}/>
                    </div>
                    <div className={classes.title}>
                        How to Use iPIPA Services
                    </div>
                    <div className={classes.intro}>
                        <span>
                            <a
                              onClick={() => gotoStep(1)}
                            >
                                {tutorialText.summary1}
                            </a>
                        </span>
                    </div>
                    <div className={classes.intro}>
                        <span>
                            <a
                              onClick={() => gotoStep(3)}
                            >
                              {tutorialText.summary2}
                            </a>
                        </span>
                    </div>
                    <div className={classes.intro}>
                        <span>
                            <a
                                onClick={() => gotoStep(6)}
                            >
                              {tutorialText.summary3}
                            </a>
                        </span>
                    </div>
                    <div className={classes.intro}>
                        <span>
                            <a
                                onClick={() => gotoStep(8)}
                            >
                              {tutorialText.summary4}
                            </a>
                        </span>
                    </div>
                    <div className={classes.intro}>
                        <span>
                            <a
                                onClick={() => gotoStep(15)}
                            >
                              {tutorialText.summary5}
                            </a>
                        </span>
                    </div>
                </div>
                <div style={{display: (templateOne? 'block' : 'none')}}>
                    <div  className={classes.imageBox}>
                        <img className={classes.image} src={image}/>
                    </div>
                    <div className={classes.subtitle}>
                        {ReactHtmlParser(String(subtitleText))}
                    </div>
                    <div className={classes.textBox} >
                        {ReactHtmlParser(String(textContent))}
                    </div>
                </div>
                <div  style={{display: (prevButtonState ? 'block' : 'none')}}>
                {/*<button className={classes.prevButton} onClick={prevStep}>Prev</button>*/}
                    <Button className={classes.prevButton} variant="contained" size="small" onClick={prevStep} startIcon={<PrevIcon/>}>Prev</Button>
                </div>
                <div  align="center">
                <   Button className={classes.closeButton} variant="contained" size="small" onClick={onClick}>Close</Button>
                </div>
                <div style={{display: (nextButtonState ? 'block' : 'none')}}>
                    <Button className={classes.nextButton} variant="contained" size="small" onClick={nextStep} endIcon={<NextIcon/>}>Next</Button>
                </div>
            </div>
        </>
    );
}

export default GetStarted;
