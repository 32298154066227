import React from 'react';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import FooterView from '../../views/Home/Footer.view';
import { stringUtils } from '../../utils/string.utils';

import iPapaIBSetupPdf from 'assets/help/Interactive Brokers Account Setup Guide.pdf';
import iPapaFQAPdf from 'assets/help/GoPIPA Frequently Asked Questions 03262024.pdf';
import summaryPdf from 'assets/help/GoPIPA Company and Solution Summary 03262024.pdf';
import overviewPdf from 'assets/help/GoPIPA Company and Solution Overview 03262024.pdf';
import RewardPdf from 'assets/help/GoPIPA Friends and Family Reward Program.pdf';
const userTutorialVideo = 'https://gopipa.s3.us-west-2.amazonaws.com/iPIPA+User+Tutorial+12212023.mp4';
const Seminar0518Video = 'https://gopipa.s3.us-west-2.amazonaws.com/GoPIPA+User+Seminar+05182024.mp4';

export default function CompanyView() {
  return (
    <div style={{ background: '#fff' }}>
      <div className="companyBanner">
        <div className="container">
          <div className="content1200">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="supportImage"></div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div className="content1300" style={{ padding: '35px 35px 35px 50px' }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <div className="supportItem">
              <div className="supportItemH4">
                <a
                  href={iPapaIBSetupPdf}
                  target="_blank"
                  style={{ color: '#387EE8' }}
                  rel="noreferrer"
                >
                  1. {stringUtils.supportIBSetup}
                </a>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div className="supportItem">
              <div className="supportItemH4">
                <a href={iPapaFQAPdf} target="_blank" style={{ color: '#387EE8' }} rel="noreferrer">
                  2. {stringUtils.supportFAQ}
                </a>
              </div>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <div className="supportItem">
              <div className="supportItemH4" style={{ paddingTop: 90 }}>
                <a
                  href={summaryPdf}
                  target="_blank"
                  style={{ color: '#387EE8' }}
                  rel="noreferrer"
                >
                  <span>3. {stringUtils.supportSummary}</span>
                </a>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div className="supportItem">
              <div className="supportItemH4" style={{ paddingTop: 90 }}>
                <a
                  href={overviewPdf}
                  target="_blank"
                  style={{ color: '#387EE8' }}
                  rel="noreferrer"
                >
                  <span>4. {stringUtils.supportOverview}</span>
                </a>
              </div>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <div className="supportItem">
              <div className="supportItemH4" style={{paddingTop: 90}}>
                <a
                    href={Seminar0518Video}
                    target="_blank"
                    style={{color: '#387EE8'}}
                    referrerPolicy="origin"
                >
                  <span>5. {stringUtils.supportSeminar20240518}</span>
                </a>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div className="supportItem">
              <div className="supportItemH4">
                <a
                    href={userTutorialVideo}
                    target="_blank"
                    style={{ color: '#387EE8' }}
                    referrerpolicy="origin"
                >
                  <span>6. {stringUtils.supportUserTutorial}</span>
                </a>
              </div>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <div className="supportItem">
              <div className="supportItemH4" style={{ paddingTop: 90 }}>
                <a
                    href={RewardPdf}
                    target="_blank"
                    style={{ color: '#387EE8' }}
                    referrerpolicy="origin"
                >
                  <span>7. {stringUtils.supportUserReward}</span>
                </a>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <FooterView></FooterView>
    </div>
  );
}
