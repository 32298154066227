import React from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-ui/core/Card';
import SortIcon from '@material-ui/icons/ArrowDownward';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

const styles = {
    positionBox: {
        paddingRight: 20,
        paddingLeft: 20,
        height: "maxHeight",
        overflow: 'scroll',
    },
    noWarp: {
        whiteSpace: 'nowrap',
    },
};

const useStyles = makeStyles(styles);

export default function AQMPositionsView(props) {
    const classes = useStyles();
    const { positionData } = props;

    const columns = [
        {
            name: 'Symbol',
            selector: 'symbol',
            sortable: true,
            width: '12%',
        },
        {
            name:
            (
                <div style={{fontWeight: 700, fontSize: '13px', textAlign: 'center'}}>Price</div>
            ),
            selector: 'marketPrice',
            sortable: true,
            right: true,
            className: classes.noWarp,
            cell: (row) => {
                var price = row.marketPrice?Number(row.marketPrice).toFixed(2):'';
                return (
                    <div>
                        {price}
                    </div>
                );
            },
            width: '22%',
        },
        {
            name:
            (
                <div style={{fontWeight: 700, fontSize: '13px', textAlign: 'center'}}>Position</div>
            ),
            selector: 'position',
            sortable: true,
            right: true,
            width: '22%',
        },
        {
            name:
            (
                <div style={{fontWeight: 700, fontSize: '13px', textAlign: 'center'}}>Position Value</div>
            ),
            selector: 'positionValue',
            sortable: true,
            right: true,
            cell: (row) => {
                var totalValue = row.positionValue?Number(row.positionValue).toFixed(2):'--';
                return (
                    <div>
                        {totalValue}
                    </div>
                );
            },
            width: '22%',
        },
        {
            name:
            (
                <div style={{fontWeight: 700, fontSize: '13px', textAlign: 'center'}}>Position Percentage</div>
            ),
            selector: 'positionPercentage',
            sortable: true,
            right: true,
            cell: (row) => {
                var percentage = row.positionPercentage?row.positionPercentage:'--';
                return (
                    <div>
                        {percentage}
                    </div>
                );
            },
            width: '22%',
        },
    ];

    return (
        <div>
            <Card>
                <div className={classes.positionBox}>
                    <DataTable
                        title="Positions"
                        data={positionData}
                        columns={columns}
                        fixedHeaderScrollHeight="50vh"
                        fixedHeader
                        highlightOnHover
                        striped
                        defaultSortFieldId={1}
                        sortIcon={<SortIcon />}
                        // responsive={true}
                    />
                </div>
            </Card>
        </div>
    );
}

AQMPositionsView.propTypes = {
    positionData: PropTypes.array,
};
