import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
// @material-ui/icons
import backtestimg from 'assets/img/backtest.png';
import introimg from "../../assets/img/gopipa_logo.png";
import Button from "../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {getEtfIDA, getStockIDA} from "../../utils/strategy.utils";
import {getLoginUser} from "../../utils/user.utils";

const styles = {
    noWarp: {
        whiteSpace: 'nowrap',
    },
    chartBox: {
        fontFamily: 'Arial, Helvetica',
        height: 'auto',
        margin: '25px 10px',
        fontWeight: 600,
        border: '1px solid #000000DE',
        maxWidth: '900px',
    },
    tabletitle: {
        fontSize: '18px',
        fontWeight: 550,
        padding: '10px 15px',
        color: '#000000DE',
        backgroundColor: '#e5e0e0',
    },
    error: {
        fontSize: '16px',
        fontWeight: 800,
        padding: '3px 0px',
        color: 'red',
    },
    imageBox: {
        display: 'block',
        position: 'relative',
        top: '5px',
        width: '1000px',
        margin: '10px',
        textAlign: 'center',
    },
    waitingMsg: {
        fontWeight: 400,
        fontSize: 20,
        textAlign: 'center',
        marginTop: 70,
        marginLeft: -80,
    },
    circularProgressTop: {
        marginTop: 20,
    },
    circularProgressDiv: {
        border: '1px solid 1px solid #d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
    },
    idaBox: {
        position: 'relative',
        width: '100%',
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '5px',
        margin: '0px 0px 5px 10px',
        fontWeight: '400',
        fontSize: '16px',
        fontFamily: 'Arial, Helvetica',
        // border: '1px solid red',
    },
    title: {
        fontSize: '16px',
        fontWeight: 400,
        padding: '10px 0px 0px 10px',
        color: '#000000DE',
    },
    functionBox: {
        width: '235px',
        display: 'inline-block',
        height: 'auto',
        padding: '5px 0px 0px 0px',
    },
    menuList: {
        marginLeft: '-15px',
        width: '235px',
        border: '1px solid #000000DE',
        marginTop: '-8px',
        padding: '5px 15px',
        borderRadius: '5px',
    },
    menuItem: {
        color: 'black',
        fontSize: '16px',
        '& .Mui-selected': {
            display: 'block',
            backgroundColor: '#EBEBEB',
        },
        fontFamily: 'Arial, Helvetica',
        color: '#000000DE',
    },
};

const useStyles = makeStyles(styles);

export default function IDAETFDataView() {
    const [idaColumns, setIdaColumns] = React.useState([]);
    const [idaTable, setIdaTable] = React.useState([]);
    const [updateTime, setUpdateTime] = React.useState('');
    const [tableWidth, setTableWidth] = React.useState('auto');
    const [progress, setProgress] = React.useState(false);
    const [disableMenu, setDisableMenu] = React.useState(true);
    const [menuIndex, setMenuIndex] = React.useState(1);
    const [showCoreETF, setShowCoreETF] = React.useState(true);
    const [showCommodityETF, setShowCommodityETF] = React.useState(false);
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);

    const menu_options = [
        'Select Comparison',
        'Major ETFs',
        'Stock Index ETFs',
        'Sector ETFs',
        'Bond ETFs',
        'Commodity ETFs',
    ]

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setMenuIndex(index);
        setAnchorEl1(null);
        getIDAEtfTable(index);
    };

    const handleClickMenuItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const etf_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Symbol</div>
                ),
            selector: 'symbol',
            sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Daily Return</div>
                ),
            selector: 'dailyReturn',
            sortable: true,
            // right: true,
            width: '110px',
            cell: (row) => {
                const data = row.dailyReturn? row.dailyReturn.toFixed(2) + '%':'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>5 Days Return</div>
                ),
            selector: 'weeklyReturn',
            sortable: true,
            // right: true,
            width: '110px',
            cell: (row) => {
                const data = row.weeklyReturn? row.weeklyReturn.toFixed(2) + '%':'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>21 Days Return</div>
                ),
            selector: 'monthlyReturn',
            sortable: true,
            // right: true,
            width: '110px',
            cell: (row) => {
                const data = row.monthlyReturn? row.monthlyReturn.toFixed(2) + '%':'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>61 Days Return</div>
                ),
            selector: 'quarterlyReturn',
            sortable: true,
            // right: true,
            width: '110px',
            cell: (row) => {
                const data = row.quarterlyReturn? row.quarterlyReturn.toFixed(2) + '%':'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>252 Days Return</div>
                ),
            selector: 'yearlyReturn',
            sortable: true,
            // right: true,
            width: '110px',
            cell: (row) => {
                const data = row.yearlyReturn? row.yearlyReturn.toFixed(2) + '%':'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Volatility (last 5 years)</div>
                ),
            selector: 'volatility',
            sortable: true,
            width: '110px',
            cell: (row) => {
                const data = row.volatility? row.volatility.toFixed(2) + '%':'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Max-Drawdown (last 5 years)</div>
                ),
            selector: 'maxDD',
            sortable: true,
            width: '110px',
            cell: (row) => {
                const data = row.maxDD? row.maxDD.toFixed(2) + '%':'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const setLoading = (on) => {
        setProgress(on);
        setDisableMenu(on);
    }

    const getIDAEtfTable = (group) => {
        getEtfIDA(group)
            .then((response) => {
                if (response.data.code === 200) {
                    const data = response.data.data;
                    setLoading(false);
                    if (data.idaTable !== null) {
                        setIdaTable(data.idaTable);
                    }
                    if (data.updateTime !== null) {
                        setUpdateTime(data.updateTime)
                    }
                } else  {
                    const err = response?.data?.errMsg? response.data.errMsg : null;
                    setIdaColumns(etf_columns);
                    setIdaTable([]);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setIdaColumns(etf_columns);
                setLoading(false);
                setIdaTable([]);
                console.debug(error);
            });
    }

    React.useEffect(() => {
        setLoading(true);
        getIDAEtfTable(1);
    }, []);

    const classes = useStyles();

    return (
        <>
            <div>
                {/*<div className={classes.title} style={{marginTop: '25px'}}>Select one of the Comparisons:</div>*/}
                <div className={classes.title} style={{marginTop: '15px'}} />
                <div className={classes.idaBox}>
                    <span className={classes.functionBox}>
                            <Box
                                ml={2}
                                sx={{ width: '50%',
                                    maxWidth: 200,
                                }}
                            >
                                <List
                                    component="nav"
                                    aria-label="Functions"
                                >
                                    <ListItem
                                        className={classes.menuList}
                                        button
                                        id="lock-button"
                                        aria-haspopup="listbox"
                                        onClick={handleClickMenuItem}
                                    >
                                        <ListItemText
                                            primary={menu_options[menuIndex]}
                                            secondary="comparison"
                                        />
                                    </ListItem>
                                </List>
                                <Menu
                                    className={classes.menuItem}
                                    id="function-menu"
                                    anchorEl={anchorEl1}
                                    open={open1}
                                    onClose={handleClose1}
                                    MenuListProps={{
                                        'aria-labelledby': 'lock-button',
                                        role: 'listbox',
                                    }}
                                >
                                    {menu_options.map((option, index) => (
                                        <MenuItem
                                            className={classes.menuItem}
                                            key={option}
                                            disabled={index === 0 || disableMenu}
                                            selected={index === menuIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </span>
                </div>
                <hr style={{margin: '15px 0px 5px 0px'}}/>
                { progress && (
                <div className={classes.circularProgressDiv}>
                    <CircularProgress className={classes.circularProgressTop} />
                    <div className={classes.waitingMsg}>Please wait...</div>
                </div>
                )}
                { showCoreETF && (
                    <div className={classes.chartBox}>
                    <div className={classes.tabletitle}>{menu_options[menuIndex]} Performance <small>(Based on End Of Day Data of {updateTime})</small></div>
                        <div>
                            <DataTable
                                data={idaTable}
                                columns={etf_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                            />
                        </div>
                        <hr style={{margin: '5px'}}/>
                    </div>
                )}
            </div>
        </>
    );
}
