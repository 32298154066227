import React, { useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { signUpAccount,  getVerificationImage, setVerificationCode } from '../../utils/user.utils';
import AlertView from 'components/Alert/Alert.view.tsx';
import { stringUtils } from '../../utils/string.utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '14px',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: 'none' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles(styles);
export default function SignUpDialog(props) {
  const [errMsg, setErrMsg] = React.useState(false);
  const [values, setValues] = React.useState({
    username: null,
    email: props?.email,
    password: null,
    repeatPassword: null,
    invitationCode: props?.invitationCode,
    referral: props?.referral,
    mobile: '',
    firstName: null,
    lastName: null,
  });
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });
  const [verifyMsg, setVerifyMsg] = React.useState(false);
  const [image, setImage ] = React.useState(null);
  const [token, setToken ] = React.useState(null);
  const [enable, setEnable ] = React.useState(true);
  const [unlocked, setUnlocked ] = React.useState(false);
  const [isLoading, setLoading ] = React.useState(false);

  const initImage = () => {
    getVerificationImage()
        .then((res) => {
          if (res.status === 200) {
            var img = res.data.data? res.data.data.img : null;
            var code = res.data.data? res.data.data.codeToken : null;
            setImage(img);
            setToken(code);
          }
        })
        .catch((error) => {
          console.log('captcha error = %o', error);
        });
  };

  const sendVerification = (position) => {
    var args = position.x + ',' + position.y;
    setVerificationCode(args, token)
        .then((res) => {
          if (res.status === 200) {
            var verify = res.data.data ? res.data.data.verified : false;
            var retry = res.data.data ? res.data.data.remainCount : 0;
            if (verify) {
              setEnable(true);
              setUnlocked(true);
              setVerifyMsg('Matched');
            } else if (retry == 0 ) {
              //renew image
              initImage();
              setVerifyMsg('Please Try Again');
            } else if (verify == false) {
              // console.info('fail to verify');
              setVerifyMsg('Not Matched');
            }
          }
        })
        .catch((error) => {
          console.log('verification error = %o', error);
        });
  };

  const classes = useStyles();
  const handleOnClickSignUp = () => {
    const params = {
      userName: values.username.trim(),
      email: values.email.trim(),
      password: values.password,
      inviteCode: values.invitationCode.trim(),
      mobile: values.mobile.trim(),
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      referral: values.referral,
    };
    // console.info('handleOnClickSignUp params = %o', params);
    signUpAccount(params)
      .then((res) => {
        // console.info('SignUpDialog: res = %o', res);
        if (res.data.code === 200) {
          props.handleSignUpDialogClick(false);
          setOpenAlertMsg({
            alert: true,
            alertContent: stringUtils.registerSuccess,
            severity: 'success',
          });
        } else {
          const data = res && res.data ? res.data : null;
          if (!data.data && data.errMsg) {
            setErrMsg(res.data.err);
          }
        }
      })
      .catch((error) => {
        console.debug(error);
        setErrMsg(error.data.errMsg);
      });
  };

  const handleChange = (name) => (event) => {
    setErrMsg(false);
    setValues({ ...values, [name]: event.target.value });
  };

  const handleOnClickLogin = () => {
    props.handleSignUpDialogClick(false);
    props.handleLoginDialogClick(true);
  };

  const handleClose = () => {
    setLoading(false);
    setVerifyMsg(false);
    setUnlocked(false);
    props.handleSignUpDialogClick(false);
  };

  const errorUsername = values.username === '';
  const errorFirstName = values.firstName === '';
  const errorLastName = values.lastName === '';
  const errorEmail = values.email === '';
  const errorPassword = values.password === '';
  const errorRepeatPassword = values.repeatPassword !== values.password;
  const errorInvitationCode = values.invitationCode === '';

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  const useMousePosition = () => {
    const [position, setPosition] = React.useState({ x: 0, y: 0 });
    React.useEffect(() => {
      const onMousemove = (e) =>  {
        setPosition({ x: e.clientX, y: e.clientY });
        var m_posx = 0, m_posy = 0, e_posx = 0, e_posy = 0,
            obj = document.getElementById('imgbox');
        if (e.pageX || e.pageY){
          m_posx = e.pageX;
          m_posy = e.pageY;
        } else if (e.clientX || e.clientY){
          m_posx = e.clientX + document.body.scrollLeft
              + document.documentElement.scrollLeft;
          m_posy = e.clientY + document.body.scrollTop
              + document.documentElement.scrollTop;
        }
        if (obj && obj.offsetParent){
          var pos = obj.getBoundingClientRect();
          e_posx = Math.round(pos.left);
          e_posy = Math.round(pos.top);
        }
        setPosition({x: m_posx-e_posx, y: m_posy-e_posy});
      }
      window.addEventListener("mousemove", onMousemove, false);

      return () => {
        window.removeEventListener("mousemove", onMousemove, false);
      };
    }, []);
    return position;
  };

  const position = useMousePosition();

  const Example = ({ data }) => <img src={`data:image/jpeg;base64,${data}`} onClick={mouseClick}  />

  function mouseClick(){
    sendVerification(position);
    var box = document.getElementById('imgbox');
    box.style.border = '3px solid blue';
    box.style.width = '406px';
    setTimeout(() => {
      box.style.border = '1px solid black';
      box.style.width = '402px';
    }, 800);
  }

  if (!isLoading) {
    setLoading(true);
    initImage();
  }

  return (
    <div>
      {openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle style={{ width: '450px' }} id="customized-dialog-title" onClose={handleClose}>
          Sign Up
        </DialogTitle>
        <DialogContent dividers>
          {errMsg && <div className="errorMsg">{errMsg}</div>}
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                id="username"
                label="User Name"
                required
                value={values.username}
                onChange={handleChange('username')}
                error={errorUsername}
                helperText={errorUsername ? stringUtils.fieldRequired : ' '}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                margin="dense"
                id="emailAddress"
                label="Email Address"
                type="email"
                required
                value={values.email}
                onChange={handleChange('email')}
                error={errorEmail}
                helperText={errorEmail ? stringUtils.fieldRequired : ' '}
                fullWidth
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                margin="dense"
                id="firstName"
                label="First Name"
                required
                value={values.firstName}
                onChange={handleChange('firstName')}
                error={errorFirstName}
                helperText={errorFirstName ? stringUtils.fieldRequired : ' '}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                margin="dense"
                id="lastName"
                label="Last Name"
                required
                value={values.lastName}
                onChange={handleChange('lastName')}
                error={errorLastName}
                helperText={errorLastName ? stringUtils.fieldRequired : ' '}
                fullWidth
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                id="password"
                label="Password"
                type="password"
                required
                value={values.password}
                onChange={handleChange('password')}
                error={errorPassword}
                helperText={errorPassword ? stringUtils.fieldRequired : ' '}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                id="repeatPassword"
                label="Re-enter Password"
                type="password"
                required
                value={values.repeatPassword}
                onChange={handleChange('repeatPassword')}
                error={errorRepeatPassword}
                helperText={errorRepeatPassword ? 'Passwords do not match' : ' '}
                fullWidth
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                margin="dense"
                id="invitationCode"
                label="Invitation Code"
                required
                value={values.invitationCode}
                onChange={handleChange('invitationCode')}
                error={errorInvitationCode}
                helperText={errorInvitationCode ? stringUtils.fieldRequired : ' '}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                margin="dense"
                id="mobile"
                label="Mobile Phone Number"
                required
                type="phone"
                value={values.mobile}
                onChange={handleChange('mobile')}
                fullWidth
              />
            </GridItem>
          </GridContainer>
          <div style={{ marginTop: '10px' }}></div>
          <div classes={{ label: classes.label}} style={{ marginLeft: '50px', marginTop: '10px', width: '400px', border:'none'}}>Match the image to unlock: <span style={{ fontWeight: 'bold'}}> {verifyMsg}</span></div>
          <div id='imgbox' style={{ display: 'block', marginLeft: 'auto', marginRight:'auto', marginTop: '0px', border:'1px solid black', width: '402px', align:'center'}}>
            <Example data={image} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOnClickSignUp}
            color="primary"
            classes={{ label: classes.label }}
            disabled={
              !values.username ||
              !values.email ||
              !values.password ||
              !values.repeatPassword ||
              !values.invitationCode ||
              !values.firstName ||
              !values.lastName ||
              !values.mobile || !unlocked
            }
          >
            Sign Up
          </Button>
        </DialogActions>
        <div style={{ float: 'right', marginLeft: '205px' }}>
          GoPIPA account already existed,
          <Button
            style={{ marginTop: '-3px' }}
            onClick={handleOnClickLogin}
            color="primary"
            classes={{ label: classes.label }}
          >
            Sign in GoPIPA
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

SignUpDialog.propTypes = {
  open: PropTypes.bool,
  email: PropTypes.string,
  invitationCode: PropTypes.string,
  referral: PropTypes.string,
  handleSignUpDialogClick: PropTypes.func,
  handleLoginDialogClick: PropTypes.func,
};
