import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
// core components
import noperf from 'assets/img/noperf.svg';
import styles from 'assets/jss/material-dashboard-react/components/strategyCardStyle.js';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ViewDetailIcon from '@material-ui/icons/ViewHeadline';
import { getSvg, subscribeAQMStrategy, AqmStrategy } from 'utils/strategy.utils';
import SubscribePortfolioDialog from 'components/Dialog/SubscribePortfolioDialog.tsx';
import AlertView from '../../Alert/Alert.view';
import { moneyValueInUnit } from '../../../utils/common.ts';
import PersonalVideo from "@material-ui/icons/PersonalVideo";

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AqmStrategyView(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [redirectMyPortfolio, setRedirectMyPortfolio] = React.useState(false);
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });
  const [longerThan5days, setLongerThan5days] = React.useState(true);
  const { strategyDetail, trackAccount, strategyType, aqmStatus, onClickOpen } = props;
  const time = Math.floor(new Date().getTime() / 3600000);

  const trackingInfoImage = getSvg(
      strategyDetail.id,
    `.svg?t=${time}`,
    strategyDetail.subPath
  );

  const tradeDate = strategyDetail.tradeDate
    ? moment(strategyDetail.tradeDate).tz("America/New_York").format('YYYY-MM-DD')
    : 'N/A';

  const activeTime = strategyDetail.activeTime
      ? moment(strategyDetail.activeTime.replace(/\.\d+/, "").replace(/-/g,"/")).tz("America/New_York").format('YYYY-MM-DD')
      : moment(strategyDetail.createTime.replace(/\.\d+/, "").replace(/-/g,"/")).tz("America/New_York").format('YYYY-MM-DD');

  const trackingTime = `${activeTime}~${tradeDate}`;

  // const daysDiff = moment(tradeDate).diff(activeTime, 'days');
  // if (daysDiff > 5)
  //   setLongerThan5days(true);
  // console.info('diff %o', daysDiff);

  const [value, setValue] = React.useState(0);
  const strategyDetailsPathName = '/admin/aqmStrategyDetails';

  const dollarFormat = (value) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value);

  const handleOnClickSubscribe = () => {
    setOpen(true);
  };

  const handleSubscribeClose = () => {
    setOpen(false);
  };

  const handleSubscribeAqm = (values) => {
    let trackAccountId = 0;
    trackAccount.forEach((item) => {
      if (item.key === values.tradingAccount) {
        trackAccountId = item.id;
      }
    });

    const params: AqmStrategy = {
      trackAccountId,
      aqmId: strategyDetail.id,
      name: values.portfolioName,
      note: values.portfolioDesc,
    };

    subscribeAQMStrategy(params)
      .then((response) => {
        // console.debug('Strategy.view: subscribeAQMStrategy response = %o', response);
        const data = response && response?.data ? response.data : null;
        // console.log('subscribeAQMStrategy data = %o', data.data);
        if (data.code === 200) {
          // console.log('subscribeAQMStrategy 200 data = %o', data.data);
          setRedirectMyPortfolio(true);
          setOpen(false);
        } else {
          setOpenAlertMsg({
            open: true,
            message: 'Error: ' + data.code,
            severity: 'error',
          });
        }
      })
      .catch((error) => {
        console.debug(error);
        if (error?.data?.errMsg.includes('DuplicateKeyException')) {
          setOpenAlertMsg({
            alert: true,
            alertContent: 'AQM Name exists',
            severity: 'error',
          });
        } else {
          setOpenAlertMsg({ alert: true, alertContent: error?.data?.errMsg, severity: 'error' });
        }
      });
  };

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  //console.log('111 aqmStatus = %s strategyDetail = %o', aqmStatus, strategyDetail);
  return (
    <div>
      <div>
        <div style={{ float: 'left', width: '80%', marginTop: 3 }}>
          <h6 style={{ margin: '0' }}>
            <span style={{ fontSize: '1.1em' }}>
              {strategyDetail.name} {`(ID: ${strategyDetail.id})`}
            </span>
          </h6>
        </div>
        <div className={classes.aqmIconPanel}>
          <Tooltip title="Subscribe AQM Model Account">
            <IconButton
              className={classes.iconPadding}
              onClick={handleOnClickSubscribe}
              aria-label="subscribe"
              size="small"
            >
              <AddIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="AQM Model Account Details">
            <IconButton aria-label="strategyDetail">
              <Link
                to={{
                  pathname: strategyDetailsPathName,
                  state: {
                    strategyId: strategyDetail.id,
                    strategyName: strategyDetail.name,
                    strategyData: strategyDetail,
                  },
                }}
              >
                <ViewDetailIcon color="primary" />
              </Link>
            </IconButton>
          </Tooltip>
          {/*<Tooltip title="Positions">*/}
          {/*  <IconButton aria-label="strategyDetail" size="small" onClick={() => onClickOpen(strategyDetail.id, strategyDetail.name)}>*/}
          {/*    <PersonalVideo color="primary" fontSize="small" />*/}
          {/*  </IconButton>*/}
          {/*</Tooltip>*/}
        </div>
      </div>
      <table width="100%">
        <tbody>
          <tr>
            <td colSpan="5">
              <div className={classes.strategyHr}></div>{' '}
            </td>
          </tr>
          <tr>
            <td width="19%">
              <span>Developer Name</span>
            </td>
            <td width="22%">
              <span>Open for New Investors </span>
            </td>
            <td width="20%">
              <span>Allow Paper Trading </span>
            </td>
            <td width="20%">
              <span>Account Type </span>
            </td>
            <td width="19%">
              <span>Account Value </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className={classes.titleValue}>{strategyDetail.developerName}</span>
            </td>
            <td>
              <span className={classes.titleValue}>
                {strategyDetail.openForInvestor ? 'Yes' : 'No'}
              </span>
            </td>
            <td>
              <span className={classes.titleValue}>
                {strategyDetail.allowPaperTrading ? 'Yes' : 'No'}
              </span>
            </td>
            <td>
              <span className={classes.titleValue}>{(strategyDetail.brokerAccountName.indexOf('DU') === 0) || (strategyDetail.brokerAccountName.indexOf('P') === 0)?'Paper Account':'Money Account'}</span>
            </td>
            <td>
              <span className={classes.titleValue}>{dollarFormat(strategyDetail.lastPerfLiquidation)}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div className={classes.strategyHr}></div>
      <div>
        <TabPanel value={value} index={0}>
          <div>
            <span>Period: </span>
            <span className={classes.titleValue}>{trackingTime}</span>
          </div>
          <div>
            <span
              className={classNames(classes.chartStrategyLegned, classes.chartStrategySpan)}
            ></span>
            <span>Total Return: </span>
            <span className={classes.titleValue}>
              {strategyDetail?.cumReturn === 0
                ? '0%'
                : strategyDetail?.cumReturn
                ? (strategyDetail.cumReturn * 100).toFixed(2) + '%'
                : 'N/A'}
            </span>
            <span
              className={classNames(
                classes.chartStrategyLegned,
                classes.chartBenchmarkSpan,
                classes.marginLeft60
              )}
            ></span>
            <span>S&P 500 Return: </span>
            <span className={classes.titleValue}>
              {strategyDetail?.benchmarkCumReturn === 0
                ? '0%'
                : strategyDetail?.benchmarkCumReturn
                ? (strategyDetail.benchmarkCumReturn * 100).toFixed(2) + '%'
                : 'N/A'}
            </span>
          </div>
          <table width="100%">
            <tbody>
              <tr>
                <td colSpan="4">
                  {' '}
                  <img
                    src={trackingInfoImage}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noperf;
                    }}
                    alt="logo"
                    height="250"
                    className={classes.img}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table table-hover table-condensed table-striped table-bordered">
            <tr className={classes.tableTrOdd}>
              <td width="30%"></td>
              <td width="35%">AQM Model Account</td>
              <td>S&P 500</td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>CAGR</td>
              <td>
                {strategyDetail?.algoCAGR === 0
                  ? '0%'
                  : longerThan5days && strategyDetail?.algoCAGR
                  ? (strategyDetail.algoCAGR * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.benchmarkCAGR === 0
                  ? '0%'
                  : strategyDetail?.benchmarkCAGR
                  ? (strategyDetail.benchmarkCAGR * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrOdd}>
              <td>Alpha</td>
              <td>
                {strategyDetail?.alpha === 0
                  ? '0'
                  : longerThan5days && strategyDetail?.alpha
                  ? strategyDetail?.alpha.toFixed(2)
                  : 'N/A'}
              </td>
              <td>0</td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>Beta</td>
              <td>
                {strategyDetail?.beta === 0
                  ? '0'
                  : longerThan5days && strategyDetail?.beta
                  ? strategyDetail?.beta.toFixed(2)
                  : 'N/A'}
              </td>
              <td>1</td>
            </tr>
            <tr className={classes.tableTrOdd}>
              <td>Sharpe</td>
              <td>
                {strategyDetail?.sharpe === 0
                  ? '0'
                  : longerThan5days && strategyDetail?.sharpe
                  ? strategyDetail?.sharpe.toFixed(2)
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.benchmarkSharpe === 0
                  ? '0'
                  : strategyDetail?.benchmarkSharpe
                  ? strategyDetail?.benchmarkSharpe.toFixed(2)
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>Sortino</td>
              <td>
                {strategyDetail?.sortino === 0
                  ? '0'
                  : longerThan5days && strategyDetail?.sortino
                  ? strategyDetail?.sortino.toFixed(2)
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.benchmarkSortino === 0
                  ? '0'
                  : strategyDetail?.benchmarkSortino
                  ? strategyDetail?.benchmarkSortino.toFixed(2)
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrOdd}>
              <td>Volatility</td>
              <td>
                {strategyDetail?.algoVolatility === 0
                  ? '0%'
                  : longerThan5days && strategyDetail?.algoVolatility
                  ? (strategyDetail.algoVolatility * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.benchmarkVolatility === 0
                  ? '0%'
                  : strategyDetail?.benchmarkVolatility
                  ? (strategyDetail.benchmarkVolatility * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
            </tr>
            <tr className={classes.tableTrEven}>
              <td>MaxDD</td>
              <td>
                {strategyDetail?.trackingReport?.maxDrawdown === 0
                  ? '0%'
                  : longerThan5days && strategyDetail?.maxDrawdown
                  ? (strategyDetail.maxDrawdown * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
              <td>
                {strategyDetail?.benchmarkMaxDrawdown === 0
                  ? '0%'
                  : strategyDetail?.benchmarkMaxDrawdown
                  ? (strategyDetail?.benchmarkMaxDrawdown * 100).toFixed(2) + '%'
                  : 'N/A'}
              </td>
            </tr>
          </table>
        </TabPanel>
      </div>
      {open && (
        <SubscribePortfolioDialog
          open={open}
          strategyType={strategyType}
          onSubscribePortfolio={handleSubscribeAqm}
          onClose={handleSubscribeClose}
          trackAccount={trackAccount}
          strategy={strategyDetail}
          aria-labelledby="responsive-dialog-title"
        ></SubscribePortfolioDialog>
      )}
      {openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      {redirectMyPortfolio && <Redirect to="/admin/aqmModelAccount" />}
    </div>
  );
}

AqmStrategyView.propTypes = {
  strategyDetail: PropTypes.object,
  trackAccount: PropTypes.array,
  strategyType: PropTypes.string,
  aqmStatus: PropTypes.string,
};
