import React, { useRef } from 'react';
// @material-ui/core components

// core components
import GoPiPaGridItem from 'components/GoPipa/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardBody from 'components/Card/CardBody.js';
import Card from 'components/Card/Card.js';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AqmStrategyView from 'components/GoPipa/Strategy/AqmStrategy.view';
import MyAqmStrategyView from 'components/GoPipa/Strategy/MyAqmStrategy.view';
import {getMyAQMStrategyList, AqmStrategy, getAQMModelAccountPositions} from '../../utils/strategy.utils';
import GridItem from "../../components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";
import PositionsSnapshot from "../Strategy/PortfolioSnapshot.view";

const useStyles = makeStyles((theme) => ({
  cardPadding: {
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
  },
}));

export default function AqmCardView(props: any) {
  const [viewType, setViewType] = React.useState('cardView');
  const [showPosition, setShowPosition] = React.useState(false);
  const [positionData, setPositionsData] = React.useState([]);
  const [postionsTitle, setPositionsTitle] = React.useState(null);
  const classes = useStyles();
  const { strategyData, trackAccount, aqmStatus } = props;
  // console.log('1111 aqmStatus = %s', aqmStatus);

  const getPosition = (id) => {
    if ( id ) {
      getAQMModelAccountPositions(id)
          .then((response) => {
            const positions = [];
            if (response.data.data) {
              const data = response.data.data;
              let total = 0;
              data.forEach((item: any) => {
                if (item.positionValue !== null)
                  total += parseFloat(item.positionValue);
              });
              data.forEach((item: any) => {
                if (item.positionValue !== null) {
                  let ratio = parseFloat(item.positionValue) / total;
                  item.position = (ratio * 100).toFixed(2) + '%';
                  positions.push(item);
                }
              });
              setPositionsData(positions);
            }
          })
          .catch((error) => {
            console.debug(error);
          });
    }
  }

  const handleOpenPosition = (id, title) => {
    getPosition(id);
    setPositionsTitle(title + " (ID: " + id + ")");
    setShowPosition(true);
  };

  const handleClosePosition = () => {
    setShowPosition(false);
  };

  return (
    <div>
      {showPosition && (
          <PositionsSnapshot
              title={postionsTitle}
              onClick={handleClosePosition}
              positionData = {positionData}
          ></PositionsSnapshot>
      )}
      {viewType === 'cardView' && strategyData && strategyData.length > 0 ? (
        <GridContainer>
          {strategyData.map((value, d) => (
            <GoPiPaGridItem id="cardId" key={d} xs={12} sm={6} md={6}>
              <Card style={{ marginBottom: 'unset' }}>
                <CardBody
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    border: '1px solid transparent',
                    borderRadius: 4,
                    borderColor: '#7a8cf7',
                  }}
                >
                  {aqmStatus === 'modelAccount' ? (
                    <AqmStrategyView
                      aqmStatus={aqmStatus}
                      strategyType="AQM"
                      strategyDetail={value}
                      trackAccount={trackAccount}
                      onClickOpen={handleOpenPosition}
                    />
                  ) : (
                    <MyAqmStrategyView
                      aqmStatus={aqmStatus}
                      strategyType="AQM"
                      strategyDetail={value}
                      trackAccount={trackAccount}
                    />
                  )}
                </CardBody>
              </Card>
            </GoPiPaGridItem>
          ))}
        </GridContainer>
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
            <Typography>No {aqmStatus} AQM Account</Typography>
          </GridItem>
        </GridContainer>
      )}
      {viewType === 'subscribeView' && <div>Subscribe a portfolio</div>}
    </div>
  );
}
