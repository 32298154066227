import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { environment } from '../../environments/environment';
import { getToken, moneyNumberHandle } from '../../utils/common';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../components/CustomButtons/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardBody from '../../components/Card/CardBody.js';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewListIcon from '@material-ui/icons/ViewList';
import PortfolioMonitorPositionsView from './PortfolioMonitorPositions.view';
import PortfolioMonitorOrdersView from './PortfolioMonitorOrders.view';
import PortfolioMonitorLivePerformanceView from './PortfolioMonitorLivePerformance.view';
import PortfolioMonitorLiveExposureView from './PortfolioMonitorLiveExposure.view';
import PortfolioMonitorLiveSignalsView from './PortfolioMonitorLiveSignals.view';
import PortfolioActivityTableView from '../../views/Portfolio/PortfolioActivityTable.view';
import PortfolioMonitorStatsView from '../../views/Portfolio/PortfolioMonitorStats.view';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import StopPortfolioDialogView from '../../components/Dialog/StopPortfolioDialog.view';
import ReactHtmlParser from "html-react-parser";
import moment from "moment";

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '12px',
    height: '45px',
  },
  marketInfoPanel: {
    height: '65px',
    position: 'relative',
  },
  marketInfo: {
    paddingTop: '4px',
    borderRight: '1px solid #e0dede',
    marginTop: '6px',
    textAlign: 'center',
    display: 'inline-block',
    minWidth: '125px',
  },
  marketVal: {
    display: 'inline-block',
    fontSize: '18px',
    position: 'relative',
    top: '1px',
    fontWeight: 'bold',
  },
  marketLabel: {
    color: '#808080',
    fontSize: '12px',
    marginTop: '3px',
  },
  cardCategoryTitle: {
    fontWeight: 400,
    color: '#000',
    fontSize: 22,
    padding: 12,
  },
  performanceChart: {
    border: '1px solid black',
    height: 350,
    padding: 12,
  },
  positiveColor: {
    color: 'green',
  },
  negativeColor: {
    color: 'red',
  },

  waitingMsg: {
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 70,
    marginLeft: -80,
  },
  marketStatusOpen: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'green',
  },
  marketStatusClose: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'rgb(143, 63, 63)',
  },
  cardMargin: {
    marginTop: 20,
  },
  portfolioNameFont: {
    fontWeight: 400,
  },
  portfolioUpdateTime: {
    fontWeight: 400,
    marginLeft: 10,
  },
  chartTitle: {
    fontWeight: 400,
    fontSize: 20,
    marginTop: 10,
    marginLeft: 10,
  },
  noLiveSignals: {
    height: 160,
    fontWeight: 400,
    fontSize: 14,
  },
  noLiveSignalsMsg: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
  },
  circularProgressTop: {
    marginTop: 20,
  },
  circularProgressDiv: {
    border: '1px solid 1px solid #d3d3d3',
    display: 'flex',
    justifyContent: 'center',
    height: 100,
  },
};

const useStyles = makeStyles(styles);

export default function PortfolioMonitorView() {
  const classes = useStyles();
  const [openStopPortfolioDlg, setOpenStopPortfolioDlg] = React.useState(false);
  const [progress, setProgress] = React.useState(true);
  const { state } = useLocation();
  const [seriesData, setSeriesData] = React.useState({
    series0Data: [],
    series1Data: [],
  });
  const [exposureSeriesData, setExposureSeriesData] = React.useState({
    series0Data: [],
    series1Data: [],
    series2Data: [],
  });
  const [signalsSeriesData, setSignalsSeriesData] = React.useState({
    series: [],
  });

  const [statsSeriesData, setStatsSeriesData] = React.useState({
    alphaSeriesData: [],
    sortinoSeriesData: [],
    betaSeriesData: [],
    volatilitySeriesData: [],
    sharpeSeriesData: [],
  });

  const [portfolioOrders, setPortfolioOrders] = React.useState([]);
  const [portfolioPositions, setPortfolioPositions] = React.useState([]);
  const [receivedPositionMsg, setReceivedPositionMsg] = React.useState(false);
  const [portfolioStats, setPortfolioStats] = React.useState({
    returns: '--',
    dollarpnl: '--',
    dailyReturn: '--',
    dailyPnl: '--',
    long: '--',
    short: '--',
    cash: '--',
    margin: '--',
    portfolioValue: '--',
    varisk: '--',
  });

  const [dailyReturn, setDailyReturn] = React.useState({});
  const [sharpeStats, setSharpeStats] = React.useState('--');
  const [marketStatus, setMarketStatus] = React.useState('Market Closed');
  const [updateTime, setUpdateTime] = React.useState(null);
  const portfolioId = state?.portfolioId ?? '';
  const portfolioName = state?.portfolioName ?? '';
  const marketType = state?.portfolioMarketType ?? 'US';
  const tradingTime = state?.period?? '--';
  const title = `-> Portfolio Monitor `;
  const today = moment().tz("America/New_York").format('YYYY-MM-DD');

  let haveSignals: boolean = false;
  let opened = false;
  let orders: any[] = [];
  let receivedPerfMsg: boolean = false;
  let statsShowMargin: boolean = true;
  let receivedPortfolioMsg: boolean = false;
  let receivedOrderMsg: boolean = false;
  let receivedRecordMsg: boolean = false;
  
  // console.info('State:  %o',state);
  function updateData() {
    const ENDPOINT = `${environment.websocket_url}?portfolioId=${portfolioId}&token=${getToken()}`;
    const client = new W3CWebSocket(ENDPOINT);
    window.addEventListener('popstate', (event) => {
      window.history.replaceState(null, null, window.location.pathname);
    });

    client.onopen = () => {
      // console.info('WebSocket Client Connected');
      opened = true;
      const timer = setTimeout(() => {
        setProgress(false);
        client.close();
      }, 3000);
      return;
    };

    client.onmessage = (e: any) => {
      if (!client || client.readyState >= 2) {
        return;
      }
      if (e.data) {
        const msg = JSON.parse(e.data);
        // console.log('SUNNY111 WebSocket Client message data type = %s', msg.type);
        if (msg.type === 'marketStatus') {
          if (msg.data === 'close') {
            setMarketStatus('Market Closed');
          } else if (msg.data === 'open') {
            setMarketStatus('Market Opened');
          }
        } else if (msg.type === 'portfolio') {
          const returns = msg.returns
              ? (msg.returns * 100).toFixed(2) + '%'
              : msg.returns === 0
                  ? '0%'
                  : 'N/A';
          const dailyReturn = msg.dailyReturn
              ? (msg.dailyReturn * 100).toFixed(2) + '%'
              : msg.dailyReturn === 0
                  ? '0%'
                  : 'N/A';
          const dollarpnl = msg.pnl
              ? moneyNumberHandle(parseFloat(msg.pnl.toFixed(2)), marketType)
              : msg.pnl === 0
                  ? 0
                  : 'N/A';
          const dailyPnl = msg.dailyPnl
              ? moneyNumberHandle(parseFloat(msg.dailyPnl.toFixed(2)), marketType)
              : msg.dailyPnl === 0
                  ? 0
                  : 'N/A';
          const long = msg.longValue
              ? moneyNumberHandle(parseFloat(msg.longValue.toFixed(2)), marketType)
              : msg.longValue === 0
                  ? 0
                  : 'N/A';
          const short = msg.shortValue
              ? moneyNumberHandle(parseFloat(msg.shortValue.toFixed(2)), marketType)
              : msg.shortValue === 0
                  ? 0
                  : 'N/A';
          const varisk = msg.varisk
              ? (msg.varisk * 100).toFixed(2) + '%'
              : msg.varisk === 0
                  ? '0%'
                  : 'N/A';
          let cash = '--';
          let margin = '--';
          if (msg.cash) {
            if (msg.cash < 0) {
              cash = moneyNumberHandle(0, marketType);
              margin = moneyNumberHandle(parseFloat(msg.cash.toFixed(2)), marketType);
              statsShowMargin = true;
            } else {
              cash = moneyNumberHandle(parseFloat(msg.cash.toFixed(2)), marketType);
              statsShowMargin = false;
            }
          } else {
            cash = 'N/A';
            statsShowMargin = false;
          }
          const portfolioValue = msg.portfolioValue
              ? moneyNumberHandle(parseFloat(msg.portfolioValue.toFixed(2)), marketType)
              : 'N/A';
          receivedPortfolioMsg = true;
          setPortfolioStats({
            returns,
            dollarpnl,
            dailyReturn,
            dailyPnl,
            long,
            short,
            cash,
            margin,
            portfolioValue,
            varisk,
          });
        } else if (msg.type === 'position') {
          setReceivedPositionMsg(true);
          const positions = [];
          msg.data.forEach((item) => {
            console.info('item: %o', item);
            const obj = {
              symbol: item.symbol,
              marketPrice: item.marketPrice? parseFloat(item.marketPrice.toFixed(2)):'',
              costBasis: item.costBasis? parseFloat(item.costBasis.toFixed(2)):'',
              position: item.position?parseFloat(item.position):'',
              positionValue: item.value? parseFloat(item.value.toFixed(2)):'',
              positionPercentage: item.positionPercentage? parseFloat((item.positionPercentage * 100).toFixed(2)):'',
              profitLoss: item.profitOrLoss? parseFloat(item.profitOrLoss.toFixed(2)): '',
            };
            positions.push(obj);
          });
          setPortfolioPositions(positions);
        } else if (msg.type === 'noop') {
          console.debug('WebSocket Client message data type noop');
          // setProgress(false);
        }
      }
    };

    client.onclose = (e: any) => {
      console.debug('client.onclose event = %o', e);
    };

    client.onerror = (e: any) => {
      console.debug('client.onerror: ' + e);
    };
  }

  useEffect(() => {
    const ENDPOINT = `${environment.websocket_url}?portfolioId=${portfolioId}&token=${getToken()}`;
    const client = new W3CWebSocket(ENDPOINT);
    window.addEventListener('popstate', (event) => {
      window.history.replaceState(null, null, window.location.pathname);
    });

    client.onopen = () => {
      // console.info('WebSocket Client Connected');
      opened = true;
      const timer = setTimeout(() => {
        // console.info('WebSocket Client open and end the progress');
        setProgress(false);
        client.close();
      }, 3000);

      // const readyState = client && client.readyState ? client.readyState : '';
      // console.log('SUNNY111 WebSocket Client Connected readyState = %s', readyState);
      // if (readyState && client.readyState <= 2) {
      //   console.log('SUNNY111 WebSocket Client Connected readyState client.readyState <= 2');
      // }
      // return () => clearTimeout(timer);
    };

    client.onmessage = (e: any) => {
      if (!client || client.readyState >= 2) {
        // console.log('SUNNY111 WebSocket Client message readyState = %s', client.readyState);
        return;
      }
      if (e.data) {
        const msg = JSON.parse(e.data);
        // console.log('SUNNY111 WebSocket Client message data type = %s', msg.type);
        if (msg.type === 'marketStatus') {
          if (msg.data === 'close') {
            // console.log('SUNNY : marketStatus : msg.data === close');
            setMarketStatus('Market Closed');
          } else if (msg.data === 'open') {
            // console.log('SUNNY : marketStatus : msg.data === open');
            setMarketStatus('Market Opened');
          }
          // console.log('SUNNY : marketStatus = %o', marketStatus);
        } else if (msg.type === 'portfolio') {
          // console.debug('portfolio msg.type = %o', msg);
          const returns = msg.returns
            ? (msg.returns * 100).toFixed(2) + '%'
            : msg.returns === 0
            ? '0%'
            : 'N/A';
          const dailyReturn = msg.dailyReturn
            ? (msg.dailyReturn * 100).toFixed(2) + '%'
            : msg.dailyReturn === 0
            ? '0%'
            : 'N/A';
          const dollarpnl = msg.pnl
            ? moneyNumberHandle(parseFloat(msg.pnl.toFixed(2)), marketType)
            : msg.pnl === 0
            ? 0
            : 'N/A';
          const dailyPnl = msg.dailyPnl
            ? moneyNumberHandle(parseFloat(msg.dailyPnl.toFixed(2)), marketType)
            : msg.dailyPnl === 0
            ? 0
            : 'N/A';
          const long = msg.longValue
            ? moneyNumberHandle(parseFloat(msg.longValue.toFixed(2)), marketType)
            : msg.longValue === 0
            ? 0
            : 'N/A';
          const short = msg.shortValue
            ? moneyNumberHandle(parseFloat(msg.shortValue.toFixed(2)), marketType)
            : msg.shortValue === 0
            ? 0
            : 'N/A';
          const varisk = msg.varisk
              ? (msg.varisk * 100).toFixed(2) + '%'
              : msg.varisk === 0
                  ? '0%'
                  : 'N/A';
          let cash = '--';
          let margin = '--';
          if (msg.cash) {
            if (msg.cash < 0) {
              cash = moneyNumberHandle(0, marketType);
              margin = moneyNumberHandle(parseFloat(msg.cash.toFixed(2)), marketType);
              statsShowMargin = true;
            } else {
              cash = moneyNumberHandle(parseFloat(msg.cash.toFixed(2)), marketType);
              statsShowMargin = false;
            }
          } else {
            cash = 'N/A';
            statsShowMargin = false;
          }
          const portfolioValue = msg.portfolioValue
            ? moneyNumberHandle(parseFloat(msg.portfolioValue.toFixed(2)), marketType)
            : 'N/A';
          receivedPortfolioMsg = true;
          setPortfolioStats({
            returns,
            dollarpnl,
            dailyReturn,
            dailyPnl,
            long,
            short,
            cash,
            margin,
            portfolioValue,
            varisk,
          });
        } else if (msg.type === 'perf') {
          receivedPerfMsg = true;
          let time = Date.UTC(
            parseInt(msg.data.tradeDate.substring(0, 4)),
            parseInt(msg.data.tradeDate.substring(4, 6)) - 1,
            parseInt(msg.data.tradeDate.substring(6))
          );
          // console.debug('SUNNY : perf msg.data.sharpe = %o', msg.data.sharpe);
          const sharpe = msg.data.sharpe
            ? msg.data.sharpe.toFixed(2)
            : msg.data.sharpe === 0
            ? 0
            : 'N/A';
          setSharpeStats(sharpe);
          const series0Data = seriesData.series0Data;
          series0Data.push([
            time,
            msg.data.cumReturn ? parseFloat((msg.data.cumReturn * 100).toFixed(2)) : 0,
          ]);

          const series1Data = seriesData.series1Data;
          series1Data.push([
            time,
            msg.data.benchmarkCumReturn
              ? parseFloat((msg.data.benchmarkCumReturn * 100).toFixed(2))
              : 0,
          ]);

          // const dailyReturn = msg.data?.dailyReturn
          //   ? (msg.data.dailyReturn * 100).toFixed(2) + '%'
          //   : 'N/A';
          // const dailyPnl = msg.data.dailyPnl
          //   ? moneyNumberHandle(parseFloat(msg.data.dailyPnl.toFixed(2)), marketType)
          //   : 'N/A';
          // setDailyReturn({ dailyReturn, dailyPnl });
          setSeriesData({ series0Data, series1Data });

          // exposure
          const exposureSeries0Data = exposureSeriesData.series0Data;
          exposureSeries0Data.push([
            time,
            msg.data.longValue ? parseFloat(msg.data.longValue.toFixed(2)) : 0,
          ]);

          const exposureSeries1Data = exposureSeriesData.series1Data;
          exposureSeries1Data.push([
            time,
            msg.data.shortValue ? parseFloat(msg.data.shortValue.toFixed(2)) : 0,
          ]);

          const exposureSeries2Data = exposureSeriesData.series2Data;
          exposureSeries2Data.push([
            time,
            msg.data.endingCash ? parseFloat(msg.data.endingCash.toFixed(2)) : 0,
          ]);

          setExposureSeriesData({ exposureSeries0Data, exposureSeries1Data, exposureSeries2Data });

          // stats series data
          const alphaSeriesData = statsSeriesData.alphaSeriesData;
          alphaSeriesData.push([time, msg.data.alpha ? parseFloat(msg.data.alpha.toFixed(2)) : 0]);
          const betaSeriesData = statsSeriesData.betaSeriesData;
          betaSeriesData.push([time, msg.data.beta ? parseFloat(msg.data.beta.toFixed(2)) : 0]);
          const sharpeSeriesData = statsSeriesData.sharpeSeriesData;
          sharpeSeriesData.push([
            time,
            msg.data.sharpe ? parseFloat(msg.data.sharpe.toFixed(2)) : 0,
          ]);
          const sortinoSeriesData = statsSeriesData.sortinoSeriesData;
          sortinoSeriesData.push([
            time,
            msg.data.sortino ? parseFloat(msg.data.sharpe.toFixed(2)) : 0,
          ]);
          const volatilitySeriesData = statsSeriesData.volatilitySeriesData;
          volatilitySeriesData.push([
            time,
            msg.data.algoVolatility ? parseFloat((msg.data.algoVolatility * 100).toFixed(2)) : 0,
          ]);
          setStatsSeriesData({
            alphaSeriesData,
            betaSeriesData,
            sharpeSeriesData,
            sortinoSeriesData,
            volatilitySeriesData,
          });
        } else if (msg.type === 'record') {
          // console.log('SUNNY record: msg.data = %o', msg.data);
          haveSignals = true;
          receivedRecordMsg = true;
          let time = Date.UTC(
            parseInt(msg.date.substring(0, 4)),
            parseInt(msg.date.substring(4, 6)) - 1,
            parseInt(msg.date.substring(6))
          );
          const chartSeries = [];
          for (let k = 0; k < msg.data.length; k++) {
            let serial = null;
            for (let j = 0; j < chartSeries.length; j++) {
              if (msg.data[k].name === chartSeries[j].name) {
                serial = chartSeries[j];
                break;
              }
            }

            if (serial) {
              chartSeries.push([time, parseFloat(msg.data[k].value)]);
            } else {
              chartSeries.push({
                name: msg.data[k].name,
                data: [[time, parseFloat(msg.data[k].value)]],
              });
            }
            setSignalsSeriesData({ series: chartSeries });
          }
        } else if (msg.type === 'order') {
          // console.log('SUNNY order: msg.data = %o', msg.data);
          receivedOrderMsg = true;
          orders.unshift(msg.data); // 最新的order放到最前面
          setPortfolioOrders(orders);
        } else if (msg.type === 'position') {
          // console.log('SUNNY444 : msg.type === position');
          setReceivedPositionMsg(true);
          // positions.splice(0, positions.length); // position都是直接替换
          // console.log('SUNNY444 :msg.data.length = %s', msg.data.length);
          const positions = [];
          msg.data.forEach((item) => {
            const obj = {
              symbol: item.symbol,
              marketPrice: item.marketPrice? parseFloat(item.marketPrice.toFixed(2)):'',
              costBasis: item.costBasis? parseFloat(item.costBasis.toFixed(2)):'',
              position: item.position?parseFloat(item.position):'',
              positionValue: item.value? parseFloat(item.value.toFixed(2)):'',
              positionPercentage: item.positionPercentage? parseFloat((item.positionPercentage * 100).toFixed(2)):'',
              profitLoss: item.profitOrLoss? parseFloat(item.profitOrLoss.toFixed(2)): '',
            };
            positions.push(obj);
          });
          setPortfolioPositions(positions);
        } else if (msg.type === 'noop') {
          console.debug('WebSocket Client message data type noop');
          // setProgress(false);
        }
      }
    };

    client.onclose = (e: any) => {
      console.debug('client.onclose event = %o', e);
    };

    client.onerror = (e: any) => {
      console.debug('client.onerror: ' + e);
    };
  }, []);

  const handleStopDialogClose = () => {
    setOpenStopPortfolioDlg(false);
  };

  const handleOpenStopPortfolioDlg = () => {
    console.debug('##### : handleOnClickStop');
    setOpenStopPortfolioDlg(true);
  };

  const handleUpdate = () => {
    updateData();
  };

  return (
    <div className="mainPagePanel">
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ marginTop: 10 }}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{ float: 'left' }}>
                      <h4>
                        <Link to="/admin/portfolio">My Portfolio</Link>
                        {title}
                        <span className={classes.portfolioNameFont}>{`(${portfolioName})`}</span>
                        <span className={classes.portfolioUpdateTime}>
                            <small>
                                Period: {tradingTime}~{today} (based on current snapshot data)
                            </small>
                        </span>
                      </h4>
                    </div>
                    <div style={{ float: 'right', marginTop: -10 }}>
                      {marketStatus === 'Market Opened' ? (
                        <span className={classes.marketStatusOpen}>{marketStatus} </span>
                      ) : (
                        <span className={classes.marketStatusClose}>{marketStatus} </span>
                      )}
                      <Button
                        onClick={handleOpenStopPortfolioDlg}
                        color="info"
                        className={classes.label}
                      >
                        Stop
                      </Button>
                      <Button
                          onClick={handleUpdate}
                          color="info"
                          className={classes.label}
                      >
                        {ReactHtmlParser("Refresh Based On<br> Realtime Data")}
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {/*{receivedPortfolioMsg && (*/}
                    <div className={classes.marketInfoPanel}>
                      <div className={classes.marketInfo} style={{ marginLeft: -40 }}>
                        <div className={classes.marketVal}>{portfolioStats.returns}</div>
                        <div className={classes.marketLabel}>Total Return</div>
                      </div>
                      <div className={classes.marketInfo}>
                        <div className={classes.marketVal}>{portfolioStats.dollarpnl}</div>
                        <div className={classes.marketLabel}>Profit/Loss</div>
                      </div>
                      <div className={classes.marketInfo}>
                        <div className={classes.marketVal}>{portfolioStats.dailyReturn}</div>
                        <div className={classes.marketLabel}>Daily Return</div>
                      </div>
                      <div className={classes.marketInfo}>
                        <div className={classes.marketVal}>{portfolioStats.dailyPnl}</div>
                        <div className={classes.marketLabel}>Daily Profit/Loss</div>
                      </div>
                      <div className={classes.marketInfo}>
                        <div className={classes.marketVal}>{sharpeStats}</div>
                        <div className={classes.marketLabel}>Sharpe</div>
                      </div>
                      <div className={classes.marketInfo}>
                        <div className={classes.marketVal}>{portfolioStats.long}</div>
                        <div className={classes.marketLabel}>Long Exposure</div>
                      </div>
                      <div className={classes.marketInfo}>
                        <div className={classes.marketVal}>{portfolioStats.short}</div>
                        <div className={classes.marketLabel}>Short Exposure</div>
                      </div>
                      <div className={classes.marketInfo}>
                        <div className={classes.marketVal}>{portfolioStats.cash}</div>
                        <div className={classes.marketLabel}>Available Cash</div>
                      </div>
                      {statsShowMargin && (
                        <div className={classes.marketInfo}>
                          <div className={classes.marketVal}>{portfolioStats.margin}</div>
                          <div className={classes.marketLabel}>Margin</div>
                        </div>
                      )}
                      <div className={classes.marketInfo}>
                        <div className={classes.marketVal}>{portfolioStats.portfolioValue}</div>
                        <div className={classes.marketLabel}>Portfolio Value</div>
                      </div>
                      <div className={classes.marketInfo}>
                        <div className={classes.marketVal}>{portfolioStats.varisk}</div>
                        <div className={classes.marketLabel}>VaR</div>
                      </div>
                    </div>
                    {/*)}*/}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            {openStopPortfolioDlg && (
              <StopPortfolioDialogView
                openStopPortfolioDlg={openStopPortfolioDlg}
                handleStopDialogClose={handleStopDialogClose}
                strategyId={portfolioId}
                strategyName={portfolioName}
              />
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: -20 }}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: 'Dashboard',
                  tabIcon: ViewModuleIcon,
                  tabContent: (
                    <div>
                      {progress ? (
                        <div className={classes.circularProgressDiv}>
                          <CircularProgress className={classes.circularProgressTop} />
                          <div className={classes.waitingMsg}>Please wait...</div>
                        </div>
                      ) : (
                        <div>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  <PortfolioMonitorLivePerformanceView
                                    classes={classes}
                                    seriesData={seriesData}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <PortfolioMonitorLiveExposureView
                                    classes={classes}
                                    seriesData={exposureSeriesData}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <PortfolioMonitorLiveSignalsView
                                    classes={classes}
                                    seriesData={signalsSeriesData}
                                  />
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  {receivedPositionMsg && (
                                    <PortfolioMonitorPositionsView
                                      positionData={portfolioPositions}
                                    />
                                  )}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <PortfolioMonitorOrdersView orderData={portfolioOrders} />
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          </GridContainer>
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  tabName: 'Stats',
                  tabIcon: ViewListIcon,
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <PortfolioMonitorStatsView classes={classes} seriesData={statsSeriesData} />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'Activities',
                  tabIcon: ViewListIcon,
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <PortfolioActivityTableView portfolioId={portfolioId} />
                      </GridItem>
                    </GridContainer>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
