import React from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import { environment } from '../../environments/environment';
import { getTextColor, jsonTransferToArray } from '../../utils/common';
import { defaultFont, grayColor } from '../../assets/jss/material-dashboard-react';
import TextField from '@material-ui/core/TextField';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationDialog from './ConfirmationDialog.view';
import { stringUtils } from '../../utils/string.utils';

const styles = {
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + ' !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
  },
  selectInput: {
    width: '100%',
    marginTop: 25,
  },
  dialogActionCenter: {
    margin: 'auto',
  },
};
const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: 'none' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function SubscribePortfolioDialog(props) {
  const theme = useTheme();
  const classes = useStyles();
  const userRiskLevel = localStorage.getItem(environment.userRiskLevelKey);
  const [redirectToUserAssessment, setredirectToUserAssessment] = React.useState(
    userRiskLevel ? false : true
  );
  const { strategy, trackAccount, portfolioData, strategyType } = props;
  const [openSESDlg, setOpenSESDlg] = React.useState(false);

  let trackAccountInfo = trackAccount && trackAccount.length > 0 ? trackAccount[0].key : '';
  let tradeTypeInfo = 'SIMU_';

  if (portfolioData) {
    trackAccount.forEach((item) => {
      console.debug(
        'Strategy view: item = %o trackingAccountId = %s',
        item,
        portfolioData.trackingAccountId
      );
      if (item.id === portfolioData.trackingAccountId) {
        trackAccountInfo = item.key;
      }
    });
    tradeTypeInfo = portfolioData.tradeType.indexOf('SIMU_') === 0 ? 'SIMU_' : 'MONEY_';
  }

  const [values, setValues] = React.useState({
    portfolioName: portfolioData ? portfolioData.name : undefined,
    investmentAmount: portfolioData ? portfolioData.portfolioValue : 10000,
    tradeType: tradeTypeInfo,
    tradingAccount: trackAccountInfo,
    portfolioDesc: portfolioData ? portfolioData.note : '',
    emergencyStop: portfolioData
      ? strategy.profile.liquidateOnEmergency
        ? 'stopNotSell'
        : 'stopSell'
      : 'stopSell',
  });
  const [errMsg, setErrMsg] = React.useState(false);
  const [errorEmergencyStop, setErrorEmergencyStop] = React.useState(false);
  const portfolio = environment.selectDataItems['portfolio'];
  const tradeType = jsonTransferToArray(portfolio.tradeType);
  // const tradeType = strategyType === 'AQS'? [{"key": "SIMU_", "value": "Paper Trade "}]: jsonTransferToArray(portfolio.tradeType);

  const handleEmergencyStopChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
    if (event.target.value === 'stopSell') {
      setErrorEmergencyStop(false);
    } else {
      setOpenSESDlg(true);
    }
  };

  const handleChange = (name) => (event) => {
    setErrMsg(false);
    setValues({ ...values, [name]: event.target.value });
  };

  const onClickSubscribePortfolio = () => {
    props.onSubscribePortfolio(values);
  };

  const handleStopSale = (value) => {
    if (value) {
      console.debug('##### value = %s', value);
    } else {
      setValues({ ...values, emergencyStop: 'stopSell' });
    }
    setOpenSESDlg(false);
  };

  const fullScreen = true; //useMediaQuery(theme.breakpoints.down('md'));
  const errorPortfolioName = values.portfolioName === '';
  const errorInvestmentAmount =
    values.investmentAmount < strategy.minInvestmentAmount ||
    values.investmentAmount > strategy.maxInvestmentAmount;

  const dlgTitle = strategyType === 'AQS' ? 'Subscribe AQS Strategy' : 'Subscribe AQM Account';

  const getDisableState = () => {
    if (strategyType === 'AQS') {
      return !values.portfolioName || errorInvestmentAmount || trackAccount.length === 0;
    } else {
      return !values.portfolioName || trackAccount.length === 0;
    }
  };

  return (
    <div>
      {redirectToUserAssessment && <Redirect to="/admin/useraccount?userAssessment=no" />}
      <ConfirmationDialog
        dlgData={{ result: 'Yes' }}
        handleDeleteDialogClick={handleStopSale}
        open={openSESDlg}
        dlgTitle={stringUtils.subPortfolioActionOnTitle}
        dlgContentText={stringUtils.subPortfolioActionOn}
      />

      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
        style={{ padding: 80 }}
      >
        <DialogTitle style={{ maxWidth: 1050 }} id="responsive-dialog-title">
          {dlgTitle}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <div className={getTextColor(strategy.name)}>
              {strategy.name} (ID: {strategy.id} )
            </div>
          </DialogContentText>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <FormControl className={classes.selectInput}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="portfolioName"
                  label={strategyType === 'AQS' ? 'Portfolio Name' : 'Account Name'}
                  required
                  value={values.portfolioName}
                  onChange={handleChange('portfolioName')}
                  error={errorPortfolioName}
                  helperText={errorPortfolioName ? 'This field is required' : ' '}
                  fullWidth
                />
              </FormControl>
            </GridItem>
            {strategyType && strategyType !== 'AQM' && (
              <GridItem xs={6} sm={6} md={6}>
                <FormControl className={classes.selectInput}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="investmentAmount"
                    label="Investment Amount ($)"
                    required
                    type="number"
                    value={values.investmentAmount}
                    onChange={handleChange('investmentAmount')}
                    error={errorInvestmentAmount}
                    helperText={
                      errorInvestmentAmount
                        ? `Investment Amount needs to be greater than $${strategy.minInvestmentAmount} and less than $${strategy.maxInvestmentAmount}`
                        : ' '
                    }
                    fullWidth
                  />
                </FormControl>
              </GridItem>
            )}
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <FormControl className={classes.selectInput}>
                <InputLabel className={classes.labelRoot} id="tradeType">
                  Trade Type
                </InputLabel>
                <Select
                  required
                  labelId="trade-type-select-label"
                  id="trade-type-select"
                  value={values.tradeType}
                  onChange={handleChange('tradeType')}
                >
                  {tradeType.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <FormControl className={classes.selectInput}>
                <InputLabel className={classes.labelRoot} id="tradingAccount">
                  Trading Account
                </InputLabel>
                <Select
                  labelId="trading-account-select-label"
                  id="trading-account-select"
                  value={values.tradingAccount}
                  onChange={handleChange('tradingAccount')}
                >
                  {trackAccount.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl className={classes.selectInput}>
                <TextField
                  margin="dense"
                  id="portfolioDesc"
                  label={strategyType === 'AQS' ? 'Portfolio Description' : 'Account Description'}
                  value={values.portfolioDesc}
                  onChange={handleChange('portfolioDesc')}
                  fullWidth
                />
              </FormControl>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActionCenter}>
          <Button disabled={getDisableState()} onClick={onClickSubscribePortfolio} color="primary">
            Submit
          </Button>
          <Button autoFocus onClick={props.onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SubscribePortfolioDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubscribePortfolio: PropTypes.func,
  strategy: PropTypes.object,
  trackAccount: PropTypes.array,
  portfolioData: PropTypes.object,
};
