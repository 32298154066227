import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import classnames from 'classnames';
// core components
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import UIButton from '@material-ui/core/Button';
import { subscribeMembership } from '../../utils/billing.utils';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog.view';
import AlertView from '../../components/Alert/Alert.view';

const styles = {
  memberShipClass: {
    color: '#000000',
    opacity: '0.8',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: 14,
    height: 30,
  },
  tableTrHeader: {
    backgroundColor: '#f9f9f9',
    fontWeight: 600,
  },
  selectMessage: {
    fontWeight: 500,
  },
  infoLabel: {
    fontSize: '14px',
    fontFamily: 'Roboto", Helvetica", Arial, sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: 4,
    letterSpacing: '0.02857em',
  },
  tdTextCenter: {
    textAlign: 'center',
  },
  labelLowercase: {
    textTransform: 'capitalize',
  },
  off15Label: {
    color: 'white',
    padding: '2px',
    background: '#18b4c9',
    fontSize: 14,
  },
  off30Label: {
    color: 'white',
    padding: '3px',
    background: '#18b4c9',
    fontSize: 14,
  },
};

const useStyles = makeStyles(styles);
export default function SubscribeMembershipView(props) {
  const classes = useStyles();
  const [openConfirmDlg, setOpenConfirmDlg] = React.useState({ open: false, productId: null });
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });
  const { paymentLevel, updateMembership, membershipState, membershipInfo } = props;
  
  const createPopupWin = (pageURL, pageTitle, popupWinWidth, popupWinHeight) => {
    const left = window.screen.width;
    const top = window.screen.height;
    const settings =
      'resizable=yes, width=' +
      popupWinWidth +
      ', height=' +
      popupWinHeight +
      ', top=' +
      top +
      ', left=' +
      left;

    console.log(
      'left = %s, top = %s popupWinWidth = %s, popupWinHeight =  %s settings = %s',
      left,
      top,
      popupWinWidth,
      popupWinHeight,
      settings
    );
    const myWindow = window.open(
      pageURL,
      pageTitle,
      'resizable=yes, width=' + popupWinWidth + ', height=' + popupWinHeight + ', top=50,left=500'
      // ', left=500'
    );
    return myWindow;
  };

  const handleSubscribeMembership = (id) => {
    subscribeMembership(id)
      .then((response) => {
        // console.log('*****UserAccountInfo.view: handleSubscribeMembership response = %o', response);
        const data = response && response?.data ? response.data : null;
        // console.log('*****handleSubscribeMembership.view: data = %o', data);
        if (data.code === 200) {
          // console.log('*****SubscribeMembership.view:data.data = %s', data.data);
          if (data.data.paymentURL) {
            // window.location.href = '/admin/useraccount?changemembership=true&success=12345';
            // launch separately tab to show stripe checkout
            const pop = (url) => {
              // const popup = createPopupWin(url, 'iPIPA Payment Checkout', 600, 800);
              const popup = window.open(
                url,
                '_self'
                // 'toolbar=0,location=0,directories=0,status=1,menubar=0,titlebar=0,scrollbars=1,resizable=1,width=600,height=700'
              );
              return popup !== null && typeof popup !== 'undefined';
            };
            pop(data.data.paymentURL);
          } else {
            if (data.data?.status === 'DOWNGRADE_NOT_ALLOWED') {
              setOpenAlertMsg({
                alert: true,
                alertContent: 'Membership downgrade is not allowed',
                severity: 'error',
              });
            } else {
              updateMembership();
            }
          }
        }
        setOpenConfirmDlg({ open: false, productId: null });
      })
      .catch((error) => {
        console.log(error);
        setOpenConfirmDlg({ open: false, productId: null });
      });
  };

  const onClickSubscribeMembership = (id) => {
    // console.log('#### SubscribeMembership subscribeMembership = %s', id);
    if (paymentLevel.paymentInfo) {
      setOpenConfirmDlg({ open: true, productId: id });
    } else {
      handleSubscribeMembership(id);
    }
  };

  const handleModifyMembershipClick = (result) => {
    if (result) {
      // console.log('#### handleModifyMembershipClick = %s', openConfirmDlg.productId);
      handleSubscribeMembership(openConfirmDlg.productId);
    } else {
      setOpenConfirmDlg({ open: false, productId: null });
    }
  };

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  return (
    <div style={{ marginTop: 20 }}>
      {openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      {openConfirmDlg.open && (
        <ConfirmationDialog
          dlgData={'Yes'}
          handleDeleteDialogClick={handleModifyMembershipClick}
          open={openConfirmDlg.open}
          dlgTitle={'Subscribe Membership'}
          dlgContentText={'Are you sure you want to subscribe this membership?'}
        />
      )}
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <span className={classes.selectMessage}>
            Please select a payment plan for your membership by clicking on the price:
          </span>
        </GridItem>
        <GridItem xs={2} sm={2} md={2} style={{ float: 'right' }}>
          <Button
            onClick={props.onClose}
            color="info"
            className={classes.label}
            style={{ height: 25 }}
          >
            Close
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={8} md={8}>
          <table className="table table-condensed table-bordered table-dense">
            <thead>
              <tr className={classes.tableTrHeader}>
                <td>
                  <span>{membershipInfo.membershipLevel.name}</span>
                </td>
                {membershipInfo.membershipLevel.data.map((item, index) =>
                  item === 'Bronze' ? (
                    <td className={classes.tdTextCenter} key={index}>
                      <span>{item}</span>
                      <br></br>
                      <span>(Trial Acct)</span>
                    </td>
                  ) : (
                    <td className={classes.tdTextCenter} key={index}>
                      <span>{item} Membership</span>
                    </td>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tableTrHeader}>
                  <span>Monthly Membership Fee</span>
                </td>
                {paymentLevel.monthly &&
                  paymentLevel.monthly.map((item, index) =>
                    item.productId !== '2991' ? (
                      <td className={classes.tdTextCenter} key={index}>
                        {(item.productId !== paymentLevel.currentUser.productId || membershipState === 'Trial') ? (
                            <div style={{paddingLeft: '9px'}}>
                              <UIButton
                              id={item.productId}
                              onClick={() => onClickSubscribeMembership(item.productId)}
                              color="primary"
                              className={classes.label}
                              >
                                {item.price}
                            </UIButton>
                          </div>
                        ) : (
                          <div style={{ marginTop: -3, color: 'green', fontWeight: 700, paddingLeft: '34px'}}>
                            <span className={classes.tdTextCenter}>{item.price}</span>
                            <CheckIcon></CheckIcon>
                          </div>
                        )}
                      </td>
                    ) : (
                      <td className={classes.tdTextCenter}>
                        {item.productId !== paymentLevel.currentUser.productId ? (
                          <span className={classes.infoLabel}>{item.price}</span>
                        ) : (
                          <div style={{ marginTop: -3, color: 'green', fontWeight: 700}}>
                            <span className={classes.infoLabel}>{item.price}</span>
                            <CheckIcon></CheckIcon>
                          </div>
                        )}
                      </td>
                    )
                  )}
              </tr>
              <tr>
                <td className={classes.tableTrHeader}>
                  <span>
                    Quarterly Membership Fee <span className={classes.off15Label}>(~15% Discount)</span>
                  </span>
                </td>
                {paymentLevel.quarterly &&
                  paymentLevel.quarterly.map((item, index) =>
                    item.discount !== '--' ? (
                      <td className={classes.tdTextCenter} key={index}>
                        {item.productId !== paymentLevel.currentUser.productId ? (
                          <div>
                            <UIButton
                              id={item.productId}
                              onClick={() => onClickSubscribeMembership(item.productId)}
                              color="primary"
                              className={classes.label}
                            >
                              {item.price}
                            </UIButton>
                          </div>
                        ) : (
                          <div style={{ marginTop: -3, color: 'green', fontWeight: 700, paddingLeft: '25px'}}>
                            <span className={classes.infoLabel}>{item.price}</span>
                            <CheckIcon></CheckIcon>
                          </div>
                        )}
                      </td>
                    ) : (
                      <td className={classes.tdTextCenter}>
                        <span className={classes.infoLabel}>{item.price}</span>
                      </td>
                    )
                  )}
              </tr>
              <tr>
                <td className={classes.tableTrHeader}>
                  <span>
                    Annual Membership Fee <span className={classes.off30Label}>(~30% Discount)</span>
                  </span>
                </td>
                {paymentLevel.annual &&
                  paymentLevel.annual.map((item, index) =>
                    item.discount !== '--' ? (
                      <td className={classes.tdTextCenter} key={index}>
                        {item.productId !== paymentLevel.currentUser.productId ? (
                          <div>
                            <UIButton
                              id={item.productId}
                              onClick={() => onClickSubscribeMembership(item.productId)}
                              color="primary"
                              className={classes.label}
                            >
                              {item.price}
                            </UIButton>
                          </div>
                        ) : (
                          <div style={{ marginTop: -3, color: 'green', fontWeight: 700, paddingLeft: '25px' }}>
                            <span className={classes.infoLabel}>{item.price}</span>
                            <CheckIcon></CheckIcon>
                          </div>
                        )}
                      </td>
                    ) : (
                      <td className={classes.tdTextCenter}>
                        <span className={classes.infoLabel}>{item.price}</span>
                      </td>
                    )
                  )}
              </tr>
              {/*<tr>*/}
              {/*  <td className={classes.tableTrHeader}>*/}
              {/*    <span>{membershipInfo.premiumStrategy.name}</span>*/}
              {/*  </td>*/}
              {/*  {membershipInfo.premiumStrategy.data.map((item, index) => (*/}
              {/*    <td className={classes.tdTextCenter} key={index}>*/}
              {/*      <span className={classes.infoLabel}>{item}</span>*/}
              {/*    </td>*/}
              {/*  ))}*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td className={classes.tableTrHeader}>*/}
              {/*    <span>{membershipInfo.maxNoStrategiesMoneyTrade.name}</span>*/}
              {/*  </td>*/}
              {/*  {membershipInfo.maxNoStrategiesMoneyTrade.data.map((item, index) => (*/}
              {/*    <td className={classes.tdTextCenter} key={index}>*/}
              {/*      <span className={classes.infoLabel}>{item}</span>*/}
              {/*    </td>*/}
              {/*  ))}*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td className={classes.tableTrHeader}>*/}
              {/*    <span>{membershipInfo.maxNoStrategiesPaperTrade.name}</span>*/}
              {/*  </td>*/}
              {/*  {membershipInfo.maxNoStrategiesPaperTrade.data.map((item, index) => (*/}
              {/*    <td className={classes.tdTextCenter} key={index}>*/}
              {/*      <span className={classes.infoLabel}>{item}</span>*/}
              {/*    </td>*/}
              {/*  ))}*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td className={classes.tableTrHeader}>*/}
              {/*    <span>{membershipInfo.maxTotalMoneyPortfolio.name}</span>*/}
              {/*  </td>*/}
              {/*  {membershipInfo.maxTotalMoneyPortfolio.data.map((item, index) => (*/}
              {/*    <td className={classes.tdTextCenter} key={index}>*/}
              {/*      <span className={classes.infoLabel}>{item}</span>*/}
              {/*    </td>*/}
              {/*  ))}*/}
              {/*</tr>*/}
            </tbody>
          </table>
        </GridItem>
      </GridContainer>
    </div>
  );
}

SubscribeMembershipView.propTypes = {
  onClose: PropTypes.func,
  paymentLevel: PropTypes.object,
  updateMembership: PropTypes.func,
  membershipState: PropTypes.string,
  membershipInfo: PropTypes.object,
};
