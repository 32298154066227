import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
// @material-ui/icons
import backtestimg from 'assets/img/backtest.png';
import introimg from "../../assets/img/gopipa_logo.png";
import Button from "../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import {getStockIDA, findSymbol} from "../../utils/strategy.utils";
import ReactHtmlParser from "html-react-parser";
import CompleteAnalysisView from "./CompleteAnalysis.view.tsx"
import {explanationText} from "./explanation-text";

const styles = {
    noWarp: {
        whiteSpace: 'nowrap',
    },
    inputBox: {
        fontSize: '16px',
        fontWeight: 200,
        padding: '0px 0px',
    },
    submitBox: {
        borderRadius: '5px',
        height: '35px',
        marginLeft: '20px',
        textTransform: 'capitalize',
        fontSize: '12px',
        marginTop: '-2px',
    },
    idaBox: {
        position: 'relative',
        width: '100%',
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '5px',
        // marginTop: '20px',
        fontWeight: '200',
        fontSize: '16px',
        fontFamily: 'Arial, Helvetica',
    },
    functionBox: {
        width: '250px',
        display: 'inline-block',
        height: 'auto',
        padding: '0px 0px 10px 0px',
        right: '5px',
        // border: '1px solid red',
    },
    functionInputBox: {
        width: 'auto',
        fontSize: '16px',
        fontFamily: 'Arial, Helvetica',
    },
    chartBox: {
        fontFamily: 'Arial, Helvetica',
        height: 'auto',
        margin: '25px 0px',
        fontWeight: 600,
        border: '1px solid #000000DE',
        // maxWidth: '950px',
        overflow: 'auto',
        zIndex: 9999,
        display: 'block',
    },
    rankBox: {
        fontFamily: 'Arial, Helvetica',
        marginTop: '-20px',
        fontWeight: 600,
        height: 'auto',
        border: '1px solid #000000DE',
        overflow: 'auto',
        // disply: 'flex',
        // flexDirection: 'column-reverse',
        // zIndex: 1,
    },
    menuList: {
        marginLeft: '-15px',
        width: '210px',
        border: '1px solid #000000DE',
        marginTop: '-8px',
        marginRight: '10px',
        padding: '5px 15px',
        borderRadius: '5px',
    },
    menuItem: {
        color: 'black',
        fontSize: '16px',
        '& .Mui-selected': {
            display: 'block',
            backgroundColor: '#EBEBEB',
        },
        fontFamily: 'Arial, Helvetica',
        color: '#000000DE',
    },
    title: {
        fontFamily: 'Roboto, Arial, Helvetica',
        fontSize: '16px',
        fontWeight: 200,
        padding: '3px 0px',
        color: '#000000DE',
    },
    tabletitle: {
        fontSize: '18px',
        fontWeight: 550,
        padding: '10px 15px',
        color: '#000000DE',
        backgroundColor: '#e5e0e0',
    },
    error: {
        fontSize: '16px',
        fontWeight: 800,
        padding: '3px 0px',
        color: 'red',
    },
    imageBox: {
        display: 'block',
        position: 'relative',
        top: '5px',
        width: '1000px',
        margin: '10px',
        textAlign: 'center',
    },
    periodBox: {
        position: 'relative',
        width: '100%',
        backgroundColor: 'white',
        borderRadius: '5px',
        marginTop: '10px',
        fontWeight: '400',
        fontSize: '16px',
        fontFamily: 'Arial, Helvetica',
    },
    waitingMsg: {
        fontWeight: 400,
        fontSize: 20,
        textAlign: 'center',
        marginTop: 70,
        marginLeft: -80,
    },
    circularProgressTop: {
        marginTop: 20,
    },
    circularProgressDiv: {
        border: '1px solid 1px solid #d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
    },
    commentBox: {
        fontFamily: 'Arial, Helvetica',
        height: 'auto',
        margin: '5px 0px 0px 5px',
        padding: '5px',
        fontWeight: 500,
        fontSize: '14px',
    },
    infoButton: {
        borderRadius: 5,
        marginLeft: '35px',
        backgroundColor: '#FFF',
        border:'1px soild #E5E0E0',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        opacity: 0.4,
        zIndex: 8888,
    },
    explanation: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '900px',
        height: '700px',
        backgroundColor: 'whitesmoke',
        padding: '20px 30px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        overflow: 'auto',
        fontWeight: '400',
        fontSize: '14px',
    },
};

const useStyles = makeStyles(styles);

export default function IDAStockDataView() {
    const [searchInput, setSearchInput] = React.useState("");
    const [showSubmit, setShowSubmit] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    // const [showTopMenu, setShowTopMenu] = React.useState(false);
    const [showFunctionMenu, setShowFunctionMenu] = React.useState(false);
    const [showDataType, setShowDataTypeMenu] = React.useState(false);
    const [showMoreDataType, setShowMoreDataTypeMenu] = React.useState(false);
    const [showPeriodBox, setShowPeriodBox] = React.useState(false);
    const [showPeriodMenu, setShowPeriodMenu] = React.useState(false);
    const [showLengthMenu, setShowLengthMenu] = React.useState(false);
    const [showViewDimMenu, setShowViewDimMenu] = React.useState(false);
    const [showTimeDimMenu, setShowTimeDimMenu] = React.useState(false);
    const [showChartView, setShowChartView] = React.useState(false);
    const [showRankingView, setShowRankingView] = React.useState(false);
    const [showCommentView, setShowCommentView] = React.useState(false);
    const [showOverallView, setShowOverallView] = React.useState(false);
    const [showExplanation, setShowExplanation] = React.useState(false);
    const [explanationMsg, setExplanationMsg] = React.useState('');
    const [datatypeOption, setDataTypeOption] = React.useState([]);
    const [moreOption, setMoreOption] = React.useState([]);
    const [periodOption, setPeriodOption] = React.useState([]);
    const [isETF, setIsETF] = React.useState(false);
    const [sectorETF, setSectorETF] = React.useState('');
    const [progress, setProgress] = React.useState(false);
    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
    const [anchorEl4, setAnchorEl4] = React.useState<null | HTMLElement>(null);
    const [anchorEl5, setAnchorEl5] = React.useState<null | HTMLElement>(null);
    const [anchorEl6, setAnchorEl6] = React.useState<null | HTMLElement>(null);
    const [anchorEl7, setAnchorEl7] = React.useState<null | HTMLElement>(null);
    const [anchorEl8, setAnchorEl8] = React.useState<null | HTMLElement>(null);
    const [selectedFunctionIndex, setFunctionIndex] = React.useState(1);
    const [selectedCategoryIndex, setCategoryIndex] = React.useState(0);
    const [selectedDataTypeIndex, setDataTypeIndex] = React.useState(0);
    const [selectedMoreDataIndex, setMoreDataIndex] = React.useState(0);
    const [selectedViewDimIndex, setViewDimIndex] = React.useState(1);
    const [selectedTimeDimIndex, setTimeDimIndex] = React.useState(1);
    const [selectedSecondaryIdex, setSecondaryIndex] = React.useState(0);
    const [periodIndex, setPeriodIndex] = React.useState(1);
    const [lengthIndex, setLengthIndex] = React.useState(3);
    const [idaColumns, setIdaColumns] = React.useState([]);
    const [rankingColumns, setRankingColumns] = React.useState([]);
    const [idaTable, setIdaTable] = React.useState(null);
    const [rankTable, setRankTable] = React.useState(null);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [chartwidth, setChartwidth] = React.useState("auto");
    const [comment, setComment] = React.useState('');
    const [reload, setReload] = React.useState(1);
    // const [isEtf, setIsEtf] = React.useState(false);
    // const open1 = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const open4 = Boolean(anchorEl4);
    const open5 = Boolean(anchorEl5);
    const open6 = Boolean(anchorEl6);
    const open7 = Boolean(anchorEl7);
    const open8 = Boolean(anchorEl8);

    // const handleClickListItem1 = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleClickListItem2 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClickListItem3 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl3(event.currentTarget);
    };
    const handleClickListItem4 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl4(event.currentTarget);
    };
    const handleClickListItem5 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl5(event.currentTarget);
    };
    const handleClickListItem6 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl6(event.currentTarget);
    };
    const handleClickListItem7 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl7(event.currentTarget);
    };
    const handleClickListItem8 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl8(event.currentTarget);
    };
    const resetMenus = () => {
        setShowFunctionMenu(false);
        setShowDataTypeMenu(false);
        setShowMoreDataTypeMenu(false);
        setShowViewDimMenu(false);
        setShowTimeDimMenu(false);
        setShowPeriodBox(false);
        setShowPeriodMenu(false);
        setShowLengthMenu(false);
        setShowSubmit(false);
    };
    const showCA = () => {
        // console.info('show FAD');
        // setShowOverallView(true);
        setMoreDataIndex(1);
        setDataTypeIndex(1);
        setShowDataTypeMenu(false);
        setDataTypeOption(fad_datatype_options);
        setShowMoreDataTypeMenu(false);
        //default is finanical metric
        setPeriodOption(day_period_options);
        setShowViewDimMenu(false);
        setShowTimeDimMenu(false);
        setShowPeriodBox(false);
        setShowPeriodMenu(false);
        setShowLengthMenu(false);
        setShowChartView(false);
        setShowRankingView(false);
    };
    const hideCA = () => {
        setMoreDataIndex(0);
        setDataTypeIndex(0);
        setShowDataTypeMenu(false);
        setDataTypeOption([]);
        setPeriodOption([]);
        setShowMoreDataTypeMenu(false);
        setShowViewDimMenu(false);
        setShowTimeDimMenu(false);
        setShowPeriodMenu(false);
        setShowLengthMenu(false);
        setShowChartView(false);
        setShowOverallView(false);
        setShowRankingView(false);
    };
    const showFAD = () => {
        // console.info('show FAD');
        setMoreDataIndex(1);
        setDataTypeIndex(1);
        setShowDataTypeMenu(true);
        setDataTypeOption(fad_datatype_options);
        setShowMoreDataTypeMenu(false);
        setShowOverallView(false);
        //default is finanical metric
        setPeriodOption(day_period_options);
        setShowViewDimMenu(false);
        setShowTimeDimMenu(false);
        setShowPeriodBox(true);
        setShowPeriodMenu(true);
        setShowLengthMenu(true);
    };
    const hideFAD = () => {
        setMoreDataIndex(0);
        setDataTypeIndex(0);
        setShowDataTypeMenu(false);
        setDataTypeOption([]);
        setPeriodOption([]);
        setShowMoreDataTypeMenu(false);
        setShowViewDimMenu(false);
        setShowTimeDimMenu(false);
        setShowPeriodMenu(false);
        setShowLengthMenu(false);
        setShowChartView(false);
        setShowOverallView(false);
        setShowRankingView(false);
    };
    const showTAD = () => {
        setDataTypeIndex(1);
        setShowDataTypeMenu(true);
        setDataTypeOption(tad_datatype_options);
        setPeriodOption(day_period_options);
        setShowPeriodBox(true);
        setShowPeriodMenu(true);
        setShowLengthMenu(true);
        setShowOverallView(false);
        setShowRankingView(false);
    }
    const hideTAD = () => {
        setDataTypeIndex(0);
        setShowDataTypeMenu(false);
        setDataTypeOption([]);
        setPeriodOption([]);
        setShowPeriodBox(false);
        setShowPeriodMenu(false);
        setShowLengthMenu(false);
        setShowChartView(false);
        setShowOverallView(false)
        setShowRankingView(false);
    }
    const showSAD = () => {
        setDataTypeIndex(1);
        setShowDataTypeMenu(true);
        setDataTypeOption(option_datatype_options);
        setPeriodOption(day_period_options);
        setShowPeriodBox(true);
        setShowPeriodMenu(true);
        setShowLengthMenu(true);
    }
    const hideSAD = () => {
        setDataTypeIndex(0);
        setShowDataTypeMenu(false);
        setDataTypeOption([]);
        setPeriodOption([]);
        setShowPeriodBox(false);
        setShowPeriodMenu(false);
        setShowLengthMenu(false);
        setShowChartView(false);
        setShowOverallView(false);
        setShowRankingView(false);
    }

    //This is now top menu
    const handleCategoryMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        //data category
        setCategoryIndex(index);
        if (index == 1) {
            // console.info("Fundamental Data");
            hideTAD();
            showFAD();
            setShowSubmit(true);
        } else if (index == 2) {
            // console.info("Technical Data");
            hideFAD();
            showTAD();
            setShowSubmit(true);
        } else if (index == 3) {
            // console.info("Semtiment Data");
            hideFAD();
            showSAD();
            setShowSubmit(true);
        } else if (index == 4) {
            // console.info("Complete report");
            showCA();
            setShowSubmit(true);
        }
        setAnchorEl2(null);
    };

    const handleDataTypeMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setDataTypeIndex(index);
        if (selectedCategoryIndex === 1) {
            if (index === 1) {
                //Daily Financial Metric
                // console.info("Daily Financial Metric");
                setShowMoreDataTypeMenu(false);
                setMoreOption([]);
                setPeriodOption(day_period_options);
                setSecondaryIndex(0);
                setMoreDataIndex(0)
            } else if (index === 2) {
                //Quaterly Financial Data
                // console.info("Quaterly Financial Data");
                setShowMoreDataTypeMenu(true);
                setMoreOption(qfd_options);
                setMoreDataIndex(1);
                setPeriodOption(quarter_period_options);
                setSecondaryIndex(3);
            }
        } else if (selectedCategoryIndex === 2) {
            // nothing
        } else {
            setMoreDataIndex(1);
            setMoreOption(fundamental_raw_options);
            setShowMoreDataTypeMenu(false);
        }
        setShowPeriodMenu(true);
        setShowSubmit(true);
        setAnchorEl3(null);
    };
    const handlePeriodMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setPeriodIndex(index);
        setShowLengthMenu(true);
        setAnchorEl4(null);
    };
    const handleLengthMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setLengthIndex(index);
        // setShowSubmit(true);
        setAnchorEl5(null);
    };
    const handleMoreDataItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setMoreDataIndex(index);
        if (selectedCategoryIndex === 1) {
            // console.info("Fundatmental Data:" + index);
            setShowViewDimMenu(false);
            setShowTimeDimMenu(false);
            setShowPeriodMenu(true);
            setShowLengthMenu(true);
        }
        setAnchorEl6(null);
    };
    const handleViewDimItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        // setLengthIndex(index);
        // setShowSubmit(true);
        setViewDimIndex(index);
        setAnchorEl7(null);
    };
    const handleTimeDimItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        // setLengthIndex(index);
        // setShowSubmit(true);
        setTimeDimIndex(index);
        setAnchorEl8(null);
    };
    const handleSubmit = () => {
        let func;
        let period;
        setErrorMsg("");
        setShowError(false);
        setProgress(true);
        // console.log("Data " + selectedCategoryIndex);
        // console.log("Data type " + selectedDataTypeIndex);
        if (selectedCategoryIndex === 1) {
            // Fundamental Data
            if (selectedDataTypeIndex === 1) {
                //Daily Finance metric
                func = 2;
                period = periodIndex;
                getIDAtable(searchInput, func, period, length_options[lengthIndex], selectedViewDimIndex - 1);
            } else if (selectedDataTypeIndex === 2) {
                // Quarterly Financial Data
                if (selectedMoreDataIndex === 1) {
                    getIDAtable(searchInput, 5, 2, length_options[lengthIndex], selectedViewDimIndex - 1);
                    getRankingtable(searchInput, 20);
                } else if (selectedMoreDataIndex === 2) {
                    getIDAtable(searchInput, 6, 2, length_options[lengthIndex], selectedViewDimIndex - 1);
                    getRankingtable(searchInput, 21);
                } else if (selectedMoreDataIndex === 3) {
                    getIDAtable(searchInput, 7, 2, length_options[lengthIndex], selectedViewDimIndex - 1);
                    getRankingtable(searchInput, 22);
                } else if (selectedMoreDataIndex === 4) {
                    getIDAtable(searchInput, 8, 2, length_options[lengthIndex], selectedViewDimIndex - 1);
                    getRankingtable(searchInput, 23);
                } else if (selectedMoreDataIndex === 5) {
                    getIDAtable(searchInput, 9, 2, length_options[lengthIndex], selectedViewDimIndex - 1);
                    getRankingtable(searchInput, 24);
                } else if (selectedMoreDataIndex === 6) {
                    getIDAtable(searchInput, 10, 2, length_options[lengthIndex], selectedViewDimIndex - 1);
                    getRankingtable(searchInput, 25);
                } else if (selectedMoreDataIndex === 7) {
                    getIDAtable(searchInput, 26, "", 1);
                    setShowRankingView(false);
                }
            }
        } else if (selectedCategoryIndex === 2) {
            // console.info('Technical Data');
            // technical data
            setShowRankingView(false);
            if (selectedDataTypeIndex === 1) {
                func = 1;
                period = periodIndex;
                getIDAtable(searchInput, func, period, length_options[lengthIndex], selectedViewDimIndex - 1);
            } else if (selectedDataTypeIndex === 2) {
                func = 11;
                period = periodIndex;
                getIDAtable(searchInput, func, period, length_options[lengthIndex], selectedViewDimIndex - 1);
            } else if (selectedDataTypeIndex === 3) {
                func = 15;
                period = periodIndex;
                getIDAtable(searchInput, func, period, length_options[lengthIndex], selectedViewDimIndex - 1);
            }
        } else if (selectedCategoryIndex === 3) {
            // semtiment data
            setShowRankingView(false);
            func = 4;
            period = periodIndex;
            getIDAtable(searchInput, func, period, length_options[lengthIndex], selectedViewDimIndex - 1);
        } else if (selectedCategoryIndex === 4) {
            setShowRankingView(false);
            setShowChartView(false);
            validateSymbol(searchInput);
            setTimeout(function () {
                setProgress(false);
                setShowOverallView(true);
                setReload(reload + 1);
            }, 500);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    const handleClose3 = () => {
        setAnchorEl3(null);
    };
    const handleClose4 = () => {
        setAnchorEl4(null);
    };
    const handleClose5 = () => {
        setAnchorEl5(null);
    };
    const handleClose6 = () => {
        setAnchorEl6(null);
    };
    const handleClose7 = () => {
        setAnchorEl7(null);
    };
    const handleClose8 = () => {
        setAnchorEl8(null);
    };

    const options = [
        'Select Function',
        'Data Analysis',
        // 'Signals',
        // 'State & Prediction',
    ];
    const function_options = [
        // [],
        // [
        'Select Catagory',
        'Fundamental Analysis',
        'Technical Analysis',
        'Sentiment  Analysis',
        'Complete Analysis',
    ];
    const function_secondary = [
        'raw data',
        'data category',
        'signals',
        // 'for state & prediction',
    ];
    const more_option_secondary = [
        'raw Data',
        'data category',
        'signals',
        'group',
        // 'for state & prediction',
    ];
    const raw_datatype_options = [
        'Select Data Type',
        'Raw Data',
    ];
    const tad_datatype_options = [
        'Select Data Type',
        'Prices & Volume',
        'Technical Metrics',
        'Momentum Ranking',
    ];
    const fad_datatype_options = [
        'Select Data Type',
        'Daily Financial Metrics',
        'Quarterly Financial Data',
    ];
    const option_datatype_options = [
        'Select Data Type',
        'Option Put Call Ratio',
    ];
    const signal_options = [
        'Select Signal Type',
        'N/A',
    ];
    const fundamental_raw_options = [
        'Select Type',
        'Financial Metrics',
        'Financial Data',
    ];
    const prediction_options = [
        'Select Prediction Type',
        'N/A',
    ];
    const day_period_options = [
        'Select Period',
        'Days',
    ]
    const quarter_period_options = [
        'Select Period',
        'Quarters',
    ]
    const length_options = [
        'Select Length',
        '1',
        '5',
        '10',
        '20',
        '30',
        '50',
    ]
    // const view_dimension_options = [
    //     'Select View Dimension',
    //     'As Reported',
    //     'Most Recent Reported',
    // ]
    const time_dimension_options = [
        'Select Time Dimension',
        'Annual',
        'Trailing Twelve Months',
        'Quarterly',
    ]
    const qfd_options = [
        'Select Group',
        'Overall',
        'Efficiency',
        'Growth',
        'Quality',
        'Profitability',
        'Dividend',
        'Score Summary'
    ];

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        if (showError) {
            setShowError(false);
            setErrorMsg("");
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (showFunctionMenu && showPeriodBox) {
                handleSubmit();
            } else {
                validateSymbol(searchInput);
            }
        }
    }
    const handleOpenInfo = () => {
        setExplanationMsg(ReactHtmlParser(explanationText.stockTaMetric));
        setShowExplanation(true);
    };
    const onClose = () => {
        setShowExplanation(false);
    }

    const tad_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Open</div>
                ),
            selector: 'open',
            sortable: true,
            right: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>High</div>
                ),
            selector: 'high',
            sortable: true,
            right: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Low</div>
                ),
            selector: 'low',
            sortable: true,
            right: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Close</div>
                ),
            selector: 'close',
            sortable: true,
            right: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Adjust Close</div>
                ),
            selector: 'adjustClose',
            sortable: true,
            right: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Volume</div>
                ),
            selector: 'volume',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        }).format(row.volume / 1000000)}M
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Daily Return</div>
                ),
            selector: 'dailyReturn',
            sortable: true,
            right: true,
            cell: (row) => {
                return (
                    <div>
                        {row.dailyReturn}%
                    </div>
                );
            },
            width: '100px',
        },
    ];

    const fad_metric_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '120px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV</div>
                ),
            selector: 'ev',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.ev)}M
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV/EBIT</div>
                ),
            selector: 'evebit',
            sortable: true,
            right: true,
            width: '120px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV/EBITDA</div>
                ),
            selector: 'evebitda',
            sortable: true,
            right: true,
            width: '120px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Marketcap</div>
                ),
            selector: 'marketcap',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.marketcap)}M
                    </div>
                )
            }
        },
        {
            // name: 'Close',
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>PB</div>
                ),
            selector: 'pb',
            sortable: true,
            right: true,
            width: '90px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>PE</div>
                ),
            selector: 'pe',
            sortable: true,
            right: true,
            width: '90px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>PS</div>
                ),
            selector: 'ps',
            sortable: true,
            right: true,
            cell: (row) => {
                const data = row.ps;
                return (
                    <div>
                        {data}
                    </div>
                );
            },
            width: '90px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Next 1Y Growth Rate</div>
                ),
            selector: 'growthRate',
            sortable: true,
            right: true,
            cell: (row) => {
                const data = row.growthRate ? Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(row.growthRate * 100) + '%' : 'N/A';
                return (
                    <div>
                        {data}
                    </div>
                )
            },
            width: '115px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Analyst Target Rrice</div>
                ),
            selector: 'targetPrice',
            sortable: true,
            right: true,
            cell: (row) => {
                const data = row.targetPrice ? row.targetPrice : 'N/A';
                return (
                    <div>
                        {data}
                    </div>
                );
            },
            width: '95px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EPS Estimate</div>
                ),
            selector: 'epsEstimate',
            sortable: true,
            right: true,
            cell: (row) => {
                const data = row.epsEstimate ? row.epsEstimate : 'N/A';
                return (
                    <div>
                        {data}
                    </div>
                );
            },
            width: '95px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EPS Actual</div>
                ),
            selector: 'epsActual',
            sortable: true,
            right: true,
            cell: (row) => {
                const data = row.epsActual ? row.epsActual : 'N/A';
                return (
                    <div>
                        {data}
                    </div>
                );
            },
            width: '95px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EPS Surprise</div>
                ),
            selector: 'epsSurprise',
            sortable: true,
            right: true,
            cell: (row) => {
                const data = row.epsSurprise ? row.epsSurprise + '%' : 'N/A';
                return (
                    <div>
                        {data}
                    </div>
                );
            },
            width: '95px',
        },
    ];

    const fad_data_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV</div>
                ),
            selector: 'ev',
            sortable: true,
            right: true,
            width: '150px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(row.ev / 1000000)}M
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV/EBIT</div>
                ),
            selector: 'evebit',
            sortable: true,
            right: true,
            width: '150px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(row.evebit / 1000000)}M
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV/EBITDA</div>
                ),
            selector: 'evebitda',
            sortable: true,
            right: true,
            width: '150px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(row.evebitda / 1000000)}M
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Marketcap</div>
                ),
            selector: 'marketcap',
            sortable: true,
            right: true,
            width: '150px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(row.marketcap / 1000000)}M
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>PB</div>
                ),
            selector: 'pb',
            sortable: true,
            right: true,
            width: '90px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>PE</div>
                ),
            selector: 'pe',
            sortable: true,
            right: true,
            width: '90px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EPS</div>
                ),
            selector: 'eps',
            sortable: true,
            right: true,
            width: '90px',
        },
    ];

    const sad_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Put Volume</div>
                ),
            selector: 'putVolume',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.putVolume)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Call Volume</div>
                ),
            selector: 'callVolume',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.callVolume)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Put/Call Ratio of Volume</div>
                ),
            selector: 'putCallVolumeRatio',
            sortable: true,
            right: true,
            width: '120px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Put Open Interest</div>
                ),
            selector: 'putOpenInterest',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.putOpenInterest)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Call Open Interest</div>
                ),
            selector: 'callOpenInterest',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.callOpenInterest)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Put/Call Ratio of Open Interest</div>
                ),
            selector: 'putCallOpenInterestRatio',
            sortable: true,
            right: true,
            width: '120px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Short Ratio</div>
                ),
            selector: 'shortRatio',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.shortRatio? row.shortRatio:"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Short Precent</div>
                ),
            selector: 'shortPercent',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.shortPercent? row.shortPercent:"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Shares Short Prior Month</div>
                ),
            selector: 'sharesShortPriorMonth',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.sharesShortPriorMonth? row.sharesShortPriorMonth:"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Shares Short</div>
                ),
            selector: 'sharesShort',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.sharesShort? row.sharesShort:"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    //overall table
    const fad1_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Market Cap</div>
                ),
            selector: 'marketCap',
            sortable: true,
            right: true,
            width: '130px',
            cell: (row) => {
                return (
                    <div>
                        ${Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(row.marketCap / 1000000)}M
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Revenue to Market Cap</div>
                ),
            selector: 'revenueToMarketCap',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.revenueToMarketCap)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price to Book</div>
                ),
            selector: 'priceToBook',
            sortable: true,
            right: true,
            width: '110px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price to Earnings</div>
                ),
            selector: 'priceToEarning',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.priceToEarning)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price to Sales</div>
                ),
            selector: 'priceToSale',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.priceToSale)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price to Free Cashflows</div>
                ),
            selector: 'priceToFreeCashflow',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.priceToFreeCashflow)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price to Operating Cashflows</div>
                ),
            selector: 'priceToOperatingCashflow',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.priceToOperatingCashflow? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.priceToOperatingCashflow):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Asset to Free Cashflows</div>
                ),
            selector: 'assetToFreeCashflow',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.assetToFreeCashflow)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Debt to Free Cashflows</div>
                ),
            selector: 'debtToFreeCashflow',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.debtToFreeCashflow)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV to Cashflows</div>
                ),
            selector: 'evToCashflow',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.evToCashflow)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV to EBIT</div>
                ),
            selector: 'evToEBIT',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.evToEBIT)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV to EBITDA</div>
                ),
            selector: 'evToEBITDA',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.evToEBITDA)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price To EBITDA</div>
                ),
            selector: 'priceToEBITDA',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.priceToEBITDA? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.priceToEBITDA):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const fad1_rank_columns = [
        {
            selector: 'name',
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Market cap</div>
                ),
            selector: 'marketCapRanking',
            sortable: true,
            right: true,
            width: '130px',
            cell: (row) => {
                const data = row.marketCapRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.marketCapRanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Revenue To Market cap</div>
                ),
            selector: 'revenueToMarketCapRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.revenueToMarketCapRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.revenueToMarketCapRanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price To Book</div>
                ),
            selector: 'priceToBookRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.priceToBookRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.priceToBookRanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price To Earnings</div>
                ),
            selector: 'priceToEarningRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.priceToEarningRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.priceToEarningRanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price To Sales</div>
                ),
            selector: 'priceToSaleRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.priceToSaleRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.priceToSaleRanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price To Free Cashflows</div>
                ),
            selector: 'priceToFreeCashflowRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.priceToFreeCashflowRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.priceToFreeCashflowRanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price To Operating Cashflows</div>
                ),
            selector: 'priceToOperatingCashflowRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.priceToOperatingCashflowRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.priceToOperatingCashflowRanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Asset To Free Cashflows</div>
                ),
            selector: 'assetToFreeCashflowRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.assetToFreeCashflowRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.assetToFreeCashflowRanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Debt To Free Cashflows</div>
                ),
            selector: 'debtToFreeCashflowRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.debtToFreeCashflowRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.debtToFreeCashflowRanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV To Cashflows</div>
                ),
            selector: 'evToCashflowRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.evToCashflowRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.evToCashflowRanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV To EBIT</div>
                ),
            selector: 'evToEBITRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.evToEBITRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.evToEBITRanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EV To EBITDA</div>
                ),
            selector: 'evToEBITDARanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.evToEBITDARanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.evToEBITDARanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Price To EBITDA</div>
                ),
            selector: 'priceToEBITDARanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.priceToEBITDARanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.priceToEBITDARanking) : 0;
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const fad2_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Capex to Asset</div>
                ),
            selector: 'capexToAsset',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.capexToAsset ? Intl.NumberFormat("en-US", {}).format(row.capexToAsset) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Capex to Cashflows</div>
                ),
            selector: 'capexToCashflow',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.capexToCashflow)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Capex to Sales</div>
                ),
            selector: 'capexToSales',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.capexToSales)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EBIT to Asset</div>
                ),
            selector: 'ebitToAsset',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.ebitToAsset)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EBITDA Margin</div>
                ),
            selector: 'ebitaMargin',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.ebitaMargin)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Operating Cashflows to Asset</div>
                ),
            selector: 'cashflowToAsset',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.cashflowToAsset)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Retained Earnings to Asset</div>
                ),
            selector: 'earningToAsset',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.earningToAsset)}
                    </div>
                )
            }
        },
    ];

    const fad2_rank_columns = [
        {
            selector: 'name',
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Capex to Asset</div>
                ),
            selector: 'capexToAssetRanking',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.capexToAssetRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.capexToAssetRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Capex to Cashflows</div>
                ),
            selector: 'capexToCashflowRanking',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.capexToCashflowRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.capexToCashflowRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Capex to Sales</div>
                ),
            selector: 'capexToSalesRanking',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.capexToSalesRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.capexToSalesRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EBIT to Asset</div>
                ),
            selector: 'ebitToAssetRanking',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.ebitToAssetRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.ebitToAssetRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EBITDA Margin</div>
                ),
            selector: 'ebitaMarginRanking',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.ebitaMarginRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.ebitaMarginRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Operating Cashflows to Asset</div>
                ),
            selector: 'cashflowToAssetRanking',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.cashflowToAssetRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.cashflowToAssetRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Retained Earnings to Asset</div>
                ),
            selector: 'earningToAssetRanking',
            sortable: true,
            right: true,
            width: '120px',
            cell: (row) => {
                const data = row.cashflowToAssetRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.earningToAssetRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const fad3_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Sales 1Q Growth CurQ vs LastQ</div>
                ),
            selector: 'sales1qGrowthLastQ',
            sortable: true,
            center: true,
            width: '160px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.sales1qGrowthLastQ)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Sales 1Q Growth CurQ vs SameQLastY</div>
                ),
            selector: 'sales1qGrowthLastY',
            sortable: true,
            center: true,
            width: '160px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.sales1qGrowthLastY)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Sales 1Y Growth CurQTrailY vs LastQTrailY</div>
                ),
            selector: 'sales1yGrowthLastQ',
            sortable: true,
            center: true,
            width: '160px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.sales1yGrowthLastQ)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Sales 1Y Growth CurQTailY vs SameQLastYTrailY</div>
                ),
            selector: 'sales1yGrowthLastY',
            sortable: true,
            center: true,
            width: '160px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.sales1yGrowthLastY)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EPS 1Q Growth CurQ vs LastQ</div>
                ),
            selector: 'eps1qGrowthLastQ',
            sortable: true,
            center: true,
            width: '150px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.eps1qGrowthLastQ)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EPS 1Q Growth CurQ vs SameQLastY</div>
                ),
            selector: 'eps1qGrowthLastY',
            sortable: true,
            center: true,
            width: '150px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.eps1qGrowthLastY)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EPS 1Y Growth CurQTrailY vs LastQTrailY</div>
                ),
            selector: 'eps1yGrowthLastQ',
            sortable: true,
            center: true,
            width: '150px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.eps1yGrowthLastQ)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>EPS 1Y Growth CurQTrailY vs SameQLastYTrailY</div>
                ),
            selector: 'eps1yGrowthLastY',
            sortable: true,
            center: true,
            width: '150px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.eps1yGrowthLastY)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>PE to EPS Growth</div>
                ),
            selector: 'peToEpsGrowth',
            sortable: true,
            center: true,
            width: '150px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.peToEpsGrowth)}
                    </div>
                )
            }
        },
    ];

    const fad3_rank_columns = [
        {
            selector: 'name',
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Sales 1Q Growth CurQ vs LastQ</div>
                ),
            selector: 'sales1qGrowthLastQRanking',
            sortable: true,
            center: true,
            width: '160px',
            cell: (row) => {
                const data = row.sales1qGrowthLastQRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.sales1qGrowthLastQRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Sales 1Q Growth CurQ vs SameQLastY</div>
                ),
            selector: 'sales1qGrowthLastYRanking',
            sortable: true,
            center: true,
            width: '160px',
            cell: (row) => {
                const data = row.sales1qGrowthLastYRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.sales1qGrowthLastYRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Sales 1Y Growth CurQTrailY vs LastQTrailY</div>
                ),
            selector: 'sales1yGrowthLastQRanking',
            sortable: true,
            center: true,
            width: '160px',
            cell: (row) => {
                const data = row.sales1yGrowthLastQRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.sales1yGrowthLastQRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Sales 1Y Growth CurQTailY vs SameQLastYTrailY</div>
                ),
            selector: 'sales1yGrowthLastYRanking',
            sortable: true,
            center: true,
            width: '160px',
            cell: (row) => {
                const data = row.sales1yGrowthLastYRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.sales1yGrowthLastYRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EPS 1Q Growth CurQ vs LastQ</div>
                ),
            selector: 'eps1qGrowthLastQRanking',
            sortable: true,
            center: true,
            width: '150px',
            cell: (row) => {
                const data = row.eps1qGrowthLastQRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.eps1qGrowthLastQRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EPS 1Q Growth CurQ vs SameQLastY</div>
                ),
            selector: 'eps1qGrowthLastYRanking',
            sortable: true,
            center: true,
            width: '150px',
            cell: (row) => {
                const data = row.eps1qGrowthLastYRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.eps1qGrowthLastYRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EPS 1Y Growth CurQTrailY vs LastQTrailY</div>
                ),
            selector: 'eps1yGrowthLastQRanking',
            sortable: true,
            center: true,
            width: '150px',
            cell: (row) => {
                const data = row.eps1yGrowthLastQRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.eps1yGrowthLastQRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EPS 1Y Growth CurQTrailY vs SameQLastYTrailY</div>
                ),
            selector: 'eps1yGrowthLastYRanking',
            sortable: true,
            center: true,
            width: '150px',
            cell: (row) => {
                const data = row.eps1yGrowthLastYRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.eps1yGrowthLastYRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>PE to EPS Growth</div>
                ),
            selector: 'peEpsGrowthRanking',
            sortable: true,
            center: true,
            width: '150px',
            cell: (row) => {
                const data = row.peEpsGrowthRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.peEpsGrowthRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize: '13px'}}>Average</div>
        //         ),
        //     selector: 'average',
        //     sortable: true,
        //     right: true,
        //     width: '110px',
        //     cell: (row) => {
        //         const data = row.average ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.average) : 0;
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     }
        // },
    ];

    const fad4_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Asset Growth LastQ</div>
                ),
            selector: 'assetGrowthLastQ',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.assetGrowthLastQ)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Asset Growth LastY</div>
                ),
            selector: 'assetGrowthLastY',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.assetGrowthLastY)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Asset Turnover</div>
                ),
            selector: 'assetTurnover',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.assetTurnover)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Current AL Ratio</div>
                ),
            selector: 'currentALRatio',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.currentALRatio)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Asset to Equity Ratio</div>
                ),
            selector: 'assettoEquityRatio',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.assettoEquityRatio)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Debt to Asset Ratio</div>
                ),
            selector: 'debtToAssetRatio',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.debtToAssetRatio)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Debt to Equity Ratio</div>
                ),
            selector: 'debtToEquityRatio',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.debtToEquityRatio)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Net Debt Reduction LastQ</div>
                ),
            selector: 'netDebtReductionLastQ',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.netDebtReductionLastQ)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Working Capital To Asset</div>
                ),
            selector: 'workingCapitalToAsset',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.workingCapitalToAsset)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Working Capital To Sales</div>
                ),
            selector: 'workingCapitalToSales',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.workingCapitalToSales)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Interest Coverage</div>
                ),
            selector: 'interestCoverage',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.interestCoverage)}
                    </div>
                )
            }
        },
    ];

    const fad4_rank_columns = [
        {
            selector: 'name',
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Asset Growth LastQ</div>
                ),
            selector: 'assetGrowthLastQRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.assetGrowthLastQRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.assetGrowthLastQRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Asset Growth LastY</div>
                ),
            selector: 'assetGrowthLastYRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.assetGrowthLastYRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.assetGrowthLastYRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Asset Turnover</div>
                ),
            selector: 'assetTurnoverRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.assetTurnoverRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.assetTurnoverRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Current AL Ratio</div>
                ),
            selector: 'currentALRatioRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.currentALRatioRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.currentALRatioRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Asset to Equity Ratio</div>
                ),
            selector: 'assettoEquityRatioRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.assettoEquityRatioRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.assettoEquityRatioRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Debt to Asset Ratio</div>
                ),
            selector: 'debtToAssetRatioRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.debtToAssetRatioRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.debtToAssetRatioRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Debt to Equity Ratio</div>
                ),
            selector: 'debtToEquityRatio',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.debtToEquityRatioRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.debtToEquityRatioRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Net Debt Reduction LastQ</div>
                ),
            selector: 'netDebtReductionLastQRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.netDebtReductionLastQRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.netDebtReductionLastQRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Working Capital To Asset</div>
                ),
            selector: 'workingCapitalToAssetRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.workingCapitalToAssetRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.workingCapitalToAssetRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Working Capital To Sales</div>
                ),
            selector: 'workingCapitalToSalesRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.workingCapitalToSalesRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.workingCapitalToSalesRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Interest Coverage</div>
                ),
            selector: 'interestCoverageRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.interestCoverageRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.interestCoverageRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const fad5_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Gross Income Margin</div>
                ),
            selector: 'grossIncomeMargin',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.grossIncomeMargin)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Net Income Margin</div>
                ),
            selector: 'netIncomeMargin',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.netIncomeMargin)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Return on Equity</div>
                ),
            selector: 'returnOnEquity',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.returnOnEquity)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Return on Asset</div>
                ),
            selector: 'returnOnAsset',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.returnOnAsset)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Return on Sales</div>
                ),
            selector: 'returnOnSales',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.returnOnSales)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Return on Invest Capital</div>
                ),
            selector: 'returnOnInvestCapital',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.returnOnInvestCapital)}
                    </div>
                )
            }
        },
    ];

    const fad5_rank_columns = [
        {
            selector: 'name',
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Gross Income Margin</div>
                ),
            selector: 'grossIncomeMarginRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.grossIncomeMarginRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.grossIncomeMarginRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Net Income Margin</div>
                ),
            selector: 'netIncomeMarginRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.netIncomeMarginRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.netIncomeMarginRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Return on Equity</div>
                ),
            selector: 'returnOnEquityRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.returnOnEquityRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.returnOnEquityRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Return on Asset</div>
                ),
            selector: 'returnOnAssetRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.returnOnAssetRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.returnOnAssetRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Return on Sales</div>
                ),
            selector: 'returnOnSalesRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.returnOnSalesRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.returnOnSalesRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Return on Invest Capital</div>
                ),
            selector: 'returnOnInvestCapitalRanking',
            sortable: true,
            right: true,
            width: '100px',
            cell: (row) => {
                const data = row.returnOnInvestCapitalRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.returnOnInvestCapitalRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const fad6_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Dividend Yield</div>
                ),
            selector: 'dividendYield',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.dividendYield)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Dividend Growth</div>
                ),
            selector: 'dividendGrowth',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.dividendGrowth)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Dividend Payout Ratio</div>
                ),
            selector: 'dividenPayoutRatio',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US", {}).format(row.dividenPayoutRatio)}
                    </div>
                )
            }
        },
    ];

    const fad6_rank_columns = [
        {

            selector: 'name',
            width: '135px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Dividend Yield</div>
                ),
            selector: 'dividendYieldRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.dividendYieldRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.dividendYieldRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Dividend Growth</div>
                ),
            selector: 'dividendGrowthRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.dividendGrowthRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.dividendGrowthRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Dividend Payout Ratio</div>
                ),
            selector: 'dividenPayoutRatioRanking',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.dividenPayoutRatioRanking ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.dividenPayoutRatioRanking) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const fad_summary_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}></div>
                ),
            selector: 'name',
            width: '150px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Overall</div>
                ),
            selector: 'overallScore',
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.overallScore ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.overallScore) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Efficiency</div>
                ),
            selector: 'efficiencyScore',
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.efficiencyScore ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.efficiencyScore) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Growth</div>
                ),
            selector: 'growthScore',
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.growthScore ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.growthScore) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Quality</div>
                ),
            selector: 'qualityScore',
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.qualityScore ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.qualityScore) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Profitability</div>
                ),
            selector: 'profitabilityScore',
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.profitabilityScore ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.profitabilityScore) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Dividend</div>
                ),
            selector: 'dividendScore',
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.dividendScore ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.dividendScore) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];


    const tad1_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '110px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>TA State</div>
                ),
            selector: 'taRating',
            sortable: true,
            width: '135px',
            cell: (row) => {
                return (
                    <div style={{fontSize: '14px', textAlign: 'center'}}>
                        {row.taRating}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>TA String</div>
                ),
            selector: 'taString',
            sortable: true,
            width: '235px',
            cell: (row) => {
                return (
                    <div style={{fontWeight: 550, fontSize: '14px', fontFamily: 'monospace', textAlign: 'center'}}>
                        {row.taString}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Trend</div>
                ),
            selector: 'trend',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend || row.trend === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.trend) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Trend 5 Day Slope</div>
                ),
            selector: 'trend5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend5 || row.trend5 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.trend5) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Trend 20 Day Slope</div>
                ),
            selector: 'trend20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend20 || row.trend20 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.trend20) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Momentum</div>
                ),
            selector: 'momentum',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum || row.momentum === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.momentum) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Momentum 5 Day Slope</div>
                ),
            selector: 'momentum5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum5 || row.momentum5 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.momentum5) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Momentum 20 Day Slope</div>
                ),
            selector: 'momentum20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum20 || row.momentum20 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.momentum20) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Breadth</div>
                ),
            selector: 'breadth',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.breadth || row.breadth === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.breadth) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Breadth 5 Day Slope</div>
                ),
            selector: 'breadth5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.breadth5 || row.breadth5 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.breadth5) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Breadth 20 Day Slope</div>
                ),
            selector: 'breadth20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.breadth20 || row.breadth20 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.breadth20) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Oscillation</div>
                ),
            selector: 'oscillation',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation || row.oscillation === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.oscillation) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Oscillation 5 Day Slope</div>
                ),
            selector: 'oscillation5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation5 || row.oscillation5 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.oscillation5) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Oscillation 20 Day Slope</div>
                ),
            selector: 'oscillation20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation20 || row.oscillation20 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.oscillation20) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>change_status1</div>
                ),
            selector: 'changeStatus1',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.changeStatus1 || row.changeStatus1 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.changeStatus1) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const tad2_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '110px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>TA State</div>
                ),
            selector: 'taRating',
            sortable: true,
            width: '135px',
            cell: (row) => {
                return (
                    <div style={{fontSize: '14px', textAlign: 'center'}}>
                        {row.taRating}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>TA String</div>
                ),
            selector: 'taString',
            sortable: true,
            width: '185px',
            cell: (row) => {
                return (
                    <div style={{fontWeight: 550, fontSize: '14px', fontFamily: 'monospace', textAlign: 'center'}}>
                        {row.taString}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Trend</div>
                ),
            selector: 'trend',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend || row.trend === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.trend) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Trend 5 Day Slope</div>
                ),
            selector: 'trend5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend5 || row.trend5 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.trend5) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Trend 20 Day Slope</div>
                ),
            selector: 'trend20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend20 || row.trend20 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.trend20) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Momentum</div>
                ),
            selector: 'momentum',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum || row.momentum === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.momentum) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Momentum 5 Day Slope</div>
                ),
            selector: 'momentum5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum5 || row.momentum5 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.momentum5) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Momentum 20 Day Slope</div>
                ),
            selector: 'momentum20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum20 || row.momentum20 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.momentum20) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Oscillation</div>
                ),
            selector: 'oscillation',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation || row.oscillation === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.oscillation) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Oscillation 5 Day Slope</div>
                ),
            selector: 'oscillation5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation5 || row.oscillation5 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.oscillation5) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Oscillation 20 Day Slope</div>
                ),
            selector: 'oscillation20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation20 || row.oscillation20 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.oscillation20) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>change_status1</div>
                ),
            selector: 'changeStatus1',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.changeStatus1 || row.changeStatus1 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.changeStatus1) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>ema_co1</div>
                ),
            selector: 'emaCo1',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.emaCo1 || row.emaCo1 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.emaCo1) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>psar_co1</div>
                ),
            selector: 'psarCo1',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.psarCo1 || row.psarCo1 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.psarCo1) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>macd_co1</div>
                ),
            selector: 'macdCo1',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.macdCo1 || row.macdCo1 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.macdCo1) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>bb_co1</div>
                ),
            selector: 'bbCo1',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.bbCo1 || row.bbCo1 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.bbCo1) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>rsi_co1</div>
                ),
            selector: 'rsiCo1',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.rsiCo1 || row.rsiCo1 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.rsiCo1) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Breakout1</div>
                ),
            selector: 'breakout1',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.breakout1 || row.breakout1 === 0 ? Intl.NumberFormat("en-US", {maximumFractionDigits: 5}).format(row.breakout1) : "N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const etf_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>ETFs (Average)</div>
                ),
            selector: 'aveRank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.aveRank ? row.aveRank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>ETFs (M2 Model)</div>
                ),
            selector: 'm2Rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.m2Rank ? row.m2Rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>ETFs (M4 Model)</div>
                ),
            selector: 'm4Rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.m4Rank ? row.m4Rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>ETFs (M3 Model)</div>
                ),
            selector: 'm3Rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.m3Rank ? row.m3Rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>ETFs (M6 Model)</div>
                ),
            selector: 'm6Rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.m6Rank ? row.m6Rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const stock_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>All Sectors Average</div>
                ),
            selector: 'aveAllRank',
            sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.aveAllRank ? row.aveAllRank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>All Sectors With M2</div>
                ),
            selector: 'all_m2_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.all_m2_rank ? row.all_m2_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>All Sectors with M4</div>
                ),
            selector: 'all_m4_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.all_m4_rank ? row.all_m4_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>All Sectors with HQ Average</div>
                ),
            selector: 'aveAllHqRank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.aveAllHqRank ? row.aveAllHqRank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>All Sectors with HQ M3</div>
                ),
            selector: 'allhq_m3_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.allhq_m3_rank ? row.allhq_m3_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>All Sectors with HQ M6</div>
                ),
            selector: 'allhq_m6_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.allhq_m6_rank ? row.allhq_m6_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Value Sectors with Average</div>
                ),
            selector: 'aveValueRank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.aveValueRank ? row.aveValueRank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Value Sectors with M2</div>
                ),
            selector: 'value_m2_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.value_m2_rank ? row.value_m2_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Value Sectors with M4</div>
                ),
            selector: 'value_m4_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.value_m4_rank ? row.value_m4_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Value Sectors with HQ Average</div>
                ),
            selector: 'aveValueHqRank',
            sortable: true,
            // right: true,
            width: '110px',
            cell: (row) => {
                const data = row.aveValueHqRank ? row.aveValueHqRank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Value Sectors with HQ M3</div>
                ),
            selector: 'valuehq_m3_rank',
            sortable: true,
            // right: true,
            width: '110px',
            cell: (row) => {
                const data = row.valuehq_m3_rank ? row.valuehq_m3_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Value Sectors with HQ M6</div>
                ),
            selector: 'valuehq_m6_rank',
            sortable: true,
            // right: true,
            width: '110px',
            cell: (row) => {
                const data = row.valuehq_m6_rank ? row.valuehq_m6_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Growth Sectors with Average</div>
                ),
            selector: 'aveGrowthRank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.aveGrowthRank ? row.aveGrowthRank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Growth Sectors with M2</div>
                ),
            selector: 'growth_m2_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.growth_m2_rank ? row.growth_m2_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Growth Sectors with M4</div>
                ),
            selector: 'growth_m4_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.growth_m4_rank ? row.growth_m4_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Growth Sectors with HQ Average</div>
                ),
            selector: 'aveGrowthHqRank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.aveGrowthHqRank ? row.aveGrowthHqRank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Growth Sectors with HQ M3</div>
                ),
            selector: 'growthhq_m3_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.growthhq_m3_rank ? row.growthhq_m3_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Growth Sectors with HQ M6</div>
                ),
            selector: 'growthhq_m6_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.growthhq_m6_rank ? row.growthhq_m6_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Tech Sectors with Average</div>
                ),
            selector: 'aveTechRank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.aveTechRank ? row.aveTechRank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Tech Sectors with M2</div>
                ),
            selector: 'tech_m2_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.tech_m2_rank ? row.tech_m2_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Tech Sectors with M4</div>
                ),
            selector: 'tech_m4_rank',
            sortable: true,
            // right: true,
            width: '110px',
            cell: (row) => {
                const data = row.tech_m4_rank ? row.tech_m4_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Tech Sectors with HQ Average</div>
                ),
            selector: 'aveTechHqRank',
            sortable: true,
            // right: true,
            width: '110px',
            cell: (row) => {
                const data = row.aveTechHqRank ? row.aveTechHqRank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Tech Sectors with HQ M3</div>
                ),
            selector: 'techhq_m3_rank',
            sortable: true,
            // right: true,
            width: '110px',
            cell: (row) => {
                const data = row.techhq_m3_rank ? row.techhq_m3_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Tech Sectors with HQ M6</div>
                ),
            selector: 'techhq_m6_rank',
            sortable: true,
            // right: true,
            width: '120px',
            cell: (row) => {
                const data = row.techhq_m6_rank ? row.techhq_m6_rank : 'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const validateSymbol = (symbol) => {
        if (symbol) {
            findSymbol(symbol)
                .then((response) => {
                    // console.info('response %o', response);
                    if (response.data.code === 200) {
                        const stock = response.data.data;
                        if (stock.validated === true) {
                            // setShowTopMenu(true);
                            setShowFunctionMenu(true);
                            setIsETF(stock.isEtf);
                            setSectorETF(stock.sectorETF);
                        } else {
                            setErrorMsg("Symbol not found");
                            setShowError(true);
                        }
                    }
                })
                .catch((error) => {
                    setShowError("Symbol not found");
                });
        }
    }

    const getIDAtable = (symbol, func, period, length, dim) => {
        // console.info("func:" + func);
        setShowCommentView(false);
        setShowRankingView(false);
        getStockIDA(symbol, func, period, length, dim)
            .then((response) => {
                if (response.data.code === 200) {
                    const data = response.data.data;
                    let isETF = data.isEtf ? true : false;
                    if (data.idaTable !== null) {
                        setIdaTable(data.idaTable);
                    } else {
                        setIdaTable([]);
                    }
                    if (data.comment !== null) {
                        setComment(data.comment);
                        setShowCommentView(true);
                    } else {
                        setComment('');
                        setShowCommentView(false);
                    }
                    setColumns(symbol, isETF, func);
                    setProgress(false);
                    setShowOverallView(false);
                    setShowChartView(true);
                    // setShowCommentView(false);
                } else {
                    const err = response?.data?.errMsg ? response.data.errMsg : null;
                    setIdaColumns(tad_columns);
                    setIdaTable([]);
                    setErrorMsg(err);
                    setShowError(true);
                    setShowCommentView(false);
                    setProgress(false);
                }
            })
            .catch((error) => {
                setProgress(false);
                setIdaColumns(tad_columns);
                setIdaTable([]);
                setShowChartView(true);
                setShowCommentView(false);
                console.debug(error);
            });
    }

    const getRankingtable = (symbol, func) => {
        setShowCommentView(false);
        getStockIDA(symbol, func, "", 1, "")
            .then((response) => {
                if (response.data.code === 200) {
                    const data = response.data.data;
                    let isETF = data.isEtf ? true : false;
                    if (data.idaTable !== null) {
                        setRankTable(data.idaTable);
                    } else {
                        setRankTable([]);
                    }
                    setComment('');
                    setShowCommentView(false);
                    switch(func) {
                        case 20:
                            setRankingColumns(fad1_rank_columns);
                            setShowRankingView(true);
                            break;
                        case 21:
                            setRankingColumns(fad2_rank_columns);
                            setShowRankingView(true);
                            break;
                        case 22:
                            setRankingColumns(fad3_rank_columns);
                            setShowRankingView(true);
                            break;
                        case 23:
                            setRankingColumns(fad4_rank_columns);
                            setShowRankingView(true);
                            break;
                        case 24:
                            setRankingColumns(fad5_rank_columns);
                            setShowRankingView(true);
                            break;
                        case 25:
                            setRankingColumns(fad6_rank_columns);
                            setShowRankingView(true);
                            break;
                        case 26:
                            setRankingColumns([]);
                            setShowRankingView(false);
                            break;
                     }
                    setShowOverallView(false);
                    setProgress(false);
                } else {
                    setRankingColumns([]);
                    setShowRankingView(false);
                    setProgress(false);
                }
            })
            .catch((error) => {
                setRankingColumns([]);
                setShowRankingView(false);
                setProgress(false);
                setRankTable([]);
                console.debug(error);
            });
    }

    const setColumns = (symbol, isETF, func) => {
        switch (func) {
            case 1:
                setChartwidth('870px');
                setIdaColumns(tad_columns);
                break;
            case 2:
                setChartwidth('auto');
                setIdaColumns(fad_metric_columns);
                break;
            case 3:
                setChartwidth('780px');
                setIdaColumns(fad_data_columns);
                break;
            case 4:
                setChartwidth('1340px');
                setIdaColumns(sad_columns);
                break;
            case 5:
                setChartwidth('auto');
                setIdaColumns(fad1_columns);
                break;
            case 6:
                setChartwidth('auto');
                setIdaColumns(fad2_columns);
                break;
            case 7:
                setChartwidth('auto');
                setIdaColumns(fad3_columns);
                break;
            case 8:
                setChartwidth('auto');
                setIdaColumns(fad4_columns);;
                break;
            case 9:
                setChartwidth('auto');
                setIdaColumns(fad5_columns);
                break;
            case 10:
                setChartwidth('auto');
                setIdaColumns(fad6_columns);
                break;
            case 11:
                setChartwidth('auto');
                if (symbol.toLowerCase() === 'spy')
                    setIdaColumns(tad1_columns);
                else
                    setIdaColumns(tad2_columns);
                break;
            case 15:
                console.info('isEtf? ' + isETF);
                if (isETF) {
                    setChartwidth('720px');
                    setIdaColumns(etf_momentum_columns);
                } else {
                    setChartwidth('auto');
                    setIdaColumns(stock_momentum_columns);
                }
                break;
            case 26:
                setChartwidth('960px');
                setIdaColumns(fad_summary_columns);
                break;
            default:
                setProgress(false);
                setErrorMsg("Data is not avalable for this symbol");
                setShowError(true);
                return;
        }
    }

    const classes = useStyles();
    return (
        <>
            <div className={classes.idaBox}>
                <div className={classes.title} style={{marginTop: '15px'}}>Enter stock symbol:</div>
                <div>
                    <span className={classes.inputBox}>
                        <input style={{height: '35px'}}
                            type="text"
                            placeholder="Symbol"
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            value={searchInput.toUpperCase()}
                        />

                    </span>
                    { showSubmit && (
                        <span>
                            <Button
                                onClick={handleSubmit}
                                color="info"
                                className={classes.submitBox}
                            >
                                Submit
                            </Button>
                        </span>
                    )}
                </div>
                { showError && (
                    <div className={classes.error}>Error: {errorMsg}</div>
                )}
                <hr style={{margin: '20px 0px 15px'}}/>
                <div>
                    {/*{ showTopMenu && (*/}
                    {/*    <>*/}
                    {/*    <div className={classes.title}>Click the button to select:</div>*/}
                    {/*    <span className={classes.functionBox}>*/}
                    {/*        <Box*/}
                    {/*            ml={2}*/}
                    {/*            sx={{ width: '60%',*/}
                    {/*                maxWidth: 250,*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <List*/}
                    {/*                component="nav"*/}
                    {/*                aria-label="Functions"*/}
                    {/*            >*/}
                    {/*                <ListItem*/}
                    {/*                    className={classes.menuList}*/}
                    {/*                    button*/}
                    {/*                    id="lock-button"*/}
                    {/*                    aria-haspopup="listbox"*/}
                    {/*                    // aria-controls="function-menu"*/}
                    {/*                    // aria-label="when device is locked"*/}
                    {/*                    aria-expanded={open1 ? 'true' : undefined}*/}
                    {/*                    onClick={handleClickListItem1}*/}
                    {/*                >*/}
                    {/*                    <ListItemText*/}
                    {/*                        primary={options[selectedFunctionIndex]}*/}
                    {/*                        secondary="function"*/}
                    {/*                    />*/}
                    {/*                </ListItem>*/}
                    {/*            </List>*/}
                    {/*            <Menu*/}
                    {/*                className={classes.menuItem}*/}
                    {/*                id="function-menu"*/}
                    {/*                anchorEl={anchorEl}*/}
                    {/*                open={open1}*/}
                    {/*                onClose={handleClose}*/}
                    {/*                MenuListProps={{*/}
                    {/*                    'aria-labelledby': 'lock-button',*/}
                    {/*                    role: 'listbox',*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                {options.map((option, index) => (*/}
                    {/*                    <MenuItem*/}
                    {/*                        className={classes.menuItem}*/}
                    {/*                        key={option}*/}
                    {/*                        disabled={index === 0}*/}
                    {/*                        selected={index === selectedFunctionIndex}*/}
                    {/*                        onClick={(event) => handleTopMenuItemClick(event, index)}*/}
                    {/*                    >*/}
                    {/*                        {option}*/}
                    {/*                    </MenuItem>*/}
                    {/*                ))}*/}
                    {/*            </Menu>*/}
                    {/*        </Box>*/}
                    {/*    </span>*/}
                    {/*    </>*/}
                    {/*)}*/}
                    { showFunctionMenu && (
                        <>
                            <div className={classes.title}>Click the button to select:</div>
                            <span className={classes.functionBox}>
                                <Box
                                    ml={2}
                                    sx={{ width: '50%',
                                        maxWidth: 200,
                                    }}
                                >
                                    <List
                                        component="nav"
                                        aria-label="Functions"
                                    >
                                        <ListItem
                                            className={classes.menuList}
                                            button
                                            id="lock-button"
                                            aria-haspopup="listbox"
                                            onClick={handleClickListItem2}
                                        >
                                            <ListItemText
                                                primary={function_options[selectedCategoryIndex]}
                                                secondary='Catagory'
                                            />
                                        </ListItem>
                                    </List>
                                    <Menu
                                        className={classes.menuItem}
                                        id="function-menu"
                                        anchorEl={anchorEl2}
                                        open={open2}
                                        onClose={handleClose2}
                                        MenuListProps={{
                                            'aria-labelledby': 'lock-button',
                                            role: 'listbox',
                                        }}
                                    >
                                        {function_options.map((option, index) => (
                                            // console.info(option);
                                            <MenuItem
                                                className={classes.menuItem}
                                                key={option}
                                                disabled={index === 0}
                                                selected={index === selectedCategoryIndex}
                                                onClick={(event) => handleCategoryMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            </span>
                        </>
                    )}
                { showDataType && (
                    <span className={classes.functionBox}>
                        <Box
                            ml={2}
                            sx={{ width: '50%',
                                maxWidth: 200,
                            }}
                        >
                            <List
                                component="nav"
                                aria-label="Functions"
                            >
                                <ListItem
                                    className={classes.menuList}
                                    button
                                    id="lock-button"
                                    aria-haspopup="listbox"
                                    onClick={handleClickListItem3}
                                >
                                    <ListItemText
                                        primary={datatypeOption[selectedDataTypeIndex]}
                                        secondary="data type"
                                    />
                                </ListItem>
                            </List>
                            <Menu
                                className={classes.menuItem}
                                id="function-menu"
                                anchorEl={anchorEl3}
                                open={open3}
                                onClose={handleClose3}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                }}
                            >
                                {datatypeOption.map((option, index) => (
                                    <MenuItem
                                        className={classes.menuItem}
                                        key={option}
                                        disabled={index === 0}
                                        selected={index === selectedDataTypeIndex}
                                        onClick={(event) => handleDataTypeMenuItemClick(event, index)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </span>
                )}
                { showMoreDataType && (
                    <span className={classes.functionBox}>
                        <Box
                            ml={2}
                            sx={{ width: '50%',
                                maxWidth: 200,
                            }}
                        >
                            <List
                                component="nav"
                                aria-label="Functions"
                            >
                                <ListItem
                                    className={classes.menuList}
                                    button
                                    id="lock-button"
                                    aria-haspopup="listbox"
                                    onClick={handleClickListItem6}
                                >
                                    <ListItemText
                                        primary={moreOption[selectedMoreDataIndex]}
                                        secondary={more_option_secondary[selectedSecondaryIdex]}
                                    />
                                </ListItem>
                            </List>
                            <Menu
                                className={classes.menuItem}
                                id="function-menu"
                                anchorEl={anchorEl6}
                                open={open6}
                                onClose={handleClose6}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                }}
                            >
                                {moreOption.map((option, index) => (
                                    <MenuItem
                                        className={classes.menuItem}
                                        key={option}
                                        disabled={index === 0}
                                        selected={index === selectedMoreDataIndex}
                                        onClick={(event) => handleMoreDataItemClick(event, index)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </span>
                )}
                { showViewDimMenu && (
                        <span className={classes.functionBox}>
                        <Box
                            ml={2}
                            sx={{ width: '50%',
                                maxWidth: 200,
                            }}
                        >
                            <List
                                component="nav"
                                aria-label="Functions"
                            >
                                <ListItem
                                    className={classes.menuList}
                                    button
                                    id="lock-button"
                                    aria-haspopup="listbox"
                                    onClick={handleClickListItem7}
                                >
                                    <ListItemText
                                        primary={view_dimension_options[selectedViewDimIndex]}
                                        secondary="view dimension"
                                    />
                                </ListItem>
                            </List>
                            <Menu
                                className={classes.menuItem}
                                id="function-menu"
                                anchorEl={anchorEl7}
                                open={open7}
                                onClose={handleClose7}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                }}
                            >
                                {view_dimension_options.map((option, index) => (
                                    <MenuItem
                                        className={classes.menuItem}
                                        key={option}
                                        disabled={index === 0}
                                        selected={index === selectedViewDimIndex}
                                        onClick={(event) => handleViewDimItemClick(event, index)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </span>
                )}
                </div>
                { (showFunctionMenu || showDataType || showTimeDimMenu) && (
                    <hr style={{margin: '10px 0px 0px'}}/>
                )}
                { showPeriodBox && (
                    <div className={classes.periodBox}>
                        <div className={classes.title}>Period:</div>
                        { showPeriodMenu && (
                            <span className={classes.functionBox}>
                            <Box
                                ml={2}
                                sx={{ width: '50%',
                                    maxWidth: 200,
                                }}
                            >
                                <List
                                    component="nav"
                                    aria-label="Functions"
                                >
                                    <ListItem
                                        className={classes.menuList}
                                        button
                                        id="lock-button"
                                        aria-haspopup="listbox"
                                        onClick={handleClickListItem4}
                                    >
                                        <ListItemText
                                            primary={periodOption[periodIndex]}
                                            secondary="unit"
                                        />
                                    </ListItem>
                                </List>
                                <Menu
                                    className={classes.menuItem}
                                    id="function-menu"
                                    anchorEl={anchorEl4}
                                    open={open4}
                                    onClose={handleClose4}
                                    MenuListProps={{
                                        'aria-labelledby': 'lock-button',
                                        role: 'listbox',
                                    }}
                                >
                                    {periodOption.map((option, index) => (
                                        <MenuItem
                                            className={classes.menuItem}
                                            key={option}
                                            disabled={index === 0}
                                            selected={index === periodIndex}
                                            onClick={(event) => handlePeriodMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </span>
                        )}
                        { showTimeDimMenu && (
                            <span className={classes.functionBox}>
                        <Box
                            ml={2}
                            sx={{ width: '50%',
                                maxWidth: 200,
                            }}
                        >
                            <List
                                component="nav"
                                aria-label="Functions"
                            >
                                <ListItem
                                    className={classes.menuList}
                                    button
                                    id="lock-button"
                                    aria-haspopup="listbox"
                                    onClick={handleClickListItem8}
                                >
                                    <ListItemText
                                        primary={time_dimension_options[selectedTimeDimIndex]}
                                        secondary="time dimension"
                                    />
                                </ListItem>
                            </List>
                            <Menu
                                className={classes.menuItem}
                                id="function-menu"
                                anchorEl={anchorEl8}
                                open={open8}
                                onClose={handleClose8}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                }}
                            >
                                {time_dimension_options.map((option, index) => (
                                    <MenuItem
                                        className={classes.menuItem}
                                        key={option}
                                        disabled={index === 0}
                                        selected={index === selectedTimeDimIndex}
                                        onClick={(event) => handleTimeDimItemClick(event, index)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </span>
                        )}
                        { showLengthMenu && (
                            <span className={classes.functionBox}>
                                <Box
                                    ml={2}
                                    sx={{ width: '50%',
                                        maxWidth: 200,
                                    }}
                                >
                                    <List
                                        component="nav"
                                        aria-label="Functions"
                                    >
                                        <ListItem
                                            className={classes.menuList}
                                            button
                                            id="lock-button"
                                            aria-haspopup="listbox"
                                            onClick={handleClickListItem5}
                                        >
                                            <ListItemText
                                                primary={length_options[lengthIndex]}
                                                secondary="length"
                                            />
                                        </ListItem>
                                    </List>
                                    <Menu
                                        className={classes.menuItem}
                                        id="function-menu"
                                        anchorEl={anchorEl5}
                                        open={open5}
                                        onClose={handleClose5}
                                        MenuListProps={{
                                            'aria-labelledby': 'lock-button',
                                            role: 'listbox',
                                        }}
                                    >
                                        {length_options.map((option, index) => (
                                            <MenuItem
                                                className={classes.menuItem}
                                                key={option}
                                                disabled={index === 0}
                                                selected={index === lengthIndex}
                                                onClick={(event) => handleLengthMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            </span>
                        )}
                    <hr style={{margin: '5px'}}/>
                    </div>
                )}
                {progress ? (
                    <div className={classes.circularProgressDiv}>x
                        <CircularProgress className={classes.circularProgressTop} />
                        <div className={classes.waitingMsg}>Please wait...</div>
                    </div>
                ) : (
                    <div>
                        { showChartView && (
                            <div className={classes.chartBox} style={{maxWidth : chartwidth}}>
                                <div className={classes.tabletitle}>{function_options[selectedCategoryIndex] + " Table"}
                                    {showCommentView && (
                                    <Tooltip title="Click to show explanation">
                                        <IconButton className={classes.infoButton} aria-label="strategyDetail" size="small" onClick={() => handleOpenInfo ()}>
                                            <InfoIcon fontSize="default" /><small style={{marginLeft:'-4px'}}>Help</small>
                                            {/*<small>info</small>*/}
                                        </IconButton>
                                    </Tooltip>
                                    )}
                                </div>
                                <div>
                                    <DataTable
                                        // title={function_options[selectedFunctionIndex][selectedDataIndex] + " Table"}
                                        data={idaTable}
                                        columns={idaColumns}
                                        fixedHeaderScrollHeight="60vh"
                                        fixedHeader
                                        highlightOnHover
                                        striped
                                        defaultSortField={1}
                                        sortIcon={<SortIcon />}
                                        noHeader={true}
                                        overflowY={true}
                                        overflowYOffset="0px"
                                    />
                                    {/*<img className={classes.imageBox} src={backtestimg} />*/}
                                </div>
                                <hr style={{margin: '5px'}}/>
                            </div>
                        )}
                        { showRankingView && (
                            <div style={{maxWidth : 'auto'}}>
                                <div>
                                    <DataTable
                                        className={classes.rankBox}
                                        data={rankTable}
                                        columns={rankingColumns}
                                        noHeader={true}
                                    />
                                </div>
                                <hr style={{margin: '5px'}}/>
                            </div>
                        )}
                    </div>
                )}
                { showOverallView && (
                    <>
                        <div className={classes.commentBox} style={{maxWidth : 'auto'}}>
                            <CompleteAnalysisView
                                symbol={searchInput.toUpperCase()}
                                isETF={isETF}
                                refresh={reload}
                                sectorETF={sectorETF}
                            />
                        </div>
                    </>
                )}
                { showCommentView && (
                    <>
                        <div className={classes.commentBox} style={{maxWidth : chartwidth}}>
                            {ReactHtmlParser(String(comment))}
                        </div>
                    </>
                )}
                { showExplanation && (
                    <>
                        <div className={classes.overlay} onClick={onClose}/>
                        <div className={classes.explanation} onClick={onClose}>
                            <div style={{textAlign:'center'}}><span><InfoIcon fontSize="default" /></span><span style={{verticalAlign:'top', fontSize:'18px'}}>Help</span></div>
                            {explanationMsg}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
