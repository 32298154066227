// handle the various http status codes.
import axios from 'axios';
import qs from 'query-string';
import { logout } from './user.utils';
export const JSON_CONTENT_TYPE = { 'content-Type': 'application/json' };
export const FORM_CONTENT_TYPE = { 'content-type': 'application/x-www-form-urlencoded' };
// import { sanitize } from './gpSanitize.utils.js';

/**
 * Alias for the axios.  Axios will automatically reject
 * the promise if the http status is a non-2xx.
 *
 * @param {string|axios.config} input
 * @param {axios.config} config - https://github.com/axios/axios#request-config
 * @property {function(cancel)} config.receiveCancel - a callback that receives a
 * `cancel` executor function which, when called, will cancel the request
 *
 * @example
 * // canceling a request example:
 * let cancel;
 * fetch('some/endpoint', { receiveCancel: (c) => cancel = c }).then( ... );
 * // something happened and you want to cancel the request
 * cancel();
 */
export function fetch(input, config = {}) {
  if (config.receiveCancel) {
    config.cancelToken = new axios.CancelToken(config.receiveCancel);
  }
  return axios(input, config).catch(handleErrors);
}

/**
 * Convenience function to consume JSON REST APIs.
 * Mirrors the axios API: https://github.com/axios/axios
 *
 * @param {string|axios.config} input
 * @param {axios.config} config - https://github.com/axios/axios#request-config
 * @property {function(cancel)} config.receiveCancel - a callback that receives a
 * `cancel` executor function which, when called, will cancel the request
 *
 * @example
 * // canceling a request example:
 * let cancel;
 * fetchJSON('some/endpoint', { receiveCancel: (c) => cancel = c }).then( ... );
 * // something happened and you want to cancel the request
 * cancel();
 * @property {boolean} config.noSanitize - boolean flag to determine if a response
 * should undergo sanitization via nsaSanitize.utils.js.
 *
 */
export function fetchJSON(input, config = {}) {
  if (config.receiveCancel) {
    config.cancelToken = new axios.CancelToken(config.receiveCancel);
  }

  return fetch(input, config).then((resp) => resp);
}

/**
 * Convenience function to consume POST JSON REST APIs.
 * The axios API: https://github.com/axios/axios/blob/master/README.md
 *
 * @param {string|axios.url} input
 * @param {object|axios.data} data input
 * @param {object|axios.config} config (optional) - https://github.com/axios/axios#request-config
 */
export function postJSON(input, data, config = {}) {
  if (config.headers) {
    config.headers = { ...JSON_CONTENT_TYPE, ...config.headers };
  } else {
    config.headers = JSON_CONTENT_TYPE;
  }
  return fetchJSON(input, { ...config, ...{ method: 'post', data } });
}

export function postFORM(input, data, config = {}) {
  if (config.headers) {
    config.headers = { ...FORM_CONTENT_TYPE, ...config.headers };
  } else {
    config.headers = FORM_CONTENT_TYPE;
  }
  return fetchJSON(input, { ...config, ...{ method: 'post', data } });
}

export function getFORM(input, config = {}) {
  if (config.headers) {
    config.headers = { ...FORM_CONTENT_TYPE, ...config.headers };
  } else {
    config.headers = FORM_CONTENT_TYPE;
  }
  return fetch(qs.stringify(input), config).then((resp) => resp);
}

export function putJSON(input, data, config = {}) {
  if (config.headers) {
    config.headers = { ...JSON_CONTENT_TYPE, ...config.headers };
  } else {
    config.headers = JSON_CONTENT_TYPE;
  }
  return fetchJSON(input, { ...config, ...{ method: 'put', data } });
}

export function putJSONNoPayload(input, data, config = {}) {
  if (config.headers) {
    config.headers = { ...JSON_CONTENT_TYPE, ...config.headers };
  } else {
    config.headers = JSON_CONTENT_TYPE;
  }
  return fetchJSON(input, { method: 'put', data });
}

export function deleteJSON(input, data, config = {}) {
  if (config.headers) {
    config.headers = { ...JSON_CONTENT_TYPE, ...config.headers };
  } else {
    config.headers = JSON_CONTENT_TYPE;
  }
  return fetchJSON(input, { ...config, ...{ method: 'delete', data } });
}

// axios.delete(URL, {
//   headers: {
//     Authorization: authorizationToken
//   },
//   data: {
//     source: source
//   }
// });

/**
 * Error handling function to handle the GET/POST JSON REST response errors.
 * The axios API: https://github.com/axios/axios/blob/master/README.md
 *
 * @param {object|axios error response}  input
 */
function handleErrors(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    console.debug(error.response.data);
    console.debug(error.response.status);
    console.debug(error.response.headers);
    console.debug('Error response = %o', error.response);
    if (error.response.data.errMsg === '用户token已失效或不存在') {
      console.debug('####Invalid Token');
      logout();
    }
    throw error.response;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.debug('Error request = %o', error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.debug('Error message = %o', error.message);
  }
  console.debug(error.config);
  throw error;
}

/**
 * Parse error to return error message detail
 */
export function getErrorMessage(error) {
  if (error.response && error.response.data) {
    return error.response.data.response.detail;
  } else if (error.request) {
    return error.request;
  }
  return error.response.message;
}
