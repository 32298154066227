import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import { getEstDateTime } from '../../utils/common';

const styles = {
    tablePadding: {
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 15,
        paddingBottom: 15,
    },
    noWrap: {
        whiteSpace: 'noWrap',
    },
    summary: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        backgroundColor: 'whitesmoke',
        paddingTop: '35px',
        padding: '5px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        height: 'auto',
        overflow: 'auto',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        opacity: 0.4,
    },
    ol: {
        padding: 0,
        margin: 'd0 0 0 1em',
    },
    ul: {
        padding: 0,
        margin: 'd0 0 0 1em',
    },
    button: {
        position: 'absolute',
        right: '15px',
        top: '5px',
        margin: '5px',
        backgroundColor: 'whitesmoke',
        fontFamily: 'Arial, Helvetica',
        borderRadius: '5px',
    },
};

const useStyles = makeStyles(styles);

function InvestmentSummary({onClick, aqsSummary}) {

    const moneyFormat = (value) => {
        if (value) {
           var data = Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(value);
           return '$' + data;
        } else {
          return '';
        }
    }

    const classes = useStyles();

    const columns = [
        {
            name: 'Account Type',
            selector: 'trackAccountType',
            sortable: false,
            wrap: true,
            width: '110px',
        },
        {
            name: 'Account Name',
            selector: 'trackAccountName',
            sortable: false,
            wrap: true,
            width: '120px',
        },
        {
            name: 'Account #',
            selector: 'brokerAccount',
            sortable: false,
            right: false,
            wrap: false,
            width: '120px',
        },
        {
            name: 'Type',
            selector: 'type',
            sortable: false,
            right: false,
            wrap: true,
            width: '120px',
        },
        {
            name: 'Name of My Portfolio or Account',
            selector: 'portfolioName',
            sortable: false,
            right: false,
            wrap: true,
            width: '180px',
        },
        {
            name: 'Name of Subscribed Strategy or Model Account',
            selector: 'subscriptionName',
            sortable: false,
            right: false,
            wrap: true,
            width: '180px',
        },
        {
            name: 'Risk Level',
            selector: 'riskLevel',
            sortable: false,
            right: false,
            wrap: true,
            width: '110px',
            cell: (row) => {
                return (
                   <div
                       style={
                           row.riskLevel === 'Subtotal'
                               ? { fontWeight: 500 }
                               : {}
                       }
                    >
                       {row.riskLevel}
                   </div>
                );
            },
        },
        {
            name: 'Total Account Value',
            selector: 'totalValue',
            sortable: false,
            right: true,
            wrap: false,
            width: '125px',
            cell: (row) => {
                return (
                    <div>
                        {moneyFormat(row.totalValue)}
                    </div>
                );
            },
        },
        {
            name: 'Total Position Value',
            selector: 'portfolioValue',
            sortable: false,
            right: true,
            wrap: false,
            width: '125px',
            cell: (row) => {
                return (
                    <div>
                        {moneyFormat(row.portfolioValue)}
                    </div>
                );
            },
        },
        {
            name: 'Positions / Account',
            selector: 'positionPercentage',
            sortable: false,
            right: true,
            wrap: false,
            width: '100px',
            cell: (row) => {
                var percentage = row.positionPercentage?row.positionPercentage.toFixed(2) + '%':'';
                return (
                    <div>
                        {percentage}
                    </div>
                );
            },
        },
        {
            name: 'Total Cash',
            selector: 'totalCash',
            sortable: false,
            right: true,
            wrap: false,
            width: '125px',
            cell: (row) => {
                return (
                    <div>
                        {moneyFormat(row.totalCash)}
                    </div>
                );
            },
        },
        {
            name: 'Total Return',
            selector: 'totalReturn',
            sortable: false,
            right: true,
            width: '150px',
            cell: (row) => {
                // var totalReturn = row.totalReturn?row.totalReturn.toFixed(2):'';
                return (
                    <div>
                        {row.totalReturn}
                    </div>
                );
            },
        },
        {
            name: 'Last Update',
            selector: 'lastUpdate',
            sortable: false,
            right: false,
            wrap: true,
            width: '120px',
            cell: (row) => {
                let date = row.lastUpdate?getEstDateTime( row.lastUpdate.substring(0,16)):'';
                return (
                    <div>
                        {date}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className={classes.overlay}/>
            <div className={classes.summary}>
                <button className={classes.button} onClick={onClick}>Close</button>
                <div className={classes.tablePadding}>
                    <DataTable
                        title="My Investment Summary"
                        data={aqsSummary}
                        columns={columns}
                        fixedHeaderScrollHeight="50vh"
                        fixedHeader
                        highlightOnHover
                        striped
                        defaultSortFieldId={1}
                        sortIcon={<SortIcon />}
                    />
                </div>
            </div>
        </>
    );
}

export default InvestmentSummary;
