import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { environment } from '../../environments/environment';
import {getEstDateTime, getToken, getValidDateTime, moneyNumberHandle} from '../../utils/common';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../components/CustomButtons/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardBody from '../../components/Card/CardBody.js';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewListIcon from '@material-ui/icons/ViewList';
import {
    AqmStrategy,
    getAQMPerformance,
    getMyAQMStrategyList,
    getAQMPosition,
    getSvg,
    getMyAQMData
} from "../../utils/strategy.utils";
import AQMPositionsView from './AQMPositions.view';
import noperf from "../../assets/img/noperf.svg";
import moment from "moment";

const styles = {
    cardCategoryWhite: {
        '&,& a,& a:hover,& a:focus': {
            color: 'rgba(255,255,255,.62)',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0',
        },
        '& a,& a:hover,& a:focus': {
            color: '#FFFFFF',
        },
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
            color: '#777',
            fontSize: '65%',
            fontWeight: '400',
            lineHeight: '1',
        },
    },
    label: {
        textTransform: 'capitalize',
        fontSize: '12px',
    },
    marketInfoPanel: {
        height: '65px',
        position: 'relative',
    },
    marketInfo: {
        paddingTop: '4px',
        borderRight: '1px solid #e0dede',
        marginTop: '6px',
        textAlign: 'center',
        display: 'inline-block',
        minWidth: '150px',
    },
    marketVal: {
        display: 'inline-block',
        fontSize: '18px',
        position: 'relative',
        top: '1px',
        fontWeight: 'bold',
    },
    marketLabel: {
        color: '#808080',
        fontSize: '12px',
        marginTop: '3px',
    },
    cardCategoryTitle: {
        fontWeight: 400,
        color: '#000',
        fontSize: 22,
        padding: 12,
    },
    performanceChart: {
        border: '1px solid black',
        height: 350,
        padding: 12,
    },
    positiveColor: {
        color: 'green',
    },
    negativeColor: {
        color: 'red',
    },
    waitingMsg: {
        fontWeight: 400,
        fontSize: 20,
        textAlign: 'center',
        marginTop: 70,
        marginLeft: -80,
    },
    marketStatusOpen: {
        fontWeight: 'bold',
        fontSize: 16,
        color: 'green',
    },
    marketStatusClose: {
        fontWeight: 'bold',
        fontSize: 16,
        color: 'rgb(143, 63, 63)',
    },
    cardMargin: {
        marginTop: 20,
    },
    portfolioNameFont: {
        fontWeight: 400,
    },
    portfolioUpdateTime: {
        fontWeight: 400,
        marginLeft: 10,
    },
    chartTitle: {
        fontWeight: 400,
        fontSize: 20,
        marginTop: 10,
        marginLeft: 10,
    },
    noLiveSignals: {
        height: 160,
        fontWeight: 400,
        fontSize: 14,
    },
    noLiveSignalsMsg: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 50,
    },
    circularProgressTop: {
        marginTop: 20,
    },
    circularProgressDiv: {
        border: '1px solid 1px solid #d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
    },
    imgbox: {
        position: 'relative',
        backgroundColor: '#fff',
        // boxShadow: '0 1px 0px 1px #aaa',
        boxShadow: '0 3px 2px -2px grey',
        padding: '6px',
        borderRadius: '5px',
        fontSize: 14,
    },
    img: {
        backgroundColor: 'transparent',
        objectFit: 'fill',
        width: '100%',
        border: 'solid grey 1px',
    },
    boxtitle: {
        float: 'left',
        fontSize: '22px',
        color: '#000000de',
        paddingLeft: '20px',
        margin: '5px 8px 10px',
        fontWeight: '400',
    },
    tableTrOdd: {
        backgroundColor: '#f9f9f9',
        fontWeight: 400,
    },
    tableTrEven: {
        fontWeight: 400,
    },

};

const useStyles = makeStyles(styles);

export default function AccountPosition() {
    const classes = useStyles();
    const [openStopPortfolioDlg, setOpenStopPortfolioDlg] = React.useState(false);
    const [progress, setProgress] = React.useState(true);
    const [trackingInfoImage, setTrackingInfoImage] = React.useState(null);
    const [strategyDetail, setStrategyDetail] = React.useState(null);
    const { state } = useLocation();
    const time = Math.floor(new Date().getTime() / 3600000);

    // const [portfolioOrders, setPortfolioOrders] = React.useState([]);
    const [portfolioPositions, setPortfolioPositions] = React.useState([]);
    // const [receivedPositionMsg, setReceivedPositionMsg] = React.useState(false);
    const [portfolioStats, setPortfolioStats] = React.useState({
        returns: '--',
        dollarpnl: '--',
        dailyReturn: '--',
        dailyPnl: '--',
        long: '--',
        cash: '--',
        portfolioValue: '--',
        sharpeStats: '--',
    });

    const [dailyReturn, setDailyReturn] = React.useState({});
    const [sharpeStats, setSharpeStats] = React.useState('--');
    // const [marketStatus, setMarketStatus] = React.useState('Market Closed');
    const [updateTime, setUpdateTime] = React.useState(null);
    const [longerThan5days, setLongerThan5days] = React.useState(false);
    const aqmId = state?.aqmOrSubscribeId ?? '';
    const aqmName = state?.aqmName ?? '';
    const marketType = state?.portfolioMarketType ?? 'US';
    const title = `-> Account Position `;
    let haveSignals: boolean = false;
    let opened = false;

    function updatePosition() {
        const params: AqmStrategy = {
            id: aqmId,
        };
        getAQMPosition(params)
            .then((response) => {
                const data = response && response?.data ? response.data : null;
                if (data.code === 200) {
                    console.log('positions data = %o', data.data);
                    setPortfolioPositions(data.data);
                }
            })
            .catch((error) => {
                console.debug(error);
                setProgress(false);
            });
    };

    useEffect(() => {
        // console.info('AQM prop %', state);
        // console.info('AQM ID %', aqmId);
        const params: AqmStrategy = {
            id: aqmId,
        };
        getMyAQMStrategyList(params)
            .then((response) => {
                const data = response && response?.data ? response.data : null;
                console.log('findAQM data = %o', data);

                if (data.code === 200) {
                    const aqmData = data.data.subscribes[0];
                    setStrategyDetail(aqmData);

                    const returns = (aqmData?.cumReturn * 100).toFixed(2) + '%';
                    const dailyreturn = (aqmData?.dailyReturn * 100).toFixed(2) + '%';
                    const dollarpnl = (aqmData?.netLiquidation - aqmData?.initLiquidationValue).toFixed(2);
                    const sharpe = aqmData?.sharpe? (aqmData?.sharpe).toFixed(2):'';
                    const long = (aqmData?.netLiquidation - aqmData?.totalCash).toFixed(2);
                    const cash = aqmData?.totalCash? (aqmData.totalCash).toFixed(2) : '--';
                    const portfolioValue = (aqmData?.lastPerfLiquidation).toFixed(2);
                    const time = getEstDateTime(getValidDateTime(aqmData.tradeDate, 'YYYY-MM-DD') + " 05:06");
                    const dailypnl = (aqmData?.netLiquidation * aqmData?.dailyReturn).toFixed(2);
                    const tradeDate = aqmData.tradeDate
                        ? moment(aqmData.tradeDate).tz("America/New_York").format('YYYY-MM-DD')
                        : 'N/A';
                    const activeTime = aqmData.activeTime
                        ? moment(aqmData.activeTime.replace(/\.\d+/, "").replace(/-/g,"/")).tz("America/New_York").format('YYYY-MM-DD')
                        : moment(aqmData.createTime.replace(/\.\d+/, "").replace(/-/g,"/")).tz("America/New_York").format('YYYY-MM-DD');
                    const trackingTime = `${activeTime}~${tradeDate}`;

                    const daysDiff = moment(tradeDate).diff(activeTime, 'days');
                    daysDiff > 5? setLongerThan5days(true): setLongerThan5days(false)

                    setUpdateTime(trackingTime);
                    setSharpeStats(sharpe);
                    setPortfolioStats({
                        returns: returns,
                        dollarpnl: dollarpnl,
                        dailyReturn: dailyreturn,
                        dailyPnl: dailypnl,
                        long: long,
                        cash: cash,
                        portfolioValue: portfolioValue,
                    });
                    setTrackingInfoImage(getSvg(aqmData.id, `.svg?t=${time}`, aqmData.subPath));
                    setProgress(false);
                }
            })
            .catch((error) => {
                console.debug(error);
                setProgress(false);
            });

        // get position
        updatePosition();
    }, []);

    const handleUpdate = () => {
        console.info('Update is pressed');
        updatePosition();
    };

    return (
        <div className="mainPagePanel">
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card style={{ marginTop: 10 }}>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div style={{ float: 'left' }}>
                                            <h4>
                                                <Link to="/admin/aqmModelAccount">AQM Accounts</Link>
                                                {title}
                                                <span className={classes.portfolioNameFont}>{`(${aqmName})`}</span>
                                                <span className={classes.portfolioUpdateTime}>
                                                    <small>
                                                        Period: {updateTime} (based on last closed data)
                                                    </small>
                                                </span>
                                            </h4>
                                        </div>                                        {/*<div style={{ float: 'right', marginTop: -10 }}>*/}
                                        {/*    <Button*/}
                                        {/*        onClick={handleUpdate}*/}
                                        {/*        color="info"*/}
                                        {/*        className={classes.label}*/}
                                        {/*    >*/}
                                        {/*        Update*/}
                                        {/*    </Button>*/}
                                        {/*</div>
*/}
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        {/*{receivedPortfolioMsg && (*/}
                                        <div className={classes.marketInfoPanel}>
                                            <div className={classes.marketInfo} style={{ marginLeft: -40 }}>
                                                <div className={classes.marketVal}>{portfolioStats.returns}</div>
                                                <div className={classes.marketLabel}>Total Return</div>
                                            </div>
                                            <div className={classes.marketInfo}>
                                                <div className={classes.marketVal}>{portfolioStats.dollarpnl}</div>
                                                <div className={classes.marketLabel}>Profit/Loss</div>
                                            </div>
                                            <div className={classes.marketInfo}>
                                                <div className={classes.marketVal}>{portfolioStats.dailyReturn}</div>
                                                <div className={classes.marketLabel}>Daily Return</div>
                                            </div>
                                            <div className={classes.marketInfo}>
                                                <div className={classes.marketVal}>{portfolioStats.dailyPnl}</div>
                                                <div className={classes.marketLabel}>Daily Profit/Loss</div>
                                            </div>
                                            {/*<div className={classes.marketInfo}>*/}
                                            {/*    <div className={classes.marketVal}>{sharpeStats}</div>*/}
                                            {/*    <div className={classes.marketLabel}>Sharpe</div>*/}
                                            {/*</div>*/}
                                            <div className={classes.marketInfo}>
                                                <div className={classes.marketVal}>{portfolioStats.long}</div>
                                                <div className={classes.marketLabel}>Long Exposure</div>
                                            </div>
                                            {/*<div className={classes.marketInfo}>*/}
                                            {/*    <div className={classes.marketVal}>{portfolioStats.short}</div>*/}
                                            {/*    <div className={classes.marketLabel}>Short Exposure</div>*/}
                                            {/*</div>*/}
                                            <div className={classes.marketInfo}>
                                                <div className={classes.marketVal}>{portfolioStats.cash}</div>
                                                <div className={classes.marketLabel}>Available Cash</div>
                                            </div>
                                            {/*{statsShowMargin && (*/}
                                            {/*    <div className={classes.marketInfo}>*/}
                                            {/*        <div className={classes.marketVal}>{portfolioStats.margin}</div>*/}
                                            {/*        <div className={classes.marketLabel}>Margin</div>*/}
                                            {/*    </div>*/}
                                            {/*)}*/}
                                            <div className={classes.marketInfo}>
                                                <div className={classes.marketVal}>{portfolioStats.portfolioValue}</div>
                                                <div className={classes.marketLabel}>Portfolio Value</div>
                                            </div>
                                            {/*<div className={classes.marketInfo}>*/}
                                            {/*    <div className={classes.marketVal}>{portfolioStats.varisk}</div>*/}
                                            {/*    <div className={classes.marketLabel}>VaR</div>*/}
                                            {/*</div>*/}
                                        </div>
                                        {/*)}*/}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: -20 }}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className={classes.imgbox}>
                                            <table width="100%">
                                                <tbody>
                                                <tr className={classes.boxtitle}>Performance</tr>
                                                <tr>
                                                    <td colSpan="4">
                                                        {' '}
                                                        <img
                                                            src={trackingInfoImage}
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src = noperf;
                                                            }}
                                                            alt="logo"
                                                            height="350"
                                                            className={classes.img}
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div style={{ padding: '3px' }}></div>
                                            <table className="table table-hover table-condensed table-striped table-bordered">
                                                <tr className={classes.tableTrOdd}>
                                                    <td width="30%"></td>
                                                    <td width="35%">AQM Model Account</td>
                                                    <td>S&P 500</td>
                                                </tr>
                                                <tr className={classes.tableTrEven}>
                                                    <td>Total Return</td>
                                                    <td>
                                                        {strategyDetail?.cumReturn === 0
                                                            ? '0%'
                                                            : longerThan5days && strategyDetail?.cumReturn
                                                                ? (strategyDetail.cumReturn * 100).toFixed(2) + '%'
                                                                : 'N/A'}
                                                    </td>
                                                    <td>
                                                        {strategyDetail?.benchmarkCAGR === 0
                                                            ? '0%'
                                                            : strategyDetail?.benchmarkCumReturn
                                                                ? (strategyDetail.benchmarkCumReturn * 100).toFixed(2) + '%'
                                                                : 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr className={classes.tableTrOdd}>
                                                    <td>CAGR</td>
                                                    <td>
                                                        { strategyDetail?.algoCAGR === 0
                                                            ? '0%'
                                                            : longerThan5days && strategyDetail?.algoCAGR
                                                                ? (strategyDetail.algoCAGR * 100).toFixed(2) + '%'
                                                                : 'N/A'}
                                                    </td>
                                                    <td>
                                                        {strategyDetail?.benchmarkCAGR === 0
                                                            ? '0%'
                                                            : strategyDetail?.benchmarkCAGR
                                                                ? (strategyDetail.benchmarkCAGR * 100).toFixed(2) + '%'
                                                                : 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr className={classes.tableTrEven}>
                                                    <td>Alpha</td>
                                                    <td>
                                                        { strategyDetail?.alpha === 0
                                                            ? '0'
                                                            : longerThan5days && strategyDetail?.alpha
                                                                ? strategyDetail?.alpha.toFixed(2)
                                                                : 'N/A'}
                                                    </td>
                                                    <td>0</td>
                                                </tr>
                                                <tr className={classes.tableTrOdd}>
                                                    <td>Beta</td>
                                                    <td>
                                                        {strategyDetail?.beta === 0
                                                            ? '0'
                                                            : longerThan5days && strategyDetail?.beta
                                                                ? strategyDetail?.beta.toFixed(2)
                                                                : 'N/A'}
                                                    </td>
                                                    <td>1</td>
                                                </tr>
                                                <tr className={classes.tableTrEven}>
                                                    <td>Sharpe</td>
                                                    <td>
                                                        {strategyDetail?.sharpe === 0
                                                            ? '0'
                                                            : longerThan5days && strategyDetail?.sharpe
                                                                ? strategyDetail?.sharpe.toFixed(2)
                                                                : 'N/A'}
                                                    </td>
                                                    <td>
                                                        {strategyDetail?.benchmarkSharpe === 0
                                                            ? '0'
                                                            : strategyDetail?.benchmarkSharpe
                                                                ? strategyDetail?.benchmarkSharpe.toFixed(2)
                                                                : 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr className={classes.tableTrOdd}>
                                                    <td>Sortino</td>
                                                    <td>
                                                        {strategyDetail?.sortino === 0
                                                            ? '0'
                                                            : longerThan5days && strategyDetail?.sortino
                                                                ? strategyDetail?.sortino.toFixed(2)
                                                                : 'N/A'}
                                                    </td>
                                                    <td>
                                                        {strategyDetail?.benchmarkSortino === 0
                                                            ? '0'
                                                            : strategyDetail?.benchmarkSortino
                                                                ? strategyDetail?.benchmarkSortino.toFixed(2)
                                                                : 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr className={classes.tableTrEven}>
                                                    <td>Volatility</td>
                                                    <td>
                                                        {strategyDetail?.algoVolatility === 0
                                                            ? '0%'
                                                            : longerThan5days && strategyDetail?.algoVolatility
                                                                ? (strategyDetail.algoVolatility * 100).toFixed(2) + '%'
                                                                : 'N/A'}
                                                    </td>
                                                    <td>
                                                        {strategyDetail?.benchmarkVolatility === 0
                                                            ? '0%'
                                                            : strategyDetail?.benchmarkVolatility
                                                                ? (strategyDetail.benchmarkVolatility * 100).toFixed(2) + '%'
                                                                : 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr className={classes.tableTrOdd}>
                                                    <td>MaxDD</td>
                                                    <td>
                                                        {strategyDetail?.trackingReport?.maxDrawdown === 0
                                                            ? '0%'
                                                            : longerThan5days && strategyDetail?.maxDrawdown
                                                                ? (strategyDetail.maxDrawdown * 100).toFixed(2) + '%'
                                                                : 'N/A'}
                                                    </td>
                                                    <td>
                                                        {strategyDetail?.benchmarkMaxDrawdown === 0
                                                            ? '0%'
                                                            : strategyDetail?.benchmarkMaxDrawdown
                                                                ? (strategyDetail?.benchmarkMaxDrawdown * 100).toFixed(2) + '%'
                                                                : 'N/A'}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        {/*{receivedPositionMsg && (*/}
                                        <AQMPositionsView
                                            positionData={portfolioPositions}
                                        />
                                        {/*)}*/}
                                    </GridItem>
                                    {/*<GridItem xs={12} sm={12} md={12}>*/}
                                    {/*    <PortfolioMonitorOrdersView orderData={portfolioOrders} />*/}
                                    {/*</GridItem>*/}
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
