import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContentText from "@material-ui/core/DialogContentText";
import PropTypes from 'prop-types';
import { stopAQMStrategy, AqmStrategy } from "../../utils/strategy.utils";
import AlertView from '../Alert/Alert.view';
import { stringUtils } from "../../utils/string.utils";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  dlgWidth: {
    width: 450,
  },
  radioGroupTop: {
    marginTop: -10,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: 'none' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles(styles);
export default function StopAqmDialogView(props) {
  const classes = useStyles();
  const { strategyId, strategyName } = props;
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });
  const stopAqmDialogTitle = `Stop AQM - ${strategyId}`;

  const handleStopAqm = () => {
    const params: AqmStrategy = {
      aqmId: strategyId,
    };
    console.debug('handleStopAqm id = %s', strategyId);
    stopAQMStrategy(params)
      .then((response) => {
        console.debug('StopAqmDialog.view: responseData = %o', response);
        props.handleStopDialogClose(false);
        window.location.reload();
      })
      .catch((error) => {
        console.debug(error);
        setOpenAlertMsg({
          alert: true,
          alertContent: error.data.errMsg,
          severity: 'error',
        });
      });
  };

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  return (
    <div>
      {openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      <Dialog
        open={props.openStopAqmDlg}
        onClose={props.handleStopDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className={classes.dlgWidth}
          id="customized-dialog-title"
          onClose={props.handleStopDialogClose}
        >
          {stopAqmDialogTitle}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div>
              <span>Are you sure you want to stop this AQM?</span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleStopDialogClose} color="primary" autoFocus>
            No
          </Button>
          <Button onClick={handleStopAqm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

StopAqmDialogView.propTypes = {
  openStopAqmDlg: PropTypes.bool,
  handleStopDialogClose: PropTypes.func,
  strategyId: PropTypes.number,
  strategyName: PropTypes.string,
};
