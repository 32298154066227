import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { environment } from 'environments/environment';
import { getEstDateTime } from '../../utils/common';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AddEditIBAccountDialog from 'components/Dialog/AddEditIBAccount.view';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.view';
import RefreshIBConfirmationDialog from 'components/Dialog/RefreshIBConfirmationDialog.view';
import LinkAccountStatusDialog from 'components/Dialog/LinkAccountStatusDialog.view';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddCircleOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import SyncIcon from '@material-ui/icons/Sync';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import UpgradeIcon from '@material-ui/icons/Publish';
import MoneyIcon from '@material-ui/icons/LocalAtm';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import FolderIcon from '@material-ui/icons/Folder';
import iPapaIBSetupPdf from 'assets/help/Interactive Brokers Account Setup Guide.pdf';
import { numberFormat } from 'numberFormat';
import BrokerAccountPosition from './BrokerAccountPosition.view.tsx';

import {
  getTrackAccount,
  getAccountBrokerProps,
  deleteTrackAccount,
  refreshTrackAccount,
  getTdAuthUrl,
  getWebapiRefreshToken,
  getAccountPosition
} from '../../utils/user.utils';
import { getWebapiAuthCallback } from '../../utils/billing.utils';
import {getESTTimeZone} from "../../utils/common";
import $ from "jquery";
import Newsletter from "../Dashboard/Newsletter.view";
import AlertView from "../../components/Alert/Alert.view";

function createData(
  name,
  accountType,
  nickName,
  accountNum,
  maxUsableCash,
  totalCash,
  netLiquidation,
  refreshTime,
  action,
  broker,
  id
) {
  return {
    name,
    accountType,
    nickName,
    accountNum,
    maxUsableCash,
    totalCash,
    netLiquidation,
    refreshTime,
    action,
    broker,
    id,
  };
}

let showRelinkNote = false;
let needRelink = false;
let rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, align: 'left', label: 'User Name' },
  { id: 'accountType', numeric: false, disablePadding: false, align: 'left', label: 'Account Type' },
  { id: 'nickName', numeric: false, disablePadding: false, align: 'left', label: 'Account Nickname' },
  { id: 'accountNum', numeric: false, disablePadding: false, align: 'left', label: 'Account Number' },
  { id: 'maxUsableCash', numeric: true, disablePadding: false, align: 'right', label: 'Max Usable Cash' },
  { id: 'totalCash', numeric: true, disablePadding: false, align: 'right', label: 'Total Cash' },
  { id: 'netLiquidation', numeric: true, disablePadding: false, align: 'right', label: 'Net Liquidation' },
  { id: 'refreshTime', numeric: false, disablePadding: false, align: 'left', label: 'Refresh Time' },
  { id: 'action', numeric: true, disablePadding: false, align: 'center', label: 'Actions' },
  { id: 'id', numeric: false, disablePadding: false, align: 'right', label: 'Id', class: 'hide' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding={'default'}></TableCell>
        {/*<TableCell padding="checkbox">*/}
        {/*  <Checkbox*/}
        {/*    indeterminate={numSelected > 0 && numSelected < rowCount}*/}
        {/*    checked={rowCount > 0 && numSelected === rowCount}*/}
        {/*    onChange={onSelectAllClick}*/}
        {/*    inputProps={{ 'aria-label': 'select all desserts' }}*/}
        {/*  />*/}
        {/*</TableCell>*/}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            className={headCell.class === 'hide' ? classes.visuallyHidden : ''}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/*<TableSortLabel*/}
            {/*  active={orderBy === headCell.id}*/}
            {/*  direction={orderBy === headCell.id ? order : 'asc'}*/}
            {/*  onClick={createSortHandler(headCell.id)}*/}
            {/*>*/}
              {headCell.label}
            {/*  {orderBy === headCell.id ? (*/}
            {/*    <span className={classes.visuallyHidden}>*/}
            {/*      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}*/}
            {/*    </span>*/}
            {/*  ) : null}*/}
            {/*</TableSortLabel>*/}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight1:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
    // className={clsx(classes.root, {
    //   [classes.highlight]: numSelected > 0,
    // })}
    >
      {numSelected > 10000 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <div style={{ width: '100%' }}>
          <div style={{ float: 'left', width: '40%' }}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              Paper Trading and Money Trading Accounts
            </Typography>
          </div>
          <div style={{ float: 'right' }}>
            <span>
              <a
                style={{ color: '#00acc1', fontWeight: 400 }}
                href={iPapaIBSetupPdf}
                target="_blank"
                rel="noreferrer"
              >
                How to set up an Interactive Brokers account for trading by iPIPA?
              </a>
            </span>
          </div>
        </div>
      )}

      {numSelected > 10000 ? (
        <div style={{ width: 200 }}>
          <Tooltip title="Edit">
            <IconButton aria-label="edit">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <div></div>
        // <Tooltip title="Filter list">
        //   <IconButton aria-label="filter list">
        //     <FilterListIcon />
        //   </IconButton>
        // </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleAddDialogClick: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionButtons: {
    "& .MuiIconButton-root": {
      padding: '5px',
    }
  },
}));

export default function IBWebApiAccountTableView(props) {
  const classes = useStyles();
  const { oauth_token, oauth_verifier, url, linkStatus } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(300);
  const [openDlg, setOpenDlg] = React.useState(false);
  const [openSchwabDlg, setSchwabOpenDlg] = React.useState(false);
  const [openPIPADlg, setPIPAOpenDlg] = React.useState(false);
  const [tradingType, setTradingType] = React.useState('Paper Trade');
  const [dlgType, setDlgType] = React.useState('Add');
  const [dlgData, setDlgData] = React.useState(null);
  const [openDeleteDlg, setOpenDeleteDlg] = React.useState(false);
  const [openRefreshDlg, setOpenRefreshDlg] = React.useState(false);
  const [openErrorDlg, setOpenErrorDlg] = React.useState(false);
  const [allIBAccountData, setAllIBAccountData] = React.useState(null);
  const [hasPIPAACcount, setHasPIPAACcount] = React.useState(false);
  const [openRelinkDlg, setOpenRelinkDlg] = React.useState(false);
  const [positionPopup, setPositionPopup] = React.useState(false);
  const [positionData, setPositionData] = React.useState([]);
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });

  environment.systemConfig = {
    brokers: { US: ['IB', 'IBWEBAPI', 'SCHWAB', 'PIPA'], CN: ['XIANGCAI'] },
    cnMarket: false,
    usMarket: true,
  };
  const brokerTypes = environment.systemConfig['brokers']['US'];
  const IBType = 'IBWebAPI';
  const SchwabType = 'SchwabAccount';
  const PIPAType = 'PIPAAccount';
  //console.debug('IBAccount.view: brokerTypes = %o', brokerTypes);

  const fetchData = React.useCallback(() => {
    getTrackAccount()
      .then((response) => {
        const tracking = response && response?.data ? response.data : null;
        //console.debug('*****IBAccount.view: getTrackAccount data = %o', tracking);
        getAccountBrokerProps()
          .then((response) => {
            const accountProp = response && response?.data ? response.data?.data : null;
            const paperAccounts = tracking.data.filter((val) => {
              //console.log('get Trackaccount: res = %o', response);
              return val.tradeType.indexOf('SIMU_IB') === 0 && brokerTypes.indexOf(val.broker) !== -1;
            });
            //console.debug('before IBAccount paperAccounts = %o', paperAccounts);
            const moneyAccounts = tracking.data.filter((val) => {
              return (
                  val.tradeType.indexOf('MONEY_IB') === 0 && brokerTypes.indexOf(val.broker) !== -1
              );
            });
            //console.debug('before IBAccount moneyAccounts = %o', moneyAccounts);
            const schwabAccounts = tracking.data.filter((val) => {
              return (
                  val.tradeType.indexOf('MONEY_SCHWAB') === 0 && brokerTypes.indexOf(val.broker) !== -1
              );
            });
            //console.debug('before IBAccount moneyAccounts = %o', moneyAccounts);
            const pipaAccounts = tracking.data.filter((val) => {
              return (
                  val.tradeType.indexOf('SIMU_PIPA') === 0 && brokerTypes.indexOf(val.broker) !== -1
              );
            });

            for (let i = 0; i < paperAccounts.length; i++) {
              for (let j = 0; j < accountProp.length; j++) {
                if (accountProp[j].trackAccountId != paperAccounts[i].id)
                  continue;
                if (
                    accountProp[j].propKey === 'totalCashValue'
                ) {
                  paperAccounts[i].totalCash = accountProp[j].propValue;
                  paperAccounts[i].refreshTime = accountProp[j].refreshTime;
                }
                if (
                    accountProp[j].propKey === 'NetLiquidation'
                ) {
                  paperAccounts[i].netLiquidation = accountProp[j].propValue;
                }
              }
            }
            //console.debug('after paperAccounts = %o', paperAccounts);
            for (let i = 0; i < moneyAccounts.length; i++) {
              for (let j = 0; j < accountProp.length; j++) {
                if (accountProp[j].trackAccountId != moneyAccounts[i].id)
                  continue;
                if (
                    accountProp[j].propKey === 'totalCashValue'
                ) {
                  moneyAccounts[i].totalCash = accountProp[j].propValue;
                  moneyAccounts[i].refreshTime = accountProp[j].refreshTime;
                }
                if (
                    accountProp[j].propKey === 'NetLiquidation'
                ) {
                  moneyAccounts[i].netLiquidation = accountProp[j].propValue;
                }
              }
            }

            for (let i = 0; i < schwabAccounts.length; i++) {
              for (let j = 0; j < accountProp.length; j++) {
                if (accountProp[j].trackAccountId != schwabAccounts[i].id)
                  continue;
                if (
                    accountProp[j].propKey === 'totalCashValue'
                ) {
                  schwabAccounts[i].totalCash = accountProp[j].propValue;
                  schwabAccounts[i].refreshTime = accountProp[j].refreshTime;
                }
                if (
                    accountProp[j].propKey === 'NetLiquidation'
                ) {
                  schwabAccounts[i].netLiquidation = accountProp[j].propValue;
                }
              }
            }

            for (let i = 0; i < pipaAccounts.length; i++) {
              for (let j = 0; j < accountProp.length; j++) {
                if (accountProp[j].trackAccountId != pipaAccounts[i].id)
                  continue;
                if (
                    accountProp[j].propKey === 'totalCashValue'
                ) {
                  pipaAccounts[i].totalCash = accountProp[j].propValue;
                  pipaAccounts[i].refreshTime = accountProp[j].refreshTime;
                }
                if (
                    accountProp[j].propKey === 'NetLiquidation'
                ) {
                  pipaAccounts[i].netLiquidation = accountProp[j].propValue;
                }
              }
            }

            let newData = [];
            rows = [];
            paperAccounts.forEach((item) => {
              const itemData = createData(
                  item.userName,
                  'IB Paper',
                  item.trackAccountName,
                  item.account,
                  item.usableCash,
                  item.totalCash,
                  item.netLiquidation,
                  item.refreshTime,
                  '',
                  item.broker,
                  item.id
              );
              newData.push(itemData);
            });

            moneyAccounts.forEach((item) => {
              const itemData = createData(
                  item.userName,
                  'IB Money',
                  item.trackAccountName,
                  item.account,
                  item.usableCash,
                  item.totalCash,
                  item.netLiquidation,
                  item.refreshTime,
                  '',
                  item.broker,
                  item.id
              );
              newData.push(itemData);
            });

            schwabAccounts.forEach((item) => {
              const itemData = createData(
                  item.userName,
                  'Schwab Money',
                  item.trackAccountName,
                  item.account,
                  item.usableCash,
                  item.totalCash,
                  item.netLiquidation,
                  item.lastSyncTime,
                  '',
                  item.broker,
                  item.id
              );
              newData.push(itemData);
            });

            pipaAccounts.forEach((item) => {
              const itemData = createData(
                  item.userName,
                  'iPIPA Paper',
                  item.trackAccountName,
                  item.account,
                  item.usableCash,
                  item.totalCash,
                  item.netLiquidation,
                  item.refreshTime,
                  '',
                  item.broker,
                  item.id
              );
              newData.push(itemData);
            });

            if (pipaAccounts.length >= 5) {
              setHasPIPAACcount(true);
            }
            rows = [...newData, ...rows];
            setAllIBAccountData(rows);
            //console.debug('after all IBAccount Data rows = %o', rows);
          })
          .catch((error) => {
            console.debug(error);
          });
      })
      .catch((error) => {
        console.debug(error);
      });
  }, []);

  React.useEffect(() => {
    if (oauth_token && oauth_verifier) {
      //console.debug('### 111 oauth_token  = %s oauth_verifier = %s', oauth_token, oauth_verifier);
      getWebapiAuthCallback(oauth_token, oauth_verifier)
          .then((res) => {
            //console.debug('UserAccount.view: res = %o', res);
            if (res.status === 200) {
              //console.debug('UserAccount.view : getWebapiAuthCallback - NO ERROR');
              fetchData();
            }
          })
          .catch((error) => {
            //console.debug('UserAccount.view error = %o', error);
            fetchData();
          });
    } else if (linkStatus) {
      window.history.replaceState(null, null, window.location.pathname);
      setOpenErrorDlg(true);
      let message;
      switch (linkStatus) {
        case '0':
          message = 'Successful';
          break;
        case '1':
          message = 'Token issues. Please try again during the next trading hours. Contact support@gopipa.com if the problem persists.';
          break;
        case '2':
          message = 'Account does not match. Please contact support@gopipa.com';
          break;
        default:
          message = 'Setup is not successful. Please try again during the next trading hours. Contact support@gopipa.com if the problem persists.';
      }
      setDlgData(message);
      fetchData();
      // setTimeout(function () {
      //   //.log("Set time out for 2 sec");
      //   fetchData();
      // }, 4000);
    } else {
      fetchData();
    }
  }, [fetchData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (values) => {
    // console.debug('The Values that you wish to edit ', values);
    setTradingType(values.accountType);
    setOpenDlg(true);
    setDlgType('Edit');
    setDlgData(values);
  };

  const handleAdjust = (values) => {
    // console.debug('The Values that you wish to edit ', values);
    setTradingType(values.accountType);
    setOpenDlg(true);
    setDlgType('Alter');
    setDlgData(values);
  };

  const handleRelinkClick = (values) => {
    if ( values.id ) {
      getWebapiRefreshToken(values.id)
          .then((res) => {
            let token;
            if (res.status === 200) {
              token = res && res?.data ? res.data?.data : null;
            }
            const gotoIB = (Url) => {
              const redirect = window.open(
                  Url,
                  '_self'
              );
              return redirect !== null && typeof popup !== 'undefined';
            };
            let url = `https://www.interactivebrokers.com/authorize?oauth_token=${token}`;
            //console.debug('getWebapiRefreshToken: url = %o', url);
            gotoIB(url);
          })
          .catch((error) => {
            console.log('getWebapiRefreshToken error = %o', error);
          });
    }
  }

  const handleRefresh = (values) => {
    console.debug('The Values that you wish to refresh ', values);
    setOpenRefreshDlg(true);
    setDlgData(values);
  }

  const handleDelete = (values) => {
    console.debug('The Values that you wish to delete ', values);
    setTradingType(values.accountType);
    setOpenDeleteDlg(true);
    setDlgData(values);
  };

  const handleAccountPosition = (values) => {
    getPosition(values);
    setTimeout(function () {
      setPositionPopup(true);
    }, 500);
  }

  const closeAccountPosition = () => {
    setPositionData([]);
    setPositionPopup(false);
  }

  const handleActionEditDialogClick = (action, addEdit = false, data) => {
    // console.debug('handleApplyEditDialogClick action = %s', action);
    // console.debug('###handle Input = %o', data);
    //setOpenDlg(action);
    setOpenDlg(false);
    setSchwabOpenDlg(false);
    setPIPAOpenDlg(false);
    setTimeout(function () {
      fetchData();
    }, 1500);
  };

  const handlcreateIPIPAAccount = (action, addEdit = false, data) => {
    console.debug('handlcreateIPIPAAccount action = %s', action);
    setPIPAOpenDlg(false);
    setTimeout(function(){
      fetchData();
    }, 1500);
  }

  const handleAddIBWebapiAccount = () => {
    console.debug('###handleAddIBWebapiAccount');
    setOpenDlg(true);
    setDlgData(null);
    setDlgType('Add');
  };

  const handleAddSchwabTDAccount = () => {
    console.log('###handleSchwabAccount');
    setSchwabOpenDlg(true);
    setDlgData(null);
    setDlgType('Add')
  };

  const handleAddPIPAAccount = () => {
    console.log('###handlePIPAAccount');
    setPIPAOpenDlg(true);
    setDlgData(null);
    setDlgType('Add')
  };

  const handleDeleteDialogClick = (ibAccount) => {
    console.debug('The Values that you wish to delete IB Account = %o', ibAccount);
    if (ibAccount.id) {
      deleteTrackAccount(ibAccount.id)
        .then((res) => {
          console.log('DeleteIBAccount: res = %o', res);
          if (res.status === 200) {
            console.log('IBAccount View: DeleteIBAccount - NO ERROR');
            fetchData();
          }
        })
        .catch((error) => {
          console.debug(error);
          setOpenAlertMsg({ alert: true, alertContent: error?.data?.errMsg, severity: 'error' });
        });
    }
    setOpenDeleteDlg(false);
  };

  const handleRefreshDialogClick = (brokerccount) => {
    console.debug('Refresh Broker Account = %o', brokerccount);
    if (brokerccount.id) {
      refreshTrackAccount(brokerccount.id)
        .then((res) => {
          console.log('RefreshIBAccount: res = %o', res);
          if (res.status === 200) {
            console.log('IBAccount View: Refresh IBAccount - NO ERROR');
            setTimeout(function () {
              fetchData();
            }, 1200);
          }
        })
        .catch((error) => {
          console.debug(error);
        });
    }
    setOpenRefreshDlg(false);
  };

  const getPosition = (values) => {
    if ( values.id ) {
      getAccountPosition(values.id)
          .then((res) => {
            if (res.status === 200) {
              let data = res && res?.data ? res.data?.data : [];
              setPositionData(data);
            }
          })
          .catch((error) => {
            console.log('getPosition error = %o', error);
          });
    }
  }

  const handleErrorDialogClick = () => {
    setOpenErrorDlg(false);
  }

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const dollarFormat = (value) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value);

  return (
    <div>
      {allIBAccountData && (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} />
            { !hasPIPAACcount && (
            <Typography style={{ float: 'right' }}>
              <Tooltip title="Add iPIPA Paper Account">
                <IconButton aria-label="add" onClick={() => handleAddPIPAAccount()}>
                  <AddIcon color="primary" />
                </IconButton>
              </Tooltip>
              <span>
                <a
                    style={{ color: '#3f51b5', fontWeight: 400, marginLeft: -10, marginRight: 20 }}
                    href="#"
                    onClick={() => handleAddPIPAAccount()}
                >
                  Add iPIPA Paper Account
                </a>
              </span>
            </Typography>
            )}
            {/*<Typography style={{ float: 'right' }}>*/}
            {/*  <Tooltip title="Add Schwab Account">*/}
            {/*    <IconButton aria-label="add" onClick={() => handleAddSchwabTDAccount()}>*/}
            {/*      <AddIcon color="primary" />*/}
            {/*    </IconButton>*/}
            {/*  </Tooltip>*/}
            {/*  <span>*/}
            {/*    <a*/}
            {/*        style={{ color: '#3f51b5', fontWeight: 400, marginLeft: -10, marginRight: 20 }}*/}
            {/*        href="#"*/}
            {/*        onClick={() => handleAddSchwabTDAccount()}*/}
            {/*    >*/}
            {/*      Add Schwab Account*/}
            {/*    </a>*/}
            {/*  </span>*/}
            {/*</Typography>*/}
            <Typography style={{ float: 'right' }}>
              <Tooltip title="Add IB Account">
                <IconButton aria-label="add" onClick={() => handleAddIBWebapiAccount()}>
                  <AddIcon color="primary" />
                </IconButton>
              </Tooltip>
              <span>
                <a
                    style={{ color: '#3f51b5', fontWeight: 400, marginLeft: -10, marginRight: 20 }}
                    href="#"
                    onClick={() => handleAddIBWebapiAccount()}
                >
                  Add IB Account
                </a>
              </span>
            </Typography>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      // if (row.broker.indexOf('IB') === 0 && row.netLiquidation === undefined) {
                      //   showRelinkNote = true;
                      //   needRelink = true;
                      // } else {
                      //   needRelink = false;
                      // }
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <TableCell padding={'default'}></TableCell>
                          {/*<TableCell padding="checkbox">*/}
                          {/*  <Checkbox*/}
                          {/*    checked={isItemSelected}*/}
                          {/*    inputProps={{ 'aria-labelledby': labelId }}*/}
                          {/*  />*/}
                          {/*</TableCell>*/}
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell>{row.accountType}</TableCell>
                          <TableCell>{row.nickName}</TableCell>
                          <TableCell style={{ width: 160 }}>{needRelink && (
                              <Tooltip title="This account needs to relink"><WarningIcon style={{ color: 'red', verticalAlign: '-9px', fontSize: 22, margin: '3px' }} /></Tooltip>)}{row.accountNum}
                          </TableCell>
                          <TableCell align="right">
                            {row.maxUsableCash ? dollarFormat(row.maxUsableCash) : ''}
                          </TableCell>
                          <TableCell align="right">
                            {row.totalCash ? dollarFormat(row.totalCash) : ''}
                          </TableCell>
                          <TableCell align="right">
                            {row.netLiquidation
                              ? dollarFormat(row.netLiquidation)
                              : ''}
                          </TableCell>
                          <TableCell>{getEstDateTime(row.refreshTime)}</TableCell>
                          <TableCell align="left">
                            <div style={{ width: 170}} className={classes.actionButtons}>
                              { (row.broker === 'IB' ) && (
                                  <Tooltip title="Upgrade to WebAPI">
                                    <IconButton aria-label="Relink" onClick={() => handleRelinkClick(row)}>
                                      <UpgradeIcon style={{color: "#FDAC20"}} />
                                    </IconButton>
                                  </Tooltip>
                              )}

                              { (row.broker === 'IBWEBAPI' || row.broker === 'TD' || row.broker === 'PIPA') && (
                                  <Tooltip title="Edit">
                                    <IconButton aria-label="edit" onClick={() => handleEdit(row)}>
                                      <EditIcon color="primary" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              { (row.broker === 'PIPA') && (
                                  <Tooltip title="Adjust Amount">
                                    <IconButton aria-label="edit" onClick={() => handleAdjust(row)}>
                                      <MoneyIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                              )}
                              { (row.broker != 'PIPA' ) && (
                                  <Tooltip title="Refresh">
                                    <IconButton aria-label="refresh" onClick={() => handleRefresh(row)}>
                                      <RefreshIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                              )}
                              <Tooltip title="Delete">
                                <IconButton aria-label="delete" onClick={() => handleDelete(row)}>
                                  <DeleteIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                              { (row.broker === 'IBWEBAPI' ) && (
                                  <Tooltip title="Relink">
                                    <IconButton aria-label="Relink" onClick={() => handleRelinkClick(row)}>
                                      <SyncIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                              )}
                              { (row.broker === 'PIPA') && (
                                  <Tooltip title="Account Positions">
                                    <IconButton aria-label="edit" onClick={() => handleAccountPosition(row)}>
                                      <FolderIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                              )}
                            </div>
                          </TableCell>
                          <TableCell className={classes.visuallyHidden}>{row.id}</TableCell>
                        </TableRow>
                      );
                    })}
                  {showRelinkNote && (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography
                            style={{float: 'left', marginTop: 15, marginBottom: 17, marginLeft: 10, fontSize: '13px'}}>
                          <span><WarningIcon style={{ color: 'red', verticalAlign: '-5px', fontSize: 20 }} /> : The account needs to relink.</span>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {openSchwabDlg && (
            <AddEditIBAccountDialog
                  dlgData={dlgData}
                  dlgType={dlgType}
                  accountType={SchwabType}
                  tradingType={tradingType}
                  handleActionEditDialogClick={handleActionEditDialogClick}
                  open={openSchwabDlg}
              />
          )}
          {openPIPADlg && (
              <AddEditIBAccountDialog
                  dlgData={dlgData}
                  dlgType={dlgType}
                  accountType={PIPAType}
                  tradingType={tradingType}
                  handleActionEditDialogClick={handlcreateIPIPAAccount}
                  open={openPIPADlg}
              />
          )}
          {openDlg && (
            <AddEditIBAccountDialog
              dlgData={dlgData}
              dlgType={dlgType}
              accountType={IBType}
              tradingType={tradingType}
              handleActionEditDialogClick={handleActionEditDialogClick}
              open={openDlg}
            />
          )}
          {openDeleteDlg && (
            <ConfirmationDialog
              dlgData={dlgData}
              handleDeleteDialogClick={handleDeleteDialogClick}
              open={openDeleteDlg}
              dlgTitle={'Delete Broker Account?'}
              dlgContentText={`Account Number: ${dlgData.accountNum}`}
            />
          )}
          {openRefreshDlg && (
            <RefreshIBConfirmationDialog
              dlgData={dlgData}
              handleRefreshDialogClick={handleRefreshDialogClick}
              open={openRefreshDlg}
            />
          )}
          {openErrorDlg && (
            <LinkAccountStatusDialog
                dlgData={dlgData}
                handleLinkAccountStatusDialogClick={handleErrorDialogClick}
              open={openErrorDlg}
            />
          )}
          {openRelinkDlg && (
              <ConfirmationDialog
                  dlgData={dlgData}
                  handleDeleteDialogClick={handleDeleteDialogClick}
                  open={openRelinkDlg}
                  dlgTitle={'Relink Broker Account?'}
                  dlgContentText={`Account Number: ${dlgData.accountNum}`}
              />
          )}
          {positionPopup && (
              <BrokerAccountPosition
                  onClick={() => closeAccountPosition()}
                  positionData={positionData}
              />
          )}
          {openAlertMsg.alert && (
              <AlertView
                  onCloseAlert={handleCloseAlert}
                  openAlert={openAlertMsg.alert}
                  message={openAlertMsg.alertContent}
                  severity={openAlertMsg.severity}
              />
          )}
        </div>
      )}
    </div>
  );
}
