import { environment } from '../environments/environment';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
// import backtest from 'assets/js/backtest';

// 数据字典项初始化
export function dataItemInitial(): void {
  environment.selectDataItems['strategy'] = {
    exceptionHandle: {
      Continue: 'strategy.exception.handle.continue',
      Restart: 'strategy.exception.handle.restart',
    },
    category: {
      Diversification: 'Diversification',
      Max_Return: 'Max Return',
      Hybrid: 'Hybrid',
      Speculation: 'Speculation',
      Others: 'Others',
    },
    algoType: {
      Diversification: {
        Min_Variance: 'Min Variance',
        Mean_Variance: 'Mean Variance',
        Risk_Parity: 'Risk Parity',
        Euqal_Weight: 'Euqal Weight',
        Others: 'Others',
      },
      Max_Return: {
        Momentum: 'Momentum',
        Fundamental: 'Fundamental',
        Trending: 'Trending',
        Mean_Reversion: 'Mean Reversion',
        Pair_Trading: 'Pair Trading',
        Market_Hedging: 'Market Hedging',
        Smart_Beta: 'Smart Beta',
        Alpha: 'Alpha',
        Event_Driven: 'Event Driven',
        Others: 'Others',
      },
      Hybrid: {
        Others: 'strategy.algoType.Others',
      },
      Speculation: {
        Future_VIX: 'strategy.algoType.Future_VIX',
        Future_Commodity: 'strategy.algoType.Future_Commodity',
        Others: 'strategy.algoType.Others',
      },
      Others: {
        Others: 'strategy.algoType.Others',
      },
    },
    algoTypeOptions: {
      Min_Variance: 'Min Variance',
      Mean_Variance: 'Mean Variance',
      Risk_Parity: 'Risk Parity',
      Euqal_Weight: 'Euqal Weight',
      Momentum: 'Momentum',
      Fundamental: 'Fundamental',
      Trending: 'Trending',
      Mean_Reversion: 'Mean Reversion',
      Pair_Trading: 'Pair Trading',
      Market_Hedging: 'Market Hedging',
      Smart_Beta: 'Smart Beta',
      Alpha: 'Alpha',
      Event_Driven: 'Event Driven',
      Future_VIX: 'Future VIX',
      Future_Commodity: 'Future Commodity',
      Others: 'Others',
    },
    componentType: {
      Stock: 'Stock',
      ETF: 'ETF',
      Hybrid: 'Hybrid',
    },
    limitToMemberClass: {
      Bronze: 'Bronze',
      Silver: 'Silver',
      Gold: 'Gold',
      Platinum: 'Platinum',
      Diamond: 'Diamond',
    },
    longShort: {
      LONG: 'Long',
      SHORT: 'Short',
      LONG_SHORT: 'Long Short',
    },
    riskLevel: {
      Conservative: 'Conservative (R1)',
      MediumConservative: 'Medium Conservative (R2)',
      Medium: 'Medium (R3)',
      MediumAggressive: 'Medium Aggressive (R4)',
      Aggressive: 'Aggressive (R5)',
    },
    true_false: {
      true: 'common_yes',
      false: 'common_no',
    },
    chargeTypeOptions: {
      Free: 'Free',
      FixedMonthly: 'Fixed Monthly',
      PercentOfPortfolioMonthly: 'Percent Of Portfolio Monthly',
    },
    chargeType: {
      Free: 'strategy.chargeAmount.free',
      FixedMonthly: 'strategy.chargeAmount.fixed',
      PercentOfPortfolioMonthly: 'strategy.chargeAmount.percent',
    },
    backtestType: {
      Test: 'Test',
      TrainAndTest: 'Train And Test',
    },
    tradingType: {
      Individual: 'Individual',
      Copy: 'Copy',
      BaseOnMembership: 'Base On Membership',
    },
    marketType: {
      US: 'US',
      CN: 'CN',
    },
    investmentTimeframe: {
      NotCare: 'strategy.longShort.notCare',
      month3: 3,
      month6: 6,
      month9: 9,
      month12: 12,
    },
  };
  environment.selectDataItems['accountProfile'] = {
    gender: {
      Male: 'accountprofile.gender.male',
      Female: 'accountprofile.gender.female',
    },
    investmentExp: {
      Professional: 'Professional',
      Good: 'Above Average',
      Fair: 'Average',
      NotMuch: 'Minimum',
      NoExp: 'No Experience',
    },
    investmentGoal: {
      Preserve_of_investment_capital:
        'A.Modest income-generating while preserving investment capital',
      Preserve_of_capital_with_some_growth: 'B.Some growth while preserving investment capita',
      Balance_between_preserving_capital_and_growth:
        'C.Balance between preserving capital and growth',
      More_growth_than_preserving_capital: 'D.Growth-oriented investment',
      Max_growth: 'E.Maximum growth investment',
    },
    workingStatus: {
      FullTime: 'FullTime',
      PartTime: 'PartTime',
      NoJob: 'Unemployed',
      Retired: 'Retired',
    },
    maritalStatus: {
      Single: 'Single',
      Married: 'Married',
    },
  };
  environment.selectDataItems['portfolio'] = {
    portfolioType: {
      NotCare: 'No Preference',
      Stock: 'Stock Portfolio',
      ETF: 'ETF Portfolio',
      Hybrid: 'Hybrid',
    },
    tradeType: { SIMU_: 'Paper Trade', MONEY_: 'Money Trade' },
    importanceOrder: {
      '123': '1.Max Return 2.Min Volatility 3.Min MaxDrawDown',
      '132': '1.Max Return 2.Min MaxDrawDown 3.Min Volatility',
      '213': '1.Min Volatility 2.Max Return 3.Min MaxDrawDown',
      '231': '1.Min Volatility 2.Min MaxDrawDown 3.Max Return',
      '312': '1.Min MaxDrawDown 2.Max Return 3.Min Volatility',
      '321': '1.Min MaxDrawDown 2.Min Volatility 3.Max Return',
    },
  };
  environment.selectDataItems['bill'] = {
    status: {
      Unsettled: 'bill.status.Unsettled',
      SettledUnpayed: 'bill.status.SettledUnpayed',
      SettledPayed: 'bill.status.SettledPayed',
      SettledReview: 'bill.status.SettledReview',
    },
    type: {
      MemberBill: 'bill.type.MemberBill',
      StrategyIncome: 'bill.type.StrategyIncome',
    },
    feeType: {
      InvestorMemberFee: 'bill.feeType.InvestorMemberFee',
      DeveloperMemberFee: 'bill.feeType.DeveloperMemberFee',
      StrategyCharge: 'bill.feeType.StrategyCharge',
      StrategyIncome: 'bill.feeType.StrategyIncome',
      Promotion: 'bill.feeType.Promotion',
    },
  };
  environment.selectDataItems['user'] = {
    investorState: {
      Trial: 'user.state.Trial',
      Enabled: 'user.state.Enabled',
      Disabled: 'user.state.Disabled',
    },
    developerState: {
      Trial: 'user.state.Trial',
      Enabled: 'user.state.Enabled',
      Disabled: 'user.state.Disabled',
    },
    memberClass: {
      Bronze: 'user.memberClass.bronze',
      Silver: 'user.memberClass.silver',
      Gold: 'user.memberClass.gold',
      Platinum: 'user.memberClass.platinum',
      Diamond: 'user.memberClass.diamond',
      Internal: 'user.memberClass.internal',
      Guest: 'user.memberClass.guest',
    },
  };
  environment.selectDataItems['forum'] = {
    order: {
      updateTime: 'forum.orderOfNew',
      starNum: 'forum.orderOfStar',
      viewNum: 'forum.orderOfView',
    },
    filter: {
      myComment: 'forum.filterOfComment',
      myStar: 'forum.filterOfStar',
      myTopic: 'forum.filterOfSubject',
    },
  };
}

export const jsonTransferToArray = (jsonObj: any) => {
  let arr = [];
  for (let key of Object.keys(jsonObj)) {
    arr.push({ key: key, value: jsonObj[key] });
  }
  return arr;
};

export const getDefaultConfig = (token: string | null) => {
  let tokenKey = token;
  tokenKey = tokenKey ? tokenKey : localStorage.getItem(environment.tokenKey);

  return {
    headers: {
      Authorization: `Bearer ${tokenKey}`,
    },
  };
};

export const getForumConfig = () => {
  const forumTokenKey = localStorage.getItem(environment.forumTokenKey);
  console.log('###111 forumTokenKey = %s', forumTokenKey);
  return {
    headers: {
      Authorization: `Token ${forumTokenKey}`,
    },
  };
};

export const getUserId = () => {
  const userId = localStorage.getItem(environment.userIdKey);
  return userId ? JSON.parse(userId) : '';
};

export const getUserName = () => {
  return localStorage.getItem(environment.userNameKey);
};

export const getToken = () => {
  return localStorage.getItem(environment.tokenKey);
};

export const getTextColor = (name: string) => {
  // console.log('getTextColor: getTextColor = %s', name.substring(0, 2));
  return `strategy${name.substring(0, 2)}`;
};

export const getNextTradeDate = (dateInfo: string | null) => {
  if (dateInfo) {
    return (
      dateInfo.substring(0, 4) + '-' + dateInfo.substring(4, 6) + '-' + dateInfo.substring(6, 8)
    )
  } else {
    return 'N/A';
  }
};

export const  eSTimeToLocal = (date: string) =>  {
  // var input = "August 21, 2015: 11:40 PM ET";
  console.log('SUNNY eSTimeToLocal date  = %s', date);
  const format = "YYYY-MM-DD";
  // const format = "MMM DD, YYYY h:mm A";
  const timeZone = "America/New_York";
  const m = momentTimeZone.tz(date, format, timeZone);
  // var output = m.format(); // "2015-08-21T23:40:00-04:00"
  const newDate = moment(m.format()).format('YYYY-MM-DD HH:mm');
  console.log('SUNNY eSTimeToLocal after local date  = %s newDate = %s', m.format(), newDate);
  return newDate;
};

export const secureCloseSocket = (socket: WebSocket) => {
  if (socket && socket.readyState < 2) {
    socket.close();
  }
};

export const moneyNumberHandle = (val: number, marketType?: string): string => {
  let currency = marketType === 'CN' ? '¥' : '$';
  if (val === 0) {
    return currency + val;
  }
  return currency + numberThirdBitHandle(val.toString());
};

export const numberThirdBitHandle = (val: string): string => {
  let splits = val.split('.');
  let splitInt = splits[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 千分位处理
  if (splits.length === 2) return splitInt + '.' + splits[1];
  return splitInt;
};

export const moneyValue = (labelValue: number) => {
  // converts number to string representation with K, M, B.
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9
      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
      // Six Zeroes for Millions
      : Math.abs(Number(labelValue)) >= 1.0e+6
          ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
          // Three Zeroes for Thousands
          : Math.abs(Number(labelValue)) >= 1.0e+3
              ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
              : Math.abs(Number(labelValue));
};

export const moneyValueInUnit = (labelValue: number) => {
  // converts number to string representation with K, M, B.
  // Nine Zeroes for Billions
  //console.log('888 labelValue = %s', labelValue);
  return Math.abs(Number(labelValue)) >= 1.0e+9
      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + " B"
      // Six Zeroes for Millions
      : Math.abs(Number(labelValue)) >= 1.0e+6
          ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + " M"
          // Three Zeroes for Thousands
          : (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + " K"
};

export const getESTTimeZone = (time: string): string => {
  //'America/New_York'  moment.tz.guess() get localtime timezone string
  // moment(time).tz('America/New_York').format('MMM DD, YYYY hh:mm A z');
  return moment(time).tz('America/New_York').format('z');
};

export const getEstDateTime = (time: string, format: string | null = null): string => {
  //'America/New_York'  moment.tz.guess() get localtime timezone string
  // moment(time).tz('America/New_York').format('MMM DD, YYYY hh:mm A z');
  const dateTimeFormat = format ? format : 'YYYY-MM-DD HH:mm z';
  const period = time ? moment(time.replace(/\.\d+/, "").replace(/-/g,"/")).tz('America/New_York').format(format ? format : dateTimeFormat) : '';
  return period;
};

export const getEstDateTime2 = (time: string, format: string | null = null): string => {
  // used for timestamp already use '-'
  // console.info('time %s', time.replace(/-/g, "/").replace(/\s\//," -"));
  const dateTimeFormat = format ? format : 'YYYY-MM-DD HH:mm z';
  const period = time ? moment(time.replace(/-/g, "/").replace(/\s\//," -")).tz('America/New_York').format(format ? format : dateTimeFormat) : '';
  return period;
};

export const getValidDateTime = (time: string, format: string | null = null): string => {
  const period = time ? moment(time.split(' +')[0]).format(format ? format : 'lll') : '';
  return period;
};

export const getUppercaseEachWord = (myWords: string) => {
  // const mySentence = "freeCodeCamp is an awesome resource";
  const words = myWords.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
};

export interface AlgorithmSocketParam {
  portfolioId: any;
  backtestIsFinish: boolean;
  backtestCanceled: boolean;
  isFullBacktest: boolean;
  canViewCode: boolean;
  performances: any;
  trainCharts?: any;
  testCharts: any;
  positions?: any[];
  trainPositions?: any[];
  orders?: any[];
  trainOrders?: any[];
  readyToDebugEvent?: Function;
  socketOpenEvent?: Function;
  socketProgressEvent?: Function;
  socketCloseEvent?: Function;
  minuteStatChart?: any;
  realtimeStatChart?: any;
  logs: any[];
  isLoadingLog: boolean;
  mouseIsReleaseForLog?: boolean;
}

export function generateBacktestLog(log: any, index: number) {
  // let logDiv = $('<div class="logline" index="' + index + '"></div>'), contentSpan = $('<span></span>');
  // if (log.logLevel === -1) // 系统级别日志整行加背景
  //   logDiv.css('background-color', '#792c09');
  // let logHtml = '<span>' + log.logTime + '</span>';
  // if (log.line)
  //   logHtml += ' &nbsp;<span class="method">' + log.className + ':' + log.line + '</span>';
  // else
  //   logHtml += ' &nbsp;<span class="method">' + log.className + '</span>';
  // logHtml += ' &nbsp;<span class="warn">' + translateService.instant('strategy.log.level.' + log.logLevel) + '</span>&nbsp;&nbsp;';
  // logDiv.html(logHtml);
  // contentSpan[0].innerText = log.content;
  // logDiv.append(contentSpan);
  let logStr: string = index === 1 ? '' : '\n';
  logStr += log.logTime;
  if (log.line)
    logStr += ' ϡ' + log.className + ':' + log.line + 'ϡ';
  else
    logStr += ' ϡ' + log.className + 'ϡ';

  const logError = 'error',
      logWarn= 'warn',
      logInfo= 'info',
      logDebug= 'debug';

    // "strategy.log.level.3": "error",
    // "strategy.log.level.2": "warn",
    // "strategy.log.level.1": "info",
    // "strategy.log.level.0": "debug",
    // "strategy.log.level.-1": "system",
  if (log.logLevel === -1)
    logStr += 'ξ' +  'system' + 'ξ';
  else if(log.logLevel === 0) {
    logStr += 'Ϡ' + logDebug  + 'Ϡ';
  }else if(log.logLevel === 1) {
    logStr += 'Ϡ' + logInfo + 'Ϡ';
  }else if(log.logLevel === 2) {
    logStr += 'Ϡ' + logWarn + 'Ϡ';
  }else if(log.logLevel === 3) {
    logStr += 'Ϡ' + logError + 'Ϡ';
  }
  logStr += ' ' + log.content;
  return logStr;
}

export function algorithmSocketHandle(sp: AlgorithmSocketParam) {
  let dailyRecords: any[],
      dailyOrders: any[];
      let handleDailyRecords = () => {
    if (dailyRecords.length !== 0) {
      // 当天的record会给多次：例如第一次给a=1、b=2，第二次给c=3，第三次给a=0、d=4，那么就会有a=0、b=2、c=3、d=4四条线
      let nameValues: any = new Map();
      for (let j = 0; j < dailyRecords.length; j++) {
        for (let k = 0; k < dailyRecords[j].data.length; k++) {
          nameValues.set(dailyRecords[j].data[k].name, dailyRecords[j].data[k].value);
        }
      }
      let time = Date.UTC(parseInt(dailyRecords[0].date.substring(0, 4)), parseInt(dailyRecords[0].date.substring(4, 6)) - 1, parseInt(dailyRecords[0].date.substring(6)));
      nameValues.forEach((value: any, name: string) => {
        let serial = null, chart = dailyRecords[0].phase === 'train' ? sp.trainCharts : sp.testCharts;
        for (let j = 0; j < chart.series.length; j++) {
          if (name === chart.series[j].name) {
            serial = chart.series[j];
            break;
          }
        }
        if (serial)
          serial.addPoint([time, parseFloat(value)], false);
        else
          chart.addSeries({name: name, data: [[time, parseFloat(value)]], yAxis: sp.isFullBacktest ? 3 : 1, tooltip: {padding: 12, valueDecimals: 2}}, false);
      });
    }
  };
  let handleDailyOrders = () => {
    if (dailyOrders.length !== 0) {
      let actionValues: any = new Map();
      for (let j = 0; j < dailyOrders.length; j++) {
        actionValues.set(dailyOrders[j].data.action, actionValues.has(dailyOrders[j].data.action) ? actionValues.get(dailyOrders[j].data.action) + (dailyOrders[j].data.price * dailyOrders[j].data.shares) : dailyOrders[j].data.price * dailyOrders[j].data.shares);
      }
      let time = Date.UTC(parseInt(dailyOrders[0].data.tradeDate.substring(0, 4)), parseInt(dailyOrders[0].data.tradeDate.substring(4, 6)) - 1, parseInt(dailyOrders[0].data.tradeDate.substring(6)));
      actionValues.forEach((value: any, action: string) => {
        if (action === 'BUY') {
          dailyOrders[0].phase === 'train' ? sp.trainCharts.series[4].addPoint([time, Number(value.toFixed(2))], false) : sp.testCharts.series[4].addPoint([time, Number(value.toFixed(2))], false);
        } else {
          dailyOrders[0].phase === 'train' ? sp.trainCharts.series[5].addPoint([time, Number(-value.toFixed(2))], false) : sp.testCharts.series[5].addPoint([time, Number(-value.toFixed(2))], false);
        }
      });
    }
  };
  environment.algorithmWorker = new Worker('assets/js/backtest/backtest.js');
  environment.algorithmWorker.onmessage = (e: any) => {
    if(e.data.indexOf('closed') >= 0) {
      const dataArr = e.data.split(';');
      if(dataArr.length === 2) {
        const errMsg = dataArr.length === 2 ? dataArr[1] : e.data;
        const msgType = dataArr.length === 2 ? dataArr[0] : e.data;
        if(dataArr.length === 2 && errMsg) {
          $('#fullChart').html(`<div style="color: red; font-size: 14px; font-weight: 400;">${errMsg}</div>`);
        }
        environment.algorithmWorker.terminate();
        sp.backtestIsFinish = true;
        if (sp.socketCloseEvent)
          sp.socketCloseEvent();
      }
      return;
    }

    if (e.data === 'connected') {
      let redrawChart = setInterval(function () {  // 连接建立成功以后每隔2秒重绘图表直至socket连接结束
        if (!sp.backtestIsFinish && !sp.backtestCanceled) {
          if (sp.trainCharts)
            sp.trainCharts.redraw();
          if (sp.realtimeStatChart)
            sp.realtimeStatChart.redraw();
          if (sp.minuteStatChart)
            sp.minuteStatChart.redraw();
          sp.testCharts.redraw();
        } else
          clearInterval(redrawChart);
      }, 2000);
      if (sp.socketOpenEvent)
        sp.socketOpenEvent();
    } else if (e.data === 'closed') {
      environment.algorithmWorker.terminate();
      sp.backtestIsFinish = true;
      if (sp.socketCloseEvent)
        sp.socketCloseEvent();
    } else if (e.data === 'error') {
      console.log('%s ##### common algorithmWorker error msg = %s', new Date().toString(), e.msg);
    } else if (e.data) {
      e.data.forEach((data: any) => {
        let msg = (<any> window).eval('(' + data + ')');
        if (msg.type === 'readyToDebug') {
          if (environment.algorithmDebugSocket && !sp.isFullBacktest && sp.readyToDebugEvent)
            sp.readyToDebugEvent();
        } else if (msg.type === 'tradeDates') {
          let tradeDates: any[] = [];
          msg.data.forEach((date: any) => {
            tradeDates.push([Date.UTC(parseInt(date.substring(0, 4)), parseInt(date.substring(4, 6)) - 1, parseInt(date.substring(6))), 0]);
          });
          msg.phase === 'train' ? sp.trainCharts.series[0].setData(tradeDates) : sp.testCharts.series[0].setData(tradeDates);
        } else if (msg.type === 'perf') {
          let time = Date.UTC(parseInt(msg.data.tradeDate.substring(0, 4)), parseInt(msg.data.tradeDate.substring(4, 6)) - 1, parseInt(msg.data.tradeDate.substring(6)));
          if (sp.isFullBacktest) {
            msg.phase === 'train' ? (sp.performances.train.return = msg.data.cumReturn ? (msg.data.cumReturn * 100).toFixed(2) + '%' : 'N/A') : sp.performances.test.return = (msg.data.cumReturn ? (msg.data.cumReturn * 100).toFixed(2) + '%' : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.spy_return = msg.data.benchmarkCumReturn ? (msg.data.benchmarkCumReturn * 100).toFixed(2) + '%' : 'N/A') : sp.performances.test.spy_return = (msg.data.benchmarkCumReturn ? (msg.data.benchmarkCumReturn * 100).toFixed(2) + '%' : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.alpha = msg.data.alpha ? (msg.data.alpha).toFixed(2) : 'N/A') : sp.performances.test.alpha = (msg.data.alpha ? (msg.data.alpha).toFixed(2) : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.beta = msg.data.beta ? (msg.data.beta).toFixed(2) : 'N/A') : sp.performances.test.beta = (msg.data.beta ? (msg.data.beta).toFixed(2) : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.sharpe = msg.data.sharpe ? (msg.data.sharpe).toFixed(2) : 'N/A') : sp.performances.test.sharpe = (msg.data.sharpe ? (msg.data.sharpe).toFixed(2) : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.sortino = msg.data.sortino ? (msg.data.sortino).toFixed(2) : 'N/A') : sp.performances.test.sortino = (msg.data.sortino ? (msg.data.sortino).toFixed(2) : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.volatility = msg.data.algoVolatility ? (msg.data.algoVolatility * 100).toFixed(2) + '%' : 'N/A') : sp.performances.test.volatility = (msg.data.algoVolatility ? (msg.data.algoVolatility * 100).toFixed(2) + '%' : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.maxdd = msg.data.maxDrawdown ? (msg.data.maxDrawdown * 100).toFixed(2) + '%' : 'N/A') : sp.performances.test.maxdd = (msg.data.maxDrawdown ? (msg.data.maxDrawdown * 100).toFixed(2) + '%' : 'N/A');
          } else {
            msg.phase === 'train' ? (sp.performances.train.return = msg.data.cumReturn ? (msg.data.cumReturn * 100).toFixed(2) + '%' : 'N/A') : sp.performances.test.return = (msg.data.cumReturn ? (msg.data.cumReturn * 100).toFixed(2) + '%' : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.spy_return = msg.data.benchmarkCumReturn ? (msg.data.benchmarkCumReturn * 100).toFixed(2) + '%' : 'N/A') : sp.performances.test.spy_return = (msg.data.benchmarkCumReturn ? (msg.data.benchmarkCumReturn * 100).toFixed(2) + '%' : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.alpha = msg.data.alpha ? (msg.data.alpha).toFixed(2) : 'N/A') : sp.performances.test.alpha = (msg.data.alpha ? (msg.data.alpha).toFixed(2) : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.beta = msg.data.beta ? (msg.data.beta).toFixed(2) : 'N/A') : sp.performances.test.beta = (msg.data.beta ? (msg.data.beta).toFixed(2) : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.sharpe = msg.data.sharpe ? (msg.data.sharpe).toFixed(2) : 'N/A') : sp.performances.test.sharpe = (msg.data.sharpe ? (msg.data.sharpe).toFixed(2) : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.sortino = msg.data.sortino ? (msg.data.sortino).toFixed(2) : 'N/A') : sp.performances.test.sortino = (msg.data.sortino ? (msg.data.sortino).toFixed(2) : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.volatility = msg.data.algoVolatility ? (msg.data.algoVolatility * 100).toFixed(2) + '%' : 'N/A') : sp.performances.test.volatility = (msg.data.algoVolatility ? (msg.data.algoVolatility * 100).toFixed(2) + '%' : 'N/A');
            msg.phase === 'train' ? (sp.performances.train.maxdd = msg.data.maxDrawdown ? (msg.data.maxDrawdown * 100).toFixed(2) + '%' : 'N/A') : sp.performances.test.maxdd = (msg.data.maxDrawdown ? (msg.data.maxDrawdown * 100).toFixed(2) + '%' : 'N/A');
          }
          let chart = msg.phase === 'train' ? sp.trainCharts : sp.testCharts;
          chart.series[1].addPoint([time, msg.data.cumReturn ? Number((msg.data.cumReturn * 100).toFixed(2)) : 0], false);// 数据太多，统一由定时器重绘图表
          chart.series[2].addPoint([time, msg.data.benchmarkCumReturn ? Number((msg.data.benchmarkCumReturn * 100).toFixed(2)) : 0], false);
          if (sp.isFullBacktest)
            chart.series[3].addPoint([time, msg.data.dailyPnl ? Number(msg.data.dailyPnl.toFixed(2)) : 0], false);
          if (sp.socketProgressEvent) {
            sp.socketProgressEvent(msg);
          }
        } else if (msg.type === 'record') {
          if (dailyRecords.length === 0) {
            dailyRecords.push(msg);
          } else {
            if (dailyRecords[0].phase === msg.phase && dailyRecords[0].date === msg.date) {
              dailyRecords.push(msg);
            } else {
              handleDailyRecords();
              dailyRecords.splice(0, dailyRecords.length);
              dailyRecords.push(msg);
            }
          }
        } else if (msg.type === 'order') {
          if (sp.isFullBacktest) {
            if (dailyOrders.length === 0) {
              dailyOrders.push(msg);
            } else {
              if (dailyOrders[0].phase === msg.phase && dailyOrders[0].data.tradeDate === msg.data.tradeDate) {
                dailyOrders.push(msg);
              } else {
                handleDailyOrders();
                dailyOrders.splice(0, dailyOrders.length);
                dailyOrders.push(msg);
              }
            }
            // @ts-ignore
            msg.phase === 'train' ? sp.trainOrders.push(msg.data) : sp.orders.push(msg.data);
          }
        } else if (msg.type === 'position') {
          if (sp.isFullBacktest) {
            let date = msg.date.substring(0, 4) + "-" + msg.date.substring(4, 6) + '-' + msg.date.substring(6);
            for (let j = 0; j < msg.data.length; j++) {
              // @ts-ignore
              msg.phase === 'train' ? sp.trainPositions.push($.extend({date: date}, msg.data[j])) : sp.positions.push($.extend({date: date}, msg.data[j]));
            }
            // @ts-ignore
            msg.phase === 'train' ? sp.trainPositions.push({date: date, symbol: "Cash", positionValue: msg.cash}) : sp.positions.push({date: date, symbol: "Cash", positionValue: msg.cash});
          }
        } else if (msg.type === 'minuteStat' && sp.minuteStatChart) {
          sp.minuteStatChart.series[0].addPoint([msg.time, msg.cpu ? Number((msg.cpu * 100).toFixed(2)) : 0], false);
          sp.minuteStatChart.series[1].addPoint([msg.time, msg.memLimit ? Number((msg.memLimit / 1024 / 1024).toFixed(2)) : 0], false);
          sp.minuteStatChart.series[2].addPoint([msg.time, msg.maxMemUsage ? Number((msg.maxMemUsage / 1024 / 1024).toFixed(2)) : 0], false);
          sp.minuteStatChart.series[3].addPoint([msg.time, msg.memUsage ? Number((msg.memUsage / 1024 / 1024).toFixed(2)) : 0], false);
          sp.minuteStatChart.series[4].addPoint([msg.time, msg.rxBytes ? Number((msg.rxBytes / 1024).toFixed(2)) : 0], false);
          sp.minuteStatChart.series[5].addPoint([msg.time, msg.txBytes ? Number((msg.txBytes / 1024).toFixed(2)) : 0], false);
        } else if (msg.type === 'realtimeStat' && sp.realtimeStatChart) {
          sp.realtimeStatChart.series[0].addPoint([msg.time, msg.cpu ? Number((msg.cpu * 100).toFixed(2)) : 0], false);
          sp.realtimeStatChart.series[1].addPoint([msg.time, msg.memLimit ? Number((msg.memLimit / 1024 / 1024).toFixed(2)) : 0], false);
          sp.realtimeStatChart.series[2].addPoint([msg.time, msg.maxMemUsage ? Number((msg.maxMemUsage / 1024 / 1024).toFixed(2)) : 0], false);
          sp.realtimeStatChart.series[3].addPoint([msg.time, msg.memUsage ? Number((msg.memUsage / 1024 / 1024).toFixed(2)) : 0], false);
          sp.realtimeStatChart.series[4].addPoint([msg.time, msg.rxBytes ? Number((msg.rxBytes / 1024).toFixed(2)) : 0], false);
          sp.realtimeStatChart.series[5].addPoint([msg.time, msg.txBytes ? Number((msg.txBytes / 1024).toFixed(2)) : 0], false);
          if (sp.realtimeStatChart.series[0].data.length > environment.realtimeStatPoints) {
            sp.realtimeStatChart.series.forEach((series: any, index: number) => {
              if (index <= 5)
                series.removePoint(0, false);
            });
          }
        } else if (msg.type === 'log' && sp.canViewCode) {
          sp.logs.push(msg.data);
          let _session: any = (<any>(document.getElementById(sp.isFullBacktest ? "fbk_log_code" : "bk_log_code"))).env.editor.session;
          _session.insert({row: _session.getLength(), column: 0}, generateBacktestLog(msg.data, sp.logs.length));
          // if (sp.logs.length <= 1000) {
          //   let logDiv = generateBacktestLog(msg.data, sp.logs.length, translateService);
          //   if (sp.logs.length === 1)
          //     logDiv.addClass('first-log');
          //   else if (sp.logs.length === 1000)
          //     logDiv.addClass('last-log');
          //   if (!sp.isFullBacktest)
          //     $("#fulltest_logs").append(logDiv);
          //   else
          //     $("#backtest_logs").append(logDiv);
          // }
        } else if (msg.type === 'finish') {
          sp.backtestIsFinish = true;
          handleDailyRecords();
          if (sp.isFullBacktest)
            handleDailyOrders();
          if (sp.trainCharts)
            sp.trainCharts.redraw();
          if (sp.realtimeStatChart)
            sp.realtimeStatChart.redraw();
          if (sp.minuteStatChart)
            sp.minuteStatChart.redraw();
          sp.testCharts.redraw();
          if (sp.socketCloseEvent)
            sp.socketCloseEvent(msg.data);
        }
      });
    }
  };
  environment.algorithmWorker.postMessage({
    type: 'init',
    url: environment.websocket_url + '?portfolioId=' + sp.portfolioId + '&token=' + localStorage.getItem(environment.tokenKey)
  });
}
