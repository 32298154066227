import React from 'react';

import {makeStyles} from "@material-ui/core/styles";
import ReactHtmlParser from "html-react-parser";
import Button from "../../components/CustomButtons/Button";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import EmailIcon from '@material-ui/icons/Email';
import {explanationText} from "../IDA/explanation-text";
import {referFriends} from '../../utils/user.utils';
import {environment} from "../../environments/environment";

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        opacity: 0.4,
        zIndex: 8888,
    },
    outterBox: {
        position: 'relative',
        fontFamily: 'Arial',
        fontSize: '14px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '-35px',
        textAlign: 'center',
        borderRadius: '8px',
        height: '500px',
        width: '800px',
        border: 'solid grey 1px',
        boxShadow: '10px 10px 5px #ccc',
    },
    textBox: {
        position: 'relative',
        fontFamily: 'Arial',
        fontSize: '15px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginLeft: '35px',
        marginRight: '35px',
        marginTop: '5px',
        textAlign: 'left',
    },
    subtitle: {
        fontWeight: 'bold',
        fontSize: '18px',
        display: 'inline-block',
        height: 'auto',
        textAlign: 'center',
        paddingTop: '25px',
        paddingBottom: '5px',
    },
    caption: {
        width: '680px',
        display: 'inline-block',
        height: 'auto',
        textAlign: 'left',
        padding: '15px 10px 3px 5px',
    },
    inputBox: {
        fontSize: '15px',
        fontWeight: 200,
        padding: '10px 10px',
        maringTop: '10px',
    },
    submitButton: {
        height: '35px',
        width: '100px',
        marginLeft: '555px',
        marginTop: '5px',
        textTransform: 'capitalize',
        align: 'left',
    },
    infoButton: {
        fontSize: '16px',
        border:'1px soild #E5E0E0',
        font: '##FFFFFF',
    },
    confirmation: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '230px',
        height: 'auto',
        backgroundColor: 'whitesmoke',
        padding: '20px 30px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        overflow: 'auto',
        fontWeight: '400',
        fontSize: '14px',
    },
};

const useStyles = makeStyles(styles);

export default function ReferFriendsView() {
    const [emailsInput, setEmailsInput] = React.useState("");
    const [confirmation, setConfirmation] = React.useState(false);
    const [confirmationMsg, setConfirmationMsg] = React.useState("");

    const handleChange = (e) => {
        e.preventDefault();
        setEmailsInput(e.target.value.toLowerCase());
    };

    const handleInvite = () => {
        referFriends(emailsInput)
            .then((response) => {
                const data = response && response?.data ? response.data : null;
                if (data.code === 200) {
                    console.log('refer a friend');
                    setConfirmationMsg('Invitation Sent. Thanks!');
                    setConfirmation(true);
                } else if (data.code === 999) {
                    setConfirmationMsg(data.errMsg);
                    setConfirmation(true);
                }
                setEmailsInput('');
            })
            .catch((error) => {
                console.error(error);
                setEmailsInput('');
            });
    };

    const onClose = () => {
        setConfirmation(false);
    }

    const classes = useStyles();

    return (
        <div className={classes.outterBox}>
            <div className={classes.subtitle}>
                GoPIPA Investment Club (GIC) Member Referral Program
            </div>
            <div className={classes.textBox}>
                Simply enter the email addresses of the friends and families you want to refer. They will receive invitation emails from us. When each one of them joins the GoPIPA Investment Club (GIC) for the first 6 months, you will receive one-month free membership credit. When each one of them joins the club for anaother 6 months, you will receive another one-month free membership credit.
            </div>
            <div className={classes.caption}>Enter emails seperated by comma:</div>
            <div>
                <span className={classes.inputBox}>
                    <textarea style={{height: '200px', width: '670px', textAligh:'top'}}
                              type="text"
                              placeholder="Enter the emaill address here"
                              onChange={handleChange}
                              value={emailsInput.toLowerCase()}
                    />
                </span>
            </div>
            <Button color='info' className={classes.submitButton}>
                <IconButton className={classes.infoButton} color='info' size='medium' onClick={() => handleInvite()}>
                    <span><EmailIcon style={{color: '#FFF'}}/></span><span style={{verticalAlign:'top', fontSize:'15px', color: '#FFF'}}>Invite</span>
                </IconButton>
            </Button>
            { confirmation && (
                <>
                    <div className={classes.overlay} onClick={onClose}/>
                    <div className={classes.confirmation} onClick={onClose}>
                        <div style={{textAlign:'center'}}><span><EmailIcon fontSize="default" /></span><span style={{verticalAlign:'top', paddingLeft: '5px', fontSize:'15px', fontWeight:'bold'}}>Refer A Friend</span></div>
                        {confirmationMsg}
                    </div>
                </>
            )}
        </div>

    );
}
