import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

// @material-ui/core components
import Card from '@material-ui/core/Card';

class TrackingBacktestChartView extends React.Component {
  constructor(props) {
    super(props);
    const { chartData, viewTitle} = props;
    // console.log('SUNNY222 TrackingBacktestChartView chartData = %o', chartData);
    let styDatas = [],
      benchDatas = [];
    chartData.forEach((n: any) => {
      let time = Date.UTC(
        parseInt(n.tradeDate.substring(0, 4)),
        parseInt(n.tradeDate.substring(4, 6)) - 1,
        parseInt(n.tradeDate.substring(6))
      );
      styDatas.push([time, n.cumReturn ? Number((n.cumReturn * 100).toFixed(2)) : 0]);
      benchDatas.push([
        time,
        n.benchmarkCumReturn ? Number((n.benchmarkCumReturn * 100).toFixed(2)) : 0,
      ]);
    });
    // console.debug('SUNNY1234 TrackingBacktestChartView styDatas = %o', styDatas);
    // console.debug('SUNNY1234 TrackingBacktestChartView benchDatas = %o', benchDatas);
    let text: string = '';
    if (styDatas.length > 0) {
      if (viewTitle) {
        text =
          'Alpha: ' +
          (chartData[styDatas.length - 1].alpha
            ? chartData[styDatas.length - 1].alpha.toFixed(2)
            : 0);
        text +=
          ', Beta: ' +
          (chartData[styDatas.length - 1].beta
            ? chartData[styDatas.length - 1].beta.toFixed(2)
            : 0);
        text +=
          ', Sharpe: ' +
          (chartData[styDatas.length - 1].sharpe
            ? chartData[styDatas.length - 1].sharpe.toFixed(2)
            : 0);
      }
      this.state = { series0Data: styDatas, series1Data: benchDatas, text };
    } else {
      this.state = { series0Data: [], series1Data: [] };
    }
  }

  render() {
    const options = {
      chart: {
        height: 260, //(9 / 16) * 100 + '%', // 16:9 ratio
      },
      rangeSelector: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      navigator: { adaptToUpdatedData: true, series: { visible: false }, margin: 5, height: 30 },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: '%',
        split: false,
        shared: true,
        borderColor: '#0BA9F9',
        borderWidth: 1,
        style: { fontSize: '13px' },
        headerFormat: '<span style="font-size: 13px">{point.key}</span><br/>',
      },
      yAxis: [
        {
          labels: {
            formatter: function () {
              return (this.value > 0 ? ' + ' : '') + this.value + '%';
            },
          },
        },
      ],
      title: { text: this.state.text, x: -20 },
      series: [],
    };

    const chartOptions = {
      ...options,
      series: [
        { data: this.state.series0Data, name: 'Strategy', type: 'line', color: '#7cb5ec' },
        {
          data: this.state.series1Data,
          name: 'Benchmark',
          fillOpacity: 0.2,
          type: 'line',
          color: '#FFA450',
        },
      ],
    };

    return (
      <div>
        <Card>
          <div
            style={{
              fontWeight: 400,
              fontSize: 20,
              marginTop: 10,
              marginLeft: 10,
              minWidth: 1200,
            }}
          >
            {this.state.text}
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            constructorType={'stockChart'}
          />
        </Card>
      </div>
    );
  }
}

export default TrackingBacktestChartView;
