import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button.js';
import GridContainer from '../../components/Grid/GridContainer';
import { getRiskAssessment, RiskAssessment, setRiskAssessment } from '../../utils/user.utils';
import AlertView from '../../components/Alert/Alert.view';
import ViewUserRiskLevelDialog from '../../components/Dialog/ViewUserRiskLevelDialog.view';

const styles = {
  label: {
    textTransform: 'capitalize',
    fontSize: 14,
    height: 30,
  },
  answerNumber: {
    marginRight: 10,
    fontSize: 16,
    fontWeight: 500,
  },
  selected: {
    border: '2px solid rgb(0, 0, 255)',
  },
};
const useStyles = makeStyles(styles);

export default function UserRiskAssessmentQuestionView(props) {
  const classes = useStyles();
  const [questions, setQuestions] = useState(null);
  const [questionAnswer, setQuestionAnswer] = useState(null);
  const name = props.currentUser.firstName + ' ' + props.currentUser.lastName;
  const answerLetter = ['', 'A', 'B', 'C', 'D', 'E'];
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });
  const [viewRiskLevelOpen, setViewRiskLevelOpen] = useState({
    open: false,
    riskInfo: null,
  });

  const handleAnswerOptionClick = (value, qId) => {
    setQuestionAnswer({ ...questionAnswer, [qId]: value });
  };

  const isDisabled = () => {
    if (!questionAnswer) {
      return true;
    }
    for (const [key, value] of Object.entries(questionAnswer)) {
      if (!value) {
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    getRiskAssessment()
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          console.debug('*****RiskAssessmentQ.view: successfully questions = %o', data.data);
          setQuestions(data.data);
          const obj = {};
          data.data.forEach((element) => {
            obj[element.questionId] = null;
          });
          setQuestionAnswer(obj);
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  }, []);

  const handleSubmit = () => {
    const param: RiskAssessment = {
      answer: questionAnswer,
    };
    console.debug('#### handleSubmit param = %o', param);
    setRiskAssessment(param)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          console.debug(
            'msg = %s',
            `your risk level is ${data.data.riskLevel}: ${data.data.riskText}`
          );
          data.data.name = name;
          setViewRiskLevelOpen({ open: true, riskInfo: data.data });
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  };

  const getAnswer = (qId) => {
    if (questionAnswer) {
      const answer = questionAnswer[qId];
      const result = answer ? `(${answerLetter[answer]})` : '';
      return result;
    } else {
      return '';
    }
  };

  const getAnswerCount = () => {
    let count = 0;
    if (questionAnswer) {
      for (const [key, value] of Object.entries(questionAnswer)) {
        if (value) {
          count++;
        }
      }
    }
    return count;
  };

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  const getHighlight = (answer, selectedValue, qId) => {
    if (answer) {
      for (const [key, value] of Object.entries(answer)) {
        if (key === qId && selectedValue === value) {
          console.debug('getHighlight found key = %s, value = %s', key, value ? value : 'null');
          return true;
        }
      }
    }
  };

  const handleViewUserRiskLevelClick = (riskInfo) => {
    setViewRiskLevelOpen({ open: false, riskInfo: null });
    if (riskInfo) {
      // update risk level
      setOpenAlertMsg({
        alert: true,
        alertContent: 'Set Risk level successfully',
        severity: 'success',
      });
      props.updateRiskLevel(riskInfo);
    }
  };

  return (
    <div className="riskAssessmentPanel">
      {openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      {viewRiskLevelOpen.open && (
        <ViewUserRiskLevelDialog
          handleViewUserRiskLevelClick={handleViewUserRiskLevelClick}
          open={viewRiskLevelOpen.open}
          data={viewRiskLevelOpen.riskInfo}
        />
      )}
      <div>
        {questions && (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  {questions.map((item, index) => (
                    <div key={index}>
                      <div className="question-section">
                        <div className="question-count">
                          <span>Question {index + 1}</span>/{questions.length}
                        </div>
                        <div className="question-text">
                          <span className="question-text-font">{item.questionText}</span>
                          <span
                            className="question-text-font"
                            style={{ marginLeft: 10, color: '#234668' }}
                          >
                            {getAnswer(item.questionId)}
                          </span>
                        </div>
                      </div>
                      {item.answersList.map((c) => (
                        <button
                          id={item.questionId + '-' + c.value}
                          className="quizButton"
                          onClick={() => handleAnswerOptionClick(c.value, item.questionId)}
                        >
                          <span
                            style={
                              getHighlight(questionAnswer, c.value, item.questionId)
                                ? { color: '#234668', backgroundColor: '#fff' }
                                : {}
                            }
                          >
                            {c.answer}
                          </span>
                        </button>
                      ))}
                      <hr />
                    </div>
                  ))}
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ float: 'right' }}>
                <span className={classes.answerNumber}>
                  You answered {getAnswerCount()} out of 10{' '}
                </span>
                <Button
                  disabled={isDisabled()}
                  onClick={handleSubmit}
                  color="info"
                  className={classes.label}
                >
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        )}
      </div>
    </div>
  );
}
