import React from 'react';

import {makeStyles} from "@material-ui/core/styles";
import ReactHtmlParser from "html-react-parser";
import aqs1img from 'assets/img/aqs_1.png';
import amsmonitorimg from 'assets/img/aqs-monitor.png';
import compareimg from 'assets/img/aqs_compare.png';
import backtestimg from 'assets/img/backtest.png';

const styles = {
    imageBox: {
        display: 'block',
        position: 'relative',
        // border:'solid black 1px',
        top: '5px',
        margin: '10px',
        textAlign: 'center',
    },
    textBox: {
        position: 'relative',
        fontFamily: 'Arial',
        fontSize: '14px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginLeft: '25px',
        marginRight: '25px',
        marginTop: '25px',
        textAlign: 'left',
    },
    image: {
        position: 'relative',
        height: '160px',
        maxWidth: '450px',
        border: 'solid grey 1px',
        boxShadow: '10px 10px 5px #ccc',
    },
    bigtitle: {
        fontFamily: 'Arial',
        color:'#999',
        fontWeight: 'bold',
        fontSize: '20px',
        margin:'20px 10px',
    },
    title: {
        textAlign: 'center',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '22px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginTop: '25px',
        marginRight: '25px',
        marginLeft: '25px',
    },
    subtitle: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '18px',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginTop: '25px',
        marginRight: '25px',
        marginLeft: '25px',
    },
    tutorial: {
        width: '880px',
        margin: 'auto',
        paddingBottom: '50px',
    }
};

const title1 = 'Find AQS Strategies to Invest';
const title2 = 'Using Backtest Data to Compare Historical Performance';
const title3 = 'Subscribe to AQS with Your IB Account or iPIPA Paper Account';
const title4 = 'Monitor the Performance of Your AQS Portfolios';

const tutorial1 = 'Need help finding good strategies to invest? Here are the tools you can use:<br><span style="font-weight: 900;">Comparing Tracking:</span>Tracking data is the strategy performance after it is online. You can see the strategies\' recent performance with tracking data. <br>1. Go to AQS Service, click on "Comparing Tracking" tab.<br>2. Click on any table header to sort the table in ascending or descending order.<br>3. CAGR (Compound Annual Growth Rate) value provides useful insignt about the strategy\'s potenial. GAGR is a financial metric that calculates the average annual growth rate of an investment over a certain period of time, assuming that the investment has grown at a constant rate each year.';
const tutorial2 = '<span style="font-weight: 900;">Comparing Backtest:</span> Backtesting is a process of evaluating the performance of an investment strategy or trading system by applying it to historical market data. iPIPA simulated the AQS investment strategy on past market conditions to see how it would have performed in real-world scenarios.<br>1. Go to AQS Service, click on "Comparing Backtest" tab.<br>2. Look at the backtest result for selected time period. Backtesting can be a useful tool in evaluating the historical performance of an investment strategy.';
const tutorial3 = 'Every user receives $1M iPIPA paper money to try out iPIPA. You could use it to subscribe to any AQS strategies you want.<br>1. Go to "AQS Service" from the sidebar menu.<br>2. You can only subscribe to AQS strategies with risk levels that are the same or below your risk level.<br>3. Subscribe by clicking on the "+".<br>4. Fill "Account Name" field as the nickname of your subscription. The "Trade Type" should be Paper Trade. "Trading Account" should be your iPIPA paper account (Default Paper Trade Account) or your IB paper account.<br>5. Press the "Submit" button to complete the subscription.';
const tutorial4 = 'Users can check the portfolios\' performance in "My AQS Portfolios":<br>1. Go to "AQS Service" from the sidebar menu.<br>2. Click on "My AQS Portfolios" to review your portfolios\' performance metrics.<br>3. The user can check detail on the portfolio\'s performance metrics and positions in the monitor (see the picture above).';

const useStyles = makeStyles(styles);

export default function TutorialView() {
    const classes = useStyles();

    return (
        <div className={classes.tutorial}>
            <div className={classes.subtitle}>
                {ReactHtmlParser(title1)}
            </div>
            <div className={classes.imageBox}>
                <img className={classes.image} style={{width: '600px'}} src={compareimg}/>
            </div>
            <div className={classes.textBox} >
                {ReactHtmlParser(tutorial1)}
            </div>
            <div className={classes.subtitle}>
                {ReactHtmlParser(title2)}
            </div>
            <div className={classes.imageBox}>
                <img className={classes.image} style={{width: '600px'}} src={backtestimg}/>
            </div>
            <div className={classes.textBox} >
                {ReactHtmlParser(tutorial2)}
            </div>
            <div className={classes.subtitle}>
                {ReactHtmlParser(title3)}
            </div>
            <div className={classes.imageBox}>
                <img className={classes.image} src={aqs1img}/>
            </div>
            <div className={classes.textBox} >
                {ReactHtmlParser(tutorial3)}
            </div>
            <div className={classes.subtitle}>
                {ReactHtmlParser(title4)}
            </div>
            <div className={classes.imageBox}>
                <img className={classes.image} style={{width: '500px'}} src={amsmonitorimg}/>
            </div>
            <div className={classes.textBox} >
                {ReactHtmlParser(tutorial4)}
            </div>
        </div>
    );
}
