import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import GridItem from '../Grid/GridItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import GridContainer from '../Grid/GridContainer';
import { defaultFont, grayColor } from '../../assets/jss/material-dashboard-react';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const styles = {
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + ' !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
  },
  selectInput: {
    width: '100%',
  },
  dialogActionCenter: {
    margin: 'auto',
  },
};
const useStyles = makeStyles(styles);

export default function EditInvestmentAmountDialog(props) {
  const classes = useStyles();
  const { strategy, portfolioData } = props;
  const [values, setValues] = React.useState({
    portfolioName: portfolioData ? portfolioData.name : undefined,
    investmentAmount: strategy.portfolioValue,
    method: 'increase',
    first: true,
  });
  const [errMsg, setErrMsg] = React.useState(false);

  // console.log(
  //   '1111 strategy.minInvestmentAmount = %s strategy.maxInvestmentAmount = %s strategy.portfolioValue =%s investmentAmount = %s method= %s',
  //   strategy.strategy.minInvestmentAmount,
  //   strategy.strategy.maxInvestmentAmount,
  //   strategy.portfolioValue,
  //   values.investmentAmount,
  //   values.method
  // );

  const getErrorInvestmentAmount = () => {
    if (values.first) {
      return false;
    } else {
      if (!values.investmentAmount) {
        return true;
      } else {
        let totalInvestmentAmount;
        if (values.method === 'increase') {
          totalInvestmentAmount = parseInt(values.investmentAmount) + strategy.portfolioValue;
        } else {
          totalInvestmentAmount = strategy.portfolioValue - parseInt(values.investmentAmount);
        }
        console.log(
          '1111 getErrorInvestmentAmount totalInvestmentAmount = %s strategy.portfolioValue = %s values.investmentAmoun',
          totalInvestmentAmount,
          strategy.portfolioValue,
          values.investmentAmount
        );
        if (totalInvestmentAmount < strategy.strategy.minInvestmentAmount) {
          return true;
        } else if (totalInvestmentAmount > strategy.strategy.maxInvestmentAmount) {
          return true;
        }
      }
    }
  };

  const errorInvestmentAmount = getErrorInvestmentAmount();
  const getErrorInvestmentAmountMsg = () => {
    let result = '';
    if (!values.first) {
      if (values.investmentAmount) {
        let totalInvestmentAmount;
        if (values.method === 'increase') {
          totalInvestmentAmount = parseInt(values.investmentAmount) + strategy.portfolioValue;
        } else {
          totalInvestmentAmount = strategy.portfolioValue - parseInt(values.investmentAmount);
        }
        if (totalInvestmentAmount < strategy.strategy.minInvestmentAmount) {
          result = `Investment Amount cannot be less than ${strategy.strategy.minInvestmentAmount}`;
        } else if (totalInvestmentAmount > strategy.strategy.maxInvestmentAmount) {
          result = `Investment Amount cannot be greater than ${strategy.strategy.maxInvestmentAmount}`;
        }
      } else {
        result = 'Investment Amount is required';
      }
    }
    return result;
  };

  const onClickInvestmentAmount = () => {
    props.handleInvestmentAmountSubmit(values);
  };

  const handleChange = (name) => (event) => {
    setErrMsg(false);
    setValues({ ...values, [name]: event.target.value, first: false });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleInvestmentAmountClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ minWidth: 620 }} id="alert-dialog-title">
          Edit Investment Amount
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <span>Portfolio Name: {strategy.name}</span>
              </GridItem>
            </GridContainer>
          </DialogContentText>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="method"
                  name="method"
                  row={true}
                  value={values.method}
                  onChange={handleChange('method')}
                >
                  <FormControlLabel value="increase" control={<Radio />} label="Increase" />
                  <FormControlLabel value="decrease" control={<Radio />} label="Decrease" />
                </RadioGroup>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl className={classes.selectInput}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="investmentAmount"
                  label="Investment Amount ($)"
                  required
                  type="number"
                  value={null}
                  onChange={handleChange('investmentAmount')}
                  error={errorInvestmentAmount}
                  helperText={errorInvestmentAmount ? getErrorInvestmentAmountMsg() : ' '}
                  fullWidth
                />
              </FormControl>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              errorInvestmentAmount || parseInt(values.investmentAmount) === strategy.portfolioValue
            }
            onClick={onClickInvestmentAmount}
            color="primary"
          >
            Submit
          </Button>
          <Button autoFocus onClick={props.handleInvestmentAmountClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditInvestmentAmountDialog.propTypes = {
  open: PropTypes.bool,
  dlgData: PropTypes.object,
  handleInvestmentAmountClose: PropTypes.func,
  handleInvestmentAmountSubmit: PropTypes.func,
  strategy: PropTypes.object,
  portfolioData: PropTypes.object,
};
