import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function RefreshIBConfirmationDialog(props) {
  const handleCloseConfirmation = () => {
    props.handleRefreshDialogClick(props.dlgData);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Refresh Broker Account'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            It will take about 1 minute to refresh this broker account info, please come back later
            to check the updated info.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

RefreshIBConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  dlgData: PropTypes.object,
  handleRefreshDialogClick: PropTypes.func,
};
