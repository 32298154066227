import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
//Views
import IDAMarketFAView from './IDAMarketFA.view.tsx';
import IDAMarketTAView from './IDAMarketTA.view.tsx';
import IDAMarketSAView from './IDAMarketSA.view.tsx';
import IDAETFDataView from './IDAETFData.view.tsx';
import IDAStockCompareView from './IDAStockCompare.view.tsx';

const styles = {
    noWarp: {
        whiteSpace: 'nowrap',
    },
    title: {
        fontFamily: 'Roboto, Arial, Helvetica',
        fontSize: '16px',
        fontWeight: 200,
        padding: '0px 0px',
        color: '#000000DE',
    },
    idaBox: {
        position: 'relative',
        width: '100%',
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '5px',
        // marginTop: '20px',
        fontWeight: '200',
        fontSize: '16px',
    },
    functionBox: {
        width: '350px',
        display: 'inline-block',
        height: 'auto',
        padding: '5px 0px 0px 0px',
    },
    menuList: {
        marginLeft: '-15px',
        width: '350px',
        border: '1px solid #000000DE',
        marginTop: '-8px',
        padding: '5px 15px',
        borderRadius: '5px',
    },
    menuItem: {
        color: 'black',
        fontSize: '16px',
        '& .Mui-selected': {
            display: 'block',
            backgroundColor: '#EBEBEB',
        },
        fontFamily: 'Arial, Helvetica',
        color: '#000000DE',
    },
}

const useStyles = makeStyles(styles);

export default function IDAMarketView() {
    const [idaColumns, setIdaColumns] = React.useState([]);
    const [idaTable, setIdaTable] = React.useState([]);
    const [tableWidth, setTableWidth] = React.useState('auto');
    const [topMenuIndex, setTopMenuIndex] = React.useState(1);
    const [progress, setProgress] = React.useState(false);
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    //show menu
    const [showMarketFA, setShowMarketFA] = React.useState(false);
    const [showMarketTA, setShowMarketTA] = React.useState(false);
    const [showMarketSA, setShowMarketSA] = React.useState(false);
    const [showETFData, setShowETFData] = React.useState(false);
    const [showStockCompare, setShowStockCompare] = React.useState(false);

    const top_menu_options = [
        'Select Analysis',
        'Market Fundamental & Sentiment Analysis',
        'Market Technical Analysis',
        'Major ETF Performance Comparison',
        'Stock/ETF Momentum Comparison',
    ]

    const secondary_title = [
        'analysis',
        'analysis',
        'comparison',
        'comparison',
    ]

    const handleTopMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setTopMenuIndex(index);
        setAnchorEl1(null);
        switch(index) {
            case 1:
                setShowMarketTA(false);
                setShowETFData(false);
                setShowStockCompare(false);
                setShowMarketSA(false);
                setShowMarketFA(true);
                break;
            case 2:
                setShowMarketFA(false);
                setShowETFData(false);
                setShowStockCompare(false);
                setShowMarketSA(false);
                setShowMarketTA(true);
                break;
            case 3:
                setShowMarketFA(false);
                setShowMarketTA(false);
                setShowETFData(true);
                setShowMarketSA(false);
                setShowStockCompare(false);
                break;
            case 4:
                setShowMarketFA(false);
                setShowMarketTA(false);
                setShowETFData(false);
                setShowMarketSA(false);
                setShowStockCompare(true);
                break;
            default:
                break;
        }
    };

    const handleClickTopMenuItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    React.useEffect(() => {
        // setShowMarketTA(true);
        setShowMarketFA(true);
    }, []);

    const classes = useStyles();

    return (
        <>
            <div className={classes.idaBox}>
            <div className={classes.title} style={{marginTop: '15px'}}>Select one of the Analysis:</div>
                <span className={classes.functionBox}>
                    <Box
                        ml={2}
                        sx={{ width: '50%',
                            maxWidth: 260
                        }}
                    >
                        <List
                            component="nav"
                            aria-label="Functions"
                        >
                            <ListItem
                                className={classes.menuList}
                                button
                                id="lock-button"
                                aria-haspopup="listbox"
                                onClick={handleClickTopMenuItem}
                            >
                                <ListItemText
                                    primary={top_menu_options[topMenuIndex]}
                                    // secondary={secondary_title[topMenuIndex-1]}
                                />
                            </ListItem>
                        </List>
                        <Menu
                            className={classes.menuItem}
                            id="function-menu"
                            anchorEl={anchorEl1}
                            open={open1}
                            onClose={handleClose1}
                            MenuListProps={{
                                'aria-labelledby': 'lock-button',
                                role: 'listbox',
                            }}
                        >
                            {top_menu_options.map((option, index) => (
                                <MenuItem
                                    className={classes.menuItem}
                                    key={option}
                                    disabled={index === 0}
                                    selected={index === topMenuIndex}
                                    onClick={(event) => handleTopMenuItemClick(event, index)}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </span>
            </div>
            { showMarketFA && (
                <div style={{margin: '-30px 5px 10px 0px'}}><IDAMarketFAView /></div>
            )}
            { showMarketTA && (
                <div style={{margin: '-30px 5px 10px 0px'}}><IDAMarketTAView /></div>
            )}
            { showETFData && (
                <div style={{margin: '-30px 5px 10px 0px'}}><IDAETFDataView /></div>
            )}
            { showStockCompare && (
                <div style={{margin: '-30px 5px 10px 0px'}}><IDAStockCompareView /></div>
            )}
        </>
    );
}
