import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";

export default function EnableMembershipDialog(props) {
  console.log('#### EnableMembershipDialog props = %o', props);
  const [values, setValues] = React.useState({
    agreement: null,
    signature: null,
  });
  const [errMsg, setErrMsg] = React.useState(false);
  const errorSignature = values.signature === '';

  const handleEnableMembership = () => {
    props.enableInvestorMembershipClick(values);
  };

  const handleCloseDlg = () => {
    console.log('#### handleCloseDlg');
    props.handleCancelDialogClick();
  };

  const handleChange = (name) => (event) => {
    setErrMsg(false);
    if (name === 'agreement') {
      setValues({ ...values, [name]: event.target.checked });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleCloseDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Enable Investor Membership Agreement'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: 18 }}>
                <Checkbox
                  style={{ marginLeft: '-12px' }}
                  checked={values.agreement}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={handleChange('agreement')}
                />
                Agree
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="signature"
                  label="Signature"
                  required
                  value={values.signature}
                  onChange={handleChange('signature')}
                  error={errorSignature}
                  helperText={errorSignature ? 'This field is required' : ' '}
                  fullWidth
                />
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDlg} color="primary" autoFocus>
            Cancel
          </Button>
          <Button
            disabled={!values.agreement || !values.signature}
            onClick={handleEnableMembership}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EnableMembershipDialog.propTypes = {
  open: PropTypes.bool,
  enableInvestorMembershipClick: PropTypes.func,
  handleCancelDialogClick: PropTypes.func,
};
