import React from 'react';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import FooterView from '../../views/Home/Footer.view';
import { stringUtils } from '../../utils/string.utils';

export default function CompanyView() {
  return (
    <div style={{ background: '#fff' }}>
      <div className="companyBanner">
        <div className="container">
          <div className="content1200">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="companyImage"></div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

      <div className="content1300" style={{ padding: '35px 50px 35px 50px' }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4 className="h4SubTitle">
              <b>{stringUtils.companyOverviewTitle}</b>
            </h4>
            <h4 className="companyH4Content" style={{ marginTop: 25 }}>
              {stringUtils.companyOverview}
            </h4>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: 35 }}>
            <h4 className="h4SubTitle">
              <b>{stringUtils.companyVisionTitle}</b>
            </h4>
            <h4 className="companyH4Content" style={{ marginTop: 25 }}>
              {stringUtils.companyVision}
            </h4>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: 35 }}>
            <h4 className="h4SubTitle">
              <b>{stringUtils.companyTeamTitle}</b>
            </h4>
            <h4 className="companyH4Content" style={{ marginTop: 25 }}>
              {stringUtils.companyTeam}
            </h4>
          </GridItem>
        </GridContainer>
      </div>
      <FooterView></FooterView>
    </div>
  );
}
