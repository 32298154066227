import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

// @material-ui/core components
import Card from "@material-ui/core/Card";

class PortfolioMonitorLiveSignalsView extends React.Component {
  constructor(props) {
    super(props);
    const { seriesData } = props;
    // console.log('SUNNY222 PortfolioMonitorLiveSignalsView seriesData = %o', seriesData);
    this.state = { seriesData };
  }

  render() {
    const options = {
      rangeSelector: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      navigator: { adaptToUpdatedData: true, series: { visible: false }, margin: 5, height: 30 },
      tooltip: {
        valueDecimals: 2,
        split: false,
        shared: true,
        borderColor: '#0BA9F9',
        borderWidth: 1,
        style: { fontSize: '13px' },
        headerFormat: '<span style="font-size: 13px">{point.key}</span><br/>',
      },
      title: 'Custom Signals',
      series: [],
    };

    const chartOptions = {
      ...options,
      series: this.state.seriesData.series,
    };

    const { classes } = this.props;
    return (
      <div className={classes.cardMargin}>
        <Card>
          <div className={classes.chartTitle}>Custom Signals</div>
          {this.state.seriesData.series.length > 0 ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
              constructorType={'stockChart'}
            />
          ) : (
            <div className={classes.noLiveSignals}>
              <span className={classes.noLiveSignalsMsg}>No Custom Signals</span>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default PortfolioMonitorLiveSignalsView;
