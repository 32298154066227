import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

// @material-ui/core components
import Card from "@material-ui/core/Card";

class PortfolioMonitorLiveExposureView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series0Data: props.seriesData.exposureSeries0Data,
      series1Data: props.seriesData.exposureSeries1Data,
      series2Data: props.seriesData.exposureSeries2Data,
    };
  }

  render() {
    const options = {
      chart: {
        height: 260, //(9 / 16) * 100 + '%', // 16:9 ratio
      },
      rangeSelector: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      navigator: { adaptToUpdatedData: true, series: { visible: false }, margin: 5, height: 30 },
      tooltip: {
        valueDecimals: 2,
        split: false,
        shared: true,
        borderColor: '#0BA9F9',
        borderWidth: 1,
        style: { fontSize: '13px' },
        headerFormat: '<span style="font-size: 13px">{point.key}</span><br/>',
      },
      title: 'Exposure',
      series: [
        {
          type: 'line',
          name: 'Long',
          data: [],
        },
        {
          type: 'line',
          name: 'Short',
          data: [],
        },
        {
          type: 'line',
          name: 'Cash',
          data: [],
        },
      ],
    };

    const chartOptions = {
      ...options,
      series: [
        {
          data: this.state.series0Data,
          name: 'Long',
          type: 'line',
          color: '#7cb5ec',
        },
        {
          data: this.state.series1Data,
          name: 'Short',
          type: 'line',
          color: '#FFA450',
        },
        {
          data: this.state.series2Data,
          name: 'Cash',
          type: 'line',
          color: '#90ee7e',
        },
      ],
    };
    const { classes } = this.props;
    return (
      <div className={classes.cardMargin}>
        <Card>
          <div className={classes.chartTitle}>Exposure</div>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            constructorType={'stockChart'}
          />
        </Card>
      </div>
    );
  }
}

export default PortfolioMonitorLiveExposureView;
