import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import {deleteForumCommentTopic} from "../../utils/forum.utils";
import {makeStyles} from "@material-ui/core/styles";

const styles = {

    newsletter: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        backgroundColor: 'whitesmoke',
        padding: '10px 50px 50px 50px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        height: '80%',
        overflow: 'auto',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        opacity: 0.4,
    },
    ol: {
        padding: 0,
        margin: 'd0 0 0 1em',
    },
    ul: {
        padding: 0,
        margin: 'd0 0 0 1em',
    },

    bigtitle: { fontFamily: 'Arial', color:'royalblue', fontWeight: 'bold', fontSize: '20px', margin:'20px 10px', paddingTop: '20px'},
    subtitle: { fontFamily: 'Arial, Helvetica', color: 'black', fontWeight: 'bold', fontSize: '16px', margin:0, padding: '.8em 0 0 0', textDecoration: 'underline'},
    announcement: { fontFamily: 'Arial, Helvetica', color:' black', fontWeight: 'normal', fontSize: '14px', margin: 0, padding: '.5em 0 0 0'},
    marketRating: { textDecoration: 'underline', color:' black', fontSize: '14px', fontWeight: 'bold'},
    rating: { fontFamily: 'Arial, Helvetica', color: 'black', fontWeight: 'normal', fontSize: '14px', margin:0 }
};

const useStyles = makeStyles(styles);

function Newsletter({onClick, date, subtitle1, marketView, subtitle2, marketRating, subtitle3, note}) {
    const classes = useStyles();

    return (
        <>
            <div className={classes.overlay} onClick={onClick} />
            <div className={classes.newsletter} onClick={onClick}>
                <div align="center" className={classes.bigtitle}>Weekly Newsletter {date}</div>
                <div align="left" className={classes.subtitle}>{subtitle1}</div>
                <div class={classes.announcement}>
                    {ReactHtmlParser(String(marketView))}
                </div>
                {marketRating && (
                    <span className={classes.marketRating}>{subtitle2}</span>
                )}
                {marketRating && (
                    <span className={classes.rating}> {marketRating} (Total 5 levels: Bearish, Bearish to Neutral, Neutral, Neutral to Bullish, Bullish)</span>
                )}
                {note && (
                    <div align="left" className={classes.subtitle}>{subtitle3}</div>
                )}
                {note && (
                    <div className={classes.announcement}>
                        {ReactHtmlParser(String(note))}
                    </div>
                )}
            </div>
        </>
    );
}

export default Newsletter;
