import React, { useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {
  adjustPaperAccount,
  changeTrackAccountNickName,
  createPaperAccount,
  getTdAuthUrl,
  newEditTrackAccount,
  TrackAccount
} from '../../utils/user.utils';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import AlertView from 'components/Alert/Alert.view';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import {getWebapiRequestToken} from '../../utils/billing.utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  selectInput: {
    width: '100%',
    marginTop: 25,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: 'none' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles(styles);

export default function AddEditIBAccount(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    userName: props.dlgData?.name ?? null,
    nickName: props.dlgData?.nickName ?? null,
    accountNumber: props.dlgData?.accountNum ?? null,
    accountId: props.dlgData?.id?? null,
    password: null,
    value: null,
    accountType: props.accountType,
  });
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });
  const [errMsg, setErrMsg] = React.useState(false);
  const errorUserName = values.userName === values.userName === '';
  const errorPassword = values.password === '';
  const errorAccount = values.accountNumber === '';
  const [errorValue, setErorValue] = React.useState(false);

  const disabled =
    values.accountType === 'IBAccount'
      ? !values.userName || !values.password || !values.accountNumber
      : values.accountType === 'PIPAAccount'? (values.value? false : !values.accountNumber) : !values.nickName;

  const pop = (url) => {
    const popup = window.open(
        url,
        '_self'
        // 'toolbar=0,location=0,directories=0,status=1,menubar=0,titlebar=0,scrollbars=1,resizable=1,width=600,height=700'
    );
    return popup !== null && typeof popup !== 'undefined';
  };

  const handleWebapiApply = () => {
    if (props.dlgType === 'Add') {
      getWebapiRequestToken(values.accountNumber, values.nickName)
          .then((res) => {
            console.debug('AddEditIBAccount: res = %o', res);
            if (res.status === 200) {
              console.debug('AddEditIBAccount: handleWebapiApply - NO ERROR');
              // props.handleActionEditDialogClick(false, true, res.data?.data);
              const data = res && res?.data ? res.data?.data : null;
              if (data) {
                const url = `https://www.interactivebrokers.com/authorize?oauth_token=${data}`;
                pop(url);
              }
            }
          })
          .catch((error) => {
            console.debug('handleWebapiApply error = %o', error);
            setOpenAlertMsg({alert: true, alertContent: error.data.errMsg, severity: 'error'});
          });
    } else if (props.dlgType === 'Edit') {
      changeTrackAccountNickName(values.nickName, values.accountId)
          .then((res) => {
            if (res.status === 200) {
              props.handleActionEditDialogClick(false, true, res.data?.data);
            }
          })
          .catch((error) => {
            console.log('createPiPAAccount error = %o', error);
            setOpenAlertMsg({alert: true, alertContent: error.data.errMsg, severity: 'error'});
          });
    }
  };

  const handleTDApply = () => {
    if (props.dlgType === 'Add') {
      getTdAuthUrl(values.accountNumber, values.nickName)
          .then((res) => {
            if (res.status === 200) {
              const tdUrl = res && res?.data ? res.data?.data : null;
              if (tdUrl)
                pop(tdUrl);
            }
            // console.debug('getTDCallback: tdUrl = %o', tdUrl);
          })
          .catch((error) => {
            console.log('handleTDApply error = %o', error);
          });
    } else if (props.dlgType === 'Edit') {
      changeTrackAccountNickName(values.nickName, values.accountId)
          .then((res) => {
            if (res.status === 200) {
              props.handleActionEditDialogClick(false, true, res.data?.data);
            }
          })
          .catch((error) => {
            // console.log('createPiPAAccount error = %o', error);
            setOpenAlertMsg({alert: true, alertContent: error.data.errMsg, severity: 'error'});
          });
    }
  };

  const handlePIPAApply = () => {
    if (props.dlgType === 'Add') {
      createPaperAccount(values.nickName, values.value)
          .then((res) => {
            if (res.status === 200) {
              props.handleActionEditDialogClick(false, true, res.data?.data);
            }
          })
          .catch((error) => {
            console.log('createPiPAAccount error = %o', error);
            setOpenAlertMsg({alert: true, alertContent: error.data.errMsg, severity: 'error'});
          });
    } else if (props.dlgType === 'Edit') {
      changeTrackAccountNickName(values.nickName, values.accountId)
          .then((res) => {
            if (res.status === 200) {
              props.handleActionEditDialogClick(false, true, res.data?.data);
            }
          })
          .catch((error) => {
            // console.log('createPiPAAccount error = %o', error);
            setOpenAlertMsg({alert: true, alertContent: error.data.errMsg, severity: 'error'});
          });
    }
  };

  const handlePIPAAdjust = () => {
    const diff = values.value - props.dlgData.netLiquidation;
    adjustPaperAccount(values.accountId, diff)
        .then((res) => {
          if (res.status === 200) {
            // console.log('data %o', res);
            props.handleActionEditDialogClick(false, true, res.data?.data);
          }
        })
        .catch((error) => {
          // console.log('adjustPipaAccount error = %o', error);
          setOpenAlertMsg({ alert: true, alertContent: error.data.errMsg, severity: 'error' });
        });
  };

  const handleOnClickApply = () => {
    console.log("Account Type %s", values.accountType )
    if ( values.accountType === 'SchwabAccount') {
      handleTDApply();
    } else if (values.accountType === 'IBWebAPI') {
      handleWebapiApply();
    } else if (values.accountType === 'PIPAAccount') {
      if (props.dlgType === 'Alter')
        handlePIPAAdjust();
      else
        handlePIPAApply();
    } else {
      // values.accountType === IBAccount
      const broker = 'IB';
      const params: TrackAccount = {
        userName: values.userName,
        account: values.accountNumber,
        broker,
        trackAccountName: values.nickName,
        tradeType: props.tradingType === 'Paper Trade' ? `SIMU_${broker}` : `MONEY_${broker}`,
        password: values.password,
      };
      if (props.dlgType === 'Edit') {
        params.id = props.dlgData.id;
      }
      console.debug('AddEditIBAccount: handleOnClickApply params = %o', params);
      newEditTrackAccount(params)
        .then((res) => {
          console.debug('AddEditIBAccount: res = %o', res);
          if (res.status === 200) {
            console.debug('AddEditIBAccount: signUpAccount - NO ERROR');
            props.handleActionEditDialogClick(false, true);
          }
        })
        .catch((error) => {
          console.debug('Add/Edit Broker account error = %o', error);
          setOpenAlertMsg({ alert: true, alertContent: error.data.errMsg, severity: 'error' });
        });
    }
  };

  const handleChange = (name) => (event) => {
    setErrMsg(false);
    setValues({ ...values, [name]: event.target.value });
  };

  const handleValueChange = () => (event) => {
    setErorValue(false);
    const value = event.target.value;
    if(isNaN(+value)) {
      setErorValue(true);
    } else {
      if (event.target.value > 1000000 || event.target.value <0) {
        setErorValue(true);
      } else {
        setValues({...values, ['value']: event.target.value});
      }
    }
  };

  const handleClose = () => {
    props.handleActionEditDialogClick(false);
  };

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  /*
  const dlgTitle =
    values.accountType === 'IBAccount'
      ? `${props.dlgType} ${props.tradingType} Account`
      : `${props.dlgType} Webapi Account`;
  */

  let dlgTitle;
  if (props.dlgType === 'Add') {
    if (values.accountType === 'SchwabAccount') {
      dlgTitle = `${props.dlgType} Schwab Account`;
    } else if (values.accountType === 'PIPAAccount') {
      dlgTitle = `${props.dlgType} iPAPA Paper Account`;
    } else if (values.accountType === 'IBWebAPI') {
      console.log("trading Type %s", props.dlgType)
      dlgTitle = `${props.dlgType} IB Account`;
    } else {
      dlgTitle = `${props.dlgType} Account`;
    }
  } else if (props.dlgType === 'Edit') {
    if (props.tradingType === 'Schwab Money') {
      values.accountType = 'SchwabAccount';
      dlgTitle = `${props.dlgType} Schwab Account`;
    } else if (props.tradingType  === 'iPIPA Paper') {
      values.accountType = 'PIPAAccount';
      dlgTitle = `${props.dlgType}  iPAPA Paper Account`;
    } else if (props.tradingType  === 'IB Paper' || props.tradingType  === 'IB Money' ) {
      values.accountType = 'IBWebAPI';
      dlgTitle = `${props.dlgType}  IB Account`;
    }
  } else if (props.dlgType === 'Alter') {
    if (props.tradingType  === 'iPIPA Paper')
      values.accountType = 'PIPAAccount';
    dlgTitle = `Adjust Account Amount`;
  }

    return (
    <div>
      {openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle style={{ width: 450 }} id="customized-dialog-title" onClose={handleClose}>
          {dlgTitle}
        </DialogTitle>
        <DialogContent dividers>
          { (values.accountType === 'IBWebAPI' || values.accountType === 'Schwabccount') && ( props.dlgType === 'Add' || props.dlgType === 'Edit') && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl className={classes.selectInput}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="username"
                    label="User Name"
                    required
                    value={values.userName}
                    onChange={handleChange('userName')}
                    error={errorUserName}
                    helperText={errorUserName? 'This field is required' : ' '}
                    disabled={props.dlgType === 'Edit'}
                    fullWidth
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          )}
          { (values.accountType === 'IBWebAPI' || values.accountType === 'SchwabAccount' || values.accountType === 'PIPAAccount') && ( props.dlgType === 'Add' || props.dlgType === 'Edit') && (
              <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl className={classes.selectInput}>
                <TextField
                  margin="dense"
                  id="nickName"
                  label="Account Nickname"
                  value={values.nickName}
                  onChange={handleChange('nickName')}
                  fullWidth
                />
              </FormControl>
            </GridItem>
          </GridContainer>
          )}
          { (values.accountType === 'IBWebAPI' || values.accountType === 'SchwabAccount') && ( props.dlgType === 'Add' || props.dlgType === 'Edit') && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl className={classes.selectInput}>
                <TextField
                  margin="dense"
                  id="accountNumber"
                  label="Account Number"
                  required
                  value={values.accountNumber}
                  onChange={handleChange('accountNumber')}
                  error={errorAccount}
                  helperText={errorAccount ? 'This field is required' : ' '}
                  disabled={props.dlgType === 'Edit'}
                  fullWidth
                />
              </FormControl>
            </GridItem>
          </GridContainer>
          )}
          {values.accountType === 'IBAccount' && props.dlgType === 'Add' && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl className={classes.selectInput}>
                  <TextField
                    margin="dense"
                    id="password"
                    label="Password"
                    type="password"
                    required
                    value={values.password}
                    onChange={handleChange('password')}
                    error={errorPassword}
                    helperText={errorPassword ? 'This field is required' : ' '}
                    fullWidth
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          )}
          {values.accountType === 'PIPAAccount' && props.dlgType === 'Add' && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.selectInput}>
                    <TextField
                        margin="dense"
                        id="value"
                        label="Initial Ammount (Max $1,000,000)"
                        required
                        value={values.value}
                        onChange={handleValueChange()}
                        error={errorValue}
                        helperText={errorValue ? 'Please enter a valid value' : ' '}
                        fullWidth
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
          )}
          {values.accountType === 'PIPAAccount' && props.dlgType === 'Alter'  && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.selectInput}>
                    <TextField
                        margin="dense"
                        id="value"
                        label="Max Ammount (Max $1,000,000)"
                        required
                        value={values.value}
                        onChange={handleValueChange()}
                        error={errorValue}
                        helperText={errorValue ? 'Please enter a valid value' : ' '}
                        fullWidth
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={disabled}
            onClick={handleOnClickApply}
            color="primary"
            classes={{ label: classes.label }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AddEditIBAccount.propTypes = {
  dlgType: PropTypes.string,
  open: PropTypes.bool,
  tradingType: PropTypes.string,
  dlgData: PropTypes.object,
  accountType: PropTypes.string,
  handleActionEditDialogClick: PropTypes.func,
};
