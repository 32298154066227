import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function StrategyTrackingInfoTableView(props) {
  const classes = useStyles();
  const { trackItem, strategyType, benchmarkSymbol } = props;

  const totalReturn =
    strategyType === 'tracking'
      ? (trackItem?.cumReturn * 100).toFixed(2) + '%'
      : (trackItem?.totalReturn * 100).toFixed(2) + '%';

  const bTotalReturn =
    strategyType === 'tracking'
      ? (trackItem?.benchmarkCumReturn * 100).toFixed(2) + '%'
      : (trackItem?.benchmarkTotalReturn * 100).toFixed(2) + '%';

  const annualizedReturn =
    strategyType === 'tracking'
      ? (trackItem?.algoAnnualReturn * 100).toFixed(2) + '%'
      : (trackItem?.annualReturn * 100).toFixed(2) + '%';

  const bAnnualizedReturn = (trackItem?.benchmarkAnnualReturn * 100).toFixed(2) + '%';

  const sCagr =
    strategyType === 'tracking'
      ? trackItem?.algoCAGR === 0
        ? '0%'
        : trackItem?.algoCAGR
        ? (trackItem?.algoCAGR * 100).toFixed(2) + '%'
        : 'N/A'
      : trackItem.cagr === 0
      ? '0%'
      : trackItem?.cagr
      ? (trackItem.cagr * 100).toFixed(2) + '%'
      : 'N/A';

  const bCagr =
    trackItem?.benchmarkCAGR === 0
      ? '0%'
      : trackItem?.benchmarkCAGR
      ? (trackItem?.benchmarkCAGR * 100).toFixed(2) + '%'
      : 'N/A';

  const sAlpha =
    trackItem?.alpha === 0 ? '0' : trackItem?.alpha ? (trackItem?.alpha).toFixed(2) : 'N/A';
  const sBeta =
    trackItem?.beta === 0 ? '0' : trackItem?.beta ? (trackItem?.beta).toFixed(2) : 'N/A';
  const sSharpe =
    trackItem?.sharpe === 0 ? '0' : trackItem?.sharpe ? (trackItem?.sharpe).toFixed(2) : 'N/A';
  const bSharpe =
    trackItem?.benchmarkSharpe === 0
      ? '0'
      : trackItem?.benchmarkSharpe
      ? (trackItem?.benchmarkSharpe).toFixed(2)
      : 'N/A';
  const sSortino =
    trackItem?.sortino === 0 ? '0' : trackItem?.sortino ? (trackItem?.sortino).toFixed(2) : 'N/A';
  const bSortino =
    trackItem?.benchmarkSortino === 0
      ? '0'
      : trackItem?.benchmarkSortino
      ? (trackItem?.benchmarkSortino).toFixed(2)
      : 'N/A';
  const sVolatility =
    strategyType === 'tracking'
      ? trackItem?.algoVolatility === 0
        ? '0%'
        : trackItem?.algoVolatility
        ? (trackItem?.algoVolatility * 100).toFixed(2) + '%'
        : 'N/A'
      : trackItem?.volatility === 0
      ? '0%'
      : trackItem?.volatility
      ? (trackItem?.volatility * 100).toFixed(2) + '%'
      : 'N/A';

  const bVolatility =
    trackItem?.benchmarkVolatility === 0
      ? '0%'
      : trackItem?.benchmarkVolatility
      ? (trackItem?.benchmarkVolatility * 100).toFixed(2) + '%'
      : 'N/A';

  const sMaxDrawdown =
    trackItem?.maxDrawdown === 0
      ? '0%'
      : trackItem?.maxDrawdown
      ? (trackItem?.maxDrawdown * 100).toFixed(2) + '%'
      : 'N/A';
  const bMaxDrawdown =
    trackItem?.benchmarkMaxDrawdown === 0
      ? '0%'
      : trackItem?.benchmarkMaxDrawdown
      ? (trackItem?.benchmarkMaxDrawdown * 100).toFixed(2) + '%'
      : 'N/A';

  const rows = [
    createData('Total Return', totalReturn, bTotalReturn),
    createData('Annualized Return', annualizedReturn, bAnnualizedReturn),
    createData('CAGR', sCagr, bCagr),
    createData('Alpha', sAlpha, 0),
    createData('Beta', sBeta, 1),
    createData('Sharpe', sSharpe, bSharpe),
    createData('Sortino', sSortino, bSortino),
    createData('Volatility', sVolatility, bVolatility),
    createData('Max Drawdown', sMaxDrawdown, bMaxDrawdown),
  ];

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="right">Strategy</StyledTableCell>
            <StyledTableCell align="right">Benchmark ({benchmarkSymbol})</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.calories}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

StrategyTrackingInfoTableView.propTypes = {
  trackItem: PropTypes.object,
  strategyType: PropTypes.string,
  benchmarkSymbol: PropTypes.string,
};
