import React from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-ui/core/Card';
import SortIcon from '@material-ui/icons/ArrowDownward';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { getEstDateTime2, getESTTimeZone } from '../../utils/common';

const styles = {
  pageMarginTop: {
    marginTop: 20,
  },
  tablePadding: {
    paddingRight: 20,
    paddingLeft: 20,
  },
  expandableDiv: {
    margin: 10,
    fontSize: 12,
    fontWeight: 400,
    color: '#000',
    borderBottom: '1px solid #ccc',
  },
};

const columns = [
  {
    name: 'Date',
    selector: 'time',
    sortable: true,
    minWidth: '180px',
    cell: (row) => {
      return getEstDateTime2(row.time);
    },
  },
  {
    name: 'Symbol',
    selector: 'symbol',
    sortable: true,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: true,
  },
  {
    name: 'Share',
    selector: 'shares',
    sortable: true,
    right: true,
    width: '60px',
  },
  {
    name: 'Order Status',
    selector: 'status',
    sortable: true,
  },
];

const useStyles = makeStyles(styles);

const ExpandableComponent = (data: any) => (
  <div
    style={{
      margin: 10,
      fontSize: 12,
      fontWeight: 400,
      color: '#000',
      borderBottom: '1px solid #ccc',
    }}
  >
    {data.data.executions.map(function (datarecord, index) {
      return (
        <div key={index}>
          {getEstDateTime2(datarecord.execTime, 'MMM DD, YYYY hh:mm A z')}&nbsp;&nbsp;{datarecord.shares}
          shares&nbsp;&nbsp;price:$
          {datarecord.price}&nbsp;&nbsp;commission:${datarecord.commission.toFixed(2)}&nbsp;&nbsp;exchange:
          {datarecord.exchange}
        </div>
      );
    })}
  </div>
);

export default function PortfolioMonitorOrdersView(props) {
  const classes = useStyles();
  const { orderData } = props;

  return (
    <div className={classes.pageMarginTop}>
      <Card>
        <div className={classes.tablePadding}>
          <DataTable
            title="Order"
            data={orderData}
            columns={columns}
            fixedHeaderScrollHeight="50vh"
            fixedHeader
            highlightOnHover
            striped
            defaultSortFieldId={1}
            sortIcon={<SortIcon />}
            expandableRows
            expandableRowsComponent={<ExpandableComponent />}
          />
        </div>
      </Card>
    </div>
  );
}

PortfolioMonitorOrdersView.propTypes = {
  orderData: PropTypes.array,
};
