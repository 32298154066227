import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
// @material-ui/core components
import { withStyles, makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GoPiPaGridItem from 'components/GoPipa/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomTabs from 'components/CustomTabs/CustomTabs.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Receipt from '@material-ui/icons/Receipt';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PortFolioView from 'components/GoPipa/Portfolio/PortFolio.view';
import { PortfolioList, getPortfolioList } from '../../utils/strategy.utils';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getLoginUser } from '../../utils/user.utils';
import { stringUtils } from '../../utils/string.utils';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  memberShipClass: {
    color: '#000000',
    opacity: '0.8',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  waitingMsg: {
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 70,
    marginLeft: -80,
  },
  circularProgressTop: {
    marginTop: 20,
  },
  circularProgressDiv: {
    border: '1px solid 1px solid #d3d3d3',
    display: 'flex',
    justifyContent: 'center',
    height: 100,
  },
  cardBodyStyle: {
    paddingLeft: 10,
    paddingRight: 10,
    border: '1px solid transparent',
    borderRadius: 4,
    borderColor: '#7a8cf7',
  },
};

const useStyles = makeStyles(styles);

export default function PortfolioListView() {
  const [portfolioType, setPortfolioType] = React.useState('Core_Strategy');
  const [portfolioStatus, setPortfolioStatus] = React.useState('Core Activated');
  const [investorState, setInvestorState] = React.useState(null);
  const [responseData, setResponseData] = React.useState(null);
  const [progress, setProgress] = React.useState(true);
  const [stoppedResponseData, setStoppedResponseData] = React.useState(null);
  const classes = useStyles();

  const handleTabChange = (event, value) => {
    const strategyType = 'Core_Strategy';
    setPortfolioType(strategyType);
    setPortfolioStatus(value === 1 ? 'Core Stopped' : 'Core Activated');
    const params: PortfolioList = {
      strategyType,
      fetchDetail: true,
      status: value === 1 ? 'Stop' : " <> 'Stop'",
      portfolioClass: 'Investor',
    };
    setProgress(true);
    getPortfolioList(params)
      .then((response) => {
        value === 1
          ? setStoppedResponseData(response.data.data)
          : setResponseData(response.data.data);
        setProgress(false);
      })
      .catch((error) => {
        console.log(error);
        setProgress(false);
      });
  };

  const fetchUserAccount = React.useCallback(() => {
    getLoginUser(null)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          setInvestorState(data.data.investorState);
          if (data.data.investorState !== 'Disabled') {
            fetchData();
          }
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  }, []);

  const fetchData = React.useCallback(() => {
    handleTabChange({}, 0);
  }, []);

  React.useEffect(() => {
    fetchUserAccount();
  }, []);

  return (
    <div className="mainPagePanel">
      <GridContainer>
        {investorState && investorState === 'Disabled' ? (
          <div style={{ marginLeft: 45, marginTop: 20 }}>
            <Typography component={'span'} variant={'h6'}>
              Your membership is disabled so you cannot view this page
              <br></br>
              Please go to
              <Link to="/admin/useraccount"> My Account </Link>
              to enable your membership in order to access {stringUtils.myQqsPortfolio} details
            </Typography>
          </div>
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              onTabChange={handleTabChange}
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: stringUtils.myCurrentAqsPortfolio,
                  tabIcon: Receipt,
                  tabContent: (
                    <div style={{ marginTop: -20 }}>
                      {progress ? (
                        <div className={classes.circularProgressDiv}>
                          <CircularProgress className={classes.circularProgressTop} />
                          <div className={classes.waitingMsg}>Please wait...</div>
                        </div>
                      ) : (
                        <div>
                          {responseData && responseData.length > 0 ? (
                            <GridContainer>
                              {responseData.map((value, d) => (
                                <GoPiPaGridItem key={d} xs={12} sm={6} md={4}>
                                  <Card style={{ marginBottom: 'unset' }}>
                                    <CardBody className={classes.cardBodyStyle}>
                                      <PortFolioView
                                        portfolioStatus={portfolioStatus}
                                        portfolioType={portfolioType}
                                        strategyDetail={value}
                                      />
                                    </CardBody>
                                  </Card>
                                </GoPiPaGridItem>
                              ))}
                            </GridContainer>
                          ) : (
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                                <Typography>
                                  <span>No Current Portfolio. Please go to </span>
                                  <Link style={{ color: '#00acc1' }} to="/admin/strategy">
                                    {stringUtils.aqsStrategies}
                                  </Link>{' '}
                                  <span> to create one</span>
                                </Typography>
                              </GridItem>
                            </GridContainer>
                          )}
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  tabName: stringUtils.myStoppedAqsPortfolio,
                  tabIcon: HighlightOffIcon,
                  tabContent: (
                    <div>
                      {stoppedResponseData && stoppedResponseData.length > 0 ? (
                        <GridContainer>
                          {stoppedResponseData.map((value, d) => (
                            <GoPiPaGridItem key={d} xs={12} sm={6} md={4}>
                              <Card style={{ marginBottom: 'unset' }}>
                                <CardBody className={classes.cardBodyStyle}>
                                  <PortFolioView
                                    portfolioStatus={portfolioStatus}
                                    portfolioType={portfolioType}
                                    strategyDetail={value}
                                  />
                                </CardBody>
                              </Card>
                            </GoPiPaGridItem>
                          ))}
                        </GridContainer>
                      ) : (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <Typography>No Stopped Portfolio.</Typography>
                          </GridItem>
                        </GridContainer>
                      )}
                    </div>
                  ),
                },
                // {
                //   tabName: 'SATELLITE PORTFOLIO',
                //   tabIcon: AccountTree,
                //   tabContent: (
                //     <div>
                //       {satResponseData && (
                //         <GridContainer>
                //           {satResponseData.map((value, d) => (
                //             <GridItem key={d} xs={6} sm={6} md={6}>
                //               <Card style={{ marginBottom: 'unset' }}>
                //                 <CardBody>
                //                   <PortFolioView
                //                     portfolioType={portfolioType}
                //                     strategyDetail={value}
                //                   />
                //                 </CardBody>
                //               </Card>
                //             </GridItem>
                //           ))}
                //           ;
                //         </GridContainer>
                //       )}
                //     </div>
                //   ),
                // },
                // {
                //   tabName: 'NEW',
                //   tabIcon: AddCircleOutlineRoundedIcon,
                //   tabContent: (
                //     <div className={classes.root}>
                //       <Stepper activeStep={activeStep}>
                //         {steps.map((label, index) => {
                //           const stepProps = {};
                //           const labelProps = {};
                //           if (isStepOptional(index)) {
                //             labelProps.optional = <Typography variant="caption">Optional</Typography>;
                //           }
                //           if (isStepSkipped(index)) {
                //             stepProps.completed = false;
                //           }
                //           return (
                //             <Step key={label} {...stepProps}>
                //               <StepLabel {...labelProps}>{label}</StepLabel>
                //             </Step>
                //           );
                //         })}
                //       </Stepper>
                //       <div>
                //         {activeStep === steps.length ? (
                //           <div>
                //             <Typography className={classes.instructions}>
                //               All steps completed - you&apos;re finished
                //             </Typography>
                //             <Button onClick={handleReset} className={classes.button}>
                //               Reset
                //             </Button>
                //           </div>
                //         ) : (
                //           <div>
                //             <Typography className={classes.instructions}>
                //               {getStepContent(activeStep)}
                //             </Typography>
                //             <div>
                //               <Button
                //                 disabled={activeStep === 0}
                //                 onClick={handleBack}
                //                 className={classes.button}
                //               >
                //                 Back
                //               </Button>
                //               {isStepOptional(activeStep) && (
                //                 <Button
                //                   variant="contained"
                //                   color="primary"
                //                   onClick={handleSkip}
                //                   className={classes.button}
                //                 >
                //                   Skip
                //                 </Button>
                //               )}
                //
                //               <Button
                //                 variant="contained"
                //                 color="primary"
                //                 onClick={handleNext}
                //                 className={classes.button}
                //               >
                //                 {activeStep === steps.length - 1
                //                   ? 'Create Portfolio and Finish'
                //                   : 'Next'}
                //               </Button>
                //             </div>
                //           </div>
                //         )}
                //       </div>
                //     </div>
                //   ),
                // },
                // {
                //   tabName: 'Activity',
                //   tabIcon: HistoryIcon,
                //   tabContent: (
                //     <Table
                //       tableHeaderColor="warning"
                //       tableHead={['ID', 'Date', 'Portfolio', 'Strategy ID', 'Status', 'Description']}
                //       tableData={portfolioActivity}
                //     />
                //   ),
                // },
              ]}
            />
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
