import React, { useState } from 'react';
import { stringUtils } from '../../utils/string.utils';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import PropTypes from 'prop-types';
import userRiskLevelDetailsImg from 'assets/img/userRiskLevelDetails.png';
import { signUserRiskLevel } from '../../utils/user.utils';
import { environment } from '../../environments/environment';

export default function ViewUserRiskLevelDialog(props) {
  const dlgTitle = 'User Risk Level Details';
  const [signature, setSignature] = useState(null);
  const [errorSignature, setErrorSignature] = React.useState(false);

  const handleClose = () => {
    props.handleViewUserRiskLevelClick();
  };

  const handleConfirm = () => {
    signUserRiskLevel(signature)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          const riskLevel = `R${props.data.riskLevel}: ${props.data.riskText}`;
          localStorage.setItem(environment.userRiskLevelKey, riskLevel);
          props.handleViewUserRiskLevelClick(riskLevel);
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  };

  const handleSignatureChange = () => (event) => {
    setSignature(event.target.value);
    if (!event.target.value || event.target.value.toLowerCase() !== props.data.name.toLowerCase()) {
      setErrorSignature(true);
    } else {
      setErrorSignature(false);
    }
  };

  const strategyMsg =
    props.data?.message && props.data.message.length > 1
      ? 'Currently you are subscribing to strategies'
      : 'Currently you are subscribing to strategy';

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dlgTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img src={userRiskLevelDetailsImg} alt="..." />
          </DialogContentText>
        </DialogContent>
        {props.data?.message === null ? (
          <DialogActions style={{ justifyContent: 'left', paddingLeft: 25 }}>
            {props.data && (
              <div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      <span style={{ color: 'blue', fontWeight: 500, marginTop: 20 }}>
                        Based on your answers, your risk level is R{props.data.riskLevel}:{' '}
                        {props.data.riskText}.
                      </span>
                    </div>
                    <div>
                      <span style={{ color: 'blue', fontWeight: 500 }}>
                        If you agree with this result, please sign your name. Otherwise, please
                        click on Redo to do it again.
                      </span>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8} style={{ marginTop: 30 }}>
                    <span
                      style={{ marginRight: 30, color: '#3f51b5', fontWeight: 500 }}
                    >{`Please print your name (${props.data.name})`}</span>
                    <TextField
                      style={{ width: 250, marginTop: -25 }}
                      margin="dense"
                      id="signature"
                      label="Signature"
                      required
                      value={signature}
                      onChange={handleSignatureChange()}
                      error={errorSignature}
                      helperText={
                        errorSignature
                          ? signature === ''
                            ? stringUtils.fieldRequired
                            : stringUtils.printedNameMsg
                          : ' '
                      }
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1} style={{ marginTop: 30 }}>
                    <Button
                      style={{
                        textTransform: 'capitalize',
                        fontSize: 14,
                      }}
                      onClick={handleClose}
                      color="primary"
                    >
                      Close
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1} style={{ marginTop: 30 }}>
                    <Button
                      style={{
                        textTransform: 'capitalize',
                        fontSize: 14,
                      }}
                      onClick={handleClose}
                      color="primary"
                    >
                      Redo
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1} style={{ marginTop: 30 }}>
                    <Button
                      style={{
                        textTransform: 'capitalize',
                        fontSize: 14,
                      }}
                      onClick={handleConfirm}
                      color="primary"
                      disabled={!signature || errorSignature}
                    >
                      Agree
                    </Button>
                  </GridItem>
                </GridContainer>
              </div>
            )}
          </DialogActions>
        ) : (
          <DialogActions style={{ justifyContent: 'left', paddingLeft: 25, marginRight: 10 }}>
            {props.data ? (
              <div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      <span style={{ color: 'blue', fontWeight: 500, marginTop: 20 }}>
                        Based on your answers, your risk level is R{props.data.riskLevel}:{' '}
                        {props.data.riskText}. {strategyMsg}:
                      </span>
                    </div>
                    <div>
                      <span>
                        <ul style={{ color: '#2d3748', fontWeight: 500 }}>
                          {props.data.message.map((item) => (
                            <li style={{ color: 'black' }}>
                              {item.name} with ID: {item.id}
                            </li>
                          ))}
                        </ul>
                      </span>
                      <span style={{ color: 'blue', fontWeight: 500 }}>
                        which have higher risk levels than your risk level.
                      </span>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <span style={{ color: 'blue', fontWeight: 500 }}>
                        You can do the options
                        <ul style={{ color: '#2d3748', fontWeight: 500 }}>
                          <li>
                            sign your name below and click on “Agree” to acknowledge that you fully
                            understand the risk of your current subscription to these strategies
                            with higher risk levels than your risk level. Also, you will take the
                            full responsibility to stop the subscription of these strategies later{' '}
                          </li>
                          <li>click on “Redo” to redo the risk assessment</li>
                          <li>click on “Close” to cancel the risk assessment process for now</li>
                        </ul>
                        You can do the risk assessment again after you stop the subscription of
                        these strategies
                      </span>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={9} style={{ marginTop: 30 }}>
                    <span
                      style={{ marginRight: 30, color: '#3f51b5', fontWeight: 500 }}
                    >{`Please print your name (${props.data.name})`}</span>
                    <TextField
                      style={{ width: 250, marginTop: -25 }}
                      margin="dense"
                      id="signature"
                      label="Signature"
                      required
                      value={signature}
                      onChange={handleSignatureChange()}
                      error={errorSignature}
                      helperText={
                        errorSignature
                          ? signature === ''
                            ? stringUtils.fieldRequired
                            : stringUtils.printedNameMsg
                          : ' '
                      }
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1} style={{ marginTop: 30 }}>
                    <Button
                      style={{
                        textTransform: 'capitalize',
                        fontSize: 14,
                      }}
                      onClick={handleClose}
                      color="primary"
                    >
                      Close
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1} style={{ marginTop: 30 }}>
                    <Button
                      style={{
                        textTransform: 'capitalize',
                        fontSize: 14,
                      }}
                      onClick={handleClose}
                      color="primary"
                    >
                      Redo
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1} style={{ marginTop: 30 }}>
                    <Button
                      style={{
                        textTransform: 'capitalize',
                        fontSize: 14,
                      }}
                      onClick={handleConfirm}
                      color="primary"
                      disabled={!signature || errorSignature}
                    >
                      Agree
                    </Button>
                  </GridItem>
                </GridContainer>
              </div>
            ) : (
              <div>
                <Button
                  style={{
                    textTransform: 'capitalize',
                    fontSize: 14,
                    marginLeft: 840,
                  }}
                  onClick={handleClose}
                  color="primary"
                >
                  Close
                </Button>
              </div>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

ViewUserRiskLevelDialog.propTypes = {
  open: PropTypes.bool,
  dlgData: PropTypes.object,
  handleViewUserRiskLevelClick: PropTypes.func,
  data: PropTypes.object,
};
