import React from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { environment } from '../../environments/environment';
import { getTextColor } from '../../utils/common';
import classnames from 'classnames';
// import Button from '@material-ui/core/Button';
import Button from '../../components/CustomButtons/Button.js';
import SubscribePortfolioDialog from '../../components/Dialog/SubscribePortfolioDialog';
import AlertView from '../../components/Alert/Alert.view';
import {
  startSubscribePortfolio,
  subscribePortfolio,
} from '../../utils/strategy.utils';
import { getTrackAccountAQSPortfolio } from '../../utils/user.utils';
import { getValidDateTime } from '../../utils/common';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItemIconWidth: {
    width: '30%',
    fontWeight: 400,
    fontSize: 16,
  },
  listItemTextWidth: {
    width: '70%',
  },
  listItemHeight: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '14px',
  },
}));

export default function StrategyBasicInfoView(props) {
  const classes = useStyles();
  const { strategyData } = props;
  const backTest = strategyData?.backTest ?? strategyData.backtestReports[0];
  const strategy = environment.selectDataItems['strategy'];
  const [trackAccount, setTrackAccount] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [redirectMyPortfolio, setRedirectMyPortfolio] = React.useState(false);
  const [openAlertMsg, setOpenAlertMsg] = React.useState({
    alert: false,
    alertContent: '',
    severity: 'info',
  });
  const nameColorCls = getTextColor(strategyData.name);
  const monthlySubscriptionFee =
    strategyData?.chargeType === 'Free'
      ? 'N/A'
      : strategyData?.chargeType === 'FixedMonthly'
      ? strategyData.chargeAmount.toFixed(2)
      : strategyData.chargeAmount * 100 + '%';

  const strategyNameId = `${strategyData.name} (ID: ${strategyData.id})`;

  const fetchTrackAccountData = React.useCallback(() => {
    getTrackAccountAQSPortfolio()
      .then((response) => {
        console.debug('StrategyBasicInfo.view: setTrackAccount = %o', response);
        // IB: DU819878   (Max Usable Cash: 428,868.02)
        const trackAcct = [];
        response.data.data.forEach((item: any) => {
          const accoutName = item.trackAccountName
            ? item.trackAccountName + (item.account ? ' (' + item.account + ')' : '')
            : item.account;
          const accountValue = item.usableCash ? parseFloat(item.usableCash.toFixed(2)) : 0;
          const accoutInfo = `${item.broker}: ${accoutName}  (Max Usable Cash: ${accountValue})`;
          trackAcct.push({ key: item.account, value: accoutInfo, id: item.id });
        });
        setTrackAccount(trackAcct);
      })
      .catch((error) => {
        console.debug(error);
      });
  }, []);

  React.useEffect(() => {
    fetchTrackAccountData();
  }, []);

  const handleSubscribePortfolio = (values) => {
    let trackAccountId = 0;
    trackAccount.forEach((item) => {
      if (item.key === values.tradingAccount) {
        trackAccountId = item.id;
      }
    });

    const params = {
      'portfolio.buyValuePerOrder': '',
      'portfolio.marketType': strategyData.marketType,
      'portfolio.name': values.portfolioName,
      'portfolio.note': values.portfolioDesc,
      'portfolio.portfolioClass': 'Investor',
      'portfolio.sellValuePerOrder': '',
      'portfolio.strategyId': strategyData.id,
      'portfolio.trackAccountId': trackAccountId,
      'profile.expectMinAnnualReturn': backTest.cagr
        ? parseFloat((backTest.cagr * 100).toFixed(2))
        : 0,
      'profile.importanceOrder': '123',
      'profile.investmentAmount': values.investmentAmount,
      'profile.investmentTimeframe': strategyData.minInvestmentPeriod,
      'profile.liquidateOnEmergency': values.emergencyStop === 'stopSell' ? false : true,
      'profile.portfolioType': 'NotCare',
      'profile.tolenranceMaxAnnualVolatility': parseFloat((backTest.volatility * 100).toFixed(2)),
      'profile.tolenranceMaxDrawdown': parseFloat((backTest.maxDrawdown * 100).toFixed(2)),
    };

    console.debug('Strategy BasicInfoview: handleSubscribePortfolio params = %o', params);
    subscribePortfolio(params)
      .then((response) => {
        console.debug('Strategy.view: handleSubscribePortfolio response = %o', response);
        startSubscribePortfolio(response.data.data.id)
          .then((response1) => {
            console.debug(
              'Strategy.BasicInfoview: startSubscribePortfolio response1 = %o',
              response1
            );
            setRedirectMyPortfolio(true);
            setOpen(false);
          })
          .catch((error) => {
            console.debug(error);
            setOpenAlertMsg({
              open: true,
              message: error.response.data.message,
              severity: 'error',
            });
          });
      })
      .catch((error) => {
        console.debug(error);
        setOpenAlertMsg({ alert: true, alertContent: error?.data?.errMsg, severity: 'error' });
      });
  };

  const handleCloseAlert = () => {
    setOpenAlertMsg({ alert: false, alertContent: '', severity: 'info' });
  };

  const handleOnClickSubscribeStrategy = () => {
    setOpen(true);
  };

  const handleSubscribeClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {open && (
        <SubscribePortfolioDialog
          open={open}
          strategyType="AQS"
          onSubscribePortfolio={handleSubscribePortfolio}
          onClose={handleSubscribeClose}
          trackAccount={trackAccount}
          strategy={strategyData}
          aria-labelledby="responsive-dialog-title"
        ></SubscribePortfolioDialog>
      )}
      {openAlertMsg.alert && (
        <AlertView
          onCloseAlert={handleCloseAlert}
          openAlert={openAlertMsg.alert}
          message={openAlertMsg.alertContent}
          severity={openAlertMsg.severity}
        />
      )}
      {redirectMyPortfolio && <Redirect to="/admin/strategy" />}
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Strategy Name</span>
          </ListItemIcon>
          <div>
            <span className={classnames(classes.listItemTextWidth, nameColorCls)}>
              {strategyNameId}
            </span>
            <Button
              onClick={handleOnClickSubscribeStrategy}
              color="info"
              className={classes.label}
              style={{ marginLeft: 20, height: 30 }}
            >
              Subscribe Strategy
            </Button>
          </div>
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Current Status</span>
          </ListItemIcon>
          <ListItemText className={classes.listItemTextWidth} primary={strategyData.status} />
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Create Time</span>
          </ListItemIcon>
          <ListItemText
            className={classes.listItemTextWidth}
            primary={getValidDateTime(strategyData.createTime)}
          />
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Modified Time</span>
          </ListItemIcon>
          <ListItemText
            className={classes.listItemTextWidth}
            primary={getValidDateTime(strategyData.modifiedTime)}
          />
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Developer Name</span>
          </ListItemIcon>
          <ListItemText
            className={classes.listItemTextWidth}
            primary={strategyData.developerName}
          />
        </ListItem>
        <Divider />
        <ListItem button className={classes.listItemHeight}>
          <ListItemIcon className={classes.listItemIconWidth}>
            <span>Strategy Description</span>
          </ListItemIcon>
          <ListItemText className={classes.listItemTextWidth} primary={strategyData.description} />
        </ListItem>
      </List>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Strategy Category</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy.category[strategyData.category]}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Strategy Type</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={
            strategyData?.algoTypeStr
              ? strategyData.algoTypeStr
              : strategy?.algoTypeOptions[strategyData?.algoType]
          }
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Strategy Risk Level</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy?.riskLevel[strategyData?.riskLevel]}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Investment Type</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy?.componentType[strategyData?.componentType]}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Portfolio Update Period</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData.rebalancePeriod}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Long and/or Short</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy?.longShort[strategyData?.longShort]}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Use Margin</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData.useMargin ? 'Yes' : 'No'}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Allow Paper Trading</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData.allowPaperTrading ? 'Yes' : 'No'}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Strategy Charge Type</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy?.chargeTypeOptions[strategyData?.chargeType]}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Fixed Monthly Subscription Fee</span>
        </ListItemIcon>
        <ListItemText className={classes.listItemTextWidth} primary={monthlySubscriptionFee} />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Minimum to Investor Member Class</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy?.limitToMemberClass[strategyData?.limitToMemberClass]}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>PiPA Rating</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData?.pipaRating ? strategyData.pipaRating : 'N/A'}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Market Type</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy?.marketType[strategyData?.marketType]}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Min Investment Amount</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData?.minInvestmentAmount.toLocaleString()}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Max Investment Amount</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData?.maxInvestmentAmount.toLocaleString()}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Min Investment Period</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData.minInvestmentPeriod + ' months'}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Customized dollar amount per LONG trade</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData?.specBuyValuePerOrder ? 'Yes' : 'No'}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Customized dollar amount per SHORT trade</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData?.specBuyValuePerOrder ? 'Yes' : 'No'}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Open for New Investors</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategyData.openForInvestor ? 'Yes' : 'No'}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Backtest Type</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy?.backtestType[strategyData?.backtestType]}
        />
      </ListItem>
      <Divider />
      <ListItem button className={classes.listItemHeight}>
        <ListItemIcon className={classes.listItemIconWidth}>
          <span>Trading Type</span>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextWidth}
          primary={strategy?.tradingType[strategyData?.tradingType]}
        />
      </ListItem>
      <Divider />
    </div>
  );
}

StrategyBasicInfoView.propTypes = {
  strategyData: PropTypes.object,
};
