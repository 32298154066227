import React from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Hidden from '@material-ui/core/Hidden';
import Poppers from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Notifications from '@material-ui/icons/Notifications';
import Dashboard from '@material-ui/icons/Dashboard';
import Search from '@material-ui/icons/Search';
// core components
import CustomInput from '../../components/CustomInput/CustomInput.js';
import Button from '../../components/CustomButtons/Button.js';
import LoginDialog from '../../components/Dialog/LoginDialog.js';
import SignUpDialog from '../../components/Dialog/SignUpDialog.js';
import { Link, useLocation } from 'react-router-dom';
import headerLinksStyle from '../../assets/jss/material-dashboard-react/components/headerLinksStyle.js';
import { environment } from '../../environments/environment';
import { dataItemInitial } from '../../utils/common.ts';
import { logout } from '../../utils/user.utils';
const useStyles = makeStyles(headerLinksStyle);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  // init common environments
  dataItemInitial();

  const handleLoginDialogClick = (flag) => {
    setOpen(flag);
  };

  const handleSignUpDialogClick = (flag) => {
    setOpenSignUp(flag);
  };

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleClickSignIn = () => {
    handleLoginDialogClick(true);
  };

  const handleClickSignUp = () => {
    handleSignUpDialogClick(true);
  };

  const handleClickLogout = () => {
    logout();
  };

  const location = useLocation();
  const tokenKey = localStorage.getItem(environment.tokenKey);
  const userName = localStorage.getItem(environment.userNameKey);
  const membership = localStorage.getItem(environment.membership);

  // console.debug(
  //   'AminNavbar: location.pathname = %s tokenKey = %s userName = %s',
  //   location.pathname,
  //   tokenKey,
  //   userName
  // );

  if (
    !tokenKey &&
    location.pathname !== '/admin/home' &&
    location.pathname !== '/admin/validate' &&
    location.pathname !== '/admin/company' &&
    // location.pathname !== '/admin/support' &&
    location.pathname !== '/admin/userAgreement'
  ) {
    // console.debug('#### tokenKey does not exist');
    return (
      <Redirect
        to={{
          pathname: '/admin/home',
          state: { open: true, from: 'AdminNavbarLinks' },
        }}
      />
    );
  }

  //User can't only access to account page if no membership
  if (
      membership !== 'Enabled' && membership !== 'Trial' &&
      location.pathname !== '/admin/useraccount' &&
      location.pathname !== '/admin/home' &&
      location.pathname !== '/admin/company' &&
      location.pathname !== '/admin/validate'

  ) {
    return (
        <Redirect
            to={{
              pathname: '/admin/useraccount',
              state: { open: true, from: 'AdminNavbarLinks' },
            }}
        />
    );
  }

  return (
    <div>
      <div className={classes.searchWrapper} style={{ display: 'none' }}>
        <CustomInput
          formControlProps={{
            className: classes.margin + ' ' + classes.search,
          }}
          inputProps={{
            placeholder: 'Search',
            inputProps: {
              'aria-label': 'Search',
            },
          }}
        />
        <Button color="white" aria-label="edit" justIcon round>
          <Search />
        </Button>
      </div>
      <Button
        color={window.innerWidth > 959 ? 'transparent' : 'white'}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
        style={{ display: 'none' }}
      >
        <Dashboard className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Dashboard</p>
        </Hidden>
      </Button>
      <div className={classes.manager} style={{ display: 'none' }}>
        <Button
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? 'notification-menu-list-grow' : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          <span className={classes.notifications}>5</span>
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) + ' ' + classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem onClick={handleCloseNotification} className={classes.dropdownItem}>
                      Mike John responded to your email
                    </MenuItem>
                    <MenuItem onClick={handleCloseNotification} className={classes.dropdownItem}>
                      You have 5 new tasks
                    </MenuItem>
                    <MenuItem onClick={handleCloseNotification} className={classes.dropdownItem}>
                      You{"'"}re now friend with Andrew
                    </MenuItem>
                    <MenuItem onClick={handleCloseNotification} className={classes.dropdownItem}>
                      Another Notification
                    </MenuItem>
                    <MenuItem onClick={handleCloseNotification} className={classes.dropdownItem}>
                      Another One
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      {!tokenKey && (
        <div className={classes.manager}>
          <Button id="signin" color="info" onClick={handleClickSignIn} className={classes.label}>
            Sign In
          </Button>
        </div>
      )}

      <div className={classes.manager}>
        {!tokenKey ? (
          <Button
            color="info"
            onClick={handleClickSignUp}
            className={classes.label}
            style={{ marginRight: 15 }}
          >
            Sign Up
          </Button>
        ) : (
          <span id="loginUser" style={{ fontSize: '18px'}}>
            {userName}
          </span>
        )}
      </div>

      {tokenKey && (
        <div className={classes.manager}>
          <Button
            color={window.innerWidth > 959 ? 'transparent' : 'white'}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openProfile ? 'profile-menu-list-grow' : null}
            aria-haspopup="true"
            onClick={handleClickProfile}
            className={classes.buttonLink}
            size="lg"
          >
            <Person className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Profile</p>
            </Hidden>
          </Button>
          <Poppers
            open={Boolean(openProfile)}
            anchorEl={openProfile}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openProfile }) + ' ' + classes.popperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseProfile}>
                    <MenuList role="menu">
                      <MenuItem onClick={handleCloseProfile} className={classes.dropdownItem}>
                        <Link style={{ color: 'inherit' }} to="/admin/useraccount">
                          My Account
                        </Link>
                      </MenuItem>
                      <Divider light />
                      <MenuItem onClick={handleClickLogout} className={classes.dropdownItem}>
                        Sign Out
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
      )}
      <LoginDialog
        handleLoginDialogClick={handleLoginDialogClick}
        open={open}
        forgetPassword={false}
      />
      <SignUpDialog
        handleLoginDialogClick={handleLoginDialogClick}
        handleSignUpDialogClick={handleSignUpDialogClick}
        open={openSignUp}
      />
    </div>
  );
}
