import React from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { makeStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts/highstock';
import StockChart from 'components/Chart/Stock.jsx';
import Button from 'components/CustomButtons/Button.js';
import { getTextColor } from '../../utils/common';
import { getSvg } from '../../utils/strategy.utils';
import moment from 'moment';
import ViewDetailIcon from '@material-ui/icons/ViewHeadline';

const stockOptions = {
  yAxis: [
    {
      height: '75%',
      labels: {
        align: 'right',
        x: -3,
      },
      title: {
        text: 'AAPL',
      },
    },
    {
      top: '75%',
      height: '25%',
      labels: {
        align: 'right',
        x: -3,
      },
      offset: 0,
      title: {
        text: 'MACD',
      },
    },
  ],
  series: [
    {
      data: [],
      type: 'ohlc',
      name: 'AAPL Stock Price',
      id: 'aapl',
    },
    {
      type: 'pivotpoints',
      linkedTo: 'aapl',
      zIndex: 0,
      lineWidth: 1,
      dataLabels: {
        overflow: 'none',
        crop: false,
        y: 4,
        style: {
          fontSize: 9,
        },
      },
    },
    {
      type: 'macd',
      yAxis: 1,
      linkedTo: 'aapl',
    },
  ],
};

const ExpandableComponent = (data: any) => (
  <div>
    <table style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td style={{ paddingLeft: '20px', verticalAlign: 'top', width: '40%' }}>
            <b>Summary</b> <h5>{data.description}</h5>
            <Button color="primary" style={{ marginTop: '20px' }}>
              {' '}
              Subscribe Core Portfolio
            </Button>
          </td>
          <td>
            <b>Tracking Info</b>{' '}
            <img src={getSvg(928, `.svg?t=${Math.floor(new Date().getTime() / 3600000)}`)} />
          </td>
          <td style={{ width: '50%' }}>
            <b>Backtest Info #Highcharts.stockChart#</b>
            <StockChart options={stockOptions} highcharts={Highcharts} />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
const conditionalRowStyles = [
  // {
  //   when: row => row.riskLevel==='Medium',
  //   style: {
  //     backgroundColor: 'darkgray',
  //     color: 'white',
  //     '&:hover': {
  //       cursor: 'pointer',
  //     },
  //   },
  // },
  // You can also pass a callback to style for additional customization
  // {
  //   when: row => row.minInvestmentAmount > 5000,
  //   style: (row) => [{
  //     backgroundColor: (row.riskLevel==='Medium')?'lightgreen':'red',
  //          color: 'white',
  //          '&:hover': {
  //           cursor: 'pointer'},
  //   }],
  // },
];
const strategyDetailsPathName = '/admin/strategyDetails';
const contextMessage = { singular: 'Strategy', plural: 'strategies ', message: ' to compare ' };
class ListView extends React.Component {
  constructor(props) {
    super(props);
    // console.log('strategyData = %o', this.props.strategyData);
    const columns = [
      {
        name: 'ID',
        selector: 'id',
        sortable: true,
        minWidth: '5px',
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        minWidth: '200px',
        cell: (row) => {
          return (
            <div>
              <Link
                className={getTextColor(row.name)}
                to={{
                  pathname: strategyDetailsPathName,
                  state: {
                    strategyId: row.id,
                    strategyName: row.name,

                  },
                }}
              >{`${row.name}`}</Link>
            </div>
          );
        },
      },
      {
        name: 'Period',
        sortable: true,
        selector: 'period',
        minWidth: '170px',
        cell: (row) => {
          const date = row.period.split('~');
          const startDate = `${date[0]}~`;
          const endDate = date[1];
          return (
            <div>
              {startDate}
              <br></br>
              {date[1]}
            </div>
          );
        },
      },
      {
        name: 'Total Return',
        selector: 'trackingReport.cumReturn',
        sortable: true,
        right: true,
        width: '120px',
        format: (row) => this.getFormatData(row.trackingReport?.cumReturn),
      },
      {
        name: 'Annualized Return',
        selector: 'trackingReport.algoAnnualReturn',
        sortable: true,
        right: true,
        width: '150px',
        format: (row) => this.getFormatData(row.trackingReport?.algoAnnualReturn),
      },
      {
        name: 'CAGR',
        selector: 'trackingReport.algoCAGR',
        sortable: true,
        right: true,
        format: (row) => this.getFormatData(row.trackingReport?.algoCAGR),
      },
      {
        name: 'Alpha',
        selector: 'trackingReport.alpha',
        sortable: true,
        right: true,
        // width: '80px',
        format: (row) => this.getFormatData(row.trackingReport?.alpha, false),
      },
      {
        name: 'Beta',
        selector: 'trackingReport.beta',
        sortable: true,
        right: true,
        // width: '80px',
        format: (row) => this.getFormatData(row.trackingReport?.beta, false),
      },
      {
        name: 'Sharpe',
        selector: 'trackingReport.sharpe',
        sortable: true,
        right: true,
        // width: '80px',
        format: (row) => this.getFormatData(row.trackingReport?.sharpe, false),
      },
      {
        name: 'Sortino',
        selector: 'trackingReport.sortino',
        sortable: true,
        right: true,
        // width: '80px',
        format: (row) => this.getFormatData(row.trackingReport?.sortino, false),
      },
      {
        name: 'Volatility',
        selector: 'trackingReport.algoVolatility',
        sortable: true,
        right: true,
        format: (row) => this.getFormatData(row.trackingReport?.algoVolatility),
        // cell: (row) => {
        // const ccolor =
        //   row.trackingReport.algoVolatility > 0.5
        //     ? 'secondary'
        //     : row.trackingReport.algoVolatility > 0.3
        //     ? 'primary'
        //     : '';
        // return (
        //   <div>
        //     <Chip color={ccolor} label={(row.trackingReport.algoVolatility * 100).toFixed(2) + '%'} />
        //   </div>
        // );
        // },
      },
      {
        name: 'MaxDD',
        selector: 'trackingReport.maxDrawdown',
        sortable: true,
        right: true,
        format: (row) => this.getFormatData(row.trackingReport?.maxDrawdown),
      },
      {
        name: 'Risk',
        sortable: true,
        selector: 'riskLevel',
        grow: 2,
        minWidth: '220px',
      },
    ];
    let { strategyData } = this.props;
    strategyData.forEach((item: any) => {
      const period = item?.trackingReport
        ? `${moment(item.trackingReport.startTradeDate).format('YYYY-MM-DD')} ~ ${moment(
            item.trackingReport.tradeDate
          ).format('YYYY-MM-DD')}`
        : '';
      item.period = `${period} (${item?.trackingReport?.tradingDays ?? 0} days)`;
      item.riskLevel =
        item.riskLevel !== 'MediumConservative' && item.riskLevel !== 'MediumAggressive'
          ? item.riskLevel
          : item.riskLevel === 'MediumConservative'
          ? 'Medium Conservative'
          : 'Medium Aggressive';
    });
    this.state = {
      strategyData,
      columns,
    };
  }

  getFormatData = (name, isPercentData = true) => {
    if (name) {
      if (isPercentData) {
        return name === 0 ? '0%' : name ? (name * 100).toFixed(2) + '%' : 'N/A';
      } else {
        return name === 0 ? 0 : name ? name.toFixed(2) : 'N/A';
      }
    } else {
      return 'N/A';
    }
  };

  render() {
    const { strategyData, columns } = this.state;
    return (
      <DataTable
        columns={columns}
        data={strategyData}
        fixedHeader
        striped={true}
        highlightOnHover
        noHeader={true}
        sortIcon={<ArrowDownward />}
      />
    );
  }
}

export default ListView;
