import React, { useEffect } from 'react';
import moment from 'moment';
import { environment } from '../../environments/environment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { getCompareStrategy, CompareStrategy } from '../../utils/strategy.utils';
import GridItem from '../../components/Grid/GridItem';
import CompareBacktestTableListView from './CompareBacktestTableList.view.tsx';
import GridContainer from '../../components/Grid/GridContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const styles = {
  waitingMsg: {
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 70,
    marginLeft: -80,
  },
  circularProgressTop: {
    marginTop: 20,
  },
  circularProgressDiv: {
    border: '1px solid 1px solid #d3d3d3',
    display: 'flex',
    justifyContent: 'center',
    height: 100,
  },
  period: {
    color: '#1890ff',
    outline: 'none',
    transition: 'color .3s',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '1.3em',
    position: 'relative',
    backgroundColor: '#f5f5f5',
  },
  accordionSummaryWidth: {
    width: '100%',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '14px',
    height: 28,
  },
  periodText: {
    paddingRight: 20,
  },
  divider: {
    marginTop: 15,
    background: 'rgba(0,0,0,0.87)',
    marginTop: 15,
  },
};
const useStyles = makeStyles(styles);

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    height: 45,
    '&$expanded': {
      minHeight: 45,
    },
    width: '100%',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function StrategyCompareBacktestView(props) {
  const classes = useStyles();
  const { strategyData } = props;
  const [expanded, setExpanded] = React.useState(null);
  const [portfolioPeriod, setPortfolioPeriod] = React.useState([]);
  const [backtestChart, setBacktestChart] = React.useState(null);
  const [progress, setProgress] = React.useState(true);
  const [viewDetails, setViewDetails] = React.useState(false);
  const [backTestReportData, setBackTestReportData] = React.useState(false);
  const strategyType = 'backtest';
  const selectItems = environment.selectDataItems['strategy'];

  const handleChange = (panel) => (event, newExpanded) => {
    // console.log('SUNNY handleChange panel = %s newExpanded = %s', panel, newExpanded);
    setExpanded(panel);
    // setExpanded(newExpanded ? panel : false);
  };

  const handleViewDetails = () => {
    setViewDetails(true);
  };

  const handleBackTest = () => {
    setViewDetails(false);
  };

  const getTableDataByPortfolioId = (object, portfolioId) => {
    const tableData = object.filter((obj) => {
      return obj.backtestPortfolioId === portfolioId;
    });
    // console.log('SUNNY getTableDataByPortfolioId tableData = %o', tableData);
    return tableData[0];
  };

  const getDataByPortfolioId = (object, portfolioId) => {
    const dataByPortfolioId = object.filter((obj) => {
      return obj.portfolioId === portfolioId;
    });
    console.debug(
      'portfolioId = %s dataByPortfolioId dataByPortfolioId = %o',
      portfolioId,
      dataByPortfolioId
    );
    return dataByPortfolioId[0]?.data;
  };

  const fetchCompareBacktest = () => {
    const params: CompareStrategy = {
      strategyType: 'Core_Strategy',
      fetchDetail: true,
      openForInvestor: true,
      fetchTrackingReport: true,
      status: 'Online',
      name: "not like 'ISF:%'",
    };

    const generateReport = (report: any, array: any[]) => {
      let begin = report.beginTime.substring(0, 10),
        end = report.endTime.substring(0, 10);
      let periodExists = false;
      for (let k = 0; k < array.length; k++) {
        if (array[k].begin === begin && array[k].end === end) {
          periodExists = true;
          break;
        }
      }
      if (!periodExists) {
        array.push({
          begin: begin,
          end: end,
          period: Math.abs(moment(end, 'YYYY-MM-DD').diff(moment(begin, 'YYYY-MM-DD'), 'days')),
          data: [
            {
              id: null,
              name: 'S&P500',
              category: '',
              algoType: '',
              riskLevel: '',
              totalReturn: report.benchmarkTotalReturn,
              annualReturn: report.benchmarkAnnualReturn,
              cagr: report.benchmarkCAGR,
              alpha: report.benchmarkAlpha,
              beta: report.benchmarkBeta,
              sharpe: report.benchmarkSharpe,
              sortino: report.benchmarkSortino,
              volatility: report.benchmarkVolatility,
              maxDrawdown: report.benchmarkMaxDrawdown,
            },
          ],
        });
      }
    };

    getCompareStrategy(params)
      .then((response) => {
        // console.log('SUNNY fetchCompareBacktest response = %o', response);
        const data = response?.data.data ?? [];
        let backTestReports: any[] = [];
        let sortReportRange = (array: any[]) => {
          for (let k = 0; k < array.length; k++) {
            for (let j = k + 1; j < array.length; j++) {
              if (array[j].begin < array[k].begin) {
                let tempObj = array[k];
                array[k] = array[j];
                array[j] = tempObj;
              }
            }
          }
        };
        let fillData = (array: any[], reportType: any) => {
          for (let k = 0; k < array.length; k++) {
            data.forEach((sty: any, i: number) => {
              let tsEquals = false,
                catagory = '',
                algoType = '',
                riskLevel = '';
              for (let key in selectItems.category) {
                if (sty.category === key) {
                  catagory = selectItems.category[key];
                  break;
                }
              }
              if (sty.algoTypeStr) algoType = sty.algoTypeStr;
              else {
                for (let key in selectItems.algoTypeOptions) {
                  if (sty.algoType === key) {
                    algoType = selectItems.algoTypeOptions[key];
                    break;
                  }
                }
              }
              for (let key in selectItems.riskLevel) {
                if (sty.riskLevel === key) {
                  riskLevel = selectItems.riskLevel[key];
                  break;
                }
              }
              if (sty[reportType]) {
                if (reportType === 'backtestReports') {
                  sty.backtestReports.every((back: any, j: number) => {
                    let begin = back.beginTime.substring(0, 10),
                      end = back.endTime.substring(0, 10);
                    if (array[k].begin === begin && array[k].end === end) {
                      tsEquals = true;
                      array[k].data.push({
                        id: sty.id,
                        name: sty.name,
                        category: catagory,
                        algoType: algoType,
                        riskLevel: riskLevel,
                        totalReturn: back.totalReturn,
                        annualReturn: back.annualReturn,
                        cagr: back.cagr,
                        alpha: back.alpha,
                        beta: back.beta,
                        sharpe: back.sharpe,
                        sortino: back.sortino,
                        volatility: back.volatility,
                        maxDrawdown: back.maxDrawdown,
                      });
                      return false;
                    }
                    return true;
                  });
                } else {
                  let begin = sty[reportType].beginTime.substring(0, 10),
                    end = sty[reportType].endTime.substring(0, 10);
                  if (array[k].begin === begin && array[k].end === end) {
                    tsEquals = true;
                    array[k].data.push({
                      id: sty.id,
                      name: sty.name,
                      category: catagory,
                      algoType: algoType,
                      riskLevel: riskLevel,
                      totalReturn: sty[reportType].totalReturn,
                      annualReturn: sty[reportType].annualReturn,
                      cagr: sty[reportType].cagr,
                      alpha: sty[reportType].alpha,
                      beta: sty[reportType].beta,
                      sharpe: sty[reportType].sharpe,
                      sortino: sty[reportType].sortino,
                      volatility: sty[reportType].volatility,
                      maxDrawdown: sty[reportType].maxDrawdown,
                    });
                  }
                }
              }
            });
          }
        };
        data.forEach((sty: any) => {
          if (sty.backtestReports) {
            sty.backtestReports.forEach((back: any) => {
              generateReport(back, backTestReports);
            });
          }
        });

        // 排序
        let arr1 = backTestReports.filter(
          (sub: any) =>
            sub.begin === '2007-05-01' || sub.begin === '2008-06-30' || sub.begin === '2014-01-01'
        );
        let arr2 = backTestReports.filter(
          (sub: any) =>
            sub.begin !== '2007-05-01' && sub.begin !== '2008-06-30' && sub.begin !== '2014-01-01'
        );
        sortReportRange(arr1);
        sortReportRange(arr2);
        backTestReports = arr1.concat(arr2);
        // 数据填充
        fillData(backTestReports, 'backtestReports');
        // console.log('##### backTestReports = %o', backTestReports.data);
        // console.log('##### backTestReports data only = %s', JSON.stringify(backTestReports.data));
        setBackTestReportData(backTestReports);
        setProgress(false);
      })
      .catch((error) => {
        setProgress(false);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCompareBacktest();
  }, []);

  return (
    <div>
      {progress ? (
        <div className={classes.circularProgressDiv}>
          <CircularProgress className={classes.circularProgressTop} />
          <div className={classes.waitingMsg}>Please wait...</div>
        </div>
      ) : (
        <div>
          {backTestReportData.map((item, index) => (
            <GridContainer>
              {/*<GridItem xs={12} sm={12} md={12}>*/}
              {/*  <Typography className={classes.accordionSummaryWidth}>*/}
              {/*    <div className={classes.period}>*/}
              {/*      <span className={classes.periodText}>*/}
              {/*        Period：{item.begin} - {item.end}*/}
              {/*      </span>*/}
              {/*    </div>*/}
              {/*  </Typography>*/}
              {/*</GridItem>*/}
              <GridItem xs={12} sm={12} md={12}>
                <CompareBacktestTableListView strategyCompareData={item} index={index + 1} />
              </GridItem>
              {/*<GridItem xs={12} sm={12} md={12}>*/}
              {/*  <Divider className={classes.divider} dark />*/}
              {/*</GridItem>*/}
            </GridContainer>
          ))}
        </div>
      )}
    </div>
  );
}

StrategyCompareBacktestView.propTypes = {
  strategyData: PropTypes.object,
};
