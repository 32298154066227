import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { stringUtils } from '../../utils/string.utils';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getUserAgreement, signUserAgreement, logout } from '../../utils/user.utils';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import { environment } from '../../environments/environment';
import UserAgreementPdf from 'assets/help/GoPIPA User Agreement v1.1 05092024.pdf';

const styles = {
  dialogCenter: {
    margin: 'auto',
    textAlign: 'center',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: 14,
    marginTop: 25,
    marginLeft: 20,
  },
  dialogContentTextWidth: {
    margin: 'auto',
    textAlign: 'center',
    width: 500,
  },
  errorMsg: {
    color: 'red',
  },
};
const useStyles = makeStyles(styles);

export default function UserAgreementView() {
  const classes = useStyles();
  const fullScreen = true;
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [openUserAgreement, setOpenUserAgreement] = React.useState(true);
  const [errMsg, setErrMsg] = React.useState(false);
  const token = !$.isEmptyObject(params) && params?.token ? params?.token : '';
  const firstName = !$.isEmptyObject(params) && params?.firstName ? params?.firstName : '';
  const lastName = !$.isEmptyObject(params) && params?.lastName ? params?.lastName : '';
  const name = firstName && lastName ? `${firstName} ${lastName}` : '';
  const [signature, setSignature] = useState(null);

  React.useEffect(() => {
    getUserAgreement(token)
      .then((response) => {
        console.debug('*****UserAgreementView: response = %o', response);
        const data = response?.data;
        if (data.code === 200) {
          setOpenUserAgreement(data.data);
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  }, []);

  const handleOnClickSubmit = () => {
    signUserAgreement(signature, token)
      .then((response) => {
        console.debug('*****UserAgreementView: handleOnClickSubmit response = %o', response);
        if (response?.data?.code === 200) {
          localStorage.setItem(environment.tokenKey, token);
          window.location.href = '../admin/dashboard';
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  };

  const handleOnClickCancel = () => {
    logout();
  };

  const handleSignatureChange = () => (event) => {
    setSignature(event.target.value);
    if (event.target.value.toLowerCase() !== name.toLowerCase()) {
      setErrMsg(true);
    } else {
      setErrMsg(false);
    }
  };

  return (
    <Typography component={'div'}>
      {openUserAgreement && (
        <Dialog
          fullScreen={fullScreen}
          open={openUserAgreement ? true : false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.dialogCenter}
        >
          <DialogTitle id="validate-email-dialog-title">{'GoPIPA User Agreement'}</DialogTitle>
          <DialogContent dividers id="validate-email--dialog-description">
            <DialogContentText id="validate-email--dialog-description">
              <Typography component={'span'} variant={'h6'}>
                {openUserAgreement}
              </Typography>
              <div style={{ marginTop: 25 }}></div>
              <iframe id="UserAgreement"
                      title="User Agreement"
                      width="680"
                      height="650"
                      marginwidth="0"
                      src={UserAgreementPdf}
                      >
              </iframe>
              <div style={{ marginTop: 20 }}></div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <span
                    style={{ marginRight: 30, color: '#3f51b5' }}
                  >{`Please print your name (${name})`}</span>
                  <TextField
                    style={{ width: 300, marginTop: -10 }}
                    autoFocus
                    margin="dense"
                    id="signature"
                    label="Signature"
                    required
                    value={signature}
                    onChange={handleSignatureChange()}
                    error={errMsg}
                    helperText={
                      errMsg
                        ? signature === ''
                          ? stringUtils.fieldRequired
                          : stringUtils.printedNameMsg
                        : ' '
                    }
                    fullWidth
                  />
                  <Button
                    style={{ marginTop: -20 }}
                    onClick={handleOnClickSubmit}
                    color="primary"
                    classes={{ label: classes.label }}
                    disabled={!signature || errMsg}
                  >
                    Agree
                  </Button>
                  <Button
                    style={{ marginTop: -20 }}
                    onClick={handleOnClickCancel}
                    color="primary"
                    classes={{ label: classes.label }}
                  >
                    Cancel
                  </Button>
                </GridItem>
              </GridContainer>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </Typography>
  );
}
