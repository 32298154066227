import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import ReactHtmlParser from "html-react-parser";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
// @material-ui/icons
import backtestimg from 'assets/img/backtest.png';
import introimg from "../../assets/img/gopipa_logo.png";
import Button from "../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import {getStockIDA} from "../../utils/strategy.utils";
import {getLoginUser} from "../../utils/user.utils";
import {explanationText} from "./explanation-text";

const styles = {
    noWarp: {
        whiteSpace: 'nowrap',
    },
    chartBox: {
        fontFamily: 'Arial, Helvetica',
        height: 'auto',
        margin: '25px 0px 0px 10px',
        fontWeight: 600,
        border: '1px solid #000000DE',
        // maxWidth: 'auto',
    },
    tabletitle: {
        fontSize: '18px',
        fontWeight: 550,
        padding: '10px 15px',
        color: '#000000DE',
        backgroundColor: '#e5e0e0',
    },
    title: {
        fontSize: '16px',
        fontWeight: 400,
        padding: '10px 0px 0px 10px',
        color: '#000000DE',
    },
    error: {
        fontSize: '16px',
        fontWeight: 800,
        padding: '3px 0px',
        color: 'red',
    },
    imageBox: {
        display: 'block',
        top: '5px',
        width: '1650px',
        padding: '0px',
        margin: '25px 0px 20px 10px',
        border: '1px solid #000000DE',
    },
    waitingMsg: {
        fontWeight: 400,
        fontSize: 20,
        textAlign: 'center',
        marginTop: 70,
        marginLeft: -80,
    },
    submitBox: {
        height: '35px',
        marginLeft: '-25px',
        textTransform: 'capitalize',
        fontSize: '12px',
        marginTop: '-2px',
    },
    circularProgressTop: {
        marginTop: 20,
    },
    circularProgressDiv: {
        border: '1px solid 1px solid #d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
    },
    idaBox: {
        position: 'relative',
        width: '100%',
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '5px',
        margin: '0px 0px 5px 10px',
        fontWeight: '400',
        fontSize: '16px',
        fontFamily: 'Arial, Helvetica',
    },
    functionBox: {
        width: '250px',
        display: 'inline-block',
        height: 'auto',
        padding: '5px 0px 0px 0px',
    },
    menuList: {
        marginLeft: '-15px',
        width: '200px',
        border: '1px solid #000000DE',
        marginTop: '-8px',
        padding: '5px 15px',
        borderRadius: '5px',
    },
    menuItem: {
        color: 'black',
        fontSize: '16px',
        '& .Mui-selected': {
            display: 'block',
            backgroundColor: '#EBEBEB',
        },
        fontFamily: 'Arial, Helvetica',
        color: '#000000DE',
    },
    commentBox: {
        fontFamily: 'Arial, Helvetica',
        height: 'auto',
        margin: '25px 0px 0px 10px',
        padding: '5px',
        fontWeight: 500,
        // border: '1px solid #000000DE',
    },
    infoButton: {
        borderRadius: 5,
        marginLeft: '35px',
        backgroundColor: '#FFF',
        border:'1px soild #E5E0E0',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        opacity: 0.4,
        zIndex: 8888,
    },
    explanation: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '900px',
        height: '700px',
        backgroundColor: 'whitesmoke',
        padding: '20px 30px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        overflow: 'auto',
        fontWeight: '400',
        fontSize: '14px',
    },
};

const useStyles = makeStyles(styles);

export default function IDAMarketTAView() {
    const [idaColumns, setIdaColumns] = React.useState([]);
    const [idaTable, setIdaTable] = React.useState([]);
    const [comment, setComment] = React.useState('');
    const [progress, setProgress] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [menuIndex, setMenuIndex] = React.useState(1);
    const [lengthIndex, setLengthIndex] = React.useState(3);
    const [showLengthMenu, setShowLengthMenu] = React.useState(true);
    const [showSPYTableView, setShowSPYTableView] = React.useState(false);
    const [showImageView, setShowImageView] = React.useState(false);
    const [showPredictionView, setShowPredictionView] = React.useState(false);
    const [showCommentView, setShowCommentView] = React.useState(false);
    const [showSubmit, setShowSubmit] = React.useState(true);
    const [tableWidth, setTableWidth] = React.useState('auto');
    const [errorMsg, setErrorMsg] = React.useState('');
    const [showExplanation, setShowExplanation] = React.useState(false);
    const [explanationMsg, setExplanationMsg] = React.useState('');
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const timestamp = new Date().getTime();

    const menu_options = [
        'Select analysis',
        'TA Metrics',
        'State Charts',
        'Predictions',
    ]

    const length_options = [
        'Select Length',
        '10',
        '20',
        '30',
        '50',
        '100',
    ]

    const tad_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '110px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>TA State</div>
                ),
            selector: 'taRating',
            sortable: true,
            width: '135px',
            cell: (row) => {
                return (
                    <div style={{fontSize: '14px', textAlign: 'center'}}>
                        {row.taRating}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>TA String</div>
                ),
            selector: 'taString',
            sortable: true,
            // right: true,
            width: '235px',
            cell: (row) => {
                return (
                    <div style={{fontWeight: 550, fontSize: '14px', fontFamily: 'monospace', textAlign: 'center'}}>
                        {row.taString}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Trend</div>
                ),
            selector: 'trend',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend || row.trend === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.trend):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Trend 5 Day Slope</div>
                ),
            selector: 'trend5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend5 || row.trend5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.trend5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Trend 20 Day Slope</div>
                ),
            selector: 'trend20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend20 || row.trend20=== 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.trend20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Momentum</div>
                ),
            selector: 'momentum',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum || row.momentum === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.momentum):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Momentum 5 Day Slope</div>
                ),
            selector: 'momentum5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum5 || row.momentum5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.momentum5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Momentum 20 Day Slope</div>
                ),
            selector: 'momentum20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum20 || row.momentum20 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.momentum20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Breadth</div>
                ),
            selector: 'breadth',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.breadth || row.breadth === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breadth):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Breadth 5 Day Slope</div>
                ),
            selector: 'breadth5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.breadth5 || row.breadth5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breadth5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Breadth 20 Day Slope</div>
                ),
            selector: 'breadth20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.breadth20 || row.breadth20 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breadth20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Oscillation</div>
                ),
            selector: 'oscillation',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation || row.oscillation === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.oscillation):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Oscillation 5 Day Slope</div>
                ),
            selector: 'oscillation5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation5 || row.oscillation5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.oscillation5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Oscillation 20 Day Slope</div>
                ),
            selector: 'oscillation20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation20 || row.oscillation20 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.oscillation20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>change_status1</div>
        //         ),
        //     selector: 'changeStatus1',
        //     sortable: true,
        //     right: true,
        //     width: '110px',
        //     cell: (row) => {
        //         const data = row.changeStatus1 || row.changeStatus1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.changeStatus1):"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     }
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>ema_co1</div>
        //         ),
        //     selector: 'emaCo1',
        //     sortable: true,
        //     right: true,
        //     width: '110px',
        //     cell: (row) => {
        //         const data = row.emaCo1 || row.emaCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.emaCo1):"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     }
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>psar_co1</div>
        //         ),
        //     selector: 'psarCo1',
        //     sortable: true,
        //     right: true,
        //     width: '110px',
        //     cell: (row) => {
        //         const data = row.psarCo1 || row.psarCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.psarCo1):"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     }
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>macd_co1</div>
        //         ),
        //     selector: 'macdCo1',
        //     sortable: true,
        //     right: true,
        //     width: '110px',
        //     cell: (row) => {
        //         const data = row.macdCo1 || row.macdCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.macdCo1):"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     }
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>bb_co1</div>
        //         ),
        //     selector: 'bbCo1',
        //     sortable: true,
        //     right: true,
        //     width: '110px',
        //     cell: (row) => {
        //         const data = row.bbCo1 || row.bbCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.bbCo1):"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     }
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>rsi_co1</div>
        //         ),
        //     selector: 'rsiCo1',
        //     sortable: true,
        //     right: true,
        //     width: '110px',
        //     cell: (row) => {
        //         const data = row.rsiCo1 || row.rsiCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.rsiCo1):"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     }
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>breakout1</div>
        //         ),
        //     selector: 'breakout1',
        //     sortable: true,
        //     right: true,
        //     width: '110px',
        //     cell: (row) => {
        //         const data = row.breakout1 || row.breakout1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breakout1):"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     }
        // },
    ];

    const pred_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            sortable: true,
            width: '120px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Current State (R1)</div>
                ),
            selector: 'currentStateR1',
            sortable: true,
            center: true,
            width: '130px',
            cell: (row) => {
                const data = row.currentStateR1? row.currentStateR1:"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Current State (H1)</div>
                ),
            selector: 'currentStateH1',
            sortable: true,
            center: true,
            width: '130px',
            cell: (row) => {
                const data = row.currentStateH1? row.currentStateH1:"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>5 Days Prediction (D1)</div>
                ),
            selector: 'pred5dDrl',
            sortable: true,
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.pred5dDrl? row.pred5dDrl:"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize: '13px'}}>10 Days</div>
        //         ),
        //     selector: 'pred10dDrl',
        //     sortable: true,
        //     right: true,
        //     width: '110px',
        //     cell: (row) => {
        //         const data = row.pred10dDrl?row.pred10dDrl:"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     }
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Win Ratio</div>
        //         ),
        //     selector: 'pred5dDrlWinRatio',
        //     sortable: true,
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.pred5dDrlWinRatio? Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(row.pred5dDrlWinRatio) :"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Profit/Loss Ratio</div>
        //         ),
        //     selector: 'pred5dDrlPnlRatio',
        //     sortable: true,
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.pred5dDrlPnlRatio? Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(row.pred5dDrlPnlRatio) :"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize:'13px'}}>Sample</div>
        //         ),
        //     selector: 'pred5dDrlSampleCnt',
        //     sortable: true,
        //     center: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.pred5dDrlSampleCnt? Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(row.pred5dDrlSampleCnt) :"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     },
        // },
    ];

    const getIDAtable = (func, length) => {
            getStockIDA('SPY', func, 1, length, 0)
                .then((response) => {
                    if (response.data.code === 200) {
                        const data = response.data.data;
                        setProgress(false);
                        // console.info(data);
                        if (data.idaTable !== null) {
                            setIdaTable(data.idaTable);
                            if (data.comment !== null) {
                                setComment(data.comment);
                                setShowCommentView(true);
                            } else {
                                setComment('');
                                setShowCommentView(false);
                            }
                        } else {
                            setIdaTable([]);
                            setComment('');
                            setShowCommentView(false);
                        }
                    } else  {
                        const err = response?.data?.errMsg? response.data.errMsg : null;
                        setIdaColumns(tad_columns);
                        setIdaTable([]);
                        setComment('');
                        setShowCommentView(false);
                        // setErrorMsg(err);
                        // setShowError(true);
                        setProgress(false);
                    }
                })
                .catch((error) => {
                    setIdaColumns(tad_columns);
                    setProgress(false);
                    setIdaTable([]);
                    console.debug(error);
                    setShowCommentView(false);
                });
        }

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setMenuIndex(index);
        setAnchorEl1(null);
        switch(index) {
            case 1:
                setShowImageView(false);
                setShowPredictionView(false);
                setShowLengthMenu(true);
                setShowSubmit(true);
                setTableWidth('auto');
                getIDAtable(11, length_options[lengthIndex]);
                setShowSPYTableView(true);
                setShowCommentView(true);
                break;
            case 2:
                setShowSPYTableView(false);
                setShowPredictionView(false);
                setShowCommentView(false);
                setShowLengthMenu(false);
                setShowSubmit(false);
                setShowImageView(true);
                break;
            case 3:
                setShowSPYTableView(false);
                setShowImageView(false);
                setShowLengthMenu(true);
                setShowSubmit(true);
                setShowPredictionView(true);
                setTableWidth('540px');
                getIDAtable(12, length_options[lengthIndex]);
                setShowCommentView(false);
                setShowPredictionView(true);
                break;
            default:
                setShowLengthMenu(false);
                setShowSPYTableView(false);
                setShowImageView(false);
                setShowPredictionView(false);
                break;
        }
    };

    const handleLengthMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setLengthIndex(index);
        switch(menuIndex) {
            case 1:
                setShowSPYTableView(false);
                setProgress(true);
                getIDAtable(11, length_options[index]);
                setShowSPYTableView(true);
                break;
            case 3:
                setShowPredictionView(false);
                setProgress(true);
                getIDAtable(12, length_options[index]);
                setShowPredictionView(true);
                break;
            default:
                break;
        }
        setAnchorEl2(null);

    };

    const handleClickMenuItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleSubmit = () => {
        setErrorMsg("");
        setShowError(false);
        switch (menuIndex) {
            case 1:
                getIDAtable(11, length_options[lengthIndex]);
                break;
            case 3:
                getIDAtable(12, length_options[lengthIndex]);
                break;
            default:
                break;
        }
    }

    const handleOpenInfo = () => {
        setExplanationMsg(ReactHtmlParser(explanationText.taMetric));
        setShowExplanation(true);
    };

    const onClose = () => {
        setShowExplanation(false);
    }

    React.useEffect(() => {
        setProgress(true);
        getIDAtable(11, 10);
        setTableWidth('1450px');
        setShowSPYTableView(true);
    }, []);

    const classes = useStyles();

    return (
        <>
            <div>
                {/*<div className={classes.title} style={{marginTop: '25px'}}>Select one of the Technical Analyses.:</div>*/}
                <div className={classes.title} style={{marginTop: '15px'}} />
                <div className={classes.idaBox}>
                    <span className={classes.functionBox}>
                        <Box
                            ml={2}
                            sx={{ width: '50%',
                                maxWidth: 200,
                            }}
                        >
                            <List
                                component="nav"
                                aria-label="Functions"
                            >
                                <ListItem
                                    className={classes.menuList}
                                    button
                                    id="lock-button"
                                    aria-haspopup="listbox"
                                    onClick={handleClickMenuItem}
                                >
                                    <ListItemText
                                        primary={menu_options[menuIndex]}
                                        secondary="analysis"
                                    />
                                </ListItem>
                            </List>
                            <Menu
                                className={classes.menuItem}
                                id="function-menu"
                                anchorEl={anchorEl1}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                }}
                            >
                                {menu_options.map((option, index) => (
                                    <MenuItem
                                        className={classes.menuItem}
                                        key={option}
                                        disabled={index === 0}
                                        selected={index === menuIndex}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </span>
                    { showLengthMenu && (
                        <span className={classes.functionBox}>
                            <Box
                                ml={2}
                                sx={{ width: '50%',
                                    maxWidth: 200,
                                }}
                            >
                                <List
                                    component="nav"
                                    aria-label="Functions"
                                >
                                    <ListItem
                                        className={classes.menuList}
                                        button
                                        id="lock-button"
                                        aria-haspopup="listbox"
                                        onClick={handleClickListItem}
                                    >
                                        <ListItemText
                                            primary={length_options[lengthIndex]}
                                            secondary="days"
                                        />
                                    </ListItem>
                                </List>
                                <Menu
                                    className={classes.menuItem}
                                    id="function-menu"
                                    anchorEl={anchorEl2}
                                    open={open2}
                                    onClose={handleClose2}
                                    MenuListProps={{
                                        'aria-labelledby': 'lock-button',
                                        role: 'listbox',
                                    }}
                                >
                                    {length_options.map((option, index) => (
                                        <MenuItem
                                            className={classes.menuItem}
                                            key={option}
                                            disabled={index === 0}
                                            selected={index === lengthIndex}
                                            onClick={(event) => handleLengthMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </span>
                    )}
                </div>
                <hr style={{margin: '15px 0px 5px 0px'}}/>
                {progress ? (
                    <div className={classes.circularProgressDiv}>
                        <CircularProgress className={classes.circularProgressTop} />
                        <div className={classes.waitingMsg}>Please wait...</div>
                    </div>
                ) : ''}
                { showSPYTableView && (
                    <>
                        <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                            <div className={classes.tabletitle}>
                                S&P500 ETF Metrics
                                <Tooltip title="Click to show explanation">
                                    <IconButton className={classes.infoButton} aria-label="strategyDetail" size="small" onClick={() => handleOpenInfo ()}>
                                        <InfoIcon fontSize="default" /><small style={{marginLeft:'-4px'}}>Help</small>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div>
                                <DataTable
                                    data={idaTable}
                                    columns={tad_columns}
                                    fixedHeaderScrollHeight="60vh"
                                    fixedHeader
                                    highlightOnHover
                                    striped
                                    defaultSortFieldId={1}
                                    sortIcon={<SortIcon />}
                                    noHeader={true}
                                    overflowY={true}
                                    overflowYOffset="0px"
                                />
                            </div>
                        </div>
                    </>
                )}
                { showImageView && (
                    <>
                        {/*<hr style={{margin: '15px 0px 5px 0px'}}/>*/}
                        <div className={classes.imageBox}>
                            <div className={classes.tabletitle}>S&P500 ETF State Charts</div>
                            <div className={classes.title}>HMM/GMM model based state chart (H1):</div>
                            <div>
                                <img src={'https://imgsrv.gopipa.com/images/ida/SPY_MktStates_H1.png?t=' + {timestamp}} width="1600" height="1200"/>
                            </div>
                            <hr/>
                            <div className={classes.title} style={{align:'center'}}>Rule based state chart (R1):</div>
                            <div>
                                <img src={'https://imgsrv.gopipa.com/images/ida/SPY_MktStates_R1.png?t=' + {timestamp}} width="1600" height="1200"/>
                            </div>
                            <hr/>
                            <div className={classes.title} style={{align:'center'}}>Breadth analysis chart:</div>
                            <div>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_Stock_Breadth.png?t=' + {timestamp}} width="1600" height="1200"/>
                            </div>
                            <hr/>
                            <div className={classes.title} style={{align:'center'}}>VIX chart:</div>
                            <div>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_VIX.png?t=' + {timestamp}} width="1600" height="1200"/>
                            </div>
                            <hr/>
                            <div className={classes.title} style={{align:'center'}}>Price ratio chart:</div>
                            <div style={{align:'center'}}>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_Price_Ratio_SPY_RSP.png?t=' + {timestamp}} width="1600" height="900"/>
                            </div>
                            <div style={{align:'center'}}>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_Price_Ratio_SPY_VEU.png?t=' + {timestamp}} width="1600" height="900"/>
                            </div>
                            <div style={{align:'center'}}>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_Price_Ratio_SPY_TLT.png?t=' + {timestamp}} width="1600" height="900"/>
                            </div>
                            <div style={{align:'center'}}>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_Price_Ratio_SPY_IWM.png?t=' + {timestamp}} width="1600" height="900"/>
                            </div>
                            <div style={{align:'center'}}>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_Price_Ratio_SPY_QQQ.png?t=' + {timestamp}} width="1600" height="900"/>
                            </div>
                            <div style={{align:'center'}}>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_Price_Ratio_SPY_XLK.png?t=' + {timestamp}} width="1600" height="900"/>
                            </div>
                            <div style={{align:'center'}}>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_Price_Ratio_SPY_XLU.png?t=' + {timestamp}} width="1600" height="900"/>
                            </div>
                            <div style={{align:'center'}}>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_Price_Ratio_SPY_XLE.png?t=' + {timestamp}} width="1600" height="900"/>
                            </div>
                            <div style={{align:'center'}}>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_Price_Ratio_SPY_GLD.png?t=' + {timestamp}} width="1600" height="900"/>
                            </div>
                            <div style={{align:'center'}}>
                                <img src={'https://imgsrv.gopipa.com/images/ida/IDA_Price_Ratio_SPYG_SPYV.png?t=' + {timestamp}} width="1600" height="900"/>
                            </div>
                        </div>
                    </>
                )}
                { showPredictionView && (
                    <>
                        <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                            <div className={classes.tabletitle}>SPY Prediction</div>
                            <div>
                                <DataTable
                                    data={idaTable}
                                    columns={pred_columns}
                                    fixedHeaderScrollHeight="60vh"
                                    fixedHeader
                                    highlightOnHover
                                    striped
                                    defaultSortFieldId={1}
                                    sortIcon={<SortIcon />}
                                    noHeader={true}
                                />
                            </div>
                        </div>
                    </>
                )}
                { showCommentView && (
                    <>
                        <div className={classes.commentBox} style={{maxWidth : tableWidth}}>
                            {ReactHtmlParser(String(comment))}
                        </div>
                    </>
                )}
                { showExplanation && (
                    <>
                        <div className={classes.overlay} onClick={onClose}/>
                        <div className={classes.explanation} onClick={onClose}>
                            <div style={{textAlign:'center'}}><span><InfoIcon fontSize="default" /></span><span style={{verticalAlign:'top', fontSize:'18px'}}>Help</span></div>
                            {explanationMsg}
                        </div>
                    </>
                )}
            </div>
        </>
    );

}
