import { environment } from 'environments/environment';
import { getDefaultConfig } from './common';
import { fetchJSON, deleteJSON, putJSON } from './fetch.js';

export const getPortfolioLog = (id: string) => {
  const url = `${environment.api_url}portfolios/logs?portfolioId=${id}&__sort__=-time&__pageno__=1&__pagesize__=1000`;
  console.debug('portfolio.utils getPortfolioLog url = %s', url);
  return fetchJSON(url, getDefaultConfig(null));
};

export const startPortfolio = (id: string, sellPosition: boolean) => {
  const url = `${environment.api_url}portfolios/start?portfolioId=${id}&liquidate=${sellPosition}`;
  console.debug('portfolio.utils startPortfolio url = %s', url);
  return putJSON(url, {}, getDefaultConfig(null));
};

export const stopPortfolio = (id: string, sellPosition: boolean) => {
  const url = `${environment.api_url}portfolios/stop?portfolioId=${id}&liquidate=${sellPosition}`;
  console.debug('portfolio.utils stopPortfolio url = %s', url);
  return putJSON(url, {}, getDefaultConfig(null));
};

export const deletePortfolio = (id: string) => {
  const url = `${environment.api_url}portfolios/delete?portfolioId=${id}`;
  console.debug('portfolio.utils deletePortfolio url = %s', url);
  return deleteJSON(url, {}, getDefaultConfig(null));
};

export const getPortfolio = (id: string) => {
  const url = `${environment.api_url}portfolios?id=${id}`;
  console.debug('portfolio.utils getPortfolio url = %s', url);
  return fetchJSON(url, getDefaultConfig(null));
};

///svr/user/portfolios/modifyCash
// get adjust investment amount records /svr/user/portfolios/cashInOuts
// cancel /svr/user/portfolios/cancelModifyCash
export const modifyInvestmentAmount = (portfolioId: string, cash: number) => {
  const url = `${environment.api_url}portfolios/modifyCash?portfolioId=${portfolioId}&cash=${cash}`;
  console.debug('portfolio.utils modifyInvestmentAmount url = %s', url);
  return putJSON(url, {}, getDefaultConfig(null));
};

export const cancelInvestmentAmount = (id: string) => {
  const url = `${environment.api_url}portfolios/cancelModifyCash?cashInOutId=${id}`;
  console.debug('portfolio.utils cancelInvestmentAmount url = %s id = %s', url, id);
  return putJSON(url, {}, getDefaultConfig(null));
};

export const getCashChangeActivities = (portfolioId) => {
  //this.portfolio.id, "__sort__": "-createTime", "__pageno__": '1', "__pagesize__": '1000'
  const url = `${environment.api_url}portfolios/cashInOuts?portfolioId=${portfolioId}&__sort__=-createTime&__pageno__=1&__pagesize__=5000`;
  console.debug('portfolio.utils getCashChangeActivities url = %s', url);
  return fetchJSON(url, getDefaultConfig(null));
};
