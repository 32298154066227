import React from 'react';
import { Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import CompareIcon from '@material-ui/icons/Compare';
import AppsIcon from '@material-ui/icons/Apps';
import StoppedAppIcon from '@material-ui/icons/HighlightOff';
import InfoIcon from '@material-ui/icons/Info';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';

import CircularProgress from '@material-ui/core/CircularProgress';
import CompareTrackingTableListView from './CompareTrackingTableList.view.tsx';
import CardView from './Card.view.tsx';
import StrategyCompareBacktestView from './StrategyCompareBacktest.view.tsx';
import MyAQSView from './MyAQS.view.tsx';
import TutorialView from './Tutorial.view.tsx';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import { getStrategyList, StrategyList, getStrategySnapshop } from '../../utils/strategy.utils';
import {getAccountPosition, getLoginUser, getTrackAccountAQSPortfolio} from '../../utils/user.utils';
import Typography from '@material-ui/core/Typography';
import { stringUtils } from '../../utils/string.utils';
import PositionsSnapshot from "./PortfolioSnapshot.view";

const styles = {
  waitingMsg: {
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 70,
    marginLeft: -80,
  },
  circularProgressTop: {
    marginTop: 20,
  },
  circularProgressDiv: {
    border: '1px solid 1px solid #d3d3d3',
    display: 'flex',
    justifyContent: 'center',
    height: 100,
  },
};
const useStyles = makeStyles(styles);

export default function StrategyListView() {
  const classes = useStyles();
  const [responseData, setResponseData] = React.useState(null);
  const [satResponseData, setSatResponseData] = React.useState(null);
  const [investorState, setInvestorState] = React.useState(null);
  const [trackAccount, setTrackAccount] = React.useState(null);
  const [progress, setProgress] = React.useState(true);
  const [showPosition, setShowPosition] = React.useState(false);
  const [positionData, setPositionsData] = React.useState([]);
  const [postionsTitle, setPositionsTitle] = React.useState(null);
  const sortStrategyByName = (array, key) => {
    return array.sort(function (a, b) {
      let x = a[key];
      let y = b[key];

      if (typeof x === 'string') {
        x = ('' + x).toLowerCase();
      }
      if (typeof y === 'string') {
        y = ('' + y).toLowerCase();
      }

      return x < y ? -1 : x > y ? 1 : 0;
    });
  };
  const getBackTestTrackInfo = (data) => {
    data.forEach((sty: any) => {
      let backIndex: number = -1;
      if (sty.backtestReports) {
        sty.backtestReports.every((r: any, j: number) => {
          if (r.reportMark === 1) {
            backIndex = j;
            return false;
          }
          return true;
        });
      }
      if (backIndex !== -1) {
        sty.backTest = sty.backtestReports[backIndex];
      }
    });
  };

  const handleTabChange = (event, value) => {
    const strategyType = value === 0 || value === 1 ? 'Core_Strategy' : 'Trading_Strategy';
    const params: StrategyList = {
      strategyType,
      fetchDetail: true,
      openForInvestor: true,
      fetchTrackingReport: true,
      status: 'Online',
    };
    setProgress(true);
    getStrategyList(params)
        .then((response) => {
          const sortedData = sortStrategyByName(response.data.data, 'name');
          getBackTestTrackInfo(sortedData);
          strategyType === 'Core_Strategy'
              ? setResponseData(sortedData)
              : setSatResponseData(sortedData);
          setProgress(false);
        })
        .catch((error) => {
          console.debug(error);
          setProgress(false);
        });
  };

  const fetchUserAccount = React.useCallback(() => {
    getLoginUser(null)
        .then((response) => {
          const data = response && response?.data ? response.data : null;
          if (data.code === 200) {
            setInvestorState(data.data.investorState);
            if (data.data.investorState !== 'Disabled') {
              fetchTrackAccountData();
              fetchData();
            }
          }
        })
        .catch((error) => {
          console.debug(error);
        });
  }, []);

  const fetchData = React.useCallback(() => {
    handleTabChange({}, 0);
  }, []);

  const fetchTrackAccountData = React.useCallback(() => {
    getTrackAccountAQSPortfolio()
        .then((response) => {
          // console.debug('StrategyList.view: setTrackAccount = %o', response);
          // IB: DU819878   (Max Usable Cash: 428,868.02)
          const trackAcct = [];
          response.data.data.forEach((item: any) => {
            const accoutName = item.trackAccountName
                ? item.trackAccountName + (item.account ? ' (' + item.account + ')' : '')
                : item.account;
            const accountValue = item.usableCash ? parseFloat(item.usableCash.toFixed(2)) : 0;
            const accoutInfo = `${item.broker}: ${accoutName}  (Max Usable Cash: ${accountValue})`;
            trackAcct.push({key: item.account, value: accoutInfo, id: item.id});
            //console.debug('StrategyList.view: trackAcct = %o', trackAcct);
          });
          setTrackAccount(trackAcct);
        })
        .catch((error) => {
          console.debug(error);
        });
  }, []);

  const getPosition = (id) => {
    if ( id ) {
      getStrategySnapshop(id)
          .then((response) => {
            const positions = [];
            if (response.data.data) {
              const data = response.data.data;
              let total = 0;
              data.forEach((item: any) => {
                total += item.positionValue;
              });
              data.forEach((item: any) => {
                let ratio = item.positionValue/total;
                item.position = (ratio*100).toFixed(2) + '%';
                positions.push(item);
              });
              setPositionsData(response.data.data);
            }
          })
          .catch((error) => {
            console.debug(error);
          });
    }
  }

  const handleOpenPosition = (id, title) => {
    getPosition(id);
    setPositionsTitle(title + " (ID: " + id + ")");
    setShowPosition(true);
  };

  const handleClosePosition = () => {
    setShowPosition(false);
  };

  React.useEffect(() => {
    fetchUserAccount();
  }, [fetchData]);

  return (
    <div>
      <div className="mainPagePanel">
        {showPosition && (
            <PositionsSnapshot
                title={postionsTitle}
                onClick={handleClosePosition}
                positionData = {positionData}
            ></PositionsSnapshot>
        )}
        <GridContainer>
          {investorState && investorState === 'Disabled' ? (
            <div style={{ marginLeft: 45, marginTop: 20 }}>
              <Typography component={'span'} variant={'h6'}>
                Your membership is disabled so you cannot view this page
                <br></br>
                Please go to
                <Link to="/admin/useraccount"> My Account </Link>
                to enable your membership in order to access {stringUtils.aqsStrategies} details
              </Typography>
            </div>
          ) : (
            <GridItem xs={12} sm={12} md={12}>
              <CustomTabs
                onTabChange={handleTabChange}
                title=""
                headerColor="primary"
                tabs={[
                  {
                    tabName: stringUtils.allAqsStrategies,
                    tabIcon: ViewModuleIcon,
                    tabContent: (
                      <div style={{ marginTop: -20 }}>
                        {progress ? (
                          <div className={classes.circularProgressDiv}>
                            <CircularProgress className={classes.circularProgressTop} />
                            <div className={classes.waitingMsg}>Please wait...</div>
                          </div>
                        ) : (
                          <CardView strategyData={responseData} trackAccount={trackAccount} onClickOpen={handleOpenPosition} />
                        )}
                      </div>
                    ),
                  },
                  {
                    tabName: 'Compare Tracking',
                    tabIcon: ViewListIcon,
                    tabContent: (
                      <div>
                        {progress ? (
                          <div className={classes.circularProgressDiv}>
                            <CircularProgress className={classes.circularProgressTop} />
                            <div className={classes.waitingMsg}>Please wait...</div>
                          </div>
                        ) : (
                          <CompareTrackingTableListView
                            strategyData={responseData}
                            trackAccount={trackAccount}
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    tabName: 'Compare Backtest',
                    tabIcon: CompareIcon,
                    tabContent: (
                      <div>
                        {progress ? (
                          <div className={classes.circularProgressDiv}>
                            <CircularProgress className={classes.circularProgressTop} />
                            <div className={classes.waitingMsg}>Please wait...</div>
                          </div>
                        ) : (
                          <StrategyCompareBacktestView strategyData={responseData} />
                        )}
                      </div>
                    ),
                  },
                  {
                    tabName: 'My AQS Portfolios',
                    tabIcon: SubscriptionsIcon,
                    tabContent: (
                        <div>
                          {progress ? (
                              <div className={classes.circularProgressDiv}>
                                <CircularProgress className={classes.circularProgressTop} />
                                <div className={classes.waitingMsg}>Please wait...</div>
                              </div>
                          ) : (
                              <MyAQSView strategyData={responseData} aqsType={1}/>
                          )}
                        </div>
                    ),
                  },
                  {
                    tabName: 'My Stopped AQS Portfolios',
                    tabIcon: StoppedAppIcon,
                    tabContent: (
                        <div>
                          {progress ? (
                              <div className={classes.circularProgressDiv}>
                                <CircularProgress className={classes.circularProgressTop} />
                                <div className={classes.waitingMsg}>Please wait...</div>
                              </div>
                          ) : (
                              <MyAQSView aqsType={0}/>
                          )}
                        </div>
                    ),
                  },
                  {
                    tabName: 'Tutorial',
                    tabIcon: InfoIcon,
                    tabContent: (
                        <div>
                          <TutorialView/>
                        </div>
                    ),
                  },
                ]}
              />
            </GridItem>
          )}
        </GridContainer>
      </div>
    </div>
  );
}
