import React from 'react';
import { Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import CompareIcon from '@material-ui/icons/Compare';
import AppsIcon from '@material-ui/icons/Apps';
import StoppedAppIcon from '@material-ui/icons/HighlightOff'
import CircularProgress from '@material-ui/core/CircularProgress';
import PollIcon from '@material-ui/icons/PollOutlined';
import LineChartIcon from '@material-ui/icons/Timeline';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import RadarIcon from '@material-ui/icons/ImageSearch';
//view module
import IDAStockDataView from './IDAStockData.view.tsx';
import IDAMarketView from './IDAMarket.view.tsx';
import IDAPatternDetectionView from './IDAPatternDetection.view.tsx'
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import { stringUtils } from '../../utils/string.utils';
import { getIDA } from '../../utils/strategy.utils';
import {getLoginUser} from "../../utils/user.utils";
import MyWatchListView from "./MyWatchList.view";
// import IDAPortfolioView from "./IDAPortfolio.view";
import InfoIcon from "@material-ui/icons/Info";
import TutorialView from "./Tutorial.view";

const styles = {
    waitingMsg: {
        fontWeight: 400,
        fontSize: 20,
        textAlign: 'center',
        marginTop: 70,
        marginLeft: -80,
    },
    circularProgressTop: {
        marginTop: 20,
    },
    circularProgressDiv: {
        border: '1px solid 1px solid #d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
    },
    title: {
        fontSize: '18px',
        fontWeight: 200,
        padding: '5px 10px',
        marginTop: '20px',
        color: '#000000DE',
    },
};

const useStyles = makeStyles(styles);

export default function IDAServiceView() {
    const classes = useStyles();
    const [progress, setProgress] = React.useState(false);
    const [developerState, setDeveloperState] = React.useState(false);

    React.useEffect(() => {
        getLoginUser(null)
            .then((response) => {
                const data = response && response?.data ? response.data : null;
                if (data.code === 200) {
                    if (data.data.developerState === 'Enabled') {
                        setDeveloperState(true);
                    }
                }
            })
            .catch((error) => {
            });
    }, []);

    return (
        <div>
            <div className="mainPagePanel">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomTabs
                            tabValue={0}
                            title=""
                            headerColor="primary"
                            tabs={[
                               {
                                    tabName: 'Market Analysis',
                                    tabIcon: PollIcon,
                                    tabContent: (
                                        <div style={{ marginTop: -20 }}>
                                            {progress ? (
                                                    <div className={classes.circularProgressDiv}>
                                                        <CircularProgress className={classes.circularProgressTop}/>
                                                        <div className={classes.waitingMsg}>Please wait...</div>
                                                    </div>
                                                ) : (
                                                    <div><IDAMarketView/></div>
                                            )}
                                        </div>
                                    ),
                                },
                                {
                                    tabName: 'Individual Stock/ETF Analysis',
                                    tabIcon: LineChartIcon,
                                    tabContent: (
                                        <div style={{ marginTop: -20 }}>
                                            {progress ? (
                                                <div className={classes.circularProgressDiv}>
                                                    <CircularProgress className={classes.circularProgressTop} />
                                                    <div className={classes.waitingMsg}>Please wait...</div>
                                                </div>
                                            ) : (
                                                <div><IDAStockDataView /></div>
                                            )}
                                        </div>
                                    ),
                                },
                                {
                                    tabName: "Trading Signals (IDA Universes)",
                                    tabIcon: RadarIcon,
                                    tabContent: (
                                        <div style={{ marginTop: -20 }}>
                                            {progress ? (
                                                <div className={classes.circularProgressDiv}>
                                                    <CircularProgress className={classes.circularProgressTop}/>
                                                    <div className={classes.waitingMsg}>Please wait...</div>
                                                </div>
                                            ) : (
                                                <div><IDAPatternDetectionView/></div>
                                            )}
                                        </div>
                                    ),
                                },
                                {
                                    tabName: "Trading Signals (My Watch List)",
                                    tabIcon: BookmarksIcon,
                                    tabContent: (
                                        <div style={{ marginTop: -20 }}>
                                            {progress ? (
                                                    <div className={classes.circularProgressDiv}>
                                                        <CircularProgress className={classes.circularProgressTop} />
                                                        <div className={classes.waitingMsg}>Please wait...</div>
                                                    </div>
                                                ) : (
                                                    <div><MyWatchListView /></div>
                                            )}
                                        </div>
                                    ),
                                },
                                // {
                                //     tabName: "Trading Signals (My Accounts)",
                                //     tabIcon: BookmarksIcon,
                                //     tabContent: (
                                //         <div style={{ marginTop: -20 }}>
                                //             {progress ? (
                                //                 <div className={classes.circularProgressDiv}>
                                //                     <CircularProgress className={classes.circularProgressTop} />
                                //                     <div className={classes.waitingMsg}>Please wait...</div>
                                //                 </div>
                                //             ) : (
                                //                 <div><IDAPortfolioView /></div>
                                //             )}
                                //         </div>
                                //     ),
                                // },
                                {
                                    tabName: 'Tutorial',
                                    tabIcon: InfoIcon,
                                    tabContent: (
                                        <div>
                                            <TutorialView/>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
