import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getUserName } from '../../utils/common';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import E from 'wangeditor';
import $ from 'jquery';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {
  starSearchTopic,
  answerSearchTopic,
  answerCommentTopic,
  AnswerComment,
} from '../../utils/forum.utils';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog.view';
import CommentEditorDialog from '../../components/Dialog/CommentEditorDialog';

const styles = {
  commentHr: {
    marginBottom: 5,
    marginTop: -5,
    border: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  dialogActionCenter: {
    margin: 'auto',
  },
};
const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: 'none' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

let editor = null;
export default function CommentIssueEditorDialog(props) {
  const theme = useTheme();
  const newIssueId = 'newIssueId';
  const newIssueEditorId = 'newIssueEditorId';
  const classes = useStyles();
  const { forumType, topicItem } = props;
  const [myStar, setMyStar] = React.useState(false);
  const [content, setContent] = React.useState('');
  const [deleteCommentConfirmation, setDeleteCommentConfirmation] = React.useState({
    open: false,
    item: null,
  });
  const [commentdlg, setCommentDlg] = React.useState({
    open: false,
    item: null,
  });
  const fullScreen = true;

  React.useEffect(() => {
    // 注：class写法需要在componentDidMount 创建编辑器
    const timer = setTimeout(() => {
      editor = new E('#' + newIssueEditorId);
      editor.config.onchange = (newHtml) => {
        setContent(newHtml);
      };
      /**一定要创建 */
      editor.create();
      $('#topic-content').html(topicItem.content);
      $('#' + newIssueEditorId).hide();
      $('#commentButton').hide();
      $('#' + newIssueEditorId + ' .w-e-text-container').height(120);

      // looking for collection
      const collected = topicItem.star.filter((el) => {
        return el.user === getUserName();
      });
      setMyStar(collected && collected.length > 0);
      return () => {
        // 组件销毁时销毁编辑器  注：class写法需要在componentWillUnmount中调用
        editor.destroy();
      };
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleQuickComment = () => {
    $('#' + newIssueEditorId).show();
    $('#commentButton').show();
  };

  const handleClickStar = () => {
    // Mark as my collection
    starSearchTopic(topicItem.topic_id)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 0) {
          props.onCommentIssueEditor(topicItem.topic_id);
          setMyStar(true);
        }
      })
      .catch((error) => {
        console.debug('CommentIssueEditor: handleStarIssue star failed = %o', error);
      });
  };

  const onClickSubmitIssue = () => {
    answerSearchTopic(topicItem.topic_id, content)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 0) {
          $('#' + newIssueEditorId).hide();
          $('#commentButton').hide();
          props.onCommentIssueEditor(topicItem.topic_id);
        }
      })
      .catch((error) => {
        console.debug('CommentIssueEditor: onClickSubmitIssue failed = %o', error);
      });
  };

  const onCloseCommentEditor = () => {
    $('#' + newIssueEditorId).hide();
    $('#commentButton').hide();
  };

  const updateCommentContent = (id: string, content: string) => {
    const timer = setTimeout(() => {
      $('#' + id).html(content);
    }, 500);
    return () => clearTimeout(timer);
  };

  const onClickDeleteCommentIssue = (item, type, answerId) => {
    item.type = type;
    if (answerId && !item.answer_id) {
      item.answer_id = answerId;
    }
    setDeleteCommentConfirmation({ open: true, item });
  };

  const handleDeleteCommentClick = (item) => {
    if (item) {
      if (item.type === 'issue') {
        props.onDeleteCommentIssue(topicItem.topic_id, item.answer_id);
      } else {
        handleAddCommentIssue(item, null);
      }
    }
    setDeleteCommentConfirmation({ open: false, item: null });
  };

  const onClickCommentReply = (commentItem, answerId) => {
    if (answerId) {
      commentItem.answer_id = answerId;
    }
    setCommentDlg({ open: true, item: commentItem });
  };

  const handleAddCommentIssue = (commentItem: object, content: string | null) => {
    const param: AnswerComment = {
      topic_id: topicItem.topic_id,
      answer_id: commentItem.answer_id,
      comment_id: commentItem.comment_id ? commentItem.comment_id : '',
    };
    if (content) {
      param.op = 'add';
      param.content = content;
    } else {
      param.op = 'delete';
    }

    answerCommentTopic(param)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 0) {
          handleCloseCommentIssue();
          props.onRefreshCommentIssue(topicItem.topic_id);
        }
      })
      .catch((error) => {
        console.debug('CommentIssueEditor: handleAddCommentIssue failed = %o', error);
      });
  };

  const handleCloseCommentIssue = () => {
    setCommentDlg({ open: false, item: null });
  };

  // @ts-ignore
  return (
    <div id={newIssueId}>
      {forumType && deleteCommentConfirmation.open && (
        <ConfirmationDialog
          dlgData={deleteCommentConfirmation.item}
          handleDeleteDialogClick={handleDeleteCommentClick}
          open={deleteCommentConfirmation.open}
          dlgTitle={'Delete Comment: Commentator - ' + deleteCommentConfirmation?.item.user}
          dlgContentText={'Are you sure you want to delete this comment?'}
        />
      )}
      {commentdlg.open && (
        <CommentEditorDialog
          open={commentdlg.open}
          commentItem={commentdlg.item}
          onCommentEditor={handleAddCommentIssue}
          onClose={handleCloseCommentIssue}
          aria-labelledby="responsive-dialog-title"
        ></CommentEditorDialog>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
        style={{ paddingLeft: 80, paddingRight: 80, paddingTop: 50, paddingBottom: 50 }}
      >
        <DialogTitle style={{ maxWidth: 1050 }} id="responsive-dialog-title">
          {topicItem.title}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <div></div>
          </DialogContentText>
          <GridContainer>
            <GridItem xs={5} sm={5} md={5}>
              <div className="font-desc">Author: {topicItem.user}</div>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <div className="font-desc">
                Share Time: {moment(topicItem.createTime).format('lll')}
              </div>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <div className="col-md-1"></div>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <div className="">
                <span className="font-desc" style={{ marginRight: 15 }}>
                  <i className="fa fa-eye topic-view"></i> {topicItem.viewNum}
                </span>
                <span className="font-desc">
                  <i className="fa fa-star-o topic-star"></i> {topicItem.starNum}
                </span>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <hr></hr>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div
                className="editor-container"
                id="topic-content"
                style={{ fontWeight: 400 }}
              ></div>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Tooltip title="Quick Comment">
                <IconButton aria-label="add" onClick={() => handleQuickComment()}>
                  <AddIcon color="primary"></AddIcon>
                  <span style={{ fontSize: 18, marginLeft: 5, color: '#3f51b5' }}>
                    Quick Comment
                  </span>
                </IconButton>
              </Tooltip>
              <Tooltip title="My Collection">
                {myStar ? (
                  <IconButton aria-label="add">
                    <StarIcon color="primary"></StarIcon>
                    <span style={{ fontSize: 18, color: '#3f51b5' }}>Collected</span>
                  </IconButton>
                ) : (
                  <IconButton aria-label="add" onClick={() => handleClickStar(topicItem.topic_id)}>
                    <StarBorderIcon color="primary"></StarBorderIcon>
                    <span style={{ fontSize: 18, color: '#3f51b5' }}>Mark as My Collection</span>
                  </IconButton>
                )}
              </Tooltip>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <hr></hr>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div id={newIssueEditorId} style={{ marginTop: 10, marginBottom: 20 }}></div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} id="commentButton">
              <Button onClick={onClickSubmitIssue} color="primary">
                Submit
              </Button>
              <Button onClick={onCloseCommentEditor} color="primary">
                Close
              </Button>
              <hr></hr>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <span className="subject-title">Discussion Area</span>
            </GridItem>
          </GridContainer>
          {topicItem &&
            topicItem.answer.map((item, index) => (
              // eslint-disable-next-line react/jsx-key
              <div>
                <GridContainer>
                  {{ index } && (
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.commentHr}></div>
                    </GridItem>
                  )}

                  <GridItem xs={5} sm={5} md={5}>
                    <div className="font-desc">Commentator：{item.user}</div>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <div className="font-desc">
                      Comment Time: {moment(item.createTime).format('lll')}
                    </div>
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                    <span
                      className="operateItem"
                      onClick={() => {
                        onClickCommentReply(item, null);
                      }}
                    >
                      Reply
                    </span>
                    {props.isAdmin && (
                      <span
                        className="operateItem"
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          onClickDeleteCommentIssue(item, 'issue');
                        }}
                      >
                        Delete
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{ marginTop: 5 }}>
                    <span style={{ lineHeight: '1.6em' }} id={item.answer_id}>
                      {updateCommentContent(item.answer_id, item.content)}
                    </span>
                  </GridItem>
                </GridContainer>
                {item.comments.map((comment, index) => (
                  <div>
                    <GridContainer>
                      <GridItem xs={5} sm={5} md={5} style={{ marginLeft: 60 }}>
                        <div className="font-desc">
                          {comment.user} reply to {comment.to_user}
                        </div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4} style={{ marginLeft: -60 }}>
                        <div className="font-desc">
                          Reply Time：{moment(comment.createTime).format('lll')}
                        </div>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}>
                        <span
                          className="operateItem"
                          onClick={() => {
                            onClickCommentReply(comment, item.answer_id);
                          }}
                        >
                          Reply
                        </span>
                        {props.isAdmin && (
                          <span
                            className="operateItem"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              onClickDeleteCommentIssue(comment, 'comment', item.answer_id);
                            }}
                          >
                            Delete
                          </span>
                        )}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} style={{ marginLeft: 60 }}>
                        <div id={comment.comment_id}></div>
                        <div style={{ display: 'none' }} id={comment.comment_id + '_button'}>
                          <Button autoFocus onClick={props.onClose} color="primary">
                            Submit
                          </Button>
                          <Button onClick={props.onClose} color="primary">
                            Close
                          </Button>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} style={{ marginLeft: 60 }}>
                        <div style={{ lineHeight: '1.6em' }} id={comment.comment_id + '_content'}>
                          {updateCommentContent(comment.comment_id + '_content', comment.content)}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                ))}
              </div>
            ))}
        </DialogContent>
        <DialogActions className={classes.dialogActionCenter}>
          <Button autoFocus onClick={props.onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CommentIssueEditorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCommentIssueEditor: PropTypes.func,
  onDeleteCommentIssue: PropTypes.func,
  onRefreshCommentIssue: PropTypes.func,
  forumType: PropTypes.array,
  topicItem: PropTypes.object,
  isAdmin: PropTypes.bool,
};
