import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function AlertNewView(props) {
  const classes = useStyles();
  const { openAlert, message, severity, onCloseAlert } = props;
  const [open, setOpen] = React.useState(openAlert);

  console.debug('AlertView props = %o', props);
  const duration = severity === 'error' ? 6000 : 3000;

  useEffect(() => {
    setOpen(openAlert);
  }, []);

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={duration}
        onClose={onCloseAlert}
      >
        <Alert onClose={onCloseAlert} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      {/*<Alert severity="error">This is an error message!</Alert>*/}
      {/*<Alert severity="warning">This is a warning message!</Alert>*/}
      {/*<Alert severity="info">This is an information message!</Alert>*/}
      {/*<Alert severity="success">This is a success message!</Alert>*/}
    </div>
  );
}
