import React, { useState } from 'react';
import $ from 'jquery';
import { stringUtils } from '../../utils/string.utils';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardBody from '../../components/Card/CardBody.js';
import UserRiskAssessmentQuestionView from './UserRiskAssessmentQuestion.view';
import Typography from '@material-ui/core/Typography';
import ViewUserRiskLevelDialog from '../../components/Dialog/ViewUserRiskLevelDialog.view';

const styles = {
  viewRiskLevelView: {
    float: 'right',
    marginTop: -25,
    fontSize: 16,
  },
  viewRiskLevelLink: {
    color: '#00acc1',
    fontWeight: 40,
  },
};

const useStyles = makeStyles(styles);

export default function UserRiskAssessmentView(props) {
  const classes = useStyles();
  const { currentUser } = props;
  const [viewRiskLevelOpen, setViewRiskLevelOpen] = useState(null);
  const handleOnViewRiskLevel = () => {
    setViewRiskLevelOpen(true);
  };

  const handleViewUserRiskLevelClick = () => {
    setViewRiskLevelOpen(false);
  };
  const message = currentUser?.riskLevel ? `Your current Risk Level is R${currentUser.riskLevel}: ${currentUser?.riskText}. ${stringUtils.redoUserAssessmentMsg}`
    : stringUtils.noUserAssessmentMsg;
  const messageColor = currentUser?.riskLevel ? 'blue' : 'red';
  const [riskLevelMessage, setRiskLevelMessage] = useState({ message, messageColor });

  const updateRiskLevel = (riskInfo) => {
    setRiskLevelMessage({
      message: `Your current Risk Level is ${riskInfo}. ${stringUtils.redoUserAssessmentMsg}`,
      messageColor: 'blue',
    });
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {viewRiskLevelOpen && (
        <ViewUserRiskLevelDialog
          handleViewUserRiskLevelClick={handleViewUserRiskLevelClick}
          open={viewRiskLevelOpen}
        />
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div>
            <div style={{ marginBottom: 5 }}>
              <span style={{ color: riskLevelMessage.messageColor, fontSize: 16, fontWeight: 500 }}>
                {riskLevelMessage.message}
              </span>
            </div>
            <Typography variant="h6" id="tableTitle" component="div">
              <span id="riskAssessmentQ">Risk Assessment Questionnaires</span>
            </Typography>
            <div className={classes.viewRiskLevelView}>
              <span>
                <a
                  className={classes.viewRiskLevelLink}
                  style={{ color: '#00acc1', fontWeight: 400 }}
                  href="#"
                  onClick={() => handleOnViewRiskLevel()}
                >
                  View Risk Level Description
                </a>
              </span>
            </div>
          </div>
          <Card>
            <CardBody>
              <UserRiskAssessmentQuestionView
                currentUser={props.currentUser}
                updateRiskLevel={updateRiskLevel}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
