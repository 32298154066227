import React from 'react';
import { useLocation } from 'react-router-dom';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { stringUtils } from '../../utils/string.utils';

export default function FooterView() {
  const location = useLocation();
  const footerText =
    location.pathname === '/admin/support' ? stringUtils.supportText : stringUtils.homeEmail;

  return (
    <div className="footer">
      <div className="content1400">
        <GridContainer>
          <GridItem xs={3} sm={3} md={3}>
            <div className="footerLogo"></div>
          </GridItem>
          <GridItem xs={9} sm={9} md={9} style={{ marginLeft: -20 }}>
            <div
              className="footContent"
              style={{
                fontSize: 16,
                color: '#fff',
                letterSpacing: '-0.43px',
                opacity: 0.6,
                lineHeight: 2,
              }}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {location.pathname === '/admin/support' ? (
                    <span style={{ fontSize: 20, fontWeight: 700 }}>{footerText}</span>
                  ) : (
                    <span>{footerText}</span>
                  )}
                </GridItem>
              </GridContainer>
            </div>
            <div
              style={{
                margin: '20px 0',
                opacity: 0.2,
                border: '1px solid #fff',
              }}
            ></div>
            <p style={{ fontSize: 16, color: '#fff', letterSpacing: -0.43, opacity: 0.6 }}>
              {stringUtils.companyName} US:{' '}
              <a
                style={{ color: '#fff', textDecoration: 'underline' }}
                href="https://www.gopipa.com/admin/home"
              >
                {stringUtils.companyWebSite}
              </a>
            </p>
            <p className="footerCopyright">
              © {stringUtils.companyName}. {stringUtils.homeReservedRight}
            </p>
          </GridItem>
        </GridContainer>
      </div>

      <div className="pull-right">
        {/*<nz-back-top className="ng-tns-c3-1">*/}
        {/*  <div className="ant-back-top ng-tns-c3-1 ng-trigger ng-trigger-enterLeave ng-star-inserted">*/}
        {/*    <a className="scrollup ng-star-inserted">*/}
        {/*      <i className="fa fa-angle-up fa-3x"></i>*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*</nz-back-top>*/}
      </div>
    </div>
  );
}
