import React from 'react';
import { Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppsIcon from '@material-ui/icons/Apps';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import StoppedAppIcon from '@material-ui/icons/HighlightOff';
import AqmCardView from './AqmCard.view.tsx';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import {AqmStrategy, getAQMStrategyList, getMyAQMStrategyList} from '../../utils/strategy.utils';
import { getLoginUser, getTrackAccountAQMSubscribe } from '../../utils/user.utils';
import Typography from '@material-ui/core/Typography';
import { stringUtils } from '../../utils/string.utils';
import InfoIcon from "@material-ui/icons/Info";
import TutorialView from "../AqmStrategy/Tutorial.view";

const styles = {
  waitingMsg: {
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 70,
    marginLeft: -80,
  },
  circularProgressTop: {
    marginTop: 20,
  },
  circularProgressDiv: {
    border: '1px solid 1px solid #d3d3d3',
    display: 'flex',
    justifyContent: 'center',
    height: 100,
  },
};
const useStyles = makeStyles(styles);

export default function AqmStrategyListView() {
  const classes = useStyles();
  const [responseData, setResponseData] = React.useState(null);
  const [myResponseData, setMyResponseData] = React.useState(null);
  const [myStoppedResponseData, setMyStoppedResponseData] = React.useState(null);
  const [myAQMResponseData, setMyAQMResponseData] = React.useState(null);
  const [investorState, setInvestorState] = React.useState(null);
  // const [developerState, setDeveloperState] = React.useState(false);
  const [trackAccount, setTrackAccount] = React.useState(null);
  const [userId, setUserId] = React.useState('');
  const [progress, setProgress] = React.useState(true);
  const sortStrategyByName = (array, key) => {
    return array.sort(function (a, b) {
      let x = a[key];
      let y = b[key];
      if (typeof x === 'string') {
        x = ('' + x).toLowerCase();
      }
      if (typeof y === 'string') {
        y = ('' + y).toLowerCase();
      }
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };
  const getBackTestTrackInfo = (data) => {
    data.forEach((sty: any) => {
      let backIndex: number = -1;
      if (sty.backtestReports) {
        sty.backtestReports.every((r: any, j: number) => {
          if (r.reportMark === 1) {
            backIndex = j;
            return false;
          }
          return true;
        });
      }
      if (backIndex !== -1) {
        sty.backTest = sty.backtestReports[backIndex];
      }
    });
  };

  const userIdRef = React.useRef(userId);
  React.useEffect(() => {
    userIdRef.current = userId;
  }, [userId]);

  const handleTabChange = (event, value) => {
    console.log('getAQMStrategy handleTabChange value = %s', value);
    setProgress(true);
    if (value === 0) {
      getAQMStrategyList()
          .then((response) => {
            const data = response && response?.data ? response.data : null;
            console.log('getAQMStrategyList data = %o', data);
            if (data.code === 200) {
                //filter AQM that is not available to investoer (developer
                let aqms = [];
                data.data.aqms.forEach((item: any) => {
                if ( item.openForInvestor  || userIdRef.current === item.userId || userIdRef.current === '103' ) {
                   aqms.push(item);
                }
              });
              const sortedData = sortStrategyByName(aqms, 'name');
              setResponseData(sortedData);
              setMyResponseData(sortedData);
              setProgress(false);
            }
          })
          .catch((error) => {
            console.debug(error);
            setProgress(false);
          });
    } else if (value === 1 || value === 2) {
      const params: AqmStrategy = {
        status: value === 1? 'Subscribed' : 'Stop',
      };
      getMyAQMStrategyList(params)
          .then((response) => {
            const data = response && response?.data ? response.data : null;
            console.log('getMyAQMStrategyList data = %o', data);
            if (data.code === 200) {
              console.log('getAQMStrategy 200 data = %o', data);
              const sortedData = sortStrategyByName(data.data.subscribes, 'name');
              setMyAQMResponseData(sortedData);
              setProgress(false);
            }
          })
          .catch((error) => {
            console.debug(error);
            setProgress(false);
          });
    }
  };

  const fetchUserAccount = React.useCallback(() => {
    getLoginUser(null)
      .then((response) => {
        const data = response && response?.data ? response.data : null;
        if (data.code === 200) {
          setInvestorState(data.data.investorState);
          if (data.data.userId !== null) {
            let userid = data.data.userId? data.data.userId:null;
            setUserId(userid);
          }
          if (data.data.investorState !== 'Disabled') {
            fetchAqmTrackAccountData();
            fetchData();
          }
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  }, []);

  const fetchData = React.useCallback(() => {
    handleTabChange({}, 0);
  }, []);

  const fetchAqmTrackAccountData = React.useCallback(() => {
    getTrackAccountAQMSubscribe()
      .then((response) => {
        // console.debug('aqmStrategyList.view: setTrackAccount = %o', response);
        // IB: DU819878   (Max Usable Cash: 428,868.02)
        const trackAcct: any = [];
        response.data.data.forEach((item: any) => {
          const accoutName = item.trackAccountName
            ? item.trackAccountName + (item.account ? ' (' + item.account + ')' : '')
            : item.account;
          const accountValue = item.usableCash ? parseFloat(item.usableCash.toFixed(2)) : 0;
          const accoutInfo = `${item.broker}: ${accoutName}  (Max Usable Cash: ${accountValue})`;
          trackAcct.push({ key: item.account, value: accoutInfo, id: item.id });
          //console.debug('aqmStrategyList.view: trackAcct = %o', trackAcct);
        });
        setTrackAccount(trackAcct);
      })
      .catch((error) => {
        console.debug(error);
      });
  }, []);

  React.useEffect(() => {
    fetchUserAccount();
  }, [fetchData]);

  return (
    <div>
      <div className="mainPagePanel">
        <GridContainer>
          {investorState && investorState === 'Disabled' ? (
            <div style={{ marginLeft: 45, marginTop: 20 }}>
              <Typography component={'span'} variant={'h6'}>
                Your membership is disabled so you cannot view this page
                <br></br>
                Please go to
                <Link to="/admin/useraccount"> My Account </Link>
                to enable your membership in order to access {stringUtils.aqsStrategies} details
              </Typography>
            </div>
          ) : (
            <GridItem xs={12} sm={12} md={12}>
              <CustomTabs
                onTabChange={handleTabChange}
                title=""
                headerColor="primary"
                tabs={[
                  {
                    tabName: stringUtils.allAqmStrategies,
                    tabIcon: ViewModuleIcon,
                    tabContent: (
                      <div style={{ marginTop: -20 }}>
                        {progress ? (
                          <div className={classes.circularProgressDiv}>
                            <CircularProgress className={classes.circularProgressTop} />
                            <div className={classes.waitingMsg}>Please wait...</div>
                          </div>
                        ) : (
                          <AqmCardView
                            aqmStatus="modelAccount"
                            strategyData={responseData}
                            trackAccount={trackAccount}
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    tabName: stringUtils.myAqm,
                    tabIcon: SubscriptionsIcon,
                    tabContent: (
                        <div style={{ marginTop: -20 }}>
                          {progress ? (
                              <div className={classes.circularProgressDiv}>
                                <CircularProgress className={classes.circularProgressTop} />
                                <div className={classes.waitingMsg}>Please wait...</div>
                              </div>
                          ) : (
                              <AqmCardView
                                  aqmStatus="Subscribed"
                                  strategyData={myAQMResponseData}
                                  trackAccount={trackAccount}
                              />
                          )}
                        </div>
                    ),
                  },
                  {
                    tabName: stringUtils.myAqmStoppedStrategies,
                    tabIcon: StoppedAppIcon,
                    tabContent: (
                        <div style={{ marginTop: -20 }}>
                          {progress ? (
                              <div className={classes.circularProgressDiv}>
                                <CircularProgress className={classes.circularProgressTop} />
                                <div className={classes.waitingMsg}>Please wait...</div>
                              </div>
                          ) : (
                              <AqmCardView
                                  aqmStatus="Stopped"
                                  strategyData={myAQMResponseData}
                                  trackAccount={trackAccount}
                              />
                          )}
                        </div>
                    ),
                  },
                  {
                    tabName: 'Tutorial',
                    tabIcon: InfoIcon,
                    tabContent: (
                        <div>
                            <TutorialView/>
                        </div>
                    ),
                  },
                ]}
              />
            </GridItem>
          )}
        </GridContainer>
      </div>
    </div>
  );
}
