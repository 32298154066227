import React from 'react';
import { useLocation, Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { validateEmailAccount, resetPassword } from '../../utils/user.utils';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const styles = {
  dialogCenter: {
    margin: 'auto',
    textAlign: 'center',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  dialogContentTextWidth: {
    margin: 'auto',
    textAlign: 'center',
    width: 500,
  },
  errorMsg: {
    color: 'red',
  },
};
const useStyles = makeStyles(styles);

export default function WebsiteValidateDialog() {
  const classes = useStyles();
  const fullScreen = true;
  const location = useLocation();
  const params = queryString.parse(location.search);
  const validateEmail = !$.isEmptyObject(params) && (params?.validate === 'changeEmail' || params?.validate === 'email');
  const resetEmailPassword = !$.isEmptyObject(params) && params?.validate === 'resetpwd';
  console.debug(
    '#### websitevalidate location = %o params = %o validateEmail = %s resetEmailPassword = %s',
    location,
    params,
    validateEmail,
    resetEmailPassword
  );
  const [openValideEmail, setOpenValideEmail] = React.useState(validateEmail);
  const [openResetEmailPassword, setOpenResetEmailPassword] = React.useState(resetEmailPassword);
  const [valideEmailMsg, setValideEmailMsg] = React.useState('');
  const [values, setValues] = React.useState({
    password: '',
    repeatPassword: '',
  });
  const [errMsg, setErrMsg] = React.useState(false);

  const errorPassword = values.password === '';
  const errorRepeatPassword =
    values.repeatPassword === '' || values.repeatPassword !== values.password;

  React.useEffect(() => {
    if (validateEmail) {
      setOpenValideEmail(true);
      validateEmailAccount(params.p)
        .then((response) => {
          console.debug('*****WebsiteValidate.view: email validation response = %o', response);
          setValideEmailMsg('Account has been activated successfully. Please login');
        })
        .catch((error) => {
          console.debug(error);
          setValideEmailMsg('Account activation failed! Please login');
        });
    }
  }, []);

  const handleChange = (name) => (event) => {
    setErrMsg(false);
    setValues({ ...values, [name]: event.target.value });
  };

  const handleOnClickSubmit = () => {
    console.log('WebsiteValidate.view reset password: handleOnClickSubmit');
    resetPassword(params.p, values.password)
      .then((response) => {
        console.log('Login dialog repo = %o', response);
        if (response && response?.data.code === 200) {
          window.location.href = '../admin/home';
        }
      })
      .catch((error) => {
        console.debug('reset password: error = %o', error);
        setErrMsg(error.data.errMsg);
      });
  };

  return (
    <Typography component={'div'}>
      {openValideEmail && (
        <Dialog
          fullScreen={fullScreen}
          open={openValideEmail}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.dialogCenter}
        >
          <DialogTitle id="validate-email-dialog-title">
            {'Validate Email Notification'}
          </DialogTitle>
          <DialogContent dividers id="validate-email--dialog-description">
            <DialogContentText id="validate-email--dialog-description">
              <Typography component={'span'} variant={'h6'}>
                {valideEmailMsg}
                <Link to="/admin/home"> GoPIPA</Link>
              </Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {openResetEmailPassword && (
        <Dialog
          fullScreen={fullScreen}
          open={openResetEmailPassword}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.dialogCenter}
        >
          <DialogTitle id="reset-password-dialog-title">{'Reset Email Password'}</DialogTitle>
          <DialogContent dividers id="reset-password-dialog-description">
            <DialogContentText className={classes.dialogContentTextWidth}>
              {errMsg && (
                <Typography className={classes.errorMsg} component={'span'} variant={'h6'}>
                  {errMsg}
                  <Link to="/admin/home"> Login GoPIPA</Link>
                </Typography>
              )}
              <TextField
                id="password"
                label="Password"
                type="password"
                required
                value={values.password}
                onChange={handleChange('password')}
                error={errorPassword}
                helperText={errorPassword ? 'This field is required' : ' '}
                fullWidth
              />
              <TextField
                id="repeatPassword"
                label="Re-enter Password"
                type="password"
                required
                value={values.repeatPassword}
                onChange={handleChange('repeatPassword')}
                error={errorRepeatPassword}
                helperText={errorRepeatPassword ? 'Passwords do not match' : ' '}
                fullWidth
              />
              <Button
                onClick={handleOnClickSubmit}
                color="primary"
                classes={{ label: classes.label }}
                disabled={errorPassword || errorRepeatPassword}
              >
                Submit
              </Button>
            </DialogContentText>
          </DialogContent>
          {/*{errMsg && { errMsg }}*/}
        </Dialog>
      )}
    </Typography>
  );
}
