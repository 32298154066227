import React from 'react';
import {getAccountPosition} from "../../utils/user.utils";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {makeStyles} from "@material-ui/core/styles";

const styles = {
    tablePadding: {
        paddingRight: 5,
        paddingLeft: 5,
        paddingBottom: 35,
        width: "310px",
    },
    noWarp: {
        whiteSpace: 'nowrap',
    },
    position: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        backgroundColor: 'whitesmoke',
        padding: '10px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        overflow: 'auto',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        opacity: 0.4,
        zIndex: 8888,
    },
    button: {
        position: 'fixed',
        bottom: '5px',
        left: '40%',
        backgroundColor: 'whitesmoke',
        fontFamily: 'Arial, Helvetica',
        borderRadius: '5px',
    },
    title: {
        color: '#000000DE',
        padding: '5px 0px 5px 8px',
        fontFamily: 'Arial, Helvetica',
        fontSize: '15.4px',
        fontWeight: 200,
        textTransform: 'uppercase',
        overflowWrap: 'break-word',
        width: "310px",
    },
};

const useStyles = makeStyles(styles);

function PositionsSnapshot({onClick, positionData, title}) {
    const classes = useStyles();

    const columns = [
        {
            name: 'Symbol',
            selector: 'symbol',
            sortable: true,
            width: '80px',
            cell: (row) => {
                return (
                    <div
                        style={
                            row.symbol === 'Cash'
                                ? { fontWeight: 600 }
                                : {}
                        }
                    >
                        {row.symbol}
                    </div>
                );
            },
        },
        {
            name: 'Ratio',
            selector: 'position',
            sortable: true,
            right: true,
        },
    ];

    return (
        <>
            <div className={classes.overlay} onClick={onClick}/>
            <div className={classes.position}>
                <div className={classes.title}>{title}</div>
                <div className={classes.tablePadding}>
                    <DataTable
                        title="Portfolio Positions"
                        data={positionData}
                        columns={columns}
                        fixedHeaderScrollHeight="50vh"
                        fixedHeader
                        highlightOnHover
                        striped
                        defaultSortFieldId={1}
                        sortIcon={<SortIcon />}
                    />
                </div>
                <div>
                    <button className={classes.button} onClick={onClick}>Close</button>
                </div>
            </div>
        </>
    );
}

export default PositionsSnapshot;
