import React from 'react';
import { useLocation, Redirect, Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import PortfolioActivityTableView from './PortfolioActivityTable.view.tsx';
import PortfolioInvestmentAmountActivityTableView from './PortfolioInvestmentAmountActivityTable.view.tsx';
import { stringUtils } from '../../utils/string.utils';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

export default function PortfolioActivities() {
  const classes = useStyles();
  let title = `-> Activities`;
  const { state } = useLocation();
  const portfolioId = state?.portfolioId ?? null;
  const portfolioName = state?.portfolioName ?? null;
  const portfolioLog = state?.portfolioLog ?? null;
  if (portfolioLog) {
    title = `-> Investment Amount Activities`;
  }

  return (
    <div className="mainPagePanel">
      <GridContainer>
        {portfolioId ? (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  <Link className={classes.cardTitleWhite} to="/admin/strategy">
                    {stringUtils.myQqsPortfolio}
                  </Link>
                  {title}
                </h4>
                <p className={classes.cardCategoryWhite}>{portfolioName}</p>
              </CardHeader>
              <CardBody>
                {portfolioLog ? (
                  <PortfolioInvestmentAmountActivityTableView portfolioId={portfolioId} />
                ) : (
                  <PortfolioActivityTableView portfolioId={portfolioId} />
                )}
              </CardBody>
            </Card>
          </GridItem>
        ) : (
          <Redirect to="/admin/strategy" />
        )}
      </GridContainer>
    </div>
  );
}
