import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ReactHtmlParser from "html-react-parser";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {saveSymbols, getSymbols, getStockIDA} from "../../utils/strategy.utils";
import Button from "../../components/CustomButtons/Button";

const styles = {
    noWarp: {
        whiteSpace: 'nowrap',
    },
    taBox: {
        fontFamily: 'Arial, Helvetica',
        height: 'auto',
        margin: '5px -10px',
        padding: '0p',
        fontWeight: 500,
        border: '1px solid #000000DE',
    },
    chartBox: {
        fontFamily: 'Arial, Helvetica',
        height: 'auto',
        margin: '5px -10px 25px',
        padding: '0p',
        fontWeight: 500,
        border: '1px solid #000000DE',
    },
    bigtitle: {
        fontSize: '16px',
        fontWeight: 550,
        padding: '10px 0px',
        padding: '10px 0px',
        color: '#000000DE',
    },
    tabletitle: {
        fontSize: '15px',
        fontWeight: 550,
        padding: '10px 15px',
        color: '#000000DE',
        backgroundColor: '#e5e0e0',
    },
    error: {
        fontSize: '16px',
        fontWeight: 800,
        padding: '3px 0px',
        color: 'red',
    },
    circularProgressTop: {
        marginTop: 20,
    },
    circularProgressDiv: {
        border: '1px solid 1px solid #d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
    },
    title: {
        fontFamily: 'Roboto, Arial, Helvetica',
        fontSize: '16px',
        fontWeight: 200,
        padding: '10px 0px 0px 0px',
        color: '#000000DE',
    },
}

const useStyles = makeStyles(styles);

export default function CompleteAnalysisView(props) {
    const { symbol, isETF, refresh, sectorETF } = props;
    const [symbolInput, setSymbolInput] = React.useState("");
    const [idaColumns, setIdaColumns] = React.useState([]);
    const [sadTable, setSadTable] = React.useState([]);
    const [tadTable, setTadTable] = React.useState([]);
    const [taSummary, setTaSummary] = React.useState('');
    const [detectTable, setDetectTable] = React.useState([]);
    const [predictTable, setPredictTable] = React.useState([]);
    const [fadTable1, setFadTable1] = React.useState([]);
    const [fadQTable1, setFadQTable1] = React.useState([]);
    const [sadTable1, setSadTable1] = React.useState([]);
    const [tadTable1, setTadTable1] = React.useState([]);
    const [taSummary2, setTaSummary2] = React.useState('');
    const [fadTable2, setFadTable2] = React.useState([]);
    const [fadQTable2, setFadQTable2] = React.useState([]);
    const [sadTable2, setSadTable2] = React.useState([]);
    const [tadTable2, setTadTable2] = React.useState([]);
    const [taSummary1, setTaSummary1] = React.useState('');
    const [detectTable1, setDetectTable1] = React.useState([]);
    const [detectTable2, setDetectTable2] = React.useState([]);
    const [predictTable1, setPredictTable1] = React.useState([]);
    const [predictTable2, setPredictTable2] = React.useState([]);
    const [tableWidth, setTableWidth] = React.useState('auto');
    const [tableTitle, setTableTitle] = React.useState('');
    const [showMarketTable, setShowMarketTable] = React.useState(false);
    const [showSectorTable, setShowSectorTable] = React.useState(true);
    const [showEtfTable, setShowEtfTable] = React.useState(false);
    const [progress, setProgress] = React.useState(false);

    const tad_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            width: '110px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>TA State</div>
                ),
            selector: 'taRating',
            sortable: true,
            width: '135px',
            cell: (row) => {
                return (
                    <div style={{fontSize: '14px', textAlign: 'center'}}>
                        {row.taRating}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>TA String</div>
                ),
            selector: 'taString',
            width: '240px',
            cell: (row) => {
                return (
                    <div style={{fontWeight: 550, fontSize: '14px', fontFamily: 'monospace', textAlign: 'center'}}>
                        {row.taString}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Trend</div>
                ),
            selector: 'trend',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.trend || row.trend === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.trend):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Trend 20 Day Slope</div>
                ),
            selector: 'trend5',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.trend5 || row.trend5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.trend5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Trend 20 Day Slope</div>
                ),
            selector: 'trend20',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.trend20 || row.trend20=== 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.trend20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Momentum</div>
                ),
            selector: 'momentum',
            center: true,
            width: '110px',
            cell: (row) => {
                const data = row.momentum || row.momentum === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.momentum):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Momentum 5 Day Slope</div>
                ),
            selector: 'momentum5',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.momentum5 || row.momentum5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.momentum5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Momentum 20 Day Slope</div>
                ),
            selector: 'momentum20',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.momentum20 || row.momentum20 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.momentum20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Breadth</div>
                ),
            selector: 'breadth',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.breadth || row.breadth === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breadth):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Breadth 5 Day Slope</div>
                ),
            selector: 'breadth5',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.breadth5 || row.breadth5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breadth5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Breadth 20 Day Slope</div>
                ),
            selector: 'breadth20',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.breadth20 || row.breadth20 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breadth20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Oscillation</div>
                ),
            selector: 'oscillation',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.oscillation || row.oscillation === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.oscillation):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Oscillation 5 Day Slope</div>
                ),
            selector: 'oscillation5',
            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.oscillation5 || row.oscillation5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.oscillation5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Oscillation 20 Day Slope</div>
                ),
            selector: 'oscillation20',

            center: true,
            width: '120px',
            cell: (row) => {
                const data = row.oscillation20 || row.oscillation20 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.oscillation20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>change_status1</div>
                ),
            selector: 'changeStatus1',
            center: true,
            width: '110px',
            cell: (row) => {
                const data = row.changeStatus1 || row.changeStatus1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.changeStatus1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>ema_co1</div>
                ),
            selector: 'emaCo1',
            center: true,
            width: '110px',
            cell: (row) => {
                const data = row.emaCo1 || row.emaCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.emaCo1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>psar_co1</div>
                ),
            selector: 'psarCo1',
            center: true,
            width: '110px',
            cell: (row) => {
                const data = row.psarCo1 || row.psarCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.psarCo1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>macd_co1</div>
                ),
            selector: 'macdCo1',
            center: true,
            width: '110px',
            cell: (row) => {
                const data = row.macdCo1 || row.macdCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.macdCo1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>bb_co1</div>
                ),
            selector: 'bbCo1',
            center: true,
            width: '110px',
            cell: (row) => {
                const data = row.bbCo1 || row.bbCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.bbCo1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>rsi_co1</div>
                ),
            selector: 'rsiCo1',
            center: true,
            width: '110px',
            cell: (row) => {
                const data = row.rsiCo1 || row.rsiCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.rsiCo1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>breakout1</div>
                ),
            selector: 'breakout1',
            center: true,
            width: '110px',
            cell: (row) => {
                const data = row.breakout1 || row.breakout1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breakout1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    //Sentiment data
    const sad_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Put Volume</div>
                ),
            selector: 'putVolume',
            center: true,
            button: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.putVolume)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Call Volume</div>
                ),
            selector: 'callVolume',
            center: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.callVolume)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Put/Call Ratio of Volume</div>
                ),
            selector: 'putCallVolumeRatio',
            center: true,
            width: '120px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Put Open Interest</div>
                ),
            selector: 'putOpenInterest',
            center: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.putOpenInterest)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Call Open Interest</div>
                ),
            selector: 'callOpenInterest',
            center: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.callOpenInterest)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Put/Call Ratio of Open Interest</div>
                ),
            selector: 'putCallOpenInterestRatio',
            center: true,
            width: '120px',
        },
    ];

    const pred_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            width: '110px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Current State (R1)</div>
                ),
            selector: 'currentStateR1',
            center: true,
            width: '130px',
            cell: (row) => {
                const data = row.currentStateR1? row.currentStateR1:"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Current State (H1)</div>
                ),
            selector: 'currentStateH1',
            center: true,
            width: '130px',
            cell: (row) => {
                const data = row.currentStateH1? row.currentStateH1:"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>5 Days</div>
                ),
            selector: 'pred5dDrl',
            center: true,
            width: '130px',
            cell: (row) => {
                const data = row.pred5dDrl? row.pred5dDrl:"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            },
        },
        // {
        //     name:
        //         (
        //             <div style={{fontWeight: 700, fontSize: '13px'}}>10 Days</div>
        //         ),
        //     selector: 'pred10dDrl',
        //     right: true,
        //     width: '120px',
        //     cell: (row) => {
        //         const data = row.pred10dDrl?row.pred10dDrl:"N/A";
        //         return (
        //             <div>
        //                 {data}
        //             </div>
        //         )
        //     }
        // },
    ];

    const fad_metric_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            width: '120px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EV</div>
                ),
            selector: 'ev',
            center: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        ${Intl.NumberFormat("en-US",{}).format(row.ev)}M
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EV/EBIT</div>
                ),
            selector: 'evebit',
            center: true,
            width: '120px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EV/EBITDA</div>
                ),
            selector: 'evebitda',
            center: true,
            width: '120px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Marketcap</div>
                ),
            selector: 'marketcap',
            center: true,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        ${Intl.NumberFormat("en-US",{}).format(row.marketcap)}M
                    </div>
                )
            }
        },
        {
            // name: 'Close',
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>PB</div>
                ),
            selector: 'pb',
            center: true,
            width: '90px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>PE</div>
                ),
            selector: 'pe',

            center: true,
            width: '90px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>PS</div>
                ),
            selector: 'ps',
            center: true,
            cell: (row) => {
                const data = row.ps;
                return (
                    <div>
                        {data}
                    </div>
                );
            },
            width: '90px',
        },
    ];

    const fad1_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Calendar Date</div>
                ),
            selector: 'tradeDate',
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Market Cap</div>
                ),
            selector: 'marketCap',
            center: true,
            width: '130px',
            cell: (row) => {
                return (
                    <div>
                        ${Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(row.marketCap/1000000)}M
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Revenue to Market Cap</div>
                ),
            selector: 'revenueToMarketCap',
            center: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.revenueToMarketCap)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Price to Book</div>
                ),
            selector: 'priceToBook',

            center: true,
            width: '110px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Price to Earnings</div>
                ),
            selector: 'priceToEarning',
            center: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.priceToEarning)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Price to Sales</div>
                ),
            selector: 'priceToSale',
            center: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.priceToSale)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Price to Free Cashflows</div>
                ),
            selector: 'priceToFreeCashflow',
            center: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.priceToFreeCashflow)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Price to Operating Cashflows</div>
                ),
            selector: 'priceToOperatingCashflow',
            center: true,
            width: '110px',
            cell: (row) => {
                const data = row.priceToOperatingCashflow? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.priceToOperatingCashflow):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Asset to Free Cashflows</div>
                ),
            selector: 'assetToFreeCashflow',
            center: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.assetToFreeCashflow)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Debt to Free Cashflows</div>
                ),
            selector: 'debtToFreeCashflow',
            center: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.debtToFreeCashflow)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EV to Cashflows</div>
                ),
            selector: 'evToCashflow',
            center: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.evToCashflow)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EV to EBIT</div>
                ),
            selector: 'evToEBIT',
            center: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.evToEBIT)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>EV to EBITDA</div>
                ),
            selector: 'evToEBITDA',
            center: true,
            width: '110px',
            cell: (row) => {
                return (
                    <div>
                        {Intl.NumberFormat("en-US",{}).format(row.evToEBITDA)}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Price To EBITDA</div>
                ),
            selector: 'priceToEBITDA',
            center: true,
            width: '110px',
            cell: (row) => {
                const data = row.priceToEBITDA? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.priceToEBITDA):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    function updateHeader(header) : string {
        let newHeader = JSON.stringify(header).replace(/[{\"}]/g,' ').replace(':',' ');
        if (newHeader.indexOf('TA Metrics Change Event') > 0) {
            if (newHeader.indexOf(' 3.0 ') > 0)
                return newHeader.replace(' 3.0 ', ' 3(val&gt;0) ');
            else if (newHeader.indexOf(' 2.0 ') > 0)
                return newHeader.replace(' 2.0 ', ' 2(s20&gt;0) ');
            else if (newHeader.indexOf(' 1.0 ') > 0)
                return newHeader.replace(' 1.0 ', ' 1(s5&gt;0) ');
            else if (newHeader.indexOf(' -3.0 ') > 0)
                return newHeader.replace(' -3.0 ', ' -3(val&lt;0) ');
            else if (newHeader.indexOf(' -2.0 ') > 0)
                return newHeader.replace(' -2.0 ', ' -2(s20&lt;0) ');
            else if (newHeader.indexOf(' -1.0 ') > 0)
                return newHeader.replace(' -1.0 ', ' -1(s5<0) ');
        } else if (newHeader.indexOf('Trend EMA Crossover Event') > 0) {
            if (newHeader.indexOf(' 2.0 ') > 0)
                return newHeader.replace(' 2.0 ', ' 2(p&gt;ema20&gt;ema50) ');
            else if (newHeader.indexOf(' 1.0 ') > 0)
                return newHeader.replace(' 1.0 ', ' 1(p&gt;ema20) ');
            else if (newHeader.indexOf(' -2.0 ') > 0)
                return newHeader.replace(' -2.0 ', ' -2(p&lt;ema20&lt;ema50) ');
            else if (newHeader.indexOf(' -1.0 ') > 0)
                return newHeader.replace(' -1.0 ', ' -1(p&lt;ema20) ');
        } else if (newHeader.indexOf('Trend PSAR Crossover Events') > 0) {
            if (newHeader.indexOf(' 1.0 ') > 0)
                return newHeader.replace(' 1.0 ', ' 1(p&gt;psar) ');
            else if (newHeader.indexOf(' -1.0 ') > 0)
                return newHeader.replace(' -1.0 ', ' -1(p&lt;psar) ');
        } else if (newHeader.indexOf('Momentum MACD Crossover Events') > 0) {
            if (newHeader.indexOf(' 2.0 ') > 0)
                return newHeader.replace(' 2.0 ', ' 2(macd&gt;sig,macd&gt;0) ');
            else if (newHeader.indexOf(' 1.0 ') > 0)
                return newHeader.replace(' 1.0 ', ' 1(macd&gt;sig,macd&lt;0) ');
            else if (newHeader.indexOf(' -2.0 ') > 0)
                return newHeader.replace(' -2.0 ', ' -2(macd&lt;sig,macd&lt;0) ');
            else if (newHeader.indexOf(' -1.0 ') > 0)
                return newHeader.replace(' -1.0 ', ' -1(macd&lt;sig,macd&gt;0) ');
        } else if (newHeader.indexOf('Bollinger Band Crossover Events') > 0) {
            if (newHeader.indexOf(' 1.0 ') > 0)
                return newHeader.replace(' 1.0 ', ' 1(p&lt;low bb) ');
            else if (newHeader.indexOf(' -1.0 ') > 0)
                return newHeader.replace(' -1.0 ', ' -1(p&gt;high bb) ');
        } else if (newHeader.indexOf('RSI Crossover Events') > 0) {
            if (newHeader.indexOf(' 2.0 ') > 0)
                return newHeader.replace(' 2.0 ', ' 2(rsi&lt;20) ');
            else if (newHeader.indexOf(' 1.0 ') > 0)
                return newHeader.replace(' 1.0 ', ' 1(rsi&lt;30) ');
            else if (newHeader.indexOf(' -2.0 ') > 0)
                return newHeader.replace(' -2.0 ', ' -2(rsi&gt;80) ');
            else if (newHeader.indexOf(' -1.0 ') > 0)
                return newHeader.replace(' -1.0 ', ' -1(rsi&gt;70) ');
        } else if (newHeader.indexOf('Price & Volume Breakout Events') > 0) {
            if (newHeader.indexOf(' 1.0 ') > 0)
                return newHeader.replace(' 1.0 ', ' 1(breakup) ');
            else if (newHeader.indexOf(' -1.0 ') > 0)
                return newHeader.replace(' -1.0 ', ' -1(breakdown) ');
        }
        return '';
    }

    const getDataTable = (func, symbols, length, type) => {
        getStockIDA(symbols, func, 1, length, 1)
            .then((response) => {
                if (response.data.code === 200) {
                    const data = response.data.data;
                    setProgress(false);
                    if (data.idaTable !== null) {
                        switch (func) {
                            case 2:
                                if (type === 2)
                                    setFadTable1(data.idaTable);
                                break;
                            case 4:
                                if (type === 3)
                                    setSadTable2(data.idaTable);
                                if (type === 2)
                                    setSadTable1(data.idaTable);
                                if (type === 1)
                                    setSadTable(data.idaTable);
                                break;
                            case 5:
                                if (type === 2)
                                    setFadQTable1(data.idaTable);
                                break;
                            case 11:
                                if (type === 3)
                                    setTadTable2(data.idaTable);
                                if (type === 2)
                                    setTadTable1(data.idaTable);
                                if (type === 1)
                                    setTadTable(data.idaTable);
                                break;
                            case 12:
                                if (type === 3)
                                    setPredictTable2(data.idaTable);
                                if (type === 2)
                                    setPredictTable1(data.idaTable);
                                if (type === 1)
                                    setPredictTable(data.idaTable);
                                break;
                            case 18:
                                if (type === 1)
                                    setDetectTable2(data.idaTable);
                                if (type === 2)
                                    setDetectTable1(data.idaTable);
                                if (type === 1)
                                    setDetectTable(data.idaTable);
                                break;
                            default:
                                break;
                        }
                    }
                    if (func === 11 && data.comment !== null) {
                        if (type === 3)
                            setTaSummary2(data.comment);
                        if (type === 2)
                            setTaSummary1(data.comment);
                        if (type === 1)
                            setTaSummary(data.comment);
                    }
                } else  {
                    const err = response?.data?.errMsg? response.data.errMsg : null;
                    setIdaColumns([]);
                    setTadTable([]);
                    setProgress(false);
                }
            })
            .catch((error) => {
                setIdaColumns([]);
                setProgress(false);
                setTadTable([]);
                console.debug(error);
            });
    }

    React.useEffect(() => {
        setSymbolInput(symbol);
        if (isETF) {
            setShowMarketTable(false);
            setShowSectorTable(false);
            setShowEtfTable(true);
        } else {
            setShowEtfTable(false);
            setShowMarketTable(true);
            // TA metric
            getDataTable(11, 'SPY', 1, 1);
            //sentiment
            getDataTable(4, 'SPY', 1, 1);
            //pattern & abnormality detection
            getDataTable(18, 'SPY', 5, 1);
            //prediction
            getDataTable(12, 'SPY', 1, 1);
            //Below are STOCK metric
            //fundamental daily
            getDataTable(2, symbol, 1, 2);
            //quarter
            getDataTable(5, symbol, 1, 2);

            if (sectorETF !== null) {
                setShowSectorTable(true);
                //below sector health type 3
                getDataTable(11, sectorETF, 1, 3);
                //sentiment
                getDataTable(4, sectorETF, 1, 3);
                //pattern & abnormality detection
                getDataTable(18, sectorETF, 5, 3);
                //prediction
                // getDataTable(12, sectorETF, 1, 3);
            } else {
                setShowSectorTable(false);
            }
        }
        //Below are ETF/STOCK metric
        /// TA metric
        getDataTable(11, symbol, 1, 2);
        //sentiment
        getDataTable(4, symbol, 1, 2);
        //pattern & abnormality detection
        getDataTable(18, symbol, 5, 2);
        //prediction
        getDataTable(12, symbol, 1, 2);
    }, [refresh]);

    const classes = useStyles();
    return (
        <>
            { showMarketTable && (
                <>
                    <div className={classes.bigtitle}><u>1. Market Health Check</u></div>
                    <div className={classes.taBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>S&P500 TA Metrics</div>
                            <DataTable
                                data={tadTable}
                                columns={tad_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}b
                            />
                    </div>
                    <div style={{margin: '5px 10px 20px 5px'}}>
                        {ReactHtmlParser(taSummary)}
                    </div>
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>S&P500 Sentiment</div>
                            <DataTable
                                data={sadTable1}
                                columns={sad_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                            />
                    </div>
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>S&P500 Pattern & Abnormality Detection</div>
                        <div>
                            {detectTable.map((detection) => (
                                <>
                                    <div className={classes.tabletitle} style={{maxWidth : tableWidth}}>{detection.header}</div>
                                    {detection.detectionList.map( (d) => {
                                        const content = updateHeader(d);
                                        if (content != '') {
                                            return (
                                                <>
                                                    <div calssName={classes.symbolslist} style={{
                                                        maxWidth: tableWidth,
                                                        minHeight: '30px',
                                                        margin: '10px, 25px',
                                                        padding: '15px'
                                                    }}>{ReactHtmlParser(content)}</div>
                                                    <hr style={{margin: '0px'}}/>
                                                </>
                                            )
                                        }
                                    })}
                                </>
                            ))}
                        </div>
                    </div>
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>S&P500 State & Prediction</div>
                        <div>
                            <DataTable
                                data={predictTable}
                                columns={pred_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                            />
                        </div>
                    </div>
                    { showSectorTable && (
                        <>
                            {/*<div className={classes.chartBox} style={{maxWidth : tableWidth}}>*/}
                            {/*    <div className={classes.tabletitle}>{symbolInput} Fundamental Daily</div>*/}
                            {/*    <div>*/}
                            {/*        <DataTable*/}
                            {/*            data={fadTable1}*/}
                            {/*            columns={fad_metric_columns}*/}
                            {/*            fixedHeaderScrollHeight="60vh"*/}
                            {/*            fixedHeader*/}
                            {/*            highlightOnHover*/}
                            {/*            striped*/}
                            {/*            defaultSortFieldId={1}*/}
                            {/*            sortIcon={<SortIcon />}*/}
                            {/*            noHeader={true}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className={classes.bigtitle}><u>2. Sector ETF Health Check</u></div>
                            <div className={classes.taBox} style={{maxWidth : tableWidth}}>
                                <div className={classes.tabletitle}>{sectorETF} TA Metrics</div>
                                <DataTable
                                    data={tadTable2}
                                    columns={tad_columns}
                                    fixedHeaderScrollHeight="60vh"
                                    fixedHeader
                                    highlightOnHover
                                    striped
                                    defaultSortFieldId={1}
                                    sortIcon={<SortIcon />}
                                    noHeader={true}b
                                />
                            </div>
                            <div style={{margin: '5px 10px 20px 5px'}}>
                                {ReactHtmlParser(taSummary1)}
                            </div>
                            <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                                <div className={classes.tabletitle}>{sectorETF}  Sentiment</div>
                                <DataTable
                                    data={sadTable2}
                                    columns={sad_columns}
                                    fixedHeaderScrollHeight="60vh"
                                    fixedHeader
                                    highlightOnHover
                                    striped
                                    defaultSortFieldId={1}
                                    sortIcon={<SortIcon />}
                                    noHeader={true}
                                />
                            </div>
                            <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                                <div className={classes.tabletitle}>{sectorETF}  Pattern & Abnormality Detection</div>
                                <div>
                                    {detectTable.map((detection) => (
                                        <>
                                            <div className={classes.tabletitle} style={{maxWidth : tableWidth}}>{detection.header}</div>
                                            {detection.detectionList.map( (d) => {
                                                const content = updateHeader(d);
                                                if (content != '') {
                                                    return (
                                                        <>
                                                            <div calssName={classes.symbolslist} style={{
                                                                maxWidth: tableWidth,
                                                                minHeight: '30px',
                                                                margin: '10px, 25px',
                                                                padding: '15px'
                                                            }}>{ReactHtmlParser(content)}</div>
                                                            <hr style={{margin: '0px'}}/>
                                                        </>
                                                    )
                                                }
                                            })}
                                        </>
                                    ))}
                                </div>
                            </div>
                            {/*<div className={classes.chartBox} style={{maxWidth : tableWidth}}>*/}
                            {/*    <div className={classes.tabletitle}>{sectorETF}  State & Prediction</div>*/}
                            {/*    <div>*/}
                            {/*        <DataTable*/}
                            {/*            data={predictTable2}*/}
                            {/*            columns={pred_columns}*/}
                            {/*            fixedHeaderScrollHeight="60vh"*/}
                            {/*            fixedHeader*/}
                            {/*            highlightOnHover*/}
                            {/*            striped*/}
                            {/*            defaultSortFieldId={1}*/}
                            {/*            sortIcon={<SortIcon />}*/}
                            {/*            noHeader={true}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </>
                    )}
                    <div className={classes.bigtitle}><u>3. Stock Health Check</u></div>
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{symbolInput} Fundamental Daily</div>
                        <div>
                            <DataTable
                                data={fadTable1}
                                columns={fad_metric_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                            />
                        </div>
                    </div>
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{symbolInput} Fundamental Quarterly</div>
                        <div>
                            <DataTable
                                data={fadQTable1}
                                columns={fad1_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                            />
                        </div>
                    </div>
                    <div className={classes.taBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{symbolInput} TA Metrics</div>
                        <div>
                            <DataTable
                                data={tadTable1}
                                columns={tad_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}b
                            />
                        </div>
                    </div>
                    <div style={{margin: '5px 10px 20px 5px'}}>
                        {ReactHtmlParser(taSummary1)}
                    </div>
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{symbolInput} Sentiment</div>
                        <div>
                            <DataTable
                                data={sadTable1}
                                columns={sad_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                            />
                        </div>
                    </div>
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{symbolInput} Pattern & Abnormality Detection</div>
                        <div>
                            {detectTable1.map((detection) => (
                                <>
                                    <div className={classes.tabletitle} style={{maxWidth : tableWidth}}>{detection.header}</div>
                                    {detection.detectionList.map( (d) => {
                                        const content = updateHeader(d);
                                        if (content != '') {
                                            return (
                                                <>
                                                    <div calssName={classes.symbolslist} style={{
                                                        maxWidth: tableWidth,
                                                        minHeight: '30px',
                                                        margin: '10px, 25px',
                                                        padding: '15px'
                                                    }}>{ReactHtmlParser(content)}</div>
                                                    <hr style={{margin: '0px'}}/>
                                                </>
                                            )
                                        }
                                    })}
                                </>
                            ))}
                        </div>
                    </div>
                </>
            )}
            { showMarketTable && symbol === 'SPY' && (
                <>
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{symbolInput} State & Prediction</div>
                        <div>
                            <DataTable
                                data={predictTable1}
                                columns={pred_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                            />
                        </div>
                    </div>
                </>
            )}
            { showEtfTable && (
                <>
                    <div className={classes.bigtitle}><u>1. ETF Health Check</u></div>
                    {/*<div className={classes.chartBox} style={{maxWidth : tableWidth}}>*/}
                    {/*    <div className={classes.tabletitle}>Fundamental</div>*/}
                    {/*    <div>*/}
                    {/*        <DataTable*/}
                    {/*            data={fadTable1}*/}
                    {/*            columns={fad_metric_columns}*/}
                    {/*            fixedHeaderScrollHeight="60vh"*/}
                    {/*            fixedHeader*/}
                    {/*            highlightOnHover*/}
                    {/*            striped*/}
                    {/*            defaultSortFieldId={1}*/}
                    {/*            sortIcon={<SortIcon />}*/}
                    {/*            noHeader={true}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={classes.taBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{symbolInput} TA Metrics</div>
                        <div>
                            <DataTable
                                data={tadTable1}
                                columns={tad_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}b
                            />
                        </div>
                    </div>
                    <div style={{margin: '5px 10px 20px 5px'}}>
                        {ReactHtmlParser(taSummary1)}
                    </div>
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{symbolInput} Sentiment</div>
                        <div>
                            <DataTable
                                data={sadTable1}
                                columns={sad_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                            />
                        </div>
                    </div>
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{symbolInput} Pattern & Abnormality Detection</div>
                        <div>
                            {detectTable1.map((detection) => (
                                <>
                                    <div className={classes.tabletitle} style={{maxWidth : tableWidth}}>{detection.header}</div>
                                    {detection.detectionList.map( (d) => {
                                        const content = updateHeader(d);
                                        if (content != '') {
                                            return (
                                                <>
                                                    <div calssName={classes.symbolslist} style={{
                                                        maxWidth: tableWidth,
                                                        minHeight: '30px',
                                                        margin: '10px, 25px',
                                                        padding: '15px'
                                                    }}>{ReactHtmlParser(content)}</div>
                                                    <hr style={{margin: '0px'}}/>
                                                </>
                                            )
                                        }
                                    })}
                                </>
                            ))}
                        </div>
                    </div>
                </>
            )}
            { symbol === 'SPY' && (
                <>
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{symbolInput} State & Prediction</div>
                        <div>
                            <DataTable
                                data={predictTable1}
                                columns={pred_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
