import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { getStrategyBacktestInfo } from '../../utils/strategy.utils';
import GridItem from '../../components/Grid/GridItem';
import TrackingBacktestTableView from './TrackingBacktestTable.view';
import TrackingBacktestChartView from './TrackingBacktestChart.view.tsx';
import GridContainer from '../../components/Grid/GridContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import Button from '../../components/CustomButtons/Button';

const styles = {
  waitingMsg: {
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 70,
    marginLeft: -80,
  },
  circularProgressTop: {
    marginTop: 20,
  },
  circularProgressDiv: {
    border: '1px solid 1px solid #d3d3d3',
    display: 'flex',
    justifyContent: 'center',
    height: 100,
  },
  period: {
    color: '#1890ff',
    outline: 'none',
    transition: 'color .3s',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '1.3em',
    position: 'relative',
    backgroundColor: '#f5f5f5',
  },
  accordionSummaryWidth: {
    width: '100%',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '14px',
    height: 28,
  },
  periodText: {
    paddingRight: 20,
  },
};
const useStyles = makeStyles(styles);

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    height: 45,
    '&$expanded': {
      minHeight: 45,
    },
    width: '100%',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function StrategyBacktestView(props) {
  const classes = useStyles();
  const { strategyData, benchmarkSymbol } = props;
  const [expanded, setExpanded] = React.useState(null);
  const [portfolioPeriod, setPortfolioPeriod] = React.useState([]);
  const [backtestChart, setBacktestChart] = React.useState(null);
  const [progress, setProgress] = React.useState(true);
  const [viewDetails, setViewDetails] = React.useState(false);
  const backtestChartData: any[] = [];
  const strategyType = 'backtest';

  const handleChange = (panel) => (event, newExpanded) => {
    // console.log('SUNNY handleChange panel = %s newExpanded = %s', panel, newExpanded);
    setExpanded(panel);
    // setExpanded(newExpanded ? panel : false);
  };

  const handleViewDetails = () => {
    setViewDetails(true);
  };

  const handleBackTest = () => {
    setViewDetails(false);
  };

  const getTableDataByPortfolioId = (object, portfolioId) => {
    const tableData = object.filter((obj) => {
      return obj.backtestPortfolioId === portfolioId;
    });
    // console.log('SUNNY getTableDataByPortfolioId tableData = %o', tableData);
    return tableData[0];
  };

  const getDataByPortfolioId = (object, portfolioId) => {
    const dataByPortfolioId = object.filter((obj) => {
      return obj.portfolioId === portfolioId;
    });
    console.debug(
      'portfolioId = %s dataByPortfolioId dataByPortfolioId = %o',
      portfolioId,
      dataByPortfolioId
    );
    return dataByPortfolioId[0]?.data;
  };

  const fetchStrategyBacktestInfo = (portfolioId, portfolioLength) => {
    getStrategyBacktestInfo(portfolioId)
      .then((response) => {
        console.debug('fetchStrategyBacktestInfo response = %o', response);
        const data = response?.data.data ?? [];
        backtestChartData.push({ portfolioId, data });
        if (backtestChartData.length === portfolioLength) {
          setBacktestChart(backtestChartData);
          setProgress(false);
        }
      })
      .catch((error) => {
        setProgress(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (strategyData.backtestReports) {
      let backtestPortfolioIds: any[] = [],
        periods: any[] = [];

      strategyData.backtestReports.forEach((r: any) => {
        if (r.backtestPortfolioId) {
          backtestPortfolioIds.push(r.backtestPortfolioId);
          const portfolioId = r.backtestPortfolioId;
          const period = `${r.beginTime.substr(0, 10)} ~ ${r.endTime.substr(0, 10)}`;
          periods.push({ portfolioId, period });
        }
      });
      setPortfolioPeriod(periods);
      if (backtestPortfolioIds.length > 0) {
        backtestPortfolioIds.forEach((backtestPortfolioId: any) => {
          fetchStrategyBacktestInfo(backtestPortfolioId, backtestPortfolioIds.length);
        });
      }
    }
  }, []);

  return (
    <div>
      {viewDetails ? (
        <div>
          <Button onClick={handleBackTest} color="info" className={classes.label}>
            Back to BackTest
          </Button>
        </div>
      ) : (
        <div>
          {progress ? (
            <div className={classes.circularProgressDiv}>
              <CircularProgress className={classes.circularProgressTop} />
              <div className={classes.waitingMsg}>Please wait...</div>
            </div>
          ) : (
            <div>
              {portfolioPeriod.map((item, index) => (
                <Accordion
                  square
                  expanded={expanded ? expanded === `panel${index}` : true}
                  // expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                >
                  <AccordionSummary
                    aria-controls={'panel' + index + 'd-content'}
                    id={'panel' + index + 'd-header'}
                  >
                    <Typography className={classes.accordionSummaryWidth}>
                      <div className={classes.period}>
                        <span className={classes.periodText}>Period：{item.period}</span>
                        {/*<Button onClick={handleViewDetails} color="info" className={classes.label}>*/}
                        {/*  View Details*/}
                        {/*</Button>*/}
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TrackingBacktestTableView
                          strategyType={strategyType}
                          trackItem={getTableDataByPortfolioId(
                            strategyData?.backtestReports,
                            item.portfolioId
                          )}
                          benchmarkSymbol={benchmarkSymbol}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        {backtestChart && (
                          <TrackingBacktestChartView
                            chartData={getDataByPortfolioId(backtestChart, item.portfolioId)}
                            viewTitle={false}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

StrategyBacktestView.propTypes = {
  strategyData: PropTypes.object,
  benchmarkSymbol: PropTypes.string,
};
