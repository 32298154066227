import React from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-ui/core/Card';
import SortIcon from '@material-ui/icons/ArrowDownward';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

const styles = {
  tablePadding: {
    paddingRight: 20,
    paddingLeft: 20,
  },
  noWarp: {
    whiteSpace: 'nowrap',
  },
};

const useStyles = makeStyles(styles);

export default function PortfolioMonitorPositionsView(props) {
  const classes = useStyles();
  const { positionData } = props;

  const columns = [
    {
      name: 'Symbol',
      selector: 'symbol',
      sortable: true,
      width: '10%',
    },
    {
      name: 'Market Price',
      selector: 'marketPrice',
      sortable: true,
      right: true,
      className: classes.noWarp,
      width: '15%',
    },
    {
      name: 'Cost Basis',
      selector: 'costBasis',
      sortable: true,
      right: true,
      className: classes.noWarp,
      width: '15%',
    },
    {
      name: 'Position',
      selector: 'position',
      sortable: true,
      right: true,
      width: '15%',
    },
    {
      name: 'Position Value',
      selector: 'positionValue',
      sortable: true,
      right: true,
      width: '15%',
    },
    {
      name: 'Position Percentage',
      selector: 'positionPercentage',
      sortable: true,
      right: true,
      cell: (row) => {
        var percentage = row.positionPercentage? row.positionPercentage +'%':'--';
        return (
            <div>
              {percentage}
            </div>
        );
      },
      width: '15%',
    },
    {
      name: 'Profit/Loss',
      selector: 'profitLoss',
      sortable: true,
      right: true,
      width: '15%',
    },
  ];

  return (
    <div>
      <Card>
        <div className={classes.tablePadding}>
          <DataTable
            title="Positions"
            data={positionData}
            columns={columns}
            fixedHeaderScrollHeight="50vh"
            fixedHeader
            highlightOnHover
            striped
            defaultSortFieldId={1}
            sortIcon={<SortIcon />}
            // responsive={true}
          />
        </div>
      </Card>
    </div>
  );
}

PortfolioMonitorPositionsView.propTypes = {
  positionData: PropTypes.array,
};
