import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import { defaultFont, grayColor } from '../../assets/jss/material-dashboard-react';
import TextField from '@material-ui/core/TextField';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import E from 'wangeditor';
import $ from 'jquery';

const styles = {
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + ' !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
  },
  selectInput: {
    width: '100%',
    marginTop: 20,
  },
  dialogActionCenter: {
    margin: 'auto',
  },
};
const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: 'none' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

let editor = null;

export default function NewEditIssueEditorDialog(props) {
  const theme = useTheme();
  const newIssueId = 'newIssueId';
  const newIssueEditorId = 'newIssueEditorId';
  const classes = useStyles();
  const { forumType, topicItem } = props;

  const [values, setValues] = React.useState({
    type: topicItem ? topicItem.type_id : forumType.length > 0 ? forumType[0].id : '',
    subject: topicItem ? topicItem.title : null,
  });
  const [errMsg, setErrMsg] = React.useState(false);

  const [content, setContent] = React.useState('');
  const fullScreen = true; //useMediaQuery(theme.breakpoints.down('md'));
  const subjectError = values.subject === '';
  console.debug(
    '#### New Issue Editor subjectError = %s values.type = %s',
    subjectError,
    values.subject
  );

  React.useEffect(() => {
    // 注：class写法需要在componentDidMount 创建编辑器
    const timer = setTimeout(() => {
      editor = new E('#' + newIssueEditorId);
      editor.config.onchange = (newHtml) => {
        setContent(newHtml);
      };
      /**一定要创建 */
      editor.create();
      $('#' + newIssueEditorId + ' .w-e-text-container').height(150);
      const data = $('.w-e-menu').data('title');
      // edit
      if (topicItem) {
        editor.txt.html(topicItem.content);
      }

      return () => {
        // 组件销毁时销毁编辑器  注：class写法需要在componentWillUnmount中调用
        editor.destroy();
      };
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  // 获取html方法1
  function getHtml() {
    alert(content);
  }

  // 获取html方法2
  function getHtml1() {
    alert(editor.txt.html());
  }

  // 获取text
  function getText() {
    alert(editor.txt.text());
  }

  const handleChange = (name) => (event) => {
    setErrMsg(false);
    setValues({ ...values, [name]: event.target.value });
  };

  const onClickNewIssue = () => {
    const op = topicItem ? 'update' : 'add';
    props.onNewIssueEditor(content, values.type, values.subject, op, topicItem?.topic_id);
  };

  const dialogTitle = topicItem ? 'Modify Issue Subject' : 'New Issue Subject';

  return (
    <div id={newIssueId}>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
        style={{
          paddingLeft: 80,
          paddingRight: 80,
          paddingTop: 50,
          paddingBottom: 50,
          height: 650,
        }}
      >
        {/*<DialogTitle*/}
        {/*  style={{ width: '100%' }}*/}
        {/*  id="responsive-dialog-title"*/}
        {/*  onClose={props.onClose}*/}
        {/*>*/}
        <DialogTitle style={{ maxWidth: 1050 }} id="responsive-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>
          {/*<DialogContentText>*/}
          {/*  <div>{issue}</div>*/}
          {/*</DialogContentText>*/}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <span className="subject-title">Title</span>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={3} sm={3} md={3}>
              <FormControl className={classes.selectInput}>
                <Select
                  labelId="sort-select-label"
                  id="sort-status-select"
                  value={values.type}
                  onChange={handleChange('type')}
                >
                  {forumType.map((item) =>
                    item.isOpened ? (
                      <MenuItem key={item.id} value={item.id}>
                        {/*{console.log('###item = %o', item)}*/}
                        {item.name}
                      </MenuItem>
                    ) : null
                  )}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={9} sm={9} md={9}>
              <TextField
                autoFocus
                margin="dense"
                id="issueSubject"
                label="Issue Subject"
                required
                value={values.subject}
                onChange={handleChange('subject')}
                error={subjectError}
                helperText={subjectError ? 'This field is required' : ' '}
                fullWidth
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <span className="subject-title">Content</span>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div id={newIssueEditorId} style={{ marginTop: 10, marginBottom: 20 }}></div>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActionCenter}>
          <Button
            disabled={!values.subject || subjectError}
            onClick={onClickNewIssue}
            color="primary"
          >
            Submit
          </Button>
          <Button autoFocus onClick={props.onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

NewEditIssueEditorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onNewIssueEditor: PropTypes.func,
  forumType: PropTypes.array,
  topicItem: PropTypes.object,
};
